import React, { useState } from "react";
import "./Faq.css";
import { FaChevronRight,FaChevronDown } from "react-icons/fa";
import { TfiDownload } from "react-icons/tfi";

const curriculum = [
  {
    sectionName: "What Will I Learn in this Stack Exploitation Course?",
    sectionDesc:
      "In this Stack Exploitation course, you will learn Assembly Language, reverse engineering, Buffer overflow, shellcoding, format strings, return oriented programming, Sigreturn oriented programming, Ret2csu, PIE bypass, bypassing NX, and more.",
  },
  {
    sectionName: "For whom is this Stack Exploitation Course designed?",
    sectionDesc:
      "Our Stack Exploitation course is designed for students, working professionals, and anyone interested in advancing their knowledge and skills in cybersecurity and exploit development.",
  },
  {
    sectionName: "How long does it take to complete the StackXploit Cyber Security Online Course?",
    sectionDesc:
      "Our StackXploit Cyber Security Online Course  is offered with a minimum duration of 2 months. However, participants have the option to extend the program if needed, ensuring flexibility in achieving learning objectives.",
  },
  {
    sectionName: "Will solutions to the practice challenges be provided in the StackXploit Cyber Security course?",
    sectionDesc:
      "In Our StackXploit course Solutions will only be provided for course challenges, not for practice challenges.",
  },
  {
    sectionName: "Will I Receive a Certificate Upon Completion of this Stack Exploitaion Course?",
    sectionDesc:
      "Yes, upon finishing our Stack Exploitation Course, you'll receive a certificate indicating your completion. However, this certificate is not the professional Stack Exploitaion certification. The professional certification can be earned after successfully passing the 48-hour certification exam.",
  },
  {
    sectionName: "Is there an exam associated with the StackXploit Cyber Security course?",
    sectionDesc:
      " Yes, there is a 48-hour exam that you need to appear within one month after your subscription to this cyber security course ends. The exam tests your knowledge and practical skills in Stack Exploitation. To pass the exam, you must score at least 70% marks.",
  },
  {
    sectionName: "Do I need prior knowledge for the StackXploit (Stack Exploitation Course)?",
    sectionDesc:
      "Yes, having prior knowledge is beneficial for the StackXploit course. It is recommended that participants possess an understanding of the C Programming Language, knowledge of Python Scripting, a basic understanding of Assembly Language, and a working knowledge of the Linux Operating System. These foundational skills will help learners grasp the concepts and techniques covered in the course more effectively, enabling them to engage with the material and practical exercises with greater confidence.",
  },
  {
    sectionName: "How does Hackersprey StackExploit course differentiate itself from other cybersecurity courses?",
    sectionDesc:
      "StackExploit stands out by focusing specifically on buffer overflow, binary exploitation, and stack buffer overflow, providing in-depth knowledge and practical skills in these critical areas of cybersecurity.",
  },
  {
    sectionName: "How does the StackXploit Cyber Security Online Certification contribute to career advancement?",
    sectionDesc:
      "This StackXploit certification boosts career prospects by validating proficiency in key cybersecurity areas like buffer overflow and binary exploitation, making individuals more attractive to employers and opening doors to advanced job opportunities and higher salaries in the cybersecurity field.",
  },
  
];

const StackXploitFAQs = () => {
  const [selectedState, setSelectedState] = useState("");
  const handleClick=(index)=>{
if(index===selectedState){
    setSelectedState("")
}
else{
    setSelectedState(index)
}
}
  
  return (
    <div className="curr-container">
      <div className="course-cur-head">
      
        <div className="bg-faq">
        <div className="course-cur-head margin-class"><p>StackXploit FAQ's</p></div>
       
        <div className=" row justify-content-center">
            <hr className="binaryhr2"></hr>
          </div>
        
          <main className="faq-container">
            {curriculum.map((section, index) => (
              <div className="section-faq-container">
                <div
                  onClick={() => handleClick(index)}
                  className="section-faq-name"
                >
                  {section.sectionName}
                  {selectedState === index   ? <FaChevronDown className="icon" size={13} />:  <FaChevronRight className="icon" size={13} />
}</div>
                {selectedState === index  ? (
                  <p className="section-faq-description">{section.sectionDesc}</p>
                ) : null}
              </div>
            ))}
          </main>
        </div>
      
      </div>
     
    </div>
  );
};

export default StackXploitFAQs;
