import React, { useState, useEffect } from 'react'
import "./challengeui.css"
import Congrats from "../../../Assets/MASCOT/Mascot SVG/Asset 29.png"

import { ImCross, ImPushpin } from "react-icons/im";
import { useNavigate } from 'react-router-dom';

import Quitchallenge from './Quitchallenge';
const Challengeui = ({ setOpenModal, ip }) => {
  const [modalopen, setmodalopen] = useState(false)
  const [modalopen2, setmodalopen2] = useState(false);
  const [ipshow, setipshow] = useState(false);
  const [victiminstance, setvictiminstance] = useState("");
  const [accatkerinstance, setaccatkerinstance] = useState("");
  const [victimip, setvictimip] = useState("");
  const [attackerip, setattackerip] = useState("");
  const navigate = useNavigate();
  const startchal = () => {
    const isValidUrl = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i.test(ip);

    if (isValidUrl) {
      window.open(ip);
    } else {
      // Assuming ip is in the format "9000" or similar
      setipshow(true);
    }
  }
  const API_Key = process.env.REACT_APP_API_URL;
  useEffect(() => {
    fetch(`${API_Key}/allintanceuser`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        mode: "cors",
        'Access-Control-Allow-Origin': `${API_Key}`,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': true
      },
      credentials: "include",
    })
      .then(async (res) => {
        const data = await res.json();
        setvictiminstance(data.message.victim);
        setaccatkerinstance(data.message.attacker);

        // Call /check_info_instance endpoint here
        return fetch(`${API_Key}/check_info_instance`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            mode: "cors",
            'Access-Control-Allow-Origin': `${API_Key}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': true
          },
          credentials: "include",
        });
      })
      .then(async (instanceInfoRes) => {
        const instanceInfo = await instanceInfoRes.json();

        const victimPrivateIp = instanceInfo.message[0][victiminstance].PrivateIpAddress;
        const attackerPrivateIp = instanceInfo.message[1][accatkerinstance].PrivateIpAddress;
        setvictimip(victimPrivateIp);
        setattackerip(attackerPrivateIp);
        console.log(victimPrivateIp, "victim");
        console.log(attackerPrivateIp, "attacker");
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, [API_Key, victiminstance, accatkerinstance]);

  return (
    <div>
      {modalopen2 && <Quitchallenge setOpenModal={setmodalopen2} />}


      <section className="modalBackground justify-content-center" >
        <div className="container  justify-content-center">

          <div className="row justify-content-center ">

            <div className="col-lg-8 col-md-10 col-10 p-1 modalContainer" >


              <div className='row p-5'>

                <div className='col-lg-4 col-md-5 col-4  '>

                  <div className='justify-content-center pl-5'><img src={Congrats} style={{ height: "45vh" }} alt="sorry" /></div>
                </div>
                <div className='col-lg-8 col-md-5 col-8   '>
                  <div className='mt-2 crossbtnarrow'>
                    <button
                      onClick={() => {
                        setOpenModal(false);
                      }}
                    >
                      <div className='text-center'><ImCross style={{ height: ".6rem", marginLeft: "-1.9rem", marginTop: ".5rem" }} /></div>
                    </button>
                  </div>
                  <div className='justify-content-center'><h2 className='text-center alertmainhead2'> {ipshow ? "Challenge Started!!" : "Start  Challenge!!"} <div className='row justify-content-center'><span><hr className='text-center alertcommonhr'></hr></span></div></h2>
                  </div>
                  {ipshow ? null : <p className='resetpara'>Please click on button  to start your challenge. </p>}


                  <br></br>
                  {ipshow ? <div className='ipdiv'>
                    <p >VICTIM IP & PORT  <span>{victimip}: {ip}</span>  </p>
                    <p >ATTACKER IP & PORT  <span>{attackerip}: 5901</span>  </p>
                    <p >Password For RealVNC  <span> stackxploit</span>  </p>
                  </div> : null}

                  {ipshow ? <div className='row justify-content-center'>
                    {/*<Link to={ip} target="_blank" rel="noopener noreferrer" > <button className=' btn saveratebtn' > START LAB</button>
       </Link>*/}
                    <button className=' btn saveratebtn' disabled> START CHALLENGE!!</button>
                  </div> : <div className='row justify-content-center'>
                    {/*<Link to={ip} target="_blank" rel="noopener noreferrer" > <button className=' btn saveratebtn' > START LAB</button>
       </Link>*/}
                    <button className=' btn saveratebtn' onClick={startchal}> START CHALLENGE!!</button>
                  </div>}

                </div></div>





            </div>
          </div>
        </div>

      </section>
    </div>


  )
}
export default Challengeui