import React from 'react';
import { GrFormCheckmark } from "react-icons/gr";
const benefits=[
   "Cybersecurity Analyst",
   "Security Operations Center (SOC) Analyst",
   "Incident Responder",
   "Ethical Hacker/Penetration Tester",
   "Security Consultant",
   "Information Security Analyst"


]

const SummerTrainingOverview = () => {
  return (
    <div className='training-highlight-section2'>
    <div className='training-highlight-section-left'> 
    <h3 className='training-heading'>Overview Of Our 6 Weeks Summer Training In Cybersecurity</h3>
    <p className='training-desc'>This 6 Weeks Summer Training In Cybersecurity is ideal for freshers and professionals who want to learn and start a career in cyber security  & ethical hacking domain. By enrolling in this summer internship in cyber security, you will gain valuable skills and knowledge that will enhance your resume and propel your career forward in your desired industry. The training provided by Hackersprey is of the highest quality, ensuring that you receive the best possible education to achieve your professional goals.
</p>
    </div>
    <div className='training-highlight-section-right'> 
    <p  className='hero-subheading margin-bottom'>Job Roles You Can Apply After Completing This Training</p>
    {benefits.map((x)=>(
<div className="display-flex ">
    
<div className="lab-desc-list-icon-wrapper">
<GrFormCheckmark className="lab-desc-list-icon" />
</div>
<p className="training-list">{x}</p>
</div>  
    ))}
   
           
    </div>

</div>
  )
}

export default SummerTrainingOverview
