import React,{useEffect,useState} from 'react'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'
import email1 from "../../Assets/Dashboard/Mails/Screenshot 2023-06-12 at 9.30.18 AM.png"

import {Faqs4} from "../Home/Homedata";
import {AiOutlinePlusCircle,AiOutlineMinusCircle} from "react-icons/ai"
import Privacypic from "../../Assets/Entity/Course/1657192139607webdrub.png"
const Webdrub = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
      const [clicked, setClicked] = useState(false);
      const toggle = index => {
          if (clicked === index) {
            //if clicked question is already active, then close it
            return setClicked(null);
          }
      
          setClicked(index);
        };
  return (
    <div>
    <section className="Dynamicbackground">
   

    <div className="container mt-5">
   
    <div class="Heading-main">
  <span>WEBDRUB</span>
  <h2>WEBDRUB</h2>
  <div className="row justify-content-center">
  <hr className="justify-content-center"></hr>

</div>
</div>
    </div>
   
</section>
<section className="Privacydiv">
<div className="container-fluid">
    <div className="row">
        <div className="col-lg-6 col-md-10 col-12 offset-md-1 order-2 order-lg-1 mt-5">
        <p className="privacypara">Welcome to WebDrub, your gateway to the thrilling world of web hacking challenges. We present you with a unique opportunity to sharpen your skills and test your mettle with eight mind-bending practice challenges, all completely free of cost. Are you ready to crack the Tough Nut lab?

</p>
<p className="privacypara">Tough Nut lab, a prestigious segment of the WebDrub program, awaits your conquest. Within its digital confines lie intricate vulnerabilities, designed to push the boundaries of your web pentesting prowess. Tackle each challenge head-on, employing your knowledge, intuition, and problem-solving abilities to unveil the hidden weaknesses lurking within.</p>
        </div>
        <div className="col-lg-3 col-md-10 col-12 offset-1  justify-content-center order-1 order-lg-2 mt-5">
        <img src={Privacypic} style={{height:"50vh"}}  className="img-fluid logoimage"   alt="Privacy_pic" />
        </div>
    </div>
</div>
<div className="container-fluid">
<div className="row d-flex flex-column justify-content-center">
    <div className="col-lg-10 col-md-10 col-12 offset-md-1 mt-2">


<p className='privacypara'>But that's not all. The rewards for conquering the Tough Nut lab are beyond measure. Upon successfully clearing the lab, you will be granted the esteemed title of <b>HackersPrey Certified Web Pentester</b>. This exclusive certification will showcase your exceptional skills, granting you recognition among the elite community of web pentesters. To earn this free certification, students must submit a detailed pentest report and share it with us at <b>certification@hackersprey.com</b> . Our team will verify it and will get back to you.</p>

<br>

</br>
<p className='privacypara'>Don't miss out on this extraordinary opportunity to gain hands-on experience, hone your hacking skills, and earn a prestigious certification – all without spending a dime. Join WebDrub today and embark on a journey that will challenge, inspire, and elevate your web hacking abilities. The path to becoming a certified web pentester starts here. Are you up for the challenge?</p>
<br>

</br>
<br>

</br>
<div className='container mt-5'>
        <div className='row'>
        <div className='col-lg-12 col-md-12 col-12 mt-5 intershipdetaildiv2'>
       <div className='row justify-content-center' style={{position: "relative"}}>
       <h2 className='justify-content-center mainfaqhead'>FAQ'S</h2>
      
      <div className='row mt-5'>   <hr className='faqhr'></hr></div>
    

       
       </div>
       <div className='row justify-content-center '>
       <div className='col-lg-10 col-md-10 col mt-3'>
       <p className='mainfaqpara'>Please note that these FAQs provide general information about the WEBDRUB program. For more detailed information and specific queries, it is recommended to reach out to us at <img src={email1} alt="email" style={{height:"30px",marginLeft:".3rem",marginTop:"-1px"}} /></p>
       </div>
      
       </div>
       
       
       <div className='row justify-content-center mt-5'>
       <div className='col-lg-10 col-md-10 col-10' >
    {Faqs4.map((faq,index)=>(
      <div className='row mt-5 mb-5'  key={index} id='faqcards'>
    
      <div className='col-lg-10 col-md-10 col-8'  onClick={() => toggle(index)} key={index} style={{cursor:"pointer"}}>
      <h2 className='faqcardshead'>{faq.question}</h2>
      </div>
      <div className='col-lg-1 col-md-1 col-1 offset-1'  onClick={() => toggle(index)} key={index} style={{cursor:"pointer"}}>
      {clicked===index ?  <AiOutlineMinusCircle color='#e32f1c' size={30} />  :<AiOutlinePlusCircle  color='#e32f1c' size={30} /> }
    
      </div>
      {clicked === index ? (
        <> <div className="container mt-1 p-3">
      <div className='row'>
      <div className='col-lg-11 col-md-11 col-11 '>
      <p className='faqansercard'>{faq.answer}</p>
      </div>
      </div>
      </div>
        
      
        
       
       
        </>  ) : null}
    
      </div>
    
      ))} 
      
  
      
       </div>
       </div>
        </div>
        </div>
        </div>



<br>

</br>
<br>

</br>
<br>

</br>




    </div>
    </div></div>

</section>
<Footer/>
    </div>
  )
}

export default Webdrub