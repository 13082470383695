import React, { useState,useEffect } from 'react'
import Footer from '../Footer/Footer'
import pic1 from "../../Assets/Dashboard/walongctf.png"
import { FaFlag } from "react-icons/fa";
import { GiDuration } from "react-icons/gi";
import { FaLocationDot } from "react-icons/fa6";
import { BsMicrosoftTeams } from "react-icons/bs";
import { FaTrophy } from "react-icons/fa";
import { FaUsersBetweenLines } from "react-icons/fa6";
import { VscGraph } from "react-icons/vsc";
import { useNavigate,useParams } from 'react-router-dom';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import gold from "../../Assets/Events/Gold.png";
import silver from "../../Assets/Events/silver.png";
import bronze from "../../Assets/Events/Bronze.png";

import moment from 'moment';
const Eventsdetails = () => {
const[event,setevent]=useState([]);
const params=useParams()
const API_Key = process.env.REACT_APP_API_URL;
const geteventdesc = async () => {

   try{
    let event_id = params.event_id;
    console.log(event_id,"eventid")
    {/*const response = await fetch(`${API_Key}/getchallenge/` + blogid);
    setchallenge(await response.json());
    if(response.status===200){
      setModalOpen4(false);
    } */}
    const res = await fetch(`${API_Key}/getWebinar/` +event_id,{
      method:"GET",
      headers:{
        mode: 'cors',
        'Access-Control-Allow-Origin':`${API_Key}`,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': true,
      },
      credentials:"include"
    
     
  });
  const data = await res.json();
 
  setevent(data);
  
}catch(err){
  console.log(err);
  

} 
  };
  useEffect(() => {
    
    geteventdesc();

  }, []);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
   <>
   <section className='eventbackground'>
   <div className='container'>
   <div className='row justify-content-center '>
   <div className='col-lg-12 col-md-12 col-12 mt-5 eventallsections'>

<div className='row justify-content-center' >
<div className='col-lg-12' id="checkphoto">
<div className='row'>
<h1 className='eventdetailshead'>{event.title}</h1>
</div>
<div className='row justify-content-center'>
<div className='col-lg-4 '>
<div className='row'><h2 className='eventdetailsdatehead'>Start Date</h2></div>
<div className='row'><h2 className='eventdetailsdatehead2'>{moment(event.startDate, 'YYYY-MM-DD').format('DD/MM/YY')}</h2></div>

</div>


<div className='col-lg-4'>
<div className='row'><h2 className='eventdetailsdatehead'>End Date</h2></div>
<div className='row'><h2 className='eventdetailsdatehead2'>{moment(event.endDate, 'YYYY-MM-DD').format('DD/MM/YY')}</h2></div>

</div>

<div className='col-lg-4'>
<div className='row'><h2 className='eventdetailsdatehead'>Total Users</h2></div>
<div className='row'><h2 className='eventdetailsdatehead2'>{event.playersjoined}</h2></div>

</div>
</div>

</div>



</div>
   </div>
   </div>
   </div>
   
   </section>
   <div className='container mt-5'>
   <div className='row'>
   <div className='col-lg-5 '>
   <div className='row'>
   <img src={event.cover_image} alt="jii" style={{borderRadius:"0rem"}} />
   </div>
   <h2 className='overvirehead'>Event OverView</h2>
   <p className='eventparagraph2'>{event.description}</p>
   
   </div>
<div className='col-lg-1'></div>





   <div className='col-lg-6 ' id='eventdtaildiv'> 
   <div className='row '>
   <div className='col-lg-3 col' id="smallcards">
   <div className='row justify-content-center mt-4'>
   <FaFlag size={22} style={{margintop:"1rem"}}/>
   </div>
   <div className='row justify-content-center'>
   <h2 className='smallcardhead'>Event Type</h2>
   </div>
   
   <div className='row justify-content-center'>
   <h2 className='smallcardhead2'>{event.type}</h2>
   </div>
   </div>


   <div className='col-lg-3 col' id="smallcards">
   <div className='row justify-content-center mt-4'>
   <GiDuration size={22} style={{margintop:"1rem"}}/>
   </div>
   <div className='row justify-content-center'>
   <h2 className='smallcardhead'>Duration</h2>
   </div>
   
   <div className='row justify-content-center'>
   <h2 className='smallcardhead2'>{event.duration} hours</h2>
   </div>
   </div>



   <div className='col-lg-3 col' id="smallcards">
   <div className='row justify-content-center mt-4'>
   <FaLocationDot size={22} style={{margintop:"1rem"}}/>
   </div>
   <div className='row justify-content-center'>
   <h2 className='smallcardhead'>Location</h2>
   </div>
   
   <div className='row justify-content-center'>
   <h2 className='smallcardhead2'>India</h2>
   </div>
   </div>
   </div>



   <div className='row '>
   <div className='col-lg-3 col' id="smallcards">
   <div className='row justify-content-center mt-4'>
   <BsMicrosoftTeams size={22} style={{margintop:"1rem"}}/>
   </div>
   <div className='row justify-content-center'>
   <h2 className='smallcardhead'> Total Teams</h2>
   </div>
   
   <div className='row justify-content-center'>
   <h2 className='smallcardhead2'>{event.totalteams}</h2>
   </div>
   </div>


   <div className='col-lg-3 col' id="smallcards">
   <div className='row justify-content-center mt-4'>
   <FaUsersBetweenLines size={22} style={{margintop:"1rem"}}/>
   </div>
   <div className='row justify-content-center'>
   <h2 className='smallcardhead'>Team Size</h2>
   </div>
   
   <div className='row justify-content-center'>
   <h2 className='smallcardhead2'>{event.teamSize} </h2>
   </div>
   </div>



   <div className='col-lg-3 col' id="smallcards">
   <div className='row justify-content-center mt-4'>
   <FaTrophy size={22} style={{margintop:"1rem"}}/>
   </div>
   <div className='row justify-content-center'>
   <h2 className='smallcardhead'>Prize</h2>
   </div>
   
   <div className='row justify-content-center'>
   <h2 className='smallcardhead2'>{event.prizePool}</h2>
   </div>
   </div>
   </div>
   <div className='row '>
   <div className='col-lg-11 col' id="smallcards">
   <div className='row justify-content-center mt-4'>
   <VscGraph size={22} style={{margintop:"1rem"}}/>
   </div>
   <div className='row justify-content-center'>
   <h2 className='smallcardhead'> Difficulty</h2>
   </div>
   
   <div className='row justify-content-center'>
   <h2 className='smallcardhead2'>Easy To Medium</h2>
   </div>
   </div>





  
   </div>


   <div className='row '>
   <div className='col-lg-11 col' id="smallcards">
   <div className='row justify-content-center mt-4'>
   <VscGraph size={22} style={{margintop:"1rem"}}/>
   </div>
   <div className='row justify-content-center'>
   <h2 className='smallcardhead'> Venue</h2>
   </div>
   
   <div className='row justify-content-center'>
   <h2 className='smallcardhead2'>{event.venue}</h2>
   </div>
   </div>





  
   </div>
   
   </div>
   </div>
   </div>

   <div className='container mt-5'>
   <div className='row justify-content-center'>
     {event?.teamWinner && event?.teamWinner?.length > 0 && (
       <div className='col-lg-12 col-md-12 col-12 mt-5 '>
         <div className='row justify-content-center'>
           <div className='col-lg-12 eventallsections'>
             <div className='row justify-content-center'>
               <h1 className='eventdetailshead3 text-center'>Top 3 Winner List</h1>
             </div>
             <div className='row justify-content-center'>
               {event.teamWinner.map((teamwin, index) => (
                 <div className='col-lg-3' id='winnercard' key={index}>
                   <div id='winnercardinner'>
                     <div className='row justify-content-center'>
                       {teamwin.position === '1' && (
                         <div className='col-lg-6 col-md-6 col mb-3'>
                           <img src={gold} alt="Gold Medal" />
                         </div>
                       )}
                       {teamwin.position === '2' && (
                         <div className='col-lg-6 col-md-6 col mb-3'>
                           <img src={silver} alt="Silver Medal" />
                         </div>
                       )}
                       {teamwin.position === '3' && (
                         <div className='col-lg-6 col-md-6 col mb-3'>
                           <img src={bronze} alt="Bronze Medal" />
                         </div>
                       )}
                     </div>
                     <div className='row justify-content-center'>
                       <h2 className='winnercardhead'>{teamwin.teamName}</h2>
                     </div>
                     <div className='row justify-content-center mt-2 mb-2'>
                       <div className='col-lg-5 col-md-5'>
                         <p className='winnercardpara'>{teamwin.score} pts</p>
                       </div>
                       <div className='col-lg-6 col-md-6'>
                         <p className='winnercardpara'>{teamwin.members} Members</p>
                       </div>
                     </div>
                     <div className='row justify-content-center mt-4 mb-5'>
                       <h2 className='winnercardhead'>{teamwin.adminname}</h2>
                     </div>
                   </div>
                 </div>
               ))}
             </div>
           </div>
         </div>
       </div>
     )}
   </div>
</div>

   <div className='container mt-5'>
   {event?.gallery && event?.gallery?.length > 0 && (
     <>
       <br></br>  <br></br>
       <h1 className='eventdetailshead3 text-center mt-5'>Gallery Section</h1>
       <br></br>  <br></br>
       <ResponsiveMasonry
         columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
       >
         <Masonry gutter="20px">
           {event.gallery.map((photo) => (
             <div key={photo._id} className="gallery-item">
               <img src={photo.photo} alt={`Event Photo ${photo._id}`} />
             </div>
           ))}
         </Masonry>
       </ResponsiveMasonry>
     </>
   )}
</div>


   <Footer/>
   </>
  )
}

export default Eventsdetails