import React, { useState } from 'react'
import Congrats from "../../../../Assets/MASCOT/Mascot SVG/5.png"

import {FaArrowRight} from "react-icons/fa";
import {ImCross} from "react-icons/im";
import { NavLink,useNavigate } from 'react-router-dom';
import Loading from '../Loading';
import Teamdeletedsuccess from './Teamdeletedsuccess';
const Deleteteams = ({setOpenModal}) => {
  const API_Key = process.env.REACT_APP_API_URL;
  const[modal2,setopenmodal2]=useState(false);
  const[modal3,setopenmodal3]=useState(false);
  const delteteam = ()=>{
    setopenmodal3(true);
   
 fetch(`${API_Key}/delete_team`, {
      method: 'POST',
      headers:{
        Accept: "application/json",
        mode: 'cors',
        'Access-Control-Allow-Origin':`${API_Key}`,
      'Content-type': 'application/json',
        
        'Access-Control-Allow-Credentials': true,
      },
      credentials:"include",
     
    }).then((res) => {
if(res.status===200){
  setopenmodal2(false);
  setopenmodal3(true);
  setOpenModal(false);
  
window.location.reload(true);
  setTimeout(() => {
    setopenmodal3(false);
  }, 3000);
}

    })
    .catch((error) => {
    console.log(error);
    
    
    
    });
  }
  return (
    <div> 
    {modal2 && (<Loading setOpenModal={setopenmodal2}/>)}
    {modal3 && (<Teamdeletedsuccess setOpenModal={setopenmodal3}/>)}
    <section className="modalBackground justify-content-center">
    <div className="container  justify-content-center">
    
      <div className="row justify-content-center ">
      
        <div className="col-lg-8 col-md-10 col-10 p-1 modalContainer" >
        
      
          <div className='row p-5'>
         
          <div className='col-lg-4 col-md-5 col-4  '>
         
          <div className='justify-content-center pl-5'><img src={Congrats} style={{height:"45vh"}} alt="sorry"/></div>
          </div>
          <div className='col-lg-8 col-md-5 col-8   '>
          <div className='mt-2 crossbtnarrow'>
          <button
            onClick={() => {
              setOpenModal(false);
            }}
          >
          <div className='text-center'><ImCross style={{height:".6rem",marginLeft:"-1.9rem",marginTop:".5rem"}}/></div> 
          </button>
        </div>
         <div className='justify-content-center'><h2 className='text-center alertmainhead2'>Confirm Team Deletion<div className='row justify-content-center'><span><hr className='text-center alertcommonhr'></hr></span></div></h2>  
         </div> 
         <p className='resetpara'>

         Are you sure you want to delete the team? This action cannot be undone. Please note that this action will delete your team's ranking and scores as well. </p>

         
         <div className='row justify-content-center'>
         <div className='col'><button className='btn savebtn' onClick={delteteam}>CONFIRM</button></div>
         <div className='col'><button className='btn savebtn' onClick={() => {setOpenModal(false);}}>CANCEL</button></div>
        
         </div>
          </div></div>
         
         
           
            
         
        </div>
      </div>
    </div>

  </section>
    </div>
  )
}

export default Deleteteams