import React,{useState,useEffect} from 'react'

import"./Profile.css";
import User from "../../../Assets/images/dp3.png";
import {AiFillCamera} from "react-icons/ai";
import {IoMdLock,IoIosArrowForward} from "react-icons/io";
import{RiArrowDropRightLine} from "react-icons/ri";
import { NavLink,useNavigate, } from 'react-router-dom';
import Loading from '../Modals/Loading';
import { useForm } from "react-hook-form";
import Passchanges from "../Modals/Passchanges";
import Currentpass from '../Modals/Currentpass';
import Wentwrong from '../Modals/Wentwrong';
import {FaEye, FaEyeSlash, FaFacebookF} from "react-icons/fa";
const API_Key = process.env.REACT_APP_API_URL;

const Changepassword = (props) => {
  const faeye=<FaEye/>;
  const faeye2=<FaEyeSlash/>
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown2, setPasswordShown2] = useState(false);
  const [passwordShown3, setPasswordShown3] = useState(false);
  const [errormsg,seterrormsg]=useState(false);
const [eye,seteye]=useState(faeye2);
const [eye2,seteye2]=useState(faeye2);
const [eye3,seteye3]=useState(faeye2);
const togglePasswordVisiblity = () => {
setPasswordShown(passwordShown ? false : true);
seteye(faeye);
};
const togglePasswordVisiblity2 = () => {
setPasswordShown2(passwordShown2 ? false : true);
seteye2(faeye);
};
const togglePasswordVisiblity3 = () => {
  setPasswordShown3(passwordShown3 ? false : true);
  seteye3(faeye);
  };
  const[modal,setopenmodal]=useState(false);
  const[modal2,setopenmodal2]=useState(false);
  const[modal3,setopenmodal3]=useState(false);
  const[modal4,setopenmodal4]=useState(false);
  const navigate=useNavigate();
  const {register,handleSubmit,formState: { errors },reset,watch,trigger,} = useForm();
  const[user,setuser]=useState([]);
  const newpassword=watch("npassword")
  const passwords=watch("password");
  
  //const[password,setpassword]=useState("");
  const[npassword,setnpassword]=useState("");
  const[cnpassword,setcnpassword]=useState("");
  const onSubmit = (data) => {
    setopenmodal(true);
    //event.preventDefault();
    //const data = { password,npassword,cnpassword};
    reset({password:"",npassword:"",cnpassword:""})
    fetch(`${API_Key}/updatepassword`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        mode: 'cors',
        'Access-Control-Allow-Origin':`${API_Key}`,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': true,
      },
      credentials:"include",
      body: JSON.stringify(data),
    })
    //.then((response) => response.json())
    //Then with the data from the response in JSON...
    .then((response) => {
      setopenmodal(false);
      if(response.status===200){
        setopenmodal2(true);
        setTimeout(() => {
          navigate("/logout")
        }, 3000);
      }else if(response.status===202){
 setopenmodal3(true);
      }else if(response.status===403){
        seterrormsg("Choose a new password that doesn't match your current one")
      }
      setTimeout(() => {
        setopenmodal2(false);
      },3000);
      setTimeout(() => {
        setopenmodal3(false);
      },3000);
     
     const noti={message:"Your Password has been successfully changed. ",category:"Profile",querytype:"Profile update"}

    fetch(`${API_Key}/notification`, {
      method: 'POST',
      headers:{
        Accept: "application/json",
        mode: 'cors',
        'Access-Control-Allow-Origin':`${API_Key}`,
      'Content-type': 'application/json',
        
        'Access-Control-Allow-Credentials': true,
      },
      credentials:"include",
      body: JSON.stringify(noti),
    })
    .then((response) => {
 if(response.status === 200){
   //window.alert("saved noti")
 }

     })
     .catch((error)=>{
      console.error('Error:', error);
     })

      
    })
    //Then with the error genereted...
    .catch((error) => {
      console.error('Error:', error);
//window.alert("no chnages")
setopenmodal4(true);
setopenmodal(false);
setTimeout(() => {
  setopenmodal4(false);
},3000);
    });
  
  
  
  
  
  
  
  
  }
  const fetchuserdetails = async () => {
setopenmodal(true);

        
    try{
      const res = await fetch(`${API_Key}/profilephoto`,{
        method:"GET",
        headers:{
          mode: 'cors',
          'Access-Control-Allow-Origin':`${API_Key}`,
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': true,
        },
        credentials:"include"
         
      });
      const data = await res.json();
    
      setuser(data);
    
  
      if(res.status !== 200 ){
          const error = new Error(res.error);
          throw error;
          
      }else{
        setopenmodal(false);
      }
      
   
  
  
  }catch(err){
    console.log(err);
    navigate("/signin");
  
  } 
  
  }

  useEffect(() => {
    fetchuserdetails();
  },[ ] );

  return (
    <div>
    {modal && <Loading setOpenModal={setopenmodal}/>}
    {modal2 && <Passchanges setOpenModal={setopenmodal2}/>}
    {modal3 && <Currentpass setOpenModal={setopenmodal3} />}
    {modal4 && <Wentwrong setOpenModal={setopenmodal4} />}

    <div className='profile_dashboard' >
    <div className='profile_dashboard2'>
 </div> {/*main profile container start*/}
    <div className='container-fluid'>
    <div className='row ' >
    {/*image container of profile image */}
    <div className='col-lg-4 col-md-4 col-10 p-5 mt-5'>
    <img src={user.photo || User} alt="userimage" className='editprofileimage2' />
  
   <div className='changeprofilediv'>
   <NavLink to="/editprofile" style={{textDecoration:"none"}}> <div className='row'>
      <div className='col-lg-2 col-md-2 col-2 pl-lg-5'>
      <IoMdLock color='white' size={20}/>
      </div>
      <div className='col-lg-7 col-md-8 col-8'>
      <p className='editprofilepara'>Edit Profile</p>
      </div>
      <div className='col-lg-1 col-md-2 col-2 pl-lg-5 mt-2'>
    <div className='arrowbutton'> <RiArrowDropRightLine className='icon'/></div> 
      </div>
      </div></NavLink>
   </div>
    </div>
    {/* edit profile form */}
   
    
    <div className='col-lg-8 col-md-8 col-11 pl-lg-5  ' id='userprofile'>
      <h1  className='Editprofilehead'>Change Password <span><hr className='commonhr'></hr></span></h1>
      <form onSubmit={handleSubmit(onSubmit)} >
      <div className="form-input col-lg-12">
    
      <div className="col">
      <label>Current Password</label>
      <input
        name="password"
        type={passwordShown ? "text" : "password"}
        className={`form-control mb-1 ${errors.password && "invalid"}`}
        placeholder="Enter Current Password"
        autoComplete='off'
        {...register("password", { required: "Current Password is Required",
        pattern: {
          value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          //message: "Current Password Should Contain minimum 8 letters,1 Uppercase(A-Z),1 Special Character and 1 Numeric value(0-9)",
        }
       })}

       onKeyUp={() => {
        trigger("password");
      }}
       
      />

      {errors.password && (
        <small className="errormes ml-2">{errors.password.message}</small>
      )}
      <span>     <i className='currentpasswordeye' onClick={togglePasswordVisiblity}>{eye}</i> </span>
	                         
      </div>
      <div className="col">
      <label>New Password</label>
      <input
        name="npassword"
        type={passwordShown2 ? "text" : "password"}
        className={`form-control mb-1 ${errors.npassword && "invalid"}`}
        autoComplete='off'
        onChange={(e)=>setnpassword(e.target.value)}
        
        placeholder="Enter New Password"
        {...register("npassword", { required: "New Password is Required",
        pattern: {
          value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          message: "Current Password Should Contain minimum 8 letters,1 Uppercase(A-Z),1 Special Character and 1 Numeric value(0-9)",
        },
        minLength: {
          value: 8,
          message: "Password must have at least 8 characters"
        },maxLength:{
          value: 32,
          //message: "Password must have at least 16 characters"

        },
       
       })}

       onKeyUp={() => {
        trigger("npassword");
      }}
       
      />
      {errors.npassword && (
        <small className="errormes ml-2">{errors.npassword.message}</small>
      )}
      <div className='mt-2 mb-4'>
    
      <small className="errormes ml-3 mt-2 mb-3">{errormsg}</small></div>
      <span>     <i className='newpasswordeye' onClick={togglePasswordVisiblity2}>{eye2}</i> </span>
      </div>
   
      
      <div className="col">
      <label>Confirm Password</label>
      <input
        name="cnpassword"
        autoComplete='off'
        onChange={(e)=>setcnpassword(e.target.value)}
        type={passwordShown3 ? "text" : "password"}
        className={`form-control mb-1 ${errors.cnpassword && "invalid"}`}
        
        placeholder="Enter Confirm Password"
        {...register("cnpassword", { required: "Confirm password is Required",
                          pattern: {
                            value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                            message: "the Password does not match",
                          },
                          validate:(value)=> value===newpassword ||"the Password does not match",
                         
                         })}
                         onKeyUp={() => {
                          trigger("cnpassword");
                        }}
       
      />
      {errors.cnpassword && (
        <small className="errormes ml-2">{errors.cnpassword.message}</small>
      )}
      <span>     <i className='confirmpasswordeye' onClick={togglePasswordVisiblity3}>{eye3}</i> </span>
      </div>
      </div>
     
   
    
   
   
     
     
   
    <div className='row justify-content-center mt-5 mb-3'>
    <button className='btn savebtn'>SUBMIT</button></div>
  
      </form>
      <br></br>
    </div>
    
    </div>
  </div>
   </div>
    </div>
  )
}

export default (Changepassword)