import React, { useState, useEffect, useRef } from "react";
import "./SummerTrainingPage.css";
import { Helmet } from "react-helmet";
import SummerTrainingHeroSection from "./IndustrialTrainingHeroSection";
import TrainingHighlights from "./TrainingHighlights";
import TrainingCurriculum from "./TrainingCurriculum";
import SummerTrainingModal from "./SummerTrainingModal";
import { FaWhatsapp } from "react-icons/fa";
import SummerTrainingOverview from "./SummerTrainingOverview";
import WebinarCourseCard from "../Webinar/WebinarCourseCard";
import StackImg from "../../Assets/webinar/stackxploit.png";
import WebpwnImg from "../../Assets/webinar/webpwn.png";
import SummerTrainingCertificate from "./SummerTrainingCertificate";
import StackXploit2FAQs from "../Dyanmicpages/BinaryCurriculum2/StackXploit2FAQs";
import Footer from "../Footer/Footer";
import SummerTrainingIntro from "./SummerTrainingIntro";
import CourseHighlights from "./CourseHighlights";
import SummerTrainingTrainer from "./SummerTrainingTrainer";
import CourseAccess from "./CourseAccess";
import IndustrialTrainingHeroSection from "./IndustrialTrainingHeroSection";
import IndustrialTrainingBar from "./IndustrialTrainingBar";
import IndustrialBenefits from "./IndustrialBenefits";
import WhyHackersprey from "./WhyHackersprey";
import InternalLinking from "./InternalLinking";
import acwimg from '../../Assets/acw.png';
import logo from '../../Assets/hplogo.png'
const stackPoints = [
  "Hands-on experience in bypassing security mechanisms found in linux binaries",
  "Engage in self-paced learning with the StackXploit Cyber Security course",
  "Industry-standard curriculum",
  "Elevate your expertise in cyber security",
];
const webPwnPoints = [
  "A Professional Web Hacking Course",
  "Comprehensive coverage and practical exercises on all OWASP Top 10 vulnerabilities",
  "70+ Real World Hacking Challenges",
  "Access To PreyGround",
];
const acwpoints=[
  'Get Industry-Recognized Certification',
  'Access Enhanced Learning Resources.',
  'Follow a Cutting-Edge Curriculum.',
  'Tackle Real-World Hacking Challenges',
  'Utilize Practice Labs.',
  

]
const faq = [
  {
    sectionName:
      " What is the duration of the Cybersecurity Industrial Training in Chandigarh?",
    sectionDesc:
      "This Cybersecurity Industrial Training In Chandigarh is designed to last 6 months, offering an in-depth learning experience in cybersecurity.",
  },
  {
    sectionName:
      " Can I enroll in the 6 Months Industrial Training In Cybersecurity if I am a beginner?",
    sectionDesc:
      "Yes, our 6 Months Industrial Training in Cybersecurity is suitable for beginners. The course starts with basic concepts and gradually advances to more complex topics, ensuring a strong foundation for all participants.",
  },
  {
    sectionName:
      "Is the Cybersecurity course in Chandigarh offered online?",
    sectionDesc:
      "Absolutely! We offer Cybersecurity Training & Industrial Training in both online and offline formats, allowing you to choose the mode of learning that best suits your needs.",
  },
 
  {
    sectionName: "What certification will I receive after completing the 6 Months Industrial Training in Cybersecurity?",
    sectionDesc:
      "Upon successful completion of 6 Months Industrial Training In Cybersecurity, you will earn an industry-recognized cybersecurity certification, which is highly valued by employers and enhances your career prospects.",
  },
  {
    sectionName:
      "Do you offer placement assistance after the Cybersecurity Industrial Training in Chandigarh?",
    sectionDesc:
      "Yes, we provide placement opportunities as part of our Cybersecurity Training in Chandigarh, helping you secure a position in the cybersecurity field.",
  },
  {
    sectionName:
      "What are the benefits of doing Cybersecurity Industrial Training in Chandigarh with Hackersprey?",
    sectionDesc:
      "Our Cybersecurity Industrial Training in Chandigarh training program offers hands-on experience, access to real-world hacking challenges, expert mentorship, and a prestigious Cybersecurity certification. We also focus on practical learning and provide a comprehensive curriculum that prepares you for the industry. ",
  },
  {
    sectionName:
      "Is there an option for summer or Winter training in Cybersecurity?",
    sectionDesc:
      "Yes, we offer both summer and winter training sessions in cybersecurity, allowing you to choose a schedule that fits your academic or professional commitments.",
  },
  {
    sectionName:
      "Who can apply for the 6 Months Industrial Training in Cybersecurity?",
    sectionDesc:
      "This Cybersecurity Industrial Training In Chandigarh is open to students, IT professionals, ethical hacking enthusiasts, career changers, and anyone interested in developing cybersecurity skills.",
  },
  {
    sectionName:"How do I apply for the Cybersecurity Industrial Training in Chandigarh?",
    sectionDesc:
      "You can apply for our Cybersecurity Industrial Training In Chandigarh through our website by filling out the registration form or by contacting our admissions team for guidance.",
  },
  {
    sectionName:"What makes Hackersprey the best choice for Cybersecurity Training in Chandigarh?",
    sectionDesc:
      "Hackersprey offers 6 Months Industrial Training In Cybersecurity, with expert instructors, real-world challenges, and flexible learning options. Our focus on practical skills, combined with our industry-recognized Cybersecurity certification and placement support, makes us the top choice for cybersecurity training in Chandigarh.",
  },
];
const benefits = [
  "Career Changers: Professionals transitioning into cybersecurity.",
  "Freelancers/Consultants: Seeking to enhance cybersecurity expertise.",
  "IT Professionals: Individuals in IT looking to specialize in cybersecurity.",
  "Ethical Hacking Enthusiasts: Passionate individuals seeking formal training.",
  "Tech-Savvy Individuals: Anyone interested in developing cybersecurity skills.",
  "Students/Graduates: Individuals currently pursuing or who have completed degrees in computer science, IT, or related fields.",
  "Final Year Students: College and university students seeking industrial training.",
];
const IndustrialTrainingPage = () => {
  const [isSyllabusDownload,setIsSyllabusDownload]=useState(false);
  const [activeSection, setActiveSection] = useState("");
  const introRef = useRef();
  const benifitRef = useRef();
  const curriculumRef = useRef();
  const overviewRef = useRef();
  const certificateRef = useRef();
  const courseRef = useRef();
  const faqRef = useRef();
  useEffect(() => {
    // Initialize Intersection Observer
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.getAttribute("data-name"));
            // setActiveSection(entry.target.getAttribute('data-name'));
          }
        });
      },
      { threshold: 0.5 }
    ); // Adjust the threshold as needed

    // Observe each section element
    const sectionRefs = document.querySelectorAll(".int-observe");

    sectionRefs.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });

    // Cleanup on unmount
    return () => {
      sectionRefs.forEach((ref) => {
        if (ref) {
          observer.unobserve(ref);
        }
      });
    };
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setShowCallbackForm(false);
    }, [5000]);
  }, []);
  const [showCallbackForm, setShowCallbackForm] = useState(false);
  const onHandleButton = (section) => {
    if (section === 1) {
     
      document.querySelector(".ind-intro").scrollIntoView({
        behavior: "smooth",
      });
    } else if (section === 2) {
      document.querySelector(".ind-highlights").scrollIntoView({
        behavior: "smooth",
      });
    } else if (section === 3) {
      document.querySelector(".ind-curriculum").scrollIntoView({
        behavior: "smooth",
      });
    } else if (section === 4) {
      document.querySelector(".ind-overview").scrollIntoView({
        behavior: "smooth",
      });
    } else if (section === 5) {
      document.querySelector(".ind-certificate").scrollIntoView({
        behavior: "smooth",
      });
    } else if (section === 6) {
      courseRef.current.scrollIntoView({
        behavior: "smooth",
      });
    } else {
      faqRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };
  const handleSyllabusDownload=async()=>{
    setIsSyllabusDownload(true);
    setShowCallbackForm(true);
  }
  return (
    <div className="summer-training-main-container industrial-overflow-hidden">
      <Helmet>
        <title>
        6 Months Industrial Training In Cybersecurity -Online | Offline
        </title>
        <link
          rel="canonical"
          href="https://www.hackersprey.com/industrial-training-in-cybersecurity"
        />
        <meta
          name="description"
          content="Join our 6 Months Industrial Training In Cybersecurity | Cybersecurity Training In Chandigarh—practical training with placement opportunities. Register Now"
        />

        <meta
          name="keyword"
          content=" 6 Months Industrial Training In Cybersecurity, Cybersecurity Industrial Training In Chandigarh, Cybersecurity Training In Chandigarh, Cybersecurity course in Chandigarh, Cybersecurity certification, Industrial Training In Cybersecurity, Industrial Training In Chandigarh, Cybersecurity Industrial Training, Cybersecurity Training, summer training in Cybersecurity, Winter training in Cybersecurity"
        />

<meta name="locale" content="en_IN" />
<meta name="geo.region" content="IN-CH" />
<meta name="geo.region" content="IN" />
<meta name="geo.placename" content="Chandigarh, India" />
<meta name="geo.position" content="30.73265000183138, 76.80071801068048" />
<meta name="ICBM" content="30.73265000183138, 76.80071801068048" />


<meta name="author" content="Hackersprey" />
<meta name="copyright" content="Hackersprey" />

<meta name="classification" content="cybersecurity courses online and offline" />
<meta name="distribution" content="global" />


<meta name="robots" content="all, follow" />
<meta name="googlebot" content="all, follow" />
<meta name="bingbot" content="all, follow" />
<meta name="yahoo-slurp" content="all, follow" />
<meta name="googlebot-image" content="all" />
<meta property="og:type" content="website" />
<meta property="og:title" content="6 Months Industrial Training In Cybersecurity -Online | Offline" />
<meta property="og:description" content="6 Months Industrial Training In Cybersecurity, Cybersecurity Industrial Training In Chandigarh, Cybersecurity Training In Chandigarh, Cybersecurity course in Chandigarh, Cybersecurity certification, Industrial Training In Cybersecurity, Industrial Training In Chandigarh, Cybersecurity Industrial Training, Cybersecurity Training, summer training in Cybersecurity, Winter training in Cybersecurity" />
<meta property="og:url" content="https://www.hackersprey.com/industrial-training-in-cybersecurity" />
<meta property="og:image" content={logo} />
<meta property="og:image:alt" content="Advance Cyber Warfare Program - Cybersecurity Certification Course" />
<meta property="og:site_name" content="Hackersprey" />


<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:site" content="@hackerspre30858" />
<meta name="twitter:title" content="6 Months Industrial Training In Cybersecurity -Online | Offline" />
<meta name="twitter:description" content="6 Months Industrial Training In Cybersecurity, Cybersecurity Industrial Training In Chandigarh, Cybersecurity Training In Chandigarh, Cybersecurity course in Chandigarh, Cybersecurity certification, Industrial Training In Cybersecurity, Industrial Training In Chandigarh, Cybersecurity Industrial Training, Cybersecurity Training, summer training in Cybersecurity, Winter training in Cybersecurity" />
<meta name="twitter:image" content={logo} /> 
<meta name="twitter:url" content="https://www.hackersprey.com/industrial-training-in-cybersecurity" />


<meta name="identifier-URL" content="https://www.hackersprey.com/industrial-training-in-cybersecurity" />
<meta name="url" content="https://www.hackersprey.com/industrial-training-in-cybersecurity" />


      </Helmet>
      <div className="callback-btn" onClick={() => setShowCallbackForm(true)}>
        <p>Get a callback</p>
      </div>
      <a href="https://api.whatsapp.com/send?phone=917888995975" target="blank">
        <div className="whatsapp-icon-wrapper">
          <FaWhatsapp className="whatsapp-icon" />
        </div>
      </a>
      {showCallbackForm ? (
        <SummerTrainingModal switchSyllabus={()=>setIsSyllabusDownload(false)} isSyllabusDownload={isSyllabusDownload} onClick={() => setShowCallbackForm(false)} />
      ) : null}

      <IndustrialTrainingHeroSection />
      <SummerTrainingIntro ref={introRef} />
     { /* <CourseHighlights /> */}
     <CourseAccess />
      <IndustrialBenefits ref={benifitRef} />
      <section className="call-to-action-section">
        <div>
          Have queries about our 3/6 Months Industrial Training In Cybersecurity? Our counselors
          are here to assist you.
        </div>
        <p onClick={() => setShowCallbackForm(true)}>Speak to a counsellor</p>
      </section>
      <SummerTrainingOverview dataName={""}  title="Navigate Your Path Through Cybersecurity Industrial Training" description="As digital innovation accelerates, incidents of data theft, breaches, and hacking are rising at an alarming rate. The demand for cybersecurity specialists is rising as companies seek advanced protection. With new technologies constantly emerging, the most reliable defense against cybercriminals is to depend on robust cybersecurity practices and skilled professionals. Our 6 Months Industrial Training in Cybersecurity, offered both offline and online, is your gateway to a successful cybersecurity career. This comprehensive Cybersecurity course in Chandigarh covers everything from foundational concepts to advanced techniques, ensuring you are well-prepared to tackle the complexities of the cyber world. With practical lab access and expert mentorship, this Cybersecurity Industrial Training In Chandigarh is the ideal starting point for anyone looking to enter the field, providing essential skills to combat the latest cyber threats." subtitle="Who can Apply" points={benefits}  />
      <TrainingCurriculum showModal={()=>handleSyllabusDownload()} ref={curriculumRef} />
      <SummerTrainingOverview  dataName="4" observeClass={'int-observe ind-overview'} ref={overviewRef}  title="Overview Of The 6 Months Industrial Training In Cybersecurity" description=" Hackersprey 6 Months Industrial Training in Cybersecurity is created to provide a thorough and practical learning experience. Our Cybersecurity Industrial Training In Chandigarh covers the full spectrum of cybersecurity, from foundational concepts to advanced techniques, ensuring that you gain the skills and knowledge necessary to excel in the industry. With access to cutting-edge labs, you'll work on real-world hacking challenges, guided by experienced mentors who are leaders in the field.
Available in both online and offline formats, Hackersprey offers flexible learning options to suit your schedule. This Cybersecurity Training In Chandigarh not only provides you with a prestigious cybersecurity certification but also enhances your career prospects with placement opportunities. Join us and take the next step toward becoming a cybersecurity professional with Hackersprey 6 Months Industrial Training In Cybersecurity." subtitle="Job Roles You Can Apply After Completing This Training" points={[ "Cybersecurity Analyst","Security Operations Center (SOC) Analyst",
  "Incident Responder",
  "Ethical Hacker/Penetration Tester",
  "Security Consultant",
  "Information Security Analyst",
]}  />
      <section className="call-to-action-section">
        <div>
          Have queries about our Cybersecurity Industrial Training In Chandigarh? Our counselors
          are here to assist you.
        </div>
        <p onClick={() => setShowCallbackForm(true)}>Speak to a counsellor</p>
      </section>
      <WhyHackersprey/>
      <SummerTrainingCertificate ref={certificateRef} />
      {/*<SummerTrainingTrainer className="int-observe" data-name="9"/> */}
      <section
        ref={courseRef}
        className="webinar-courses-card-section2 padding-top int-observe"
        data-name="6"
      >
        <h1 className="webinar-section-heading">
          Start your path to success with our courses
        </h1>
        <div className="red-line-class">
          <hr className="binaryhr"></hr>
        </div>

        <div className="webinar-card-container">
          <WebinarCourseCard
            image={StackImg}
            title="StackXploit"
            features={stackPoints}
            link="/stack-xploit-cyber-security-course"
          />
           <WebinarCourseCard
            image={acwimg}
            title="ACW"
            features={acwpoints}
            link="/acw-cyber-security-certification-course"
          />
          <WebinarCourseCard
            image={WebpwnImg}
            title="WEBPWN"
            features={webPwnPoints}
            link="/webpwn-professional-web-hacking-course"
          />
        </div>
      </section>

      <div
        ref={faqRef}
        className="training-syl int-observe training-syl-padding int-observe"
        data-name="7"
      >
        <StackXploit2FAQs faq={faq} heading="FAQ's" />
      </div>
      <IndustrialTrainingBar
        onClick={() => setShowCallbackForm(true)}
        activeSection={activeSection}
        setActiveSection={(section) => onHandleButton(section)}
      />
      <InternalLinking/>
      <Footer />
    </div>
  );
};

export default IndustrialTrainingPage;
