import React from 'react';
import BinaryLogo from "../../Assets/binary/sxplogo.png";
import './WebinarCourseCard.css'
import ReactPlayer from 'react-player';
import { GrFormCheckmark } from "react-icons/gr";
import { BiSolidCircle } from "react-icons/bi";
import { Link } from 'react-router-dom';

const WebinarCourseCard = (props) => {
  return (
    <div className='webinar-course-component'>
    <header className='webinar-course-card-header'>
      <img className='webinar-img-course' src={props?.image} width="100%"  />
        
    </header>
    <div className='webinar-course-card-body'>
        <h6 className='webinar-course-card-title'>{props?.title}</h6>
        <div className='webinar-course-features'>
          {props?.features?.map((stack)=>(
  <ul className='webinar-courses-features-list'><BiSolidCircle className='webinar-circle-icon'/>{stack}</ul>
          ))}
        </div>
       
        </div>
        <div className='webinar-course-card-btn-wrap'>
        <Link className='webinar-link2' to={props.link}><p className='webinar-course-card-btn'>Know More</p></Link>
       
    </div>
    
    </div>
  )
}

export default WebinarCourseCard
