import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import "./Userslider.css";

import Usersliderdata from "./Usersliderdata";
import 'swiper/css';

import { Pagination, EffectCoverflow } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
const Userslider = () => {
  const [slideIndex, setSlideIndex] = useState(2);

  const nextSlide = () => {
    if (slideIndex !== Usersliderdata.length) {
      setSlideIndex(slideIndex + 1);
    } else if (slideIndex === Usersliderdata.length) {
      setSlideIndex(1);
    }
  };

  const prevSlide = () => {
    if (slideIndex !== 1) {
      setSlideIndex(slideIndex - 1);
    } else if (slideIndex === 1) {
      setSlideIndex(Usersliderdata.length);
    }
  };

  const moveDot = (index) => {
    setSlideIndex(index);
  };

  return (
    <>
      <div className="container-sliders">
        <Swiper
          style={{
            "--swiper-pagination-color": "#e32f1c",
            "--swiper-pagination-bullet-inactive-color": "white",
            "--swiper-pagination-bullet-inactive-opacity": "2",
            "--swiper-pagination-bullet-size": "10px",
            "--swiper-pagination-bullet-horizontal-gap": "6px",
          }}
          slidesPerView={"1"}
          effect={""}
          spaceBetween={0}
          grabCursor={true}
          pagination={{
            clickable: true,
          }}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 0,
            modifier: 0,
            slideShadows: false,
          }}
          modules={[Pagination]}
          className="mySwiper2"
        >
          {Usersliderdata &&
            Usersliderdata.map((reviews, is) => (
              <SwiperSlide>
          
                  <img
                    src={reviews.photo}
                    alt="testimonial_image"
                    style={{ borderRadius: "3rem" }}
                  />
         
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </>
  );
};

export default Userslider;
