import React from 'react'
import './SummerTrainer.css';
import Trainer1 from '../../Assets/photo-1.png'
import Trainer2 from '../../Assets/photo-2.jpeg'
import Trainer3 from '../../Assets/photo-3.jfif'


const SummerTrainingTrainer = () => {
  return (
    <div className='trainer-cont'>
      <p className='text-center training-heading5'>Our Trainers</p>
      <div className='trainer-component'>
        <div className='trainer-card'>
        <img src={Trainer1} className='trainer-card-img'/>
        <div>
          <h1 className='trainer-name'>Saurabh Kaushal</h1>
          <p className='trainer-designation-2'>14+ Years Experience</p>
          <h1 className='trainer-designation'>Cyber Security Expert</h1>
          
          <h1 className='trainer-desc'>An industry expert has mentored 3000+ candidates and trained 100+ technical teachers in cybersecurity & Forensics also Successfully completed two projects of Cyber Security sponsored by the Ministry of Electronics & Information Technology, New Delhi</h1>
        </div>
        </div>
        <div className='trainer-card'>
        <img src={Trainer2} className='trainer-card-img'/>
        <div>
          <h1 className='trainer-name'>Vipul Mandhar</h1><h1 className='trainer-designation-2'>6+ Years Experience</h1>
          
          <h1 className='trainer-designation'>Security Analyst</h1>
          
          <h1 className='trainer-desc'>Currently working as a Security Analyst having 2 years of experience as an Assistant Professor specializing in cyber forensics and security, and trained 100+ technical teachers in cybersecurity and Forensics.</h1>
        </div>
        </div>
        <div className='trainer-card'>
        <img src={Trainer3} className='trainer-card-img'/>
        <div>
          <h1 className='trainer-name'>Aman Sharma</h1><h1 className='trainer-designation-2'>3+ Years Experience</h1>
          <h1 className='trainer-designation'>Security Researcher</h1>
          
          <h1 className='trainer-desc'>Currently working as a Security Researcher having 3 years of experience and has also successfully trained over 1000+ candidates in the field of cybersecurity.</h1>
        </div>
        </div>

      </div>
    </div>
  )
}

export default SummerTrainingTrainer