import React from 'react'
import "./Sorry.css"

import {FaArrowRight} from "react-icons/fa";
import {ImCross} from "react-icons/im";
import { NavLink } from 'react-router-dom';
const Passchanges = ({setOpenModal}) => {
  return (
      <>
    <section className="modalBackground justify-content-center" onClick={() => {
      setOpenModal(false);
    }}>
    <div className="container  justify-content-center">
    
      <div className="row justify-content-center " >
      
        <div className="col-lg-7 col-md-10 col-10 p-2" id='passchangeddiv'>
        
      <div className="titleCloseBtn">
        
           
          </div>
          <div className='row p-5'>
         
        <div className='col-lg-4 col-md-4 col'></div>
          <div className='col-lg-8 col-md-5 col   '>
          <div className='mt-2 crossbtnarrow'>
         <NavLink to="/signin"> <button
            onClick={() => {
              setOpenModal(false);
            }}
          >
          <div className='text-center'><ImCross style={{height:".6rem",marginLeft:"-1.9rem",marginTop:".5rem"}}/></div> 
          </button></NavLink>
        </div>
         <div className='justify-content-center'><h2 className='text-center alertmainhead2'>Password Changed Successfully<div className='row justify-content-center'><span><hr className='text-center alertcommonhr'></hr></span></div></h2></div> 
         
        <div className='justify-content-center'>
        <p className='text-center passchangepara '>Congratulations! Your password has been successfully changed. </p>
        </div>
        
     
   
         <div className='row justify-content-center'>
        <NavLink to="/signin"> <button className='Arrowbtn' onClick={() => {
          setOpenModal(false);
        }}>
         <FaArrowRight color="white" size={20}/>
         
         </button></NavLink>
         </div>
          </div></div>
         
         
           
            
         
        
      </div>
    </div>
    </div> 
  </section>
  </>
  )
}

export default Passchanges