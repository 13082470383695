import React, { useState,useEffect} from 'react'
import { ImCross } from 'react-icons/im';
import { useNavigate } from 'react-router-dom';
import Wentwrong from './Wentwrong';
import Loading from './Loading';
import { useForm } from "react-hook-form";

import Cantsendmail from './Cantsendmail';
import { faL } from '@fortawesome/free-solid-svg-icons';
import Invitesentsuccess from './Teammodals/Invitesentsuccess';
import Teamfull from './Teammodals/Teamfull';
import Alreadyamember from './Teammodals/Alreadyamember';
const Inviteteammember = ({setOpenModal}) => {
  const {register,handleSubmit,formState: { errors },reset,watch,trigger,} = useForm();
  const[useremail,setuseremail]=useState("")
  const [modal,setmodal]=useState(false);
  const [modal2,setmodal2]=useState(false);
  const [modal3,setmodal3]=useState(false);
  const [modal4,setmodal4]=useState(false);

  const API_Key = process.env.REACT_APP_API_URL;
    const onSubmit = (data)=>{
  setmodal2(true);
        //e.preventDefault();
        
        //const data = { invite_email};
        fetch(`${API_Key}/group/member`, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              mode: 'cors',
              'Access-Control-Allow-Origin':`${API_Key}`,
              'Content-Type': 'application/json',
              'Access-Control-Allow-Credentials': true,
            },
            credentials:"include",
            body: JSON.stringify(data),
          }).then((res) => {
 
            if(res.status===200){
             
          
setmodal2(false);
setmodal(true);

          setTimeout(() => {
       setmodal(false);
       window.location.reload(true);
          },5000);


          
        
               

        } 
else if(res.status===403){
  setmodal2(false);
  setmodal3(true);
}else if(res.status===402){
  setmodal2(false);
  setmodal4(true);
}

      })
    }
  
      

    
  return (
    <div>
   {modal && (<Invitesentsuccess  setOpenModal={setmodal}  />)}

   {modal2 && (<Loading setOpenModal={setmodal2}/>)}
   {modal3 && (<Teamfull setOpenModal={setmodal3}/>)}
   {modal4 && (<Alreadyamember setOpenModal={setmodal4}/>)}
   
    
    <section className="modalBackground justify-content-center" 
   
  >
    <div className="container  justify-content-center">
    
      <div className="row justify-content-center ">
      
        <div className="col-lg-8 col-md-10 col-10  modalContainer" >
        <div className='mt-5 crossbtnarrow '>
        <button
          onClick={() => {
            setOpenModal(false);
          }}
        >
        <div className='text-center'><ImCross style={{height:".6rem",marginLeft:"-1.9rem",marginTop:".5rem"}}/></div> 
        </button>
      </div>
<h2 className='text-center alertmainhead2'>Invite Your Teammate! <div className='row justify-content-center'><span><hr className='text-center alertcommonhr'></hr></span></div></h2>  


<div className='row justify-content-center'>

<div className='col-lg-10 col-md-6  col-9 '>
<p className='resetpara'>Gather your squad and conquer the challenges together in this thrilling web pentesting CTF. Invite your friends to be part of your team for an unforgettable hacking experience! <br></br><b>Note: </b>Send One Invite at a time.</p>


<div className='row justify-content-center mt-2'>
<form  className='col-lg-12' onSubmit={handleSubmit(onSubmit)} >
<div className="form-input ">
    
<div className="col">

<input
  name="invite_email"
  type="text"
  autoComplete='off'
  className={`form-control   ${errors.invite_email && "invalid"}`}
  placeholder="johndoe@gmail.com"
  {...register("invite_email", { required: "Email is Required" ,
  pattern: {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    message: "Invalid email address",
  },
  validate:(value)=> value!==useremail ||"Oops! You can’t send invitations to your own email account. Please enter another existing email account!",
})}
  onKeyUp={() => {
    trigger("email");
  }}/>


 </div></div>
 <div className='row justify-content-center'>
<button className=' btn saveratebtn mt-5' > Invite</button>
</div>
</form>
</div>

</div>

</div>

        </div></div></div></section></div>
  )
}

export default Inviteteammember