import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import Error from './Components/Authpages/Error';
import { Provider } from 'react-redux';
import { configureStore } from './redux/configureStore';
const store=configureStore()
if(window.self === window.top) {
  ReactDOM.render(<Provider store={store}><BrowserRouter><App  /></BrowserRouter></Provider>, document.getElementById('root'));
} else{
  ReactDOM.render(<Provider store={store}><BrowserRouter><Error/></BrowserRouter></Provider>, document.getElementById('root'));
}
 
/*ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
