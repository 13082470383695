import React,{useEffect} from 'react'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import Mascot from "../../Assets/MASCOT/Mascot SVG/Asset 28.png"

const Comingsoon = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  return (
    <div>
    
    <section className="commonbackground " >
  
  
  <br></br>     <br></br>
  <div className="container mt-5" id="Heading_container">
      <div className="row justify-content-center">
     
      <span className="Privacypolicy_span">Coming  Soon</span>
  <h2 className="comingsoon_head">Coming   soon....</h2>
          <hr className="commonpagehr"></hr>
       
    
      </div>

     
  </div>
  </section>
  <section className="Privacydiv">
  <div className="container">
      <div className="row">
          <div className="col-lg-8 col-md-12 col-12  order-2 order-lg-1 mt-5">
         
          <h1 className='disclaimerhead'>Coming soon</h1>
    
<p className="privacypara"> Best Customise self learning Hacking programs Made For You!
We just dont teach Ethical Hacking. We Build Cyber Careers too!
This  Page Is coming soon .


</p>
          </div>
          <div className="col-lg-3 col-md-12 col-12 offset-1 justify-content-center order-1 order-lg-2 mt-5">
          <img src={Mascot}  className="img-fluid privacypic"  alt="Privacy_pic" />
          </div>
      </div>
  </div>
  </section>
  <Footer/>
    </div>
  )
}

export default Comingsoon