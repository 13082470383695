import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { RiDoubleQuotesR } from "react-icons/ri";
import { DummyData } from "./dummydata"
import { Link } from 'react-router-dom';



const CourseSlider = () => {
    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 2,
        autoplay: true,
        autoplaySpeed: 1500,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1024, // Tablet devices
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 768, // Mobile devices
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    return (
        <div className="container">
            <div className='instrutor'>
                <h2 >Our Leading Cybersecurity Courses</h2>
                <p id="par" className=''>Boost your skills with our top-rated Cybersecurity Certification Courses. Each course will equip you with the skills needed to excel in today's fast-paced digital world.</p>
            </div>
            <Slider {...settings}>
                {DummyData ? DummyData.map((item, index) => (
                    <div className="row" id='corseslide'>
                        <div className='col-lg-12 col-md-12 col-sm-6' key={index}>
                            <div className='cardresecse ml-5'>
                                <div className='cardstyle'>
                                    <img src={item.img} alt={item.name} />
                                    <div className='itemdetails'>
                                        <h2>{item.title}</h2>
                                        <p className='beginner'> </p>
                                        <p className='itemtext'>{item.description}</p>
                                        <h1 className="price">{item.prize}</h1>

                                        <Link className="enroll-button" to={item.Relink}>View Course</Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                )) : null}
            </Slider>
        </div>
    );
};

export default CourseSlider;
