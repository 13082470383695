import React, { Suspense } from "react";
import { Helmet } from "react-helmet";
import Loading from "../Dashboard/Modals/Loading";
const Mainhome = React.lazy(() => import("./Mainhome"));
const Whoweare = React.lazy(() => import("./Whoweare"));
const Features = React.lazy(() => import("./Features"));
const Courses = React.lazy(() => import("./Courses"));
const Howitworks = React.lazy(() => import("./Howitworks"));
const Newsletter = React.lazy(() => import("./Newsletter"));
const Footer = React.lazy(() => import("../Footer/Footer"));
const Internship = React.lazy(() => import("./internship"));
const Testimonial = React.lazy(() => import("./Testimonial"));

const Home = () => {
  return (
    <Suspense fallback={Loading}>
      <Helmet>
        <title>
          Learn Cyber and Ethical Hacking Courses Online | Hackers Prey
        </title>

        <meta
          name="description"
          content="Hackers Prey is the leading platform that provides you best ethical and Cyber hacking online courses. Enhance your problem-solving skills and capability with us. Contact us now."
        />
      </Helmet>

      <Mainhome />

      <Whoweare />
      <Features />
      {/*} <Spacing/>*/}
      <Internship />
      {/*      <Internship2 />*/}
      <Courses />



      <Testimonial />
      <Howitworks />
      <Newsletter />
      <Footer />
    </Suspense>
  );
};

export default Home;
