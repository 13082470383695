import React, { useEffect, useState } from 'react'

import "./Common.css";

import basic from "../../../Assets/subsplan/Basic.svg"
import Webhack from "../../../Assets/Landing page/webhacklogo.png"
import Dot from "../../../Assets/images/Activity.svg";
import Vip from "../../../Assets/subsplan/VIP.svg";
import Enterprise from "../../../Assets/subsplan/Enterprise.svg";

import {useNavigate, NavLink} from "react-router-dom"
import Loading from '../Modals/Loading';
import Susbxriptionsaves from '../Modals/Susbxriptionsaves';
import Fakesubscription from '../Modals/Fakesubscription';

import Subscriptionsucess from '../../Authpages/Subscriptionsucess';

const Mastersubscription = (props) => {
    const API_Key = process.env.REACT_APP_API_URL;
    const API_Key2=process.env.REACT_APP_RAZORPAY_ID;
    const [initstate, setInitState] = useState("/subscription");
  
    const [plans,setplans]=useState([]);
    const[modal,setopenmodal]=useState(false);
    const[modal2,setopenmodal2]=useState(false);
    const[modal3,setopenmodal3]=useState(false);
    const[plansid,setplansid]=useState();
    const[plandetail,setplaindetail]=useState([]);
    const navigate=useNavigate();
    
      
    
   
  
  const getplans =async()=>{
    setopenmodal(true)
    try{
      const res = await fetch(`${API_Key}/get_master_Plan`,{
          method:"GET",
          headers:{
              Accept:"application/json",
              "Content-Type":"application/json",
              'Access-Control-Allow-Origin':`${API_Key}`,
              'Access-Control-Allow-Credentials': 'true',
          },
          credentials:"include"
         
      });
      const data = await res.json([]);
  
      setplans(data);
      setopenmodal(false)
    
  
      if(res.status !== 200 ){
          const error = new Error(res.error);
          throw error;
         
          
      }
      
   
  
  
  }catch(err){
    console.log(err);
   navigate({
    pathname : '/signin',
    state: initstate
  
    } 
  );
   
  
  } 
  
  
  }
  
  
  
    useEffect(()=>{
  getplans()
    },[])
   
  
       
  
    return (
      <div>
      {modal && <Loading setOpenModal={setopenmodal} />}
      {modal2 && <Susbxriptionsaves setOpenModal={setopenmodal2} />}
      {modal3 && <Fakesubscription setOpenModal={setopenmodal3} />}
  
     
      <div className='background_dashboard p-2'>
      <div className='commontopper'>
      <div className='container'>
      <div className='row justify-content-center'>
      <h1 className='commonhead'>Master Plans<div className='row justify-content-center'><hr className='commonheadhr'></hr></div></h1>
   
      
      </div>
      <div className='row justify-content-center'>
      <p className='Commonpara'>Welcome to this massive hacking playground! Choose your plans to progress your hacking skills. </p>
      </div>
      </div>
            
     
      </div>
    
      <div className='container-fluid mt-5 p-5'>
      <div className='row justify-content-center p-5  '>
  
      {plans.map((plans,id)=>(
        <div className='col-lg-3 col-10 m-5' >
     <div className='card p-5' id='subscard'>
   <div  className='row justify-content-center'>
   <div className='col-lg-5 col'> <img src={plans.image} alt="basic"/></div>
   <div className='col-lg-7 col pt-5'> <h2 className='subplanhead'>{plans.name}</h2>
 {/* <h6 className='subplanpara '> <span className='subplanparadspan'>&#x20B9;</span>{plans.price}<span className='subplanparadspan'>/6 weeks</span></h6>*/}
   </div>
   
   </div>
   <div  className='row  justify-content-center mt-5'>
   
   <div className='col-lg-12'>
   <hr className='subplanhr'></hr>
   
   </div>
   
   
   </div>
   <div  className='row  justify-content-center mt-1'>
   
   <div className='col-lg-12 col-12 '>
   {plans.features && plans.features.map((feature,id)=>(   <ul>
    <li className='subplanfeaturepara'><span className='mr-3'> <img src={Dot} alt="dot" style={{height:"1.5rem"}}/></span>{feature.feature}</li>

   </ul>))}
   </div>
   
  

   </div>
   <div className='row justify-content-center '>
   <div className='col-lg-8'>
  <NavLink to={"/subscription/"+plans.courseid}><button className='btn  subsplansubscribebtn' >View Plans</button></NavLink>
   </div>
   
   </div>
     </div>
     </div>
      ))}
   
     </div>
      </div>
     
      </div>
      </div>
    )
  }
  
export default (Mastersubscription)