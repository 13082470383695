import React, { useEffect, useState,useRef } from 'react'

import "./Entity.css";

import{AiOutlineTrophy,AiOutlinePieChart,AiOutlineFieldTime,AiOutlineFlag, AiOutlineTeam} from "react-icons/ai"
import{RiPriceTag3Line} from "react-icons/ri"

import Videoimg from "../../../Assets/Entity/Webfare/innerphoto3.png"
import{SlLocationPin} from "react-icons/sl"
import{TbChartInfographic} from "react-icons/tb"
import{CgCommunity} from "react-icons/cg"
import havoclogo from "../../../Assets/Entity/Webfare/innerphoto3.png"


import User2 from "../../../Assets/images/dp3.png"
import Inviteteammember from '../Modals/Inviteteammember';

import Edit from "../../../Assets/Entity/Lab/Edit.svg"
import { NavLink, useNavigate } from 'react-router-dom';
import Gotolink from "../../../Assets/Entity/Lab/Go To Link (1).svg";
import { useForm } from "react-hook-form";
import Loading from '../Modals/Loading';
import Teamaddedsucces from '../Modals/Teammodals/Teamaddedsucces';
import Teamnametaken from '../Modals/Teammodals/Teamnametaken';
import Adminalreadyteam from '../Modals/Teammodals/Adminalreadyteam';
import Deleteteams from '../Modals/Teammodals/Deleteteams';
import Removemembersuccess from "../Modals/Teammodals/Removemembersuccess";
import Aceeptinivitation from "../Modals/Teammodals/Aceeptinivitation"
import Rejectinvites from '../Modals/Teammodals/Rejectinvites';
import Leaveteams from '../Modals/Teammodals/Leaveteams';
import Forbidden from '../Modals/Forbidden';

import Teamphotorequired from "../Modals/Teammodals/Teamphotorequired"
import Admincantremove from '../Modals/Teammodals/Admincantremove';
import Teamnotfound from '../Modals/Teammodals/Teamnotfound';
import { Nav } from 'react-bootstrap';
const Hackerhavocoverview = (props) => {
/* modals start*/
const[modal2,setopenmodal2]=useState(false);
const[modal3,setopenmodal3]=useState(false);
const[modal4,setopenmodal4]=useState(false);
const[modal5,setopenmodal5]=useState(false);
const[modal6,setopenmodal6]=useState(false);
const[modal7,setopenmodal7]=useState(false);
const[modal8,setopenmodal8]=useState(false);
const[modal9,setopenmodal9]=useState(false);
const[modal10,setopenmodal10]=useState(false);
const[modal11,setopenmodal11]=useState(false);
const[modal12,setopenmodal12]=useState(false);
const[modal13,setopenmodal13]=useState(false);
const[modal14,setopenmodal14]=useState(false);
const [bugbounty,setbugbounty]=useState([]);
const [teamrank,setteamrank]=useState([]);
const[collegename,setcollegname]=useState([]);

  const navigate=useNavigate();
  const {register,handleSubmit,formState: { errors },reset,watch,trigger,} = useForm();
  const filePickerRef = useRef(null);
  const [search,setsearch]=useState();
    const [searchdata,setsearchdata]=useState([])
 
  const [previewUrl, setPreviewUrl] = useState();
  const [designation,setdesignation]=useState(false)
  const[adminmail,setadminmail]=useState("")
  const[userdetail,setuserdetail]=useState([])
  const [totalteammember,settotalteammember]=useState("");
  const API_Key = process.env.REACT_APP_API_URL;
  const [img, setImg] = useState();
  const [file, setfile] = useState("");
  const[teamdata,setteadata]=useState([]);
  const handleImageUpload2 = (event) => {
    event.preventDefault();
    setImg(URL.createObjectURL(event.target.files[0]));
 if (event.target.files) {
      const pickedFile = event.target.files[0];
      setfile(pickedFile);
      return;
    }
  };

  const fetchbugbounty = async () => {
   
   
  
    try{
      const res = await fetch(`${API_Key}/getbugbounty`,{
        method:"GET",
        headers:{
          mode: 'cors',
          'Access-Control-Allow-Origin':`${API_Key}`,
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': true,
        },
        credentials:"include"
      
       
  
         
      });
      const data = await res.json();
      setbugbounty(data);
  
    
  
      if(res.status !== 200 ){
          const error = new Error(res.error);
          throw error;
          
      }
      
   
  
  
  }catch(err){
    console.log(err);
    navigate("/signin");
  
  } 
  
  }
  useEffect(() => {
    fetchbugbounty();
  },[ ] );
  const fetchcolllegename = async () => {
   
   
  
    try{
      const res = await fetch(`${API_Key}/getuniversityname`,{
        method:"GET",
        headers:{
          mode: 'cors',
          'Access-Control-Allow-Origin':`${API_Key}`,
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': true,
        },
        credentials:"include"
      
       
  
         
      });
      const data = await res.json();
     
      setcollegname(data);
  
    
  
      if(res.status !== 200 ){
          const error = new Error(res.error);
          throw error;
          
      }
      
   
  
  
  }catch(err){
    console.log(err);
    //history.push("/signin");
  
  } 
  
  }
  useEffect(() => {
    fetchcolllegename();
  },[ ] );

  useEffect(() => {

    if(search !==""){
      fetch("https://restcountries.com/v3.1/all")
      .then((res) =>res.json())
      .then((data) =>{ setsearchdata(data)
     }
      );
 
    }
    
      
    }, [])
    
  const getLevelName =(invite_email)=>{
    if (invite_email ===adminmail) {
      return   "Skipper" 
  }else{
    return   "Member" 
    
  }
}
const getLevelName2 =(status)=>{
  if (status ==="true") {
    return   "Accepted" 
}else{
  return   "Pending" 
}
}
 


  useEffect(() => {
    if (!file) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewUrl(fileReader.result);
    };
    fileReader.readAsDataURL(file);
  }, [file]);
  const handleFilePicker = () => {
    filePickerRef.current.click();
  };
  const [addteam, setaddteam] = useState({
    team_name: "",
   
    team_moto: "",
    country: "",
    team_description:""


  });
  const handleChange = (event) => {
    setaddteam((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
   
  };

  const onSubmit = (data) => {
setopenmodal3(true);

    //event.preventDefault();
   

    const fd = new FormData();
    fd.append("team_name", data.team_name);
  
    fd.append("team_moto", data.team_moto);
  

    fd.append("team_description", data.team_description);
    fd.append("country", data.country);
    fd.append("college_name", data.college_name);
 fd.append("file",file);

  
     

fetch(`${API_Key}/group`, {
  method: 'POST',
  headers:{
    Accept: "application/json",
    mode: 'cors',
    'Access-Control-Allow-Origin':`${API_Key}`,
    //'Content-type': 'application/x-www-form-urlencoded',
    "type": "formData",
    'Access-Control-Allow-Credentials': true,
  },
  credentials:"include",
  body:fd,
})
.then((res) => {
  
if(res.status===200){
  setopenmodal3(false);
  setopenmodal2(true)
reset();
 setTimeout(() => {

     setopenmodal2(false,window.location.reload(true))
  
 }, 5000);
 

  
}else if(res.status===400){
  setopenmodal3(false);
  setopenmodal4(true)
}else if(res.status===403){
  setopenmodal3(false);
  setopenmodal5(true)
}else if(res.status===406){
  setopenmodal3(false);
  setopenmodal11(true);
}else if(res.status===500){
  setopenmodal3(false);
  setopenmodal12(true);
}
  

  
  const noti={message:"Your Team has been Created Successfully",category:"Bug Bounty",querytype:"Team Created"}

    fetch(`${API_Key}/notification`, {
      method: 'POST',
      headers:{
        Accept: "application/json",
        mode: 'cors',
        'Access-Control-Allow-Origin':`${API_Key}`,
      'Content-type': 'application/json',
        
        'Access-Control-Allow-Credentials': true,
      },
      credentials:"include",
      body: JSON.stringify(noti),
    })
  }).catch((error) => {
   console.log(error);


   
 });
}
  
 
    const[item,setitem]=useState("B")
    const[item2,setitem2]=useState("A")
    const[item3,setitem3]=useState("")
    const[modalOpen,setModalOpen]=useState(false);
    const[adminlogin,setadminlogin]=useState("");
    const[invitebyteam,setinvitebyteam]=useState([]);
    const openteammodal=()=>{
      setModalOpen(true);
    }

    const fetchteamdata = async () => {
     
      
              
          try{
            const res = await fetch(`${API_Key}/getteamdata`,{
                method:"GET",
                headers:{
                    Accept:"application/json",
                    "Content-Type":"application/json"
                },
                credentials:"include"
               
            });
            const data = await res.json();
         
            setteadata(data);
       
           
       
            setadminmail(data.admin_email);
            settotalteammember(data.team_member.length)
 

            

            
          
        
            if(res.status !== 200 ){
                const error = new Error(res.error);
                throw error;
                
            }else{
              //setopenmodal3(false);
            }
            
         
        
        
        }catch(err){
          console.log(err);
          //navigate("/signin");
        
        } 
        
        }
      
        useEffect(()=>{
 
          if(adminmail===userdetail.email){
   
   setdesignation(true)
          }
          
        })
        useEffect(() => {
          fetchteamdata();
        },[] );
        const fetchteaminvite = async () => {
     
      
              
          try{
            const res = await fetch(`${API_Key}/getteaminvite`,{
                method:"GET",
                headers:{
                    Accept:"application/json",
                    "Content-Type":"application/json"
                },
                credentials:"include"
               
            });
            const data = await res.json();
         
            setinvitebyteam(data);
           
            
            

            
          
        
            if(res.status !== 200 ){
                const error = new Error(res.error);
                throw error;
                
            }else{
              //setopenmodal3(false);
            }
            
         
        
        
        }catch(err){
          console.log(err);
          //navigate("/signin");
        
        } 
        
        }
      
        useEffect(() => {
          fetchteaminvite();
        },[] );
        const Acceptinvite = async(event)=>{
          setopenmodal3(true);
const{id}=event.target;

fetch(`${API_Key}/team_accept`, {
  method: 'POST',
  headers:{
    Accept: "application/json",
    mode: 'cors',
    'Access-Control-Allow-Origin':`${API_Key}`,
  'Content-type': 'application/json',
    
    'Access-Control-Allow-Credentials': true,
  },
  credentials:"include",
  body: JSON.stringify({email:id}),
}).then((res)=>{
  if(res.status===200){
    setopenmodal3(false);
    setopenmodal8(true);

 
    setTimeout(() => {
      setopenmodal8(false);
      window.location.reload(true)
      setitem2("C")
  
    }, 5000);
  }
})
.catch((error) => {
console.log(error);



});

   











        }
        const Rejectinvite = async(event)=>{
          setopenmodal3(true);
          const{id}=event.target;
   

fetch(`${API_Key}/reject_accept`, {
  method: 'POST',
  headers:{
    Accept: "application/json",
    mode: 'cors',
    'Access-Control-Allow-Origin':`${API_Key}`,
  'Content-type': 'application/json',
    
    'Access-Control-Allow-Credentials': true,
  },
  credentials:"include",
  body: JSON.stringify({email:id}),
}).then((res)=>{
  if(res.status===200){
    setopenmodal3(false);
    setopenmodal9(true);
    setTimeout(() => {
      setopenmodal9(false);
      window.location.reload(true)
      setitem2("C")
    }, 5000);
  }
})
.catch((error) => {
console.log(error);



});
                  }
                  



const deleteadminteam = ()=>{
  setopenmodal6(true)
  


}
const Leaveteam = ()=>{
  setopenmodal3(true);
  fetch(`${API_Key}/exit_team`, {
    method: 'POST',
    headers:{
      Accept: "application/json",
      mode: 'cors',
      'Access-Control-Allow-Origin':`${API_Key}`,
    'Content-type': 'application/json',
      
      'Access-Control-Allow-Credentials': true,
    },
    credentials:"include",
   
  }).then((res)=>{
    if(res.status===200){
      setopenmodal3(false);
      setopenmodal10(true);
     
      
    }else if(res.status===403){
        setopenmodal3(false);
        setopenmodal14(true);
    }
  })
  .catch((error) => {
  console.log(error);
  
  
  
  });
}



                  const fetchuser = async () => {


        
                    try{
                      const res = await fetch(`${API_Key}/adminloggedin`,{
                          method:"GET",
                          headers:{
                            mode: 'cors',
                            'Access-Control-Allow-Origin':`${API_Key}`,
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Credentials': true,
                          },
                          credentials:"include"
                         
                      });
                      const data = await res.json();
                
                      setuserdetail(data);
                    
                  
                      if(res.status !== 200 ){
                          const error = new Error(res.error);
                          throw error;
                          
                      }
                      
                   
                  
                  
                  }catch(err){
                    console.log(err);
                    navigate("/signin");
                  
                  } 
                  
                  }
                  useEffect(() => {
                    fetchuser();
                  },[ ] );

                  const fetchteamrank = async () => {


        
                    try{
                      const res = await fetch(`${API_Key}/findbountryrank`,{
                          method:"GET",
                          headers:{
                            mode: 'cors',
                            'Access-Control-Allow-Origin':`${API_Key}`,
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Credentials': true,
                          },
                          credentials:"include"
                         
                      });
                      const data = await res.json();
                
                 
                    setteamrank(data.userRank);
                  
                      if(res.status !== 200 ){
                          const error = new Error(res.error);
                          throw error;
                          
                      }
                      
                   
                  
                  
                  }catch(err){
                    console.log(err);
                    
                  
                  } 
                  
                  }
                  useEffect(() => {
                    fetchteamrank();
                  },[ ] );



                  const Deleteteam= async(event)=>{
                    setopenmodal3(true);
                    const {id}=event.target;
                    fetch(`${API_Key}/admin-delete_team_member`, {
                      method: 'POST',
                      headers:{
                        Accept: "application/json",
                        mode: 'cors',
                        'Access-Control-Allow-Origin':`${API_Key}`,
                      'Content-type': 'application/json',
                        
                        'Access-Control-Allow-Credentials': true,
                      },
                      credentials:"include",
                      body: JSON.stringify({email:id}),
                    }).then((res) => {
                      if(res.status===200){
                        setitem("B");
                        setitem2("C")
                       setopenmodal3(false);
                       setopenmodal7(true);
                      
                       setTimeout(() => {
                        setopenmodal7(false);
                        window.location.reload(true);
                        setitem2("C")
                       }, 5000);
                      }

                      else if(res.status===403){
                        setitem("B");
                        setitem2("C")
                       setopenmodal3(false);
                       setopenmodal13(true);
                       setTimeout(() => {
                        setopenmodal13(false);
                       }, 5000);
                      }
                    
                    
                    
                    })                      
                    .catch((error) => {
                    console.log(error);
                    
                    
                    
                    });
                  }

             
  return (
    <div>
    {modalOpen && (
      <Inviteteammember
       
        setOpenModal={setModalOpen}
      />
    )} 
    {modal2 && (<Teamaddedsucces setOpenModal={setopenmodal2}/>)}
    {modal3 && (<Loading setOpenModal={setopenmodal3}/>)}
    {modal4 && (<Teamnametaken setOpenModal={setopenmodal4}/>)}
    {modal5 && (<Adminalreadyteam setOpenModal={setopenmodal5}/>)}
    {modal6 && (<Deleteteams setOpenModal={setopenmodal6}/>)}
    {modal7 && (<Removemembersuccess setOpenModal={setopenmodal7}/>)}
    {modal8 && (<Aceeptinivitation setOpenModal={setopenmodal8}/>)}
    {modal9 && (<Rejectinvites setOpenModal={setopenmodal9}/>)}
    {modal10 && (<Leaveteams setOpenModal={setopenmodal10}/>)}
    {modal11 && (<Forbidden setOpenModal={setopenmodal11}/>)}
    {modal12 && (<Teamphotorequired setOpenModal={setopenmodal12}/>)}
    {modal13 && (<Admincantremove setOpenModal={setopenmodal13}/>)}
    {modal14 && (<Teamnotfound setOpenModal={setopenmodal14}/>)}
   
    <div className='Entity_background2 p-1'>
    <div className='Entitycommontopper3'>
 
    <div className='container'>
    <div className='row justify-content-center'>
   <div className='col-lg-8 col-md-8 col-12 p-5'>
   <h1 className='Entitycommonhead'>{bugbounty.title}<span><hr className='Entitycommonheadhr'></hr></span></h1>
   <p className='Entitycommonheadpara'>Welcome to Hack Master Series, a web pentesting CTF. Unleash your cybersecurity skills, collaborate with peers, and conquer challenges in this exciting event. Our objective is to foster ethical hacking, teamwork, and knowledge-sharing, while offering a realistic and challenging CTF experience. See your live rankings in the CTF leaderboard and showcase your talent in the world of cybersecurity!   </p>
   
   </div>
   <div className='col-lg-1'></div>
   <div className='col-lg-3 col-md-4 col-12 p-2 mt-4 mb-4'>
   <img src={Videoimg} alt="labs"  ></img>
   
   </div>
    
    </div>
   
    </div>
    
   </div>
 
  <div className='container mt-5 p-1'>
 
   <div className='row p-5'>
   
  {/*</div> <div className='col-lg-3 col-md-3 col-10'>
   <div className='row justify-content-center mt-2 mb-5 p-4 havocbtn'   ><span className='mr-3'><AiOutlineSearch size={30}/></span><h1 className='enterpreyhead'> OverView</h1></div>

   </div>
   <div className='row'>
   
   <div className='col-lg-3 col-md-3 col-10'>
   <div className='row justify-content-center mt-2 mb-5 p-4 havocbtn'   ><span className='mr-3'><AiOutlinePlusCircle size={30}/></span><h1 className='enterpreyhead'> Create Your Team</h1></div>
   </div>
   </div>
   <div className='row'>
   
   <div className='col-lg-3 col-md-3 col-10'>
   <div className='row justify-content-center mt-2 mb-5 p-4 havocbtn2'   ><span className='mr-3'><SlGraph size={30}/></span><h1 className='enterpreyhead'> Ranking</h1></div>
  </div>*/}
  <div className='col-lg-12 col-md-9 col-12 '>
 <div className='row p-2'>


  <div className=' col-lg-2 col-md-12 col-12 m-0' style={{background:"none"}} onClick={() => setitem("A")}>
  <NavLink to="/hackmasterseries" style={{textDecoration:"none"}}><div className={item ==="A" ? "row justify-content-center mt-2 mb-5 p-4 topbtn2":"row justify-content-center mt-2 mb-5 p-4 topbtn"}    > <h1 className='enterpreyhead'> OverView</h1></div></NavLink>
  </div>





  
  <div className=' col-lg-2 col-md-12 col-12 m-0' style={{background:"none"}} onClick={() => setitem("B")}>
  <div className={item ==="B" ? "row justify-content-center mt-2 mb-5 p-4 topbtn2":"row justify-content-center mt-2 mb-5 p-4 topbtn"}   ><h1 className='enterpreyhead'> Team</h1></div>

  </div>




  <div className=' col-lg-2 col-md-12 col-12 m-0' style={{background:"none"}} onClick={() => setitem("C")}>
  <NavLink to="/hackmasterseries/leaderboard" style={{textDecoration:"none"}}><div className={item ==="C" ? "row justify-content-center mt-2 mb-5 p-4 topbtn2":"row justify-content-center mt-2 mb-5 p-4 topbtn"}   ><h1 className='enterpreyhead'> Leaderboard</h1></div></NavLink>
  </div>
 
  </div>
  </div>
   </div>

 
  
  
  

  
 

   

            
 
  
 
  
  
  
  </div>
 
  <div className='container mt-0 p-1'>
  <div className='row'>

 {item === "A" && 
 <div className='container p-5'>
<div className='row'>
<div className='col-lg-8 col-md-8 col-12'>
<h1 className='overviewhead2'>Event Overview<div className='row'>
<span><hr className='havochr ml-4'></hr></span></div></h1>
<div className='row p-4 '>
<p className='havocpara'>
{bugbounty.desc}
</p>
</div>

{/*<div className='row mt-5'>
<div className=' col-lg-3 col-md-4 col p-3' id='havocbountycard' >
<div className='row justify-content-center mt-4'>
<AiOutlineTrophy color='#F7A80F' size={30}/>

</div>
<div className='row justify-content-center'>
<h1 className='text-center eventhead' >Event Prize</h1>
</div>
<div className='row justify-content-center'>
<p className='text-center eventpara'>$21000</p>
</div>



</div>

<div className=' col-lg-3 col-md-4 col p-3' id='havocbountycard' >
<div className='row justify-content-center mt-4'>
<AiOutlineTeam color='#F7A80F' size={30}/>

</div>
<div className='row justify-content-center'>
<h1 className='text-center eventhead' >Team Size</h1>
</div>
<div className='row justify-content-center'>
<p className='text-center eventpara'>1-5</p>
</div>



</div>
<div className=' col-lg-3 col-md-4 col p-3' id='havocbountycard' >
<div className='row justify-content-center mt-4'>
<AiOutlinePieChart color='#F7A80F' size={30}/>

</div>
<div className='row justify-content-center'>
<h1 className='text-center eventhead' >Challenges</h1>
</div>
<div className='row justify-content-center'>
<p className='text-center eventpara'>10</p>
</div>



</div>


</div>*/}
<div className='row mt-5 p-4'>
<h1 className='overviewhead'>Information<div className='row'>
<span><hr className='havochr ml-4'></hr></span></div></h1>

</div>
<div className='row  justify-content-center mt-4'>
<div className='col-lg-3 col-md-3 col-4' id='havocbountycard2'>
<span><h1 className='eventhead2'><AiOutlinePieChart  className='mr-2' color='#F7A80F' size={30}/>CTF Style</h1></span>
<p className='eventpara2'>Web penetration</p>
</div>
<div className='col-lg-3 col-md-3 col-4' id='havocbountycard2'>
<span><h1 className='eventhead2'><AiOutlineFieldTime  className='mr-2' color='#F7A80F' size={30}/>Duration</h1></span>
<p className='eventpara2'>24 Hours</p>
</div>
<div className='col-lg-3 col-md-3 col-4' id='havocbountycard2'>
<span><h1 className='eventhead2'><RiPriceTag3Line  className='mr-2' color='#F7A80F' size={30}/>Entry Fee</h1></span>
<p className='eventpara2'>Free</p>
</div>
<div className='col-lg-10 col-md-3 col-4' id='havocbountycard2'>
<span><h1 className='text-center eventhead2'><SlLocationPin  className='mr-2' color='#F7A80F' size={30}/>Location</h1></span>
<p className='eventpara2 text-center'>Global</p>
</div>
<div className='col-lg-3 col-md-3 col-4' id='havocbountycard2'>
<span><h1 className='eventhead2'><TbChartInfographic  className='mr-2' color='#F7A80F' size={30}/>Difficulty</h1></span>
<p className='eventpara2'>Easy To Hard</p>
</div>
<div className='col-lg-3 col-md-3 col-4' id='havocbountycard2'>
<span><h1 className='eventhead2'><AiOutlineFlag  className='mr-2' color='#F7A80F' size={30}/> Flags</h1></span>
<p className='eventpara2'>10</p>
</div>
<div className='col-lg-3 col-md-3 col-10' id='havocbountycard2'>
<span><h1 className='eventhead2'><AiOutlinePieChart  className='mr-2' color='#F7A80F' size={30}/>CTF Style</h1></span>
<p className='eventpara2'>Web penetration</p>
</div>
</div>
{/*<div className='row mt-5  p-4'>
<h1 className='overviewhead'>Ethical Guidelines<div className='row'>
<span><hr className='havochr ml-4'></hr></span></div></h1>

</div>
<div className='row p-2 mt-0'>
<ul>
<li className='havoclist'><span><img src={havoclogo} style={{height:"3rem",marginRight:"1rem"}} alt="logo"/></span>Lorem Ipsum is simply dummy text is simply dummy text is simply dummy text </li>
<li className='havoclist'><span><img src={havoclogo} style={{height:"3rem",marginRight:"1rem"}} alt="logo"/></span>Lorem Ipsum is simply dummy text is simply dummy text is simply dummy text </li>
<li className='havoclist'><span><img src={havoclogo} style={{height:"3rem",marginRight:"1rem"}} alt="logo"/></span>Lorem Ipsum is simply dummy text is simply dummy text is simply dummy text </li>
<li className='havoclist'><span><img src={havoclogo} style={{height:"3rem",marginRight:"1rem"}} alt="logo"/></span>Lorem Ipsum is simply dummy text is simply dummy text is simply dummy text </li>
<li className='havoclist'><span><img src={havoclogo} style={{height:"3rem",marginRight:"1rem"}} alt="logo"/></span>Lorem Ipsum is simply dummy text is simply dummy text is simply dummy text </li>
<li className='havoclist'><span><img src={havoclogo} style={{height:"3rem",marginRight:"1rem"}} alt="logo"/></span>Lorem Ipsum is simply dummy text is simply dummy text is simply dummy text </li>
<li className='havoclist'><span><img src={havoclogo} style={{height:"3rem",marginRight:"1rem"}} alt="logo"/></span>Lorem Ipsum is simply dummy text is simply dummy text is simply dummy text </li>
<li className='havoclist'><span><img src={havoclogo} style={{height:"3rem",marginRight:"1rem"}} alt="logo"/></span>Lorem Ipsum is simply dummy text is simply dummy text is simply dummy text </li>
<li className='havoclist'><span><img src={havoclogo} style={{height:"3rem",marginRight:"1rem"}} alt="logo"/></span>Lorem Ipsum is simply dummy text is simply dummy text is simply dummy text </li>
</ul>

</div>*/}



</div>
<div className='col-lg-1 col-md-1 col-1 mt-0'></div>
<div className='col-lg-3 col-md-9 col-12 mt-3'>
<br></br>
<div className='row justify-content-center mt-5 ' >

<div className='col-lg-12 col-md-12 col-10' id='havocsubmitbtn'>
<button className='btn'><span><img src={Gotolink} style={{height:"2rem",marginRight:"1.22rem"}}/></span>Start Your Bounty</button>
</div>
</div>

<div className='row justify-content-center mt-5' >
<div className='col-lg-12 col-md-12 col-10' id='havocsubmitbtn'>
<button className='btn'><span><AiOutlineFlag color='white' size={25} style={{marginRight:"1.2rem"}}/></span>Submit Flag</button>
</div>
</div>

<div className='row justify-content-center mt-5' >
<div className='col-lg-12 col-md-12 col-10' id='havocsubmitbtn'>
<button className='btn'><span><img src={Edit} style={{height:"2rem",marginRight:"1.2rem"}}/></span>Submit Review</button>
</div>
</div>

<div className='row justify-content-center  mt-5' >
<div className='col-lg-12 col-md-12 col-10' id='havocsubmitbtn'>
<button className='btn'><span><CgCommunity color='white' size={30} style={{marginRight:"1.2rem"}}/></span>Join our discord</button>
</div>
</div>
</div>
</div>
</div>





}
{item === "B" &&
<div className='container'>
<div className='row justify-content-center mb-5'>
<div className='col-lg-8 col-md-6 col-12'>
<div className='row justify-content-center'>

{designation ?<div className='col-lg-3 col-md-3 col-10' onClick={() => setitem2("A")}>


<div className={item2 ==="A" ? "row justify-content-center mt-2 mb-5 p-4 havocbtn3":"row justify-content-center mt-2 mb-5 p-4 havocbtn"}   ><h1 className='enterpreyhead'> Create Team</h1></div>


</div>:<div className='col-lg-3 col-md-3 col-10' onClick={() => setitem2("A")}>


<div className={item2 ==="A" ? "row justify-content-center mt-2 mb-5 p-4 havocbtn3":"row justify-content-center mt-2 mb-5 p-4 havocbtn"}   ><h1 className='enterpreyhead'> Create Team</h1></div>


</div> }

<div className='col-lg-3 col-md-3 col-10'   onClick={() => setitem2("B")} >
<div className={item2 ==="B" ? "row justify-content-center mt-2 mb-5 p-4 havocbtn3":"row justify-content-center mt-2 mb-5 p-4 havocbtn"}   ><h1 className='enterpreyhead'> Team Profile</h1></div>
</div>
<div className='col-lg-3 col-md-3 col-10' onClick={() => setitem2("C")}>
<div className={item2 ==="C" ? "row justify-content-center mt-2 mb-5 p-4 havocbtn3":"row justify-content-center mt-2 mb-5 p-4 havocbtn"}   ><h1 className='enterpreyhead'> Members</h1></div>
</div>
<div className='col-lg-3 col-md-3 col-10' onClick={() => setitem2("D")}>
<div className={item2 ==="D" ? "row justify-content-center mt-2 mb-5 p-4 havocbtn3":"row justify-content-center mt-2 mb-5 p-4 havocbtn"}   ><h1 className='enterpreyhead'> Invitations</h1></div>
</div>
</div>
</div>
<div className='col-lg-1'></div>

<div className='col-lg-3'></div>
</div>
   {item2 === "A" &&
<div className='row justify-content-center' >
<div className='col-lg-12 col-md-12 col'>
<form onSubmit={handleSubmit(onSubmit)}>
<div className='row justify-content-center'>
<div className='col-lg-8 col-md-8 col-12'>

<div className="havoc-input col-lg-12">
<div className="row">
<div className="col">
<label>Team Name</label>
<input
name="team_name"
type="text"

autoComplete='off'
placeholder={ teamdata.team_name||"Enter Teamname"}
className={`form-control mb-1 ${errors.team_name && "invalid"}`}

onChange={handleChange}

{...register("team_name", { required: "Team Name is Required",
pattern:/^[a-zA-Z 0-9 ]*$/,
  message:"Please Enter  Team name",
  maxLength:{
    value: 30,
    message: "Team Name should have maximum 30 characters"

  }
  
})}
onKeyUp={() => {
 trigger("team_name");
}}
/>
{errors.team_name && (
<small className="errormes ml-2 ">{errors.team_name.message}</small>
)} 
</div>
</div></div>
<div className="havoc-input col-lg-12">
<div className="row">
<div className="col">
<label>Team Moto</label>
<input
name="team_moto"
type="text"

autoComplete='off'
placeholder={ teamdata.team_moto||"Enter Team moto"}
className={`form-control mb-1 ${errors.team_moto && "invalid"}`}

onChange={handleChange}

{...register("team_moto", { required: "Team Moto is Required",
pattern:/^[a-zA-Z 0-9 ]*$/,
  message:"Please Enter  Team Moto",
  maxLength:{
    value: 50,
    message: "Team Moto should have maximum 50 characters"

  }
  
})}
onKeyUp={() => {
 trigger("team_moto");
}}
/>
{errors.team_moto && (
<small className="errormes ml-2 ">{errors.team_moto.message}</small>
)} 
</div>
</div></div>
<div className="havoc-input col-lg-12">
<div className="row">
<div className="col">
<label>College Name</label>
{/*<input
name="college_name"
type="text"

autoComplete='off'
placeholder={ teamdata.college_name||"Enter Your College Name"}
className={`form-control mb-1 ${errors.college_name && "invalid"}`}

onChange={handleChange}

{...register("college_name", { required: "College Name is Required",
pattern:/^[a-zA-Z 0-9 ]*$/,
  message:"Please Enter  College Name",
  maxLength:{
    value: 50,
    message: "College Name should have maximum 50 characters"

  }
  
})}
onKeyUp={() => {
 trigger("college_name");
}}
/>*/}
<select   className={`form-control mb-1 ${errors.college_name && "invalid"}`} placeholder={ teamdata.college_name||"Enter Your College Name"}  name="college_name"   {...register("college_name", { required: "College Name is Required",
pattern:/^[a-zA-Z 0-9 ]*$/,
message:"Please Enter  College Name",
  
})}   >
    

{collegename.map((college,id)=>(
 <option key={id} value={college.university} className='option'> {college.university}</option>


))}
                    
                     </select>
{errors.college_name && (
<small className="errormes ml-2 ">{errors.college_name.message}</small>
)} 
</div>
</div></div>
<div className="havoc-input col-lg-12">
<div className="row">
<div className="col">
<label>Country</label>

<select   className={`form-control mb-1 ${errors.country && "invalid"}`} placeholder="India"  name="country"    {...register("country", { required: "Team Name is Required",
pattern:/^[a-zA-Z 0-9 ]*$/,
  message:"Please Enter  Team name",
  
})}   >
    
<option className='option'>India</option>
<option className='option'>UAE</option>
{searchdata.map((course,id)=>(
 <option key={id} value={course.name.common} className='option'> {course.name.common}</option>

))}
                    
                     </select>
{errors.country && (
<small className="errormes ml-2 ">{errors.country.message}</small>
)} 
</div>
</div></div>
<div className="havoc-input col-lg-12">
<div className="row">
<div className="col">
<label> Description</label>
<input
name="team_description"
type="text"

autoComplete='off'
placeholder={ teamdata.team_description||"Enter Description"}

className={`form-control mb-1 ${errors.team_description && "invalid"}`}

onChange={handleChange}

{...register("team_description", { required: "Team Description is Required",
pattern:/^[a-zA-Z 0-9 ]*$/,
  message:"Please Enter  Team description",
  maxLength:{
    value: 100,
    message: "Team Description should have maximum 100 characters"

  }
  
})}
onKeyUp={() => {
 trigger("team_description");
}}
/>
{errors.team_description && (
<small className="errormes ml-2 ">{errors.team_description.message}</small>
)} 
</div>
</div></div>



</div>
<div className='col-lg-1'> </div>

<div className='col-lg-3 col-10 mt-5 order-lg-1'>

<div className='teamprofilephotodiv'>
{previewUrl ? <img src={previewUrl} style={{height:"15rem",width:"100%"}} alt="author_profile" />:<img src={ havoclogo} style={{width:"100%"}}/>}

</div>
<div className='row justify-content-center'>
<div className='col-lg-10 col-4'>
<input
name="photo"
ref={filePickerRef}
style={{ display: "none" }}
type="file"
accept="image/png, image/jpeg,image/jpg"
id='revphoto'
onChange={handleImageUpload2}


/>
<div className='btn mt-5' id='editteambtn2' onClick={handleFilePicker}>Upload Team Image</div>
</div>

</div>

</div>

</div>

<div className='row justify-content-center'>
<div className='col-lg-3 col-3 mt-5'>
<button  className='btn mt-5 mb-5' id='editteambtn' onSubmit={handleSubmit(onSubmit)}>Save Team</button>
</div>
</div>
</form>

</div>






















</div> 

}




{item2 ==="B" &&

<div className='row' >
<div className='col-lg-12 col-md-12 col-12 p-5'>
<div className='row'>
<div className='col-lg-5 col-md-3 col-4'>
<h1 className='teamnamehead'>{teamdata.team_name || "Your Team name"}</h1>
<p className='teamnamepara'>{teamdata.team_moto ||"Your Team moto" }</p>
</div>
<div className='col-lg-1 col-md-3 col'>

</div>
<div className='col-lg-2 col-md-3 col'>

</div>
<div className='col-lg-4 col-md-3 col-6'>
{designation ? <div className='row'>


<div className='col-lg-6 col-md-6 col'>

</div>
<div className='col-lg-6 col-md-6 col'>
<button className='btn' id='editteambtn' onClick={deleteadminteam}>Delete Team</button>
</div>
</div> : <div className='row'>

<div className='col-lg-6 col-md-6 col'>
</div>
<div className='col-lg-6 col-md-6 col'>
<button className='btn' id='editteambtn' onClick={Leaveteam}>Leave Team</button>

</div>

</div> }

</div>
</div>
<div className='row mt-5 p-4 justify-content-center'>
<div className='col-lg-12 col-md-12 col-10' id='teamprofilecard'>
<div className='row'>
<div className='col-lg-7 col-md-7 col p-2 '>
<div className='row mt-5'>
<div className='col-lg-6'>
<h1 className='teamrowhead'>SKIPPER'S EMAIL</h1>
</div>
<div className='col-lg-6 mt-4'>
<h1 className='teamrowhead2'>{teamdata.admin_email ||"-"}</h1></div>
</div>
<div className='row'>
<div className='col-lg-6 '>
<h1 className='teamrowhead'>COUNTRY</h1>
</div>
<div className='col-lg-6 mt-4'>

<h1 className='teamrowhead2'>{teamdata.country ||"-"}</h1></div>
</div>
<div className='row'>
<div className='col-lg-6'>
<h1 className='teamrowhead'>TEAM MEMBERS</h1>
</div>
<div className='col-lg-6 mt-4'>
<h1 className='teamrowhead2'>{totalteammember ||"--"}</h1>
</div>
</div>
<div className='row'>
<div className='col-lg-6'>
<h1 className='teamrowhead'>TEAM RANK</h1>
</div>
<div className='col-lg-6 mt-4'>
<h1 className='teamrowhead2'>{teamrank || "--"}</h1>
</div>
</div>
<div className='row'>
<div className='col-lg-6'>
<h1 className='teamrowhead'>TEAM SCORE</h1>
</div>
<div className='col-lg-6 mt-4'>
<h1 className='teamrowhead2'>{teamdata.score || "--"}</h1>
</div>
</div>
</div>
<div className='col-lg-5 col-md-5 col'>

<div className='row'>
<div className='col-lg-4 col-md-4 col'></div>
<div className='col-lg-8 col-md-8 col p-5'>
<div className='teamprofilephotodiv'>
{!previewUrl ? (
  <div className="testdummydiv">
  <img src={teamdata.photo|| havoclogo} alt="preview" />
  </div>
) : (
  <img src={previewUrl} alt="preview" />
)}
</div>

</div>
</div>

</div>

</div>

{designation ?<div className='row justify-content-center'>
<div >  <button className='btn mt-2 mb-5' id='editteambtn' onClick={openteammodal}>ADD MEMBERS</button></div>
</div> :<div className='row justify-content-center'>
<div >  <button className='btn mt-2 mb-5' id='editteambtn' disabled={true}>ADD MEMBERS</button></div>
</div>}

</div>
</div>


</div>

{/*<form>
<div className='row'>
<div className='col-lg-8 col-md-8 col-12'>

<div className="form-input col-lg-12">
<div className="row">
<div className="col">
<label>Team Name</label>
<input
name="fname"
type="text"

autoComplete='off'
placeholder={ "Enter Firstname"}

className={"form-control mb-1"}


  

  
  />
</div>


</div>
<div className="row">
<div className="col">
<label>Team Name</label>
<input
name="fname"
type="text"

autoComplete='off'
placeholder={ "Enter Firstname"}

className={"form-control mb-1"}


  

  
  />
</div>


</div>
<div className="row">
<div className="col">
<label>Team Name</label>
<input
name="fname"
type="text"

autoComplete='off'
placeholder={ "Enter Firstname"}

className={"form-control mb-1"}


  

  
  />
</div>


</div>
<div className="row">
<div className="col">
<label>Team Name</label>
<input
name="fname"
type="text"

autoComplete='off'
placeholder={ "Enter Firstname"}

className={"form-control mb-1"}


  

  
  />
</div>


</div>
<div className="row">
<div className="col">
<label>Team Name</label>
<input
name="fname"
type="text"

autoComplete='off'
placeholder={ "Enter Firstname"}

className={"form-control mb-1"}


  

  
  />
</div>


</div>
</div>
</div>
<div className='col-lg-4 col-md-4 col mt-5 '>
<div className='row justify-content-center'>

 <div className="havocteampic">
<MdPhoto  size={80} color="white"/>

 </div>

</div>



<input


  name="file"

  style={{ display: "none" }}
  type="file"

  
  accept="image/png, image/jpeg,image/jpg"
/>





 <div className='row justify-content-center ml-0 mb-0'>
<button className='btn savebtn ' >Upload Image</button></div> 
</div>

</div>
<div className='row justify-content-center mt-5 mb-5'>
<button className='btn savebtn ' >Save Team</button>
</div>

</form>*/}











</div>












}
{item2 ==="C" &&
<div className='row justify-content-center' >
{designation ?<div className='col-lg-12 col-md-12 col-10'>
<div className='row' id='teamrow'>
<div className='col-lg-2 col'>
<h1 className='rankteamrow'>NAme</h1>
</div>
<div className='col-lg-2 col'>
<h1 className='rankteamrow'>Role</h1>
</div>
<div className='col-lg-3 col'>
<h1 className='rankteamrow'>Email</h1>
</div>
<div className='col-lg-2 col'>
<h1 className='rankteamrow'>Country</h1>
</div>
<div className='col-lg-3 col'>
<h1 className='rankteamrow'>Status</h1>
</div>
</div>
{teamdata.team_member  && teamdata.team_member.map((teamdatas,id)=>(
  
  
 
  <div className='row' id='teamrow2'>
  <div className='col-lg-2 col'>
  <h1 className='teamrowhead3 pt-4'><span><img src={teamdatas.photo ||User2} style={{height:"3rem",marginRight:"1rem",borderRadius:"50%"}} /></span>{teamdatas.fname}</h1>
  </div>
  <div className='col-lg-2 col'>
  <h1 className='teamrowhead3 mt-3'>{getLevelName(teamdatas.invite_email)} </h1>
  </div>
  <div className='col-lg-3 col'>
  <h1 className='teamrowhead3 mt-3'>{teamdatas.invite_email} </h1>
  </div>
  <div className='col-lg-2 col'>
  <h1 className='teamrowhead3 mt-3'>{teamdata.country}</h1>
  </div>
  <div className='col-lg-3 col'>
  <div className='row'>
  <div className='col'> <h1 className='teamrowhead3 mt-3'>{ getLevelName2(JSON.stringify(teamdatas.status)) }</h1></div>
 
  {designation ?<div className='col'><button className='btn editteambtn mt-3' id={teamdatas.invite_email} onClick={Deleteteam}>Remove</button></div> :null}
  </div>
 
  </div>
  </div>

))

}



</div>:
<div className='col-lg-12 col-md-12 col'>
<div className='row' id='teamrow'>
<div className='col-lg-2 col'>
<h1 className='rankteamrow'>NAme</h1>
</div>
<div className='col-lg-2 col'>
<h1 className='rankteamrow'>Role</h1>
</div>
<div className='col-lg-3 col'>
<h1 className='rankteamrow'>Email</h1>
</div>
<div className='col-lg-2 col'>
<h1 className='rankteamrow'>Country</h1>
</div>
<div className='col-lg-3 col'>
<h1 className='rankteamrow'>Status</h1>
</div>
</div>
{teamdata.team_member  ? teamdata.team_member.map((teamdatas,id)=>(
  
  
 
  <div className='row' id='teamrow2'>
  <div className='col-lg-2 col'>
  <h1 className='teamrowhead3'><span><img src={teamdatas.photo || User2} style={{height:"3rem",marginRight:"1rem",borderRadius:"50%"}} /></span>{teamdatas.fname}</h1>
  </div>
  <div className='col-lg-2 col'>
  <h1 className='teamrowhead3'>{getLevelName(teamdatas.invite_email)} </h1>
  </div>
  <div className='col-lg-3 col'>
  <h1 className='teamrowhead3'>{teamdatas.invite_email} </h1>
  </div>
  <div className='col-lg-2 col'>
  <h1 className='teamrowhead3'>{teamdata.country}</h1>
  </div>
  <div className='col-lg-3 col'>
  <div className='row'>
  <div className='col'> <h1 className='teamrowhead3'>{ getLevelName2(JSON.stringify(teamdatas.status)) }</h1></div>
 
  {designation ?<div className='col'><button className='btn editteambtn' id={teamdatas.invite_email} onClick={Deleteteam}>Remove</button></div> :null}
  </div>
 
  </div>
  </div>

)): <div className='row justify-content-center p-5' >
<div className='col-lg-8 col-md-12 col-10' id='teamprofilecard'>
<div className='row'>
<div className='col-lg-4 col-md-6 col p-5'>
<img src={havoclogo} />
</div>

<div className='col-lg-8 col-md-6 col p-5 pb-5'>
<div className='justify-content-center'><h2 className='text-center alertmainhead2'>Get Ready for Hack Master<div className='row justify-content-center'><span><hr className='text-center alertcommonhr'></hr></span></div></h2>  
<p className='resetpara'>You are not currently part of any team for Hack Master. Create your own team and assemble your hacking dream team, or check your invitations to join an existing team. Exciting challenges await, so don't miss out on the action! </p>
</div>


</div>
</div>
</div> 
 </div>

}
 </div>
}


  
</div>

}
{item2 ==="D" &&

<div className='row justify-content-center' >

{invitebyteam.length=== 0?
  <div className='row justify-content-center p-5' >
 <div className='col-lg-8 col-md-12 col-10' id='teamprofilecard'>
 <div className='row'>
<div className='col-lg-4 col-md-6 col p-5'>
<img src={havoclogo} />
</div>

<div className='col-lg-8 col-md-6 col p-5 pb-5'>
<div className='justify-content-center'><h2 className='text-center alertmainhead2'>No New Invitation<div className='row justify-content-center'><span><hr className='text-center alertcommonhr'></hr></span></div></h2>  
<p className='resetpara'>Currently, you have no pending invites to join a team for Hack Master. Take the lead and create your own team to embark on this thrilling web pentesting CTF adventure! </p>
</div>


</div>
</div>
 </div> 
  </div>
  :<div className='col-lg-12 col-md-12 col-10'>
  <div className='row' id='teamrow'>
  <div className='col-lg-2 col'>
  <h1 className='rankteamrow'>Team</h1>
  </div>
  <div className='col-lg-2 col'>
  <h1 className='rankteamrow'>Team Moto</h1>
  </div>
  <div className='col-lg-3 col'>
  <h1 className='rankteamrow'>Skipper's Email</h1>
  </div>
  <div className='col-lg-2 col'>
  <h1 className='rankteamrow'>Country</h1>
  </div>
  <div className='col-lg-3 col'>
  <h1 className='rankteamrow'>Status</h1>
  </div>
  </div>
  
  
  
  
  
   {invitebyteam && invitebyteam.map((teamdatas2,id)=>(
    <div className='row' id='teamrow2'>
    <div className='col-lg-2 col'>
    <h1 className='teamrowhead3'><span><img src={User2} style={{height:"3rem",marginRight:"1rem"}} /></span>{teamdatas2.team_name}</h1>
    </div>
    <div className='col-lg-2 col pt-2'>
    <h1 className='teamrowhead3'>team moto</h1>
    </div>
    <div className='col-lg-3 col pt-2'>
    <h1 className='teamrowhead3'>{teamdatas2.admin_email}</h1>
    </div>
    <div className='col-lg-2 col pt-2'>
    <h1 className='teamrowhead3'>{teamdatas2.country}</h1>
    </div>
    <div className='col-lg-3 col'>
   <div className='row'>
   <div className='col-lg-6'>
   <button className='btn editteambtn'  id={teamdatas2.invited_email} onClick={Acceptinvite}>Accept</button>
   </div>
   <div className='col-lg-6'>
   <button className='btn editteambtn'  id={teamdatas2.invited_email} onClick={Rejectinvite}>Reject</button>
   </div>
   </div>
    </div>
    </div>

   ))} 
   
   
    
    


  
  
  </div>}

  
</div>

}
</div>

}
{item === "C" && 
<div className='container'>
<div className='row justify-content-center p-5' >
<div className='col-lg-8 col-md-12 col-10' id='teamprofilecard'>
<div className='row'>
<div className='col-lg-4 col-md-6 col p-5'>
<img src={havoclogo} />
</div>

<div className='col-lg-8 col-md-6 col p-5 pb-5'>
<div className='justify-content-center'><h2 className='text-center alertmainhead2'>Live Ranking<div className='row justify-content-center'><span><hr className='text-center alertcommonhr'></hr></span></div></h2>  
<p className='resetpara'>Live Rankings Begin on Hack Master Date! Track Your Team's Progress as the CTF Unfolds. Stay Tuned for Real-time Leaderboard Updates!</p>
</div>


</div>
</div>
</div> 
 </div>

{/*<div className='row justify-content-center ' id='teamrow'>
      <div className='col-lg-2 col-md-2 col-1'  ><h1 className='leaderboardrank'>RANK</h1></div>
      <div className='col-lg-2 col-md-2 col-3'  ><h1 className='leaderboardrank'>Rank name</h1></div>
      <div className='col-lg-2 col-md-2 col-2'  ><h1 className='leaderboardrank'>Name</h1></div>
      <div className='col-lg-2 col-md-2 col-2'  > <h1 className='leaderboardrank'>score</h1></div>
      <div className='col-lg-2 col-md-2 col-3'  > <h1 className='leaderboardrank'>challenges</h1></div>
      <div className='col-lg-2 col-md-2 col-1'  ><h1 className='leaderboardrank'>Labs</h1></div>
     
     
      </div>
      <div className='row justify-content-center ' id='teamrow2'>
      <div className='col-lg-2 col-md-2 col-1'  ><h1 className='teamrowhead3'>RANK 1</h1></div>
      <div className='col-lg-2 col-md-2 col-3'  ><h1 className='teamrowhead3'>100</h1></div>
      <div className='col-lg-2 col-md-2 col-2'  ><h1 className='teamrowhead3'><span><img src={User2} style={{height:"3rem",marginRight:"1rem"}} /></span>Avenger</h1></div>
      <div className='col-lg-2 col-md-2 col-2'  > <h1 className='teamrowhead3'  >1</h1></div>
      <div className='col-lg-2 col-md-2 col-3'  > <h1 className='teamrowhead3'>2</h1></div>
      <div className='col-lg-2 col-md-2 col-1'  ><h1 className='teamrowhead3'>3</h1></div>

</div>
<div className='row justify-content-center ' id='teamrow2'>
      <div className='col-lg-2 col-md-2 col-1'  ><h1 className='teamrowhead3'>RANK 1</h1></div>
      <div className='col-lg-2 col-md-2 col-3'  ><h1 className='teamrowhead3'>100</h1></div>
      <div className='col-lg-2 col-md-2 col-2'  ><h1 className='teamrowhead3'><span><img src={User2} style={{height:"3rem",marginRight:"1rem"}} /></span>Avenger</h1></div>
      <div className='col-lg-2 col-md-2 col-2'  > <h1 className='teamrowhead3'  >1</h1></div>
      <div className='col-lg-2 col-md-2 col-3'  > <h1 className='teamrowhead3'>2</h1></div>
      <div className='col-lg-2 col-md-2 col-1'  ><h1 className='teamrowhead3'>3</h1></div>

</div>
<div className='row justify-content-center ' id='teamrow2'>
      <div className='col-lg-2 col-md-2 col-1'  ><h1 className='teamrowhead3'>RANK 1</h1></div>
      <div className='col-lg-2 col-md-2 col-3'  ><h1 className='teamrowhead3'>100</h1></div>
      <div className='col-lg-2 col-md-2 col-2'  ><h1 className='teamrowhead3'><span><img src={User2} style={{height:"3rem",marginRight:"1rem"}} /></span>Avenger</h1></div>
      <div className='col-lg-2 col-md-2 col-2'  > <h1 className='teamrowhead3'  >1</h1></div>
      <div className='col-lg-2 col-md-2 col-3'  > <h1 className='teamrowhead3'>2</h1></div>
      <div className='col-lg-2 col-md-2 col-1'  ><h1 className='teamrowhead3'>3</h1></div>

</div>
<div className='row justify-content-center ' id='teamrow2'>
      <div className='col-lg-2 col-md-2 col-1'  ><h1 className='teamrowhead3'>RANK 1</h1></div>
      <div className='col-lg-2 col-md-2 col-3'  ><h1 className='teamrowhead3'>100</h1></div>
      <div className='col-lg-2 col-md-2 col-2'  ><h1 className='teamrowhead3'><span><img src={User2} style={{height:"3rem",marginRight:"1rem"}} /></span>Avenger</h1></div>
      <div className='col-lg-2 col-md-2 col-2'  > <h1 className='teamrowhead3'  >1</h1></div>
      <div className='col-lg-2 col-md-2 col-3'  > <h1 className='teamrowhead3'>2</h1></div>
      <div className='col-lg-2 col-md-2 col-1'  ><h1 className='teamrowhead3'>3</h1></div>

</div>
<div className='row justify-content-center ' id='teamrow2'>
      <div className='col-lg-2 col-md-2 col-1'  ><h1 className='teamrowhead3'>RANK 1</h1></div>
      <div className='col-lg-2 col-md-2 col-3'  ><h1 className='teamrowhead3'>100</h1></div>
      <div className='col-lg-2 col-md-2 col-2'  ><h1 className='teamrowhead3'><span><img src={User2} style={{height:"3rem",marginRight:"1rem"}} /></span>Avenger</h1></div>
      <div className='col-lg-2 col-md-2 col-2'  > <h1 className='teamrowhead3'  >1</h1></div>
      <div className='col-lg-2 col-md-2 col-3'  > <h1 className='teamrowhead3'>2</h1></div>
      <div className='col-lg-2 col-md-2 col-1'  ><h1 className='teamrowhead3'>3</h1></div>

</div>
<div className='row justify-content-center ' id='teamrow2'>
      <div className='col-lg-2 col-md-2 col-1'  ><h1 className='teamrowhead3'>RANK 1</h1></div>
      <div className='col-lg-2 col-md-2 col-3'  ><h1 className='teamrowhead3'>100</h1></div>
      <div className='col-lg-2 col-md-2 col-2'  ><h1 className='teamrowhead3'><span><img src={User2} style={{height:"3rem",marginRight:"1rem"}} /></span>Avenger</h1></div>
      <div className='col-lg-2 col-md-2 col-2'  > <h1 className='teamrowhead3'  >1</h1></div>
      <div className='col-lg-2 col-md-2 col-3'  > <h1 className='teamrowhead3'>2</h1></div>
      <div className='col-lg-2 col-md-2 col-1'  ><h1 className='teamrowhead3'>3</h1></div>

</div>
<div className='row justify-content-center ' id='teamrow2'>
      <div className='col-lg-2 col-md-2 col-1'  ><h1 className='teamrowhead3'>RANK 1</h1></div>
      <div className='col-lg-2 col-md-2 col-3'  ><h1 className='teamrowhead3'>100</h1></div>
      <div className='col-lg-2 col-md-2 col-2'  ><h1 className='teamrowhead3'><span><img src={User2} style={{height:"3rem",marginRight:"1rem"}} /></span>Avenger</h1></div>
      <div className='col-lg-2 col-md-2 col-2'  > <h1 className='teamrowhead3'  >1</h1></div>
      <div className='col-lg-2 col-md-2 col-3'  > <h1 className='teamrowhead3'>2</h1></div>
      <div className='col-lg-2 col-md-2 col-1'  ><h1 className='teamrowhead3'>3</h1></div>

</div>*/}



</div>


}
   </div>
  </div>
  </div>
  </div>

  )
}

export default Hackerhavocoverview





