import React, { useEffect, useState } from 'react'

import "./Common.css";
import Activity from "../../../Assets/images/Activity.svg"
import basic from "../../../Assets/subsplan/Basic.svg"
import Webhack from "../../../Assets/Landing page/webhacklogo.png"
import Dot from "../../../Assets/images/Activity.svg";
import Vip from "../../../Assets/subsplan/VIP.svg";
import Enterprise from "../../../Assets/subsplan/Enterprise.svg";
import axios from 'axios';
import { event } from 'jquery';
import { useNavigate, useParams } from "react-router-dom"
import Loading from '../Modals/Loading';
import Susbxriptionsaves from '../Modals/Susbxriptionsaves';
import Fakesubscription from '../Modals/Fakesubscription';
function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement('script')
    script.src = src
    script.onload = () => {
      resolve(true)
    }
    script.onerror = () => {
      resolve(false)
    }
    document.body.appendChild(script)
  })
}

const __DEV__ = document.domain === 'localhost'
const Extendsubscription = (props) => {
  const [plan, setplan] = useState([]);
  const API_Key = process.env.REACT_APP_API_URL;
  const API_Key2 = process.env.REACT_APP_RAZORPAY_ID;

  const [coursename, setcoursename] = useState("");
  const [modal, setopenmodal] = useState(false);
  const [modal2, setopenmodal2] = useState(false);
  const [modal3, setopenmodal3] = useState(false);
  const [plansid, setplansid] = useState();
  const [plandetail, setplaindetail] = useState([]);
  const navigate = useNavigate();
  const params = useParams()

  const subscibe = async (event) => {
    const { id } = event.target;

    event.preventDefault();
    const data = { planid: id, courseid: params.course_id }
    fetch(`${API_Key}/purchaseextendPlan`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': `${API_Key}`,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
      },
      credentials: "include",
      body: JSON.stringify(data),



    })
      .then(async (response) => {
        const datas = await response.json();
        const acutalamounts = datas.acutalamount;


        const data = { userid: datas.userid, planid: datas.planid, currency: datas.currency, receipt: datas.receipt, amount: datas.amount, courseid: datas.courseid }

        fetch(`${API_Key}/createorder_extendplan`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': `${API_Key}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': 'true',
          },
          credentials: "include",
          body: JSON.stringify(data),

        })
          .then(async (response) => {
            const datas = await response.json();


            //event.preventDefault();
            //const dataa = { subject:credential.subject,body:credential.body};



            const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

            if (!res) {
              alert('Razorpay SDK failed to load. Are you online?')
              return
            }
            if (datas.amount / 100 !== acutalamounts) {

              setopenmodal3(true);



            } else {
              const options = {
                key: `${API_Key2}`,
                currency: datas.currency,
                amount: datas.amount,
                order_id: datas.id,
                name: 'Hackersprey',
                description: datas.receipt,
                image: basic,
                handler: function (response) {

                  const money = { userid: datas.userid, order_id: response.razorpay_order_id, payment_id: response.razorpay_payment_id }

                  const signature = {
                    'x-razorpay-signature': [response.razorpay_signature],
                  }

                  fetch(`${API_Key}/verifyextendPayment`, {
                    method: 'POST',
                    headers: {
                      'Accept': 'application/json',
                      'Access-Control-Allow-Origin': `${API_Key}`,
                      'Content-Type': 'application/json',
                      'Access-Control-Allow-Credentials': 'true',
                      'x-razorpay-signature': [response.razorpay_signature]

                    },

                    credentials: "include",
                    body: JSON.stringify(money),


                  })
                    .then(async (response) => {
                      if (response.status === 200) {
                        setopenmodal2(true);
                      }
                    }).catch((error) => {
                      console.error('Error:', error);
                      //setModalOpen2(true);

                    })
                    .catch((error) => {
                      console.error('Error:', error);
                      //setModalOpen2(true);

                    })
                },
                prefill: {
                  name: "none",
                  email: 'none',
                  phone_number: 'none'
                }
              }
              const paymentObject = new window.Razorpay(options)
              paymentObject.open()



            }


          })



      })


      //Then with the error genereted...
      .catch((error) => {
        console.error('Error:', error);
        //setModalOpen2(true);

      });

  }

  const fetchCoursedesc = async () => {
    //setopenmodal(true);

    let course_id = params.course_id;

    fetch(`${API_Key}/getextendplans`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        mode: "cors",
        'Access-Control-Allow-Origin': `${API_Key}`,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': true
      },
      credentials: "include",
      body: JSON.stringify({ courseid: course_id }),
    })
      .then(async (response) => {

        const data = await response.json();

        setplan(data.extendPlans);
        setcoursename(data.coursename);


        //setmodal(false);
        if (response.status === 200) {
          //navigate("/welcome")
          //setModalOpen(false);
          //setModalOpen2(true);
          setTimeout(() => {
            //setModalOpen2(false);
          }, 3000);

        } else if (response.status === 400) {
          //setModalOpen3(true);
          //setModalOpen(false);
          setTimeout(() => {
            //setModalOpen3(false);
          }, 3000);

        }


      })

      .catch((error) => {
        console.error('Error:', error);
        //setModalOpen3(true);
      });








  }
  useEffect(() => {
    fetchCoursedesc();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      {modal && <Loading setOpenModal={setopenmodal} />}
      {modal2 && <Susbxriptionsaves setOpenModal={setopenmodal2} />}
      {modal3 && <Fakesubscription setOpenModal={setopenmodal3} />}


      <div className='background_dashboard p-2'>
        <div className='commontopper'>
          <div className='container'>
            <div className='row justify-content-center'>
              <h1 className='commonhead'>Extend a Plan<div className='row justify-content-center'><hr className='commonheadhr'></hr></div></h1>


            </div>
            <div className='row justify-content-center'>
              <p className='Commonpara'>Wish to extend your {coursename} Journey ? Extend your subscription for continuous learning. </p>
            </div>
          </div>


        </div>

        <div className='container-fluid mt-5 p-5'>
          <div className='row justify-content-center p-5  '>

            {plan.map((plans, id) => (
              <div className='col-lg-3 col-10 m-5' >
                <div className='card p-5' id='subscard'>
                  <div className='row justify-content-center'>
                    <div className='col-lg-5 col'> <img src={plans.image} alt="alt" /></div>
                    <div className='col-lg-7 col pt-3'> <h2 className='subplanhead'>{plans.name}</h2>
                      <h6 className='subplanpara '> <span className='subplanparadspan'>&#x20B9;</span>{plans.price}<span className='subplanparadspan'></span></h6>
                    </div>

                  </div>
                  <div className='row  justify-content-center mt-5'>

                    <div className='col-lg-12'>
                      <hr className='subplanhr'></hr>

                    </div>


                  </div>
                  <div className='row  justify-content-center mt-1'>

                    <div className='col-lg-12 col-12 '>
                      {plans.features.map((planextend, id) => (<ul>
                        <li className='subplanfeaturepara'><span className='mr-3'> <img src={Dot} alt="dot" style={{ height: "1.5rem" }} /></span>{planextend.feature}</li>
                      </ul>))}



                    </div>


                  </div>
                  <div className='row justify-content-center '>
                    <div className='col-lg-8'>
                      <button className='btn  subsplansubscribebtn' id={plans._id} onClick={subscibe}>SUBSCRIBE</button>
                    </div>

                  </div>
                </div>
              </div>
            ))}


          </div>
        </div>
      </div>
    </div>
  )
}

export default (Extendsubscription)