import React, { useState } from "react";
import "./BinaryCurriculum.css";
import { FaChevronRight,FaChevronDown } from "react-icons/fa";
import { TfiDownload } from "react-icons/tfi";



const BinaryCurriculum = ({curriculum}) => {
  const [selectedState, setSelectedState] = useState("");
  const handleClick=(index)=>{
if(index===selectedState){
    setSelectedState("")
}
else{
    setSelectedState(index)
}
}
  
  return (
    <div className="curr-container p-5">
      <div className="course-cur-head">
      
        <div className="bg-curriculum">
        <div className="course-cur-head margin-class"><p>Course Curriculum</p></div>
        <div className=" row justify-content-center">
            <hr className="binaryhr2"></hr>
          </div>
          <main className="curriculum-container">
            {curriculum.map((section, index) => (
              <div className="section-container">
                <div
                  onClick={() => handleClick(index)}
                  className="section-name"
                >
                  {section.sectionName}
                  {selectedState === index   ? <FaChevronDown className="icon" size={13} />:  <FaChevronRight className="icon"  size={13}/>
}</div>
                {selectedState === index  ? (
                  <p className="section-description">{section.sectionDesc}</p>
                ) : null}
              </div>
            ))}
          </main>
          <div className="download-btn-cont"><a href="https://hackersprey.com/StackXploit Syllabus.pdf" target="_blank" rel="noopener noreferrer" className="download-btn"><TfiDownload/>Program Syllabus</a></div>
        </div>
      
      </div>
     
    </div>
  );
};

export default BinaryCurriculum;
