import React, { useState, useEffect } from "react";
import { MdOutlineChevronRight } from "react-icons/md";
import { Line } from "rc-progress";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { IoMdLock } from "react-icons/io";
import { MdKeyboardArrowDown } from "react-icons/md";
import { AiOutlineClockCircle } from "react-icons/ai";
import { CgExtensionAdd } from "react-icons/cg";
import Doc from "../../../Assets/Entity/Lab/Doc.svg";
import user from "../../../Assets/Entity/Course/web.png";
import axios from "axios";
import { NavLink, useNavigate, Link, useParams } from "react-router-dom";
import Gotolink from "../../../Assets/Entity/Lab/Go To Link (1).svg";
import users from "../../../Assets/images/Mask_Group_19.png";
import Loading from "../Modals/Loading";
import Spinner from "../spinner/Spinner";
import moment from "moment";
import { MdOndemandVideo } from "react-icons/md";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";




const Dashcoursedesc = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const [userdetail, setuserdetail] = useState([]);
  const [Resource, setResource] = useState(true);
  const [Reviews, setReviews] = useState(true);
  const [desc, setdesc] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [course, setcourse] = useState([]);
  const [postlist, setpostlist] = useState("");
  const [isSticky, setIsSticky] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setopenmodal] = useState(false);
  const [selectedSection, setSelectedSection] = useState("");
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setIsSticky(offset > 200);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const API_Key = process.env.REACT_APP_API_URL;
  const startvpn = async () => {
    setopenmodal(true);

    try {
      const res = await fetch(`${API_Key}/startvpn`, {
        method: "GET",
        headers: {
          mode: "cors",
          "Access-Control-Allow-Origin": `${API_Key}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
        credentials: "include",
      });
      const data = await res.json();
      if (res.status === 200) {
        window.alert("vpn cconected");
        setopenmodal(false);
      }

      if (res.status !== 200) {
        const error = new Error(res.error);
        throw error;
      }
    } catch (err) {
      console.log(err);
      //navigate("/signin");
    }
  };
  const stopvpn = async () => {
    setopenmodal(true);

    try {
      const res = await fetch(`${API_Key}/disconnect-vpn`, {
        method: "GET",
        headers: {
          mode: "cors",
          "Access-Control-Allow-Origin": `${API_Key}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
        credentials: "include",
      });
      const data = await res.json();
      if (res.status === 200) {
        window.alert("vpn discconected");
        setopenmodal(false);
      }

      if (res.status !== 200) {
        const error = new Error(res.error);
        throw error;
      }
    } catch (err) {
      console.log(err);
      //navigate("/signin");
    }
  };

  /*const fetchCoursedesc = async () => {
  setopenmodal(true);

  let course_id = props.match.params.course_id;
  try{
    const res = await fetch(`${API_Key}/getcourse/` + course_id,{
      method:"GET",
      headers:{
        mode: 'cors',
        'Access-Control-Allow-Origin':`${API_Key}`,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': true,
      },
      credentials:"include"
    
     

       
    });
    const data = await res.json();
    
    setcourse(data);
    setopenmodal(false);

    if(res.status !== 200 ){
        const error = new Error(res.error);
        throw error;
        
    }
}catch(err){
  console.log(err);
  navigate("/signin");

} 

}
useEffect(() => {
  fetchCoursedesc();
},[ ] );*/


  const fetchCourseexpirydata = async () => {
    setopenmodal(true);

    try {
      const res = await fetch(
        `${API_Key}/know_expire_plan/${params.course_id}`,
        {
          method: "GET",
          headers: {
            mode: "cors",
            "Access-Control-Allow-Origin": `${API_Key}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Credentials": true,
          },
          credentials: "include",
        }
      );
      const data = await res.json();

      setuserdetail(data);
      setopenmodal(false);

      if (res.status !== 200) {
        const error = new Error(res.error);
        throw error;
      }
    } catch (err) {
      console.log(err);
    }
  };



  useEffect(() => {
    fetchCourseexpirydata();
  }, []);


  const fetchCourseaccess = async () => {
    setopenmodal(true);
    let course_id = params.course_id;

    try {
      const res = await fetch(`${API_Key}/knowcourseaccess/` + course_id, {
        method: "GET",
        headers: {
          mode: "cors",
          "Access-Control-Allow-Origin": `${API_Key}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
        credentials: "include",
      });

      const data = await res.json();

      console.log("first", data)

      setcourse(data);
      setpostlist(data.chapters.length);
      setopenmodal(false);

      if (res.status !== 200) {
        const error = new Error(res.error);
        throw error;
      }
    } catch (err) {
      console.log(err);
      navigate("/signin");
    }
  };
  useEffect(() => {
    fetchCourseaccess();
  }, []);

  useEffect(() => {
    if (postlist) {
      const scrollPosition = sessionStorage.getItem("scrollPosition");
      if (scrollPosition) {
        window.scrollTo(0, parseInt(scrollPosition, 10), {
          behavior: "smooth",
        });
        sessionStorage.removeItem("scrollPosition");
      } else {
        window.scrollTo(0, 0);
      }
    }
  }, [postlist]);
  const date = moment(userdetail);
  const formatedDate = date.format("DD-MMM-YY");
  const time = date.fromNow();

  const handleSectionSelected = (index) => {
    if (index === selectedSection) {
      setSelectedSection("");
    } else {
      setSelectedSection(index);
    }
  };


  console.log(course.chapters, "dina")
  return (
    <div>
      {modal && <Loading setOpenModal={setopenmodal} />}

      {/*   {isLoading ? <LoadingSpinner /> : renderUser}*/}
      <div className="Entity_background p-1">
        <div className="Entitycommontopper">
          <div className="container pb-2">
            <div className="row justify-content-center pb-5">
              <div className="col-lg-8 col-md-8 col-12 ">
                <h1 className="Entitycommonhead">
                  {course.coursename}
                  <span>
                    <hr className="Entitycommonheadhr"></hr>
                  </span>
                </h1>
                <p className="Entitycommonheadpara"> {course.desc}</p>
              </div>
              <div className="col-lg-3 col-md-4 col-12 offset-md-1 ">
                <img
                  src={course.cover_image}
                  className="img-fluid"
                  style={{ padding: "4rem" }}
                  alt="src"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-8 col-12">
              {/*} <div className='row '>
  <div className='col-lg-5 col-md-10 col ' id='courseratingdiv'>
  <h1 className='ratingdivhead'>Ratings</h1>
  </div>
  <div className='col-lg-5 col-md-10 col' id='coursescorediv'>
  <h1 className='ratingdivhead2'>Score</h1>
  </div>
 
  </div>*/}
              {isLoading ? (
                <Spinner />
              ) : (
                <div className="row mt-5 ">
                  {course.chapters &&
                    course.chapters.map((chapter, index) => (
                      <div className="col-lg-12 col-md-10 col-12 mb-5">
                        <div className="row ">
                          <div className="col">
                            <h1 className="Chapterhead1">
                              {chapter.chaptername}
                            </h1>
                          </div>
                          <div className="col-lg-3 col">

                          </div>
                        </div>
                        <div className="row justify-content-center mt-5">
                          <div className="col-lg-9 col-12">
                            <p className="chapterdescpara">{chapter.content}</p>
                          </div>
                          <div className="col-lg-3 col-5  mt-4 text-center">
                            <Link
                              to={`/chapterdesc/${chapter._id}`}
                              state={{ courseid: params.course_id }}
                              id={course._id}
                              onClick={() =>
                                sessionStorage.setItem(
                                  "scrollPosition",
                                  window.pageYOffset
                                )
                              }
                            >
                              <button className="btn gotochapterbtn">
                                Go to chapter
                                <span className="ml-3">
                                  <MdOutlineArrowForwardIos
                                    color="white"
                                    size={10}
                                  />
                                </span>
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              )}

              {isLoading ? (
                <Spinner />
              ) : (
                <div className="course-section">
                  {course.sections &&
                    course.sections.map((section, index) => (
                      <div
                        className="section-box"
                        onClick={() => handleSectionSelected(index)}
                      >
                        <h1 className="dash-course-section-heading">
                          {section.sectionName}
                          {selectedSection === index ? <FaChevronDown className="w-6 h-6 text-white " /> : <FaChevronRight className="w-6 h-6 text-white " />}
                        </h1>
                        <div className="section-chapter-container">
                          {section.sectionContents.map((chapter) =>
                            selectedSection === index ? (
                              <Link
                                className="section-box-chapter-link"
                                to={`/chapterdesc/${chapter._id}`}
                                state={{ courseid: params.course_id, sectionid: index }}
                              >
                                <h1 className="section-box-chapter">
                                  <MdOndemandVideo />
                                  {chapter.contentName}
                                </h1>
                              </Link>
                            ) : null
                          )}
                        </div>
                      </div>
                    ))}

                </div>
              )}
            </div>
            {/*<div className="col-lg-4 col-md-4 col-12 p-5 " id="stickycoursediv">
              <Link
                to={"/challenges/" + course._id}
                style={{ textDecoration: "none" }}
              >
                {" "}
                <div
                  className="row justify-content-center mt-5 mb-5 p-4 submitflagbtn"
                  id={course._id}
                >
                  <span className="mr-3">
                    <img
                      src={Gotolink}
                      style={{ height: "2.5rem", marginTop: ".4rem" }}
                      alt="write"
                    />
                  </span>
                  <h1 className="enterpreyhead"> Enter into Prey Ground</h1>
                </div>
              </Link>

              {course.is_premium === true ? (
                <div
                  className="row justify-content-center mt-5 mb-5 p-4 "
                  id="writereviewbtn"
                  style={{ border: "2px solid red", borderRadius: "1rem" }}
                >
                  <span className="mr-3">
                    <AiOutlineClockCircle color="white" size={30} />
                  </span>
                  <h1 className="enterpreyhead2">
                    {" "}
                    your Plan expires on {formatedDate || 0}{" "}
                  </h1>
                </div>
              ) : (
                <div
                  className="row justify-content-center mt-5 mb-5 p-4 "
                  id="writereviewbtn"
                  style={{ border: "2px solid red", borderRadius: "1rem" }}
                >
                  <span className="mr-3">
                    <AiOutlineClockCircle color="white" size={30} />
                  </span>
                  <h1 className="enterpreyhead2"> your Plan never Expires </h1>
                </div>
              )}

              {course.is_premium === true ? (
                <Link
                  to={"/extendplan/" + course._id}
                  style={{ textDecoration: "none" }}
                >
                  {" "}
                  <div
                    className="row justify-content-center mt-5 mb-5 p-4 submitflagbtn"
                    id={course._id}
                  >
                    <span className="mr-3">
                      <CgExtensionAdd color="white" size={30} />
                    </span>
                    <h1 className="enterpreyhead"> Extend Your Plan</h1>
                  </div>
                </Link>
              ) : null}

              <div className="row mb-5" id="resourcecard">
                <div className="col-lg-12 col-md-12 col-12 Raviewscard2">
                  <div className="row">
                    <div className="col-lg-3 col">
                      <h1 className="resourcehead pt-4 pb-2">Resources</h1>
                    </div>
                    <div className="col-lg-1 col pt-3 pb-2 offset-7">
                      <div
                        className="Labbtn "
                        onClick={() => setResource(!Resource)}
                      >
                        <MdKeyboardArrowDown
                          className="span"
                          color="white"
                          size={15}
                          style={{ marginTop: "-2.4rem" }}
                        />
                      </div>
                    </div>
                    {Resource && (
                      <div className="row   pl-5 pr-5 pb-5 pt-4 ">
                        {course.chapters &&
                          course.chapters.slice(0, 4).map((chapter, id) => (
                            <div className="col">
                              <a
                                href={chapter.image}
                                target="_self"
                                rel="noopener noreferrer"
                                key={id}
                              >
                                <img src={Doc} alt="doc" />
                              </a>
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="row mb-5" id="resourcecard">
                <div className="col-lg-12 col-md-12 col-12 Raviewscard3">
                  <div className="row">
                    <div className="col-lg-3 col">
                      <h1 className="resourcehead pt-4 pb-2">Reviews</h1>
                    </div>
                    <div className="col-lg-1 col pt-4 pb-2 offset-7">
                      <div
                        className="Labbtn "
                        onClick={() => setReviews(!Reviews)}
                      >
                        <MdKeyboardArrowDown
                          className="span"
                          color="white"
                          size={15}
                          style={{ marginTop: "-2.4rem", rotate: "30px" }}
                        />
                      </div>
                    </div>
                  </div>
                  {Reviews && (
                    <div className="row pt-4 pl-4 pr-4 pb-4 ">
                      {course.reviews &&
                        course.reviews.slice(0, 3).map((review, id) => (
                          <div className=" col-lg-12 col-12 mt-2 mb-2 reviewdetail">
                            <div className="row">
                              <div className="col-lg-7 col">
                                <img
                                  src={users}
                                  style={{ height: "3.5rem" }}
                                  alt="doc"
                                />
                                <span className="ml-2 reviewcardspan">
                                  {review.uid}
                                </span>
                              </div>
                              <div className="col-4 col  offset-1">
                                {" "}
                                <p className="reviewchptrname pt-4">
                                  chapter 1
                                </p>
                              </div>
                            </div>
                            <div className="row pl-4 pr-4 pb-3 pt-4">
                              <p className="reviewpara"> {review.review}</p>
                            </div>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </div>
                        </div>*/}
                        {!course.Personalcourse && (
                          <div className="col-lg-4 col-md-4 col-12 p-5" id="stickycoursediv">
                            <Link to={"/challenges/" + course._id} style={{ textDecoration: "none" }}>
                              <div
                                className="row justify-content-center mt-5 mb-5 p-4 submitflagbtn"
                                id={course._id}
                              >
                                <span className="mr-3">
                                  <img
                                    src={Gotolink}
                                    style={{ height: "2.5rem", marginTop: ".4rem" }}
                                    alt="write"
                                  />
                                </span>
                                <h1 className="enterpreyhead">Enter into Prey Ground</h1>
                              </div>
                            </Link>
                        
                            {course.is_premium ? (
                              <div
                                className="row justify-content-center mt-5 mb-5 p-4"
                                id="writereviewbtn"
                                style={{ border: "2px solid red", borderRadius: "1rem" }}
                              >
                                <span className="mr-3">
                                  <AiOutlineClockCircle color="white" size={30} />
                                </span>
                                <h1 className="enterpreyhead2">
                                  Your Plan expires on {formatedDate || 0}
                                </h1>
                              </div>
                            ) : (
                              <div
                                className="row justify-content-center mt-5 mb-5 p-4"
                                id="writereviewbtn"
                                style={{ border: "2px solid red", borderRadius: "1rem" }}
                              >
                                <span className="mr-3">
                                  <AiOutlineClockCircle color="white" size={30} />
                                </span>
                                <h1 className="enterpreyhead2">Your Plan never Expires</h1>
                              </div>
                            )}
                        
                            {course.is_premium && (
                              <Link to={"/extendplan/" + course._id} style={{ textDecoration: "none" }}>
                                <div
                                  className="row justify-content-center mt-5 mb-5 p-4 submitflagbtn"
                                  id={course._id}
                                >
                                  <span className="mr-3">
                                    <CgExtensionAdd color="white" size={30} />
                                  </span>
                                  <h1 className="enterpreyhead">Extend Your Plan</h1>
                                </div>
                              </Link>
                            )}
                        
                            <div className="row mb-5" id="resourcecard">
                              <div className="col-lg-12 col-md-12 col-12 Raviewscard2">
                                <div className="row">
                                  <div className="col-lg-3 col">
                                    <h1 className="resourcehead pt-4 pb-2">Resources</h1>
                                  </div>
                                  <div className="col-lg-1 col pt-3 pb-2 offset-7">
                                    <div className="Labbtn" onClick={() => setResource(!Resource)}>
                                      <MdKeyboardArrowDown
                                        className="span"
                                        color="white"
                                        size={15}
                                        style={{ marginTop: "-2.4rem" }}
                                      />
                                    </div>
                                  </div>
                                  {Resource && (
                                    <div className="row pl-5 pr-5 pb-5 pt-4">
                                      {course.chapters &&
                                        course.chapters.slice(0, 4).map((chapter, id) => (
                                          <div className="col" key={id}>
                                            <a
                                              href={chapter.image}
                                              target="_self"
                                              rel="noopener noreferrer"
                                            >
                                              <img src={Doc} alt="doc" />
                                            </a>
                                          </div>
                                        ))}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                        
                            <div className="row mb-5" id="resourcecard">
                              <div className="col-lg-12 col-md-12 col-12 Raviewscard3">
                                <div className="row">
                                  <div className="col-lg-3 col">
                                    <h1 className="resourcehead pt-4 pb-2">Reviews</h1>
                                  </div>
                                  <div className="col-lg-1 col pt-4 pb-2 offset-7">
                                    <div className="Labbtn" onClick={() => setReviews(!Reviews)}>
                                      <MdKeyboardArrowDown
                                        className="span"
                                        color="white"
                                        size={15}
                                        style={{ marginTop: "-2.4rem", rotate: "30px" }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                {Reviews && (
                                  <div className="row pt-4 pl-4 pr-4 pb-4">
                                    {course.reviews &&
                                      course.reviews.slice(0, 3).map((review, id) => (
                                        <div
                                          className="col-lg-12 col-12 mt-2 mb-2 reviewdetail"
                                          key={id}
                                        >
                                          <div className="row">
                                            <div className="col-lg-7 col">
                                              <img
                                                src={users}
                                                style={{ height: "3.5rem" }}
                                                alt="doc"
                                              />
                                              <span className="ml-2 reviewcardspan">
                                                {review.uid}
                                              </span>
                                            </div>
                                            <div className="col-4 col offset-1">
                                              <p className="reviewchptrname pt-4">chapter 1</p>
                                            </div>
                                          </div>
                                          <div className="row pl-4 pr-4 pb-3 pt-4">
                                            <p className="reviewpara">{review.review}</p>
                                          </div>
                                        </div>
                                      ))}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashcoursedesc;