import React, { useState } from 'react'
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import {ImCross} from "react-icons/im";
import Wentwrong from './Wentwrong';
import Diffisaved from "../Modals/Diffisaved";
import Loading from './Loading';

const Adddifficulty = ({setOpenModal,id,category}) => {
  const[modalOpen,setModalOpen]=useState(false);
const[modalOpen2,setModalOpen2]=useState(false);
const[modalOpen3,setModalOpen3]=useState(false);
  const API_Key = process.env.REACT_APP_API_URL;

  const[diffi,setdiffii]=useState("");
  const[level,setlevel]=useState("Easy")
  const handlediffi =(rating)=>{
const data=setdiffii(rating);
if(diffi < 0 && diffi==1){
  setlevel("Easy")
}else if(diffi <2){
  setlevel("Easy")
}else if (diffi <3){
  setlevel("Medium")
}
else if (diffi <4){
  setlevel("Hard")
}
else if (diffi <5){
  setlevel("Insane")
}
//window.alert(diffi);
  }
  const onchanged = (rating)=>{
    const data=setdiffii(rating);
if(diffi < 2){
  setlevel("Low")
}else if(diffi <3){
  setlevel("Medium")
}else{
  setlevel("Hard")
}
  }
 
 
 const saverate = async()=>{
   setModalOpen3(true);
  const res = await fetch(`${API_Key}/postdifficulty`, {
    method: "POST",
    body: JSON.stringify({
    
      rating:diffi || "1",
      cateid:id,
      category:category
      
    }),
    headers: {
      mode: 'cors',
      'Access-Control-Allow-Origin':`${API_Key}`,
      'Content-Type': 'application/json',
      'Access-Control-Allow-Credentials': true,
    },
    credentials:"include",
   
  }).then((res) => {
      setModalOpen3(false)
    if(res.status===200){
   setModalOpen(true);
    }
    setTimeout(() => {
      setOpenModal(false);
    },3000);
    const noti={message:"Your Difficulty has been successfully Saved. ",category:"Entity",querytype:"Difficulty Saved"}

    fetch(`${API_Key}/notification`, {
      method: 'POST',
      headers:{
        Accept: "application/json",
        mode: 'cors',
        'Access-Control-Allow-Origin':`${API_Key}`,
      'Content-type': 'application/json',
        
        'Access-Control-Allow-Credentials': true,
      },
      credentials:"include",
      body: JSON.stringify(noti),
    })
    .then((response) => {
 if(response.status === 200){
   //window.alert("saved noti")
 }

     })
     .catch((error)=>{
      console.error('Error:', error);
     })

      
  

  }
  ).catch((error) => {
    console.error('Error:', error);
    setModalOpen2(true);

  });}
  
  return (
    <div>
    {modalOpen && (
      <Diffisaved
       
        setOpenModal={setModalOpen}
      />
    )}
    {modalOpen2 && (
      <Wentwrong
       
        setOpenModal={setModalOpen2}
      />
    )}
    {modalOpen3 && (
      <Loading
       
        setOpenModal={setModalOpen3}
      />
    )}
    <section className="modalBackground justify-content-center" >
    <div className="container  justify-content-center">
    
      <div className="row justify-content-center ">
      
        <div className="col-lg-8 col-md-10 col-10 p-1 modalContainer" >
        <div className='mt-5 crossbtnarrow mr-5'>
        <button
          onClick={() => {
            setOpenModal(false);
          }}
        >
        <div className='text-center'><ImCross style={{height:".6rem",marginLeft:"-1.9rem",marginTop:".5rem"}}/></div> 
        </button>
      </div>
<h2 className='text-center alertmainhead2'>RATE DIFFICULTY <div className='row justify-content-center'><span><hr className='text-center alertcommonhr'></hr></span></div></h2>  
<p className='resetpara'>To rate the difficulty level, Please drag below.</p>

<div className='row justify-content-center'>

<div className='col-lg-9 col-md-6  col-9 '>

<div className='row mt-3 '>
<div className='col-lg-2'> <p className='ratehead'>{level}</p></div>
<div className='col-lg-1 offset-9'> <p className='ratehead'>{diffi || "1"}</p></div>
</div>
<Slider defaultValue="1" className='sliders'  railStyle={{backgroundColor:"grey",height: 10}} trackStyle={{backgroundColor:"#ec2542",height: 10}} handleStyle={{background:"#e32f19",width:20,height:20,borderColor:"white"}} activeDotStyle={{backgroundColor:"yellow"}} min={0} max={5} onChange={handlediffi}   />
  




<div className='row justify-content-center mt-5'>
<button className=' btn saveratebtn' onClick={saverate}> SAVE RATING</button>
</div>
</div>

</div>

        </div></div></div></section></div>
  )
}

export default Adddifficulty