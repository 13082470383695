import React from "react";
import { GrFormCheckmark } from "react-icons/gr";
import { motion } from "framer-motion";
const benefits = [
  "Cybersecurity Analyst",
  "Security Operations Center (SOC) Analyst",
  "Incident Responder",
  "Ethical Hacker/Penetration Tester",
  "Security Consultant",
  "Information Security Analyst",
];

const SummerTrainingOverview = ({title,description, subtitle,points,observeClass,dataName}) => {
  return (
    <div  className={`training-highlight-section2 ${observeClass}`} data-name={dataName}> 
      <motion.div initial={{x:-50,opacity:0.5}} whileInView={{x:0,opacity:1}} viewport={{once:true}} transition={{duration:1}} className="training-highlight-section-left">
        <h3 className="training-heading">
         {title}
        </h3>
        <p className="training-desc">
       {description}
.
        </p>
      </motion.div>
      <motion.div  initial={{x:50,opacity:0.5}} whileInView={{x:0,opacity:1}} viewport={{once:true}} transition={{duration:1}} className="training-highlight-section-right">
        <p className="hero-subheading margin-bottom">{subtitle}
          
        </p>
        {points?.map((x) => (
          <div className="display-flex ">
            <div className="lab-desc-list-icon-wrapper">
              <GrFormCheckmark className="lab-desc-list-icon" />
            </div>
            <p className="training-list">{x}</p>
          </div>
        ))}
      </motion.div>
    </div>
  );
};

export default SummerTrainingOverview;
