import React,{useEffect, useState} from 'react'
import "./Commonpages.css";

import {MdLocalPhone,MdLocationOn} from "react-icons/md";
import {AiOutlineMail} from "react-icons/ai";
import {Helmet} from "react-helmet";
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import { useForm } from "react-hook-form";
import Contactussuccess from '../Dashboard/Modals/Contactussuccess';
import Loading from '../Dashboard/Modals/Loading';
import Wentwrong from '../Dashboard/Modals/Wentwrong';
import emailimg from "../../Assets/Dashboard/Mails/Screenshot 2023-06-12 at 9.28.15 AM.png"
const Contactus = () => {
  const[modal,setmodalopen]=useState(false);
  const[modal2,setmodalopen2]=useState(false);
  const[modal3,setmodalopen3]=useState(false);
  const {register,handleSubmit,formState: { errors },reset,watch,trigger,} = useForm();
  
  const API_Key = process.env.REACT_APP_API_URL;
  const onSubmit = async(data)=>{
    setmodalopen2(true);
    reset();
    fetch(`${API_Key}/contactus`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        mode:"cors",
                'Access-Control-Allow-Origin':`${API_Key}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': true
      },
      credentials:"include",
      body: JSON.stringify(data),
    })
    .then((response) => {
   
      if(response.status===201){
     setmodalopen2(false);
     setmodalopen(true);
     setTimeout(() => {
       setmodalopen(false)
     }, 3000);
      
      }
    //Then with the data from the response in JSON...
    
    })
    //Then with the error genereted...
    .catch((error) => {
      console.error('Error:', error);
      setmodalopen2(false);
     setmodalopen3(true);
    });
  }


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
    return (
        <div>
        <Helmet>
        <title>Contact Us | Hackers Prey</title>

        <meta name="description" content="Contact us now if you have any concerns or queries, We will happy to answer your question. submit the given form and we will contact you as soon as possible."/>
      </Helmet>
        {modal && <Contactussuccess setOpenModal={setmodalopen} />}
        {modal2 && <Loading setOpenModal={setmodalopen2} />}
        {modal3 && <Wentwrong setOpenModal={setmodalopen3} />}
               <section className="commonbackground " >
               
            
          <br></br>     <br></br>
          <div className="container mt-5" id="Heading_container">
              <div className="row justify-content-center">
             
              <span className="Dataprivacy_span">CONTACT US</span>
                  <h2 className="Contact_head">CONTACT US</h2>
                  <hr className="commonpagehr"></hr>
               
            
              </div>
        
             
          </div>
          </section>
          <section className="content_faq">
          

       <div className="container-fluid">
           <div className="row">
               <div className="col-lg-3 col-md-4 col-10 offset-1 justify-content-center" id="contactus_form">
                   
                  
                         
                          <h2 className="text-center contacthead mt-5">Get in Touch</h2>
                          <p className="contact_para mt-5">Is it a comment, a query, or a collabration? please feel free to reach out to us. 
</p> 

 <div className="contactus_imagediv">
<div className="row justify-content-center mt-2">
<div className='col-2 contact_condiv'><span className="contact_icon"><MdLocalPhone  size={20} /></span></div>
   <div className='col-10'> <p className='contact_icon_para'> +91 172 403 8499</p></div>
</div>
<div className="row justify-content-center mt-2">

    <div className='col-2 contact_condiv'><span className="contact_icon"><AiOutlineMail  size={20} /></span></div>
    <div className='col-10'> <p className='contact_icon_para'><img src={emailimg} alt="img" style={{height:"45px",marginTop:"-1rem"}}/></p></div>
    </div>

<div className="row  justify-content-center mt-2 ">
   
<div className='col-2 contact_condiv'><span className="contact_icon"><MdLocationOn   size={20} /></span></div>
<div className='col-10'> <p className='contact_icon_para'>SCF-4, Sector 7C, Chandigarh-160019</p></div>

</div>
                 
                 </div>
                        </div>
                   
               <div className="col-lg-6 col-md-6 col-10 mt-5  offset-1">
               <form onSubmit={handleSubmit(onSubmit)}>
  <div class="form-row">
    <div class="col">
    <label>First name</label>
      <input type="text"      name="fname"  class={`form-control ${errors.fname && "invalid"}`} placeholder="Enter  First name"  
      autoComplete='off'
      
      {...register("fname", { required: "Firstname is Required",
        pattern: {
         value: /^[a-zA-Z ]*$/,
         message: "Please Enter Valid Name",
       },
          
        })}
          
          onKeyUp={() => {
            trigger("fname");
          }}/>
          {errors.fname && (
            <small className="errormes ml-5 ">{errors.fname.message}</small>
          )} 

    </div>
    <div class="col">
    <label>Last name</label>
      <input type="text" name='lname' className={`form-control ${errors.lname && "invalid"}`} placeholder="Enter  Last name" 
      autoComplete='off'
      {...register("lname", { required:"Lastname is Required",
        pattern: {
          value: /^[a-zA-Z ]*$/,
          message: "Please Enter Valid Name",
        },
       })}
         
         onKeyUp={() => {
           trigger("lname");
         }}
      />
      {errors.lname && (
        <small className="errormes ml-5 ">{errors.lname.message}</small>
      )} 
    </div>
  </div>
  <div class="form-row ">
    <div class="col">
    <label>Email</label>
      <input type="text" name='email' class={`form-control && ${errors.email && "invalid"}`} placeholder="Enter  Email"
      
      autoComplete='off'
      {...register("email", { required: "Email is Required" ,
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: "Invalid email address",
      }})}
      onKeyUp={() => {
        trigger("email");
      }}/>	
      {errors.email && (
        <small className="errormes ml-4">{errors.email.message}</small>
      )}
      
    </div>
    <div class="col">
    <label>Phone</label>
      <input type="text" name='phone' class={`form-control ${errors.phone && "invalid"}`} placeholder=" Enter Phone"
      autoComplete='off'
      
      {...register("phone", { required:"Phone is Required",
        pattern: {
          value: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
          message: "Invalid phone no",
        },
       })}
       onKeyUp={() => {
        trigger("phone");
      }}
      />
      {errors.phone && (
        <small className="errormes ml-5 ">{errors.phone.message}</small>
      )} 
    </div>
  </div>
  

 
    <div class="form-rows">
    <div class="col">
  
    <label>Message</label>
<textarea className={`textarea ${errors.message && "invalid"}`} name='message' placeholder="Enter Message" rows="4" cols="12" 
autoComplete='off'
{...register("message", { required:"Message is required",
  pattern: {
    value: /^[a-zA-Z,. \n @ ?]*$/,
    message: "Only text  , . ? and @ is allowed ",
  },
 })}
   
   onKeyUp={() => {
     trigger("message");
   }}

></textarea>
{errors.message && (
  <small className="errormes ml-5 ">{errors.message.message}</small>
)} 
    
</div>
    
   
  </div>

  <div className="row justify-content-center mb-5">
      <button className="btn contactbtn">SUBMIT</button>
  </div>
  
</form>
               </div>
               

           </div>
       </div>
       <Footer/>
          </section>
        </div>
    )
}

export default Contactus
