import React from 'react';
import {motion} from 'framer-motion';

const hackerspreyPoint=[
    'We deliver the best 6 Months Industrial Training In Cybersecurity.',
    'We keep you updated on the latest developments in cybersecurity.',
    'We conduct regular doubt-clearing sessions, ensuring no questions go unanswered.',
    'Our faculty consists of working professionals with extensive industry experience.',
    'We immerse our students in a real-world working environment.',
    'We emphasize practical skills over rote memorization.',
    'We provide access to state-of-the-art labs and real-world hacking challenges.',
    'Our comprehensive program is designed to prepare you for a successful career in a reputed organization.'
]
const conventionalPoint=[
    'Limited training duration with insufficient focus on cybersecurity.',
    'Outdated curriculum with minimal focus on recent cybersecurity trends.',
    'Rare or non-existent doubt-clearing sessions, leaving students with unresolved queries.',
    'Faculty with limited or no real-world industry experience.',
    'Theoretical learning with little to no exposure to real-world applications.',
    'Focus on theoretical knowledge and rote memorization over practical application.',
    'Lack of access to advanced labs and minimal exposure to real-world hacking scenarios.',
    'Limited preparation for real-world challenges, leading to fewer career opportunities in reputed firms.',
    
]
const headings=[
    'Duration and Quality',
'Curriculum and Updates',
'Student Support',
'Faculty Expertise',
'Learning Environment',
'Teaching Methodology',
'Lab and Practical Access',
'Career Preparation'
]

const WhyHackersprey = () => {
  return (
    <motion.div initial={{y:100,opacity:0}} whileInView={{y:0,opacity:1}} viewport={{once:true}} transition={{duration:1.5}} className='why-hackersprey'>
        <p className='training-heading'>Why should you choose Hackersprey to get the best Cybersecurity Training in Chandigarh?</p>
        <p className='training-description'>If you're seeking the best Cybersecurity Industrial Training in Chandigarh, Hackersprey should be your top choice. Since 2021, we have built a strong reputation as one of the foremost providers of Cybersecurity Industrial Training in Chandigarh. We offer comprehensive 6 Months Industrial Training in Cybersecurity on both offline and online platforms, ensuring flexibility and accessibility. Our team of highly experienced and qualified trainers is dedicated to delivering quality and cost-effective Cybersecurity Training in Chandigarh. At Hackersprey, we are dedicated to delivering an outstanding learning experience that prepares students for success in the cybersecurity field.</p>
        
        <div className='comparison-box'>
        <div className='table-container'>
           <div className='heading-card'>
            <p className='card-heading'></p>
            {headings.map((x,index)=>(
                <div className='card-points' key={index}>{x}</div>
            ))}
            
           </div>

        </div>
           <div className='table-container'>
           <div className='hackersprey-card'>
            <p className='card-heading'>Hackersprey's Program</p>
            {hackerspreyPoint.map((x,index)=>(
                <div className='card-points' key={index}>{x}</div>
            ))}
            
           </div>

        </div>
        <div className='table-container'>
           <div className='comparison-card'>
            <p className='card-heading'>Conventional Programs</p>
            {conventionalPoint.map((x,index)=>(
                <div className='card-points' key={index}>{x}</div>
            ))}
            
           </div>

        </div>
    </div>
    </motion.div>
  )
}

export default WhyHackersprey