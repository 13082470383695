import React from 'react'
import Sorry1 from "../../../Assets/MASCOT/Mascot SVG/Asset 28.png";
import {FaArrowRight} from "react-icons/fa";
import {ImCross} from "react-icons/im";

const Uninotfound = ({setOpenModal}) => {
  return (
    <div>
    <section className="modalBackground justify-content-center" onClick={() => {
      setOpenModal(false);
    }}>
    <div className="container  justify-content-center">
    
    <div className="row justify-content-center ">
    
      <div className="col-lg-8 col-md-10 col-10 p-1 modalContainer" >
      
    
        <div className='row p-5'>
       
        <div className='col-lg-4 col-md-5 col-4  '>
       
        <div className='justify-content-center pl-5'><img src={Sorry1} style={{height:"45vh"}} alt="sorry"/></div>
        </div>
        <div className='col-lg-8 col-md-5 col-8   '>
        <div className='mt-2 crossbtnarrow'>
        <button
          onClick={() => {
            setOpenModal(false);
          }}
        >
        <div className='text-center'><ImCross style={{height:".6rem",marginLeft:"-1.9rem",marginTop:".5rem"}}/></div> 
        </button>
      </div>
       <div className='justify-content-center'><h2 className='text-center alertmainhead2'>Access Denied!!<div className='row justify-content-center'><span><hr className='text-center alertcommonhr'></hr></span></div></h2>  
       </div> 
       <p className='resetpara'>We're sorry, but your university is not currently registered with the HackersPrey Program. However, you can still register as an individual to gain access to the portal. We appreciate your interest!</p>

       
       <div className='row justify-content-center'>
    <button className='Arrowbtn' onClick={() => {
          setOpenModal(false);
        }}>
       <FaArrowRight color="white" size={20}/>
       
       </button>
    
       </div>
        </div></div>
       
       
         
          
       
      </div>
    </div>
  </div>

  </section>
    </div>
  )
}

export default Uninotfound