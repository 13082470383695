import React,{useState,useEffect} from 'react'

import { Line } from "rc-progress";
import Record from "../../../Assets/images/Activity.svg";
import User from "../../../Assets/images/Mask_Group_19.png"
import User2 from "../../../Assets/images/dp3.png"
import ReactStars from "react-rating-stars-component";
import {BsFlagFill,BsArrowUp} from "react-icons/bs";
import {useNavigate,useParams } from 'react-router-dom';
import Edit from "../../../Assets/Entity/Lab/Edit.svg"
import Gotolink from "../../../Assets/Entity/Lab/Go To Link (1).svg";
import{MdKeyboardArrowDown, MdKeyboardArrowUp} from "react-icons/md"
import Doc from "../../../Assets/Entity/Lab/Doc.svg"
import axios from 'axios';
import Submitflag from '../Modals/Submitflag';
import AddReview from '../Modals/AddReview';
import Adddifficulty from '../Modals/Adddifficulty';
import video from "../../../Assets/Entity/Challenges/bypass fileupload filtering.mp4"
import Challengeicon from "../../../Assets/Dashboard/Challenges.svg"
import Loading from '../Modals/Loading';
import Challengeui from '../Modals/Challengeui';
import ReactPlayer from 'react-player';
import moment from 'moment';
//import { LineChart, Line, Tooltip } from "recharts";
import Challstartsuccess from '../Modals/Challstartsuccess';
const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100
  }
];
const Challengedesc = (props) => {
  const params=useParams()
    const[Resource,setResource]=useState(true);
    const[challenge,setchallenge]=useState("");
    const[challengevid,setchallengevid]=useState("");
    const[ip,setip]=useState("");

    const [modalOpen, setModalOpen] = useState(false);
    const [modalOpen2, setModalOpen2] = useState(false);
    const [modalOpen3, setModalOpen3] = useState(false);
    const [modalOpen4, setModalOpen4] = useState(false);
    const [modalOpen5, setModalOpen5] = useState(false);
    const [modalOpen6, setModalOpen6] = useState(false);
    const[subs,setsubs]=useState();
    const[level,setlevel]=useState("Easy")
    const[color,setcolor]=useState("green")
    const [shown, setShown] = useState(false)
    const [disableb,setdisableb]=useState(false);
 const navigate=useNavigate();
    const openip = async()=>{
setModalOpen5(true);
    }
 const disablebtn = async()=>{

 }
    useEffect(()=>{
   disablebtn();
 },[])
 

    const API_Key = process.env.REACT_APP_API_URL;
    const getchallengedesc = async () => {
      setModalOpen4(true);
     try{
      let chall_id = params.chall_id;
      {/*const response = await fetch(`${API_Key}/getchallenge/` + chall_id);
      setchallenge(await response.json());
      if(response.status===200){
        setModalOpen4(false);
      } */}
      const res = await fetch(`${API_Key}/getchallenge/` + chall_id,{
        method:"GET",
        headers:{
          mode: 'cors',
          'Access-Control-Allow-Origin':`${API_Key}`,
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': true,
        },
        credentials:"include"
      
       
    });
    const data = await res.json();

  setchallenge(data);
 setchallengevid(data.cover_animation)
 const subbb=setsubs(data.subscription);
 if(data.timelimit <= 2.0){
   setlevel("Easy")
   setcolor("#AAFF00")
 }else if(data.timelimit <=3.0){
   setlevel("Medium")
   setcolor("#FFBF00")
 }
 else if(data.timelimit <=4.0){
  setlevel("Hard")
  setcolor("red")
}
else if(data.timelimit <=5.0){
  setlevel("Insane")
  setcolor("#6A0DAD")
}



    
  
    if(res.status===200){
      setModalOpen4(false);
    }
  }catch(err){
    console.log(err);
    navigate("/signin");
  
  } 
    };
    useEffect(() => {
      
      getchallengedesc();
  
    }, []);
    

    const gotolink = async(e)=>{
      //setModalOpen4(true);
     

      if(challenge.ranking===""){
    setModalOpen6(true);
      }else{
        setModalOpen5(true);
      }
      const {id}=e.target;
   
    
      const datas={id:id}
      fetch(`${API_Key}/teststartchallenge` , {
        method: 'POST',
        headers: {
          Accept: 'application/json',
                mode:"cors",
                        'Access-Control-Allow-Origin':`${API_Key}`, 
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Credentials': true
              },
              credentials:"include",
        body: JSON.stringify(datas),
      })
   
      //Then with the data from the response in JSON...
      .then(async(res) => { 
       
         const data=await res.json();
         setip([data.mess]);
        // window.alert([ip.mess])
         //window.alert(data);
         if(res.status===200){
           setModalOpen4(false);
         }
          
     
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error('Error:', error);
      });
  
    }

const category="Challenges";
useEffect(() => {
  window.scrollTo(0, 0)
}, [])


  return (
    <div>
   
    

    {modalOpen && (
      <Submitflag
        id={challenge._id}
        setOpenModal={setModalOpen}
      />
    )}
    {modalOpen2 && (
      <AddReview
        id={challenge._id}
        category={category}
        setOpenModal={setModalOpen2}
      />
    )}
    {modalOpen3 && (
      <Adddifficulty
        id={challenge._id}
        setOpenModal={setModalOpen3}
        category={category}
      />
    )}
    {modalOpen4 && (
      <section className="modalBackground3 justify-content-center">

      <div className="container  justify-content-center">
      
        <div className="row justify-content-center " >
        
        <img src={challenge.cover_image} alt="jhjs" className='challengecarimg'/>
       
      </div>
      </div> 
    </section>
    )}
    {modalOpen5 && (
      <Challengeui
        ip={challenge.ranking}
        setOpenModal={setModalOpen5}
       
      />
    )}
    {modalOpen6 && (
      <Challstartsuccess
   
        setOpenModal={setModalOpen6}
       
      />
    )}
   
    <div className='Entity_background p-1'>
    <div className='Entitycommontopper'>
    <div className='container'>
    <div className='row'>
   <div className='col-lg-8 col-md-8 col-12 p-1'>
   <h1 className='Entitycommonhead'>{challenge.title}<span><hr className='Entitycommonheadhr'></hr></span></h1>
   <p className='Entitycommonheadpara'>{challenge.desc}</p>
   <div className='row p-2'>
  {/*} <div className='col-lg-3 col'>
   <div className='row pl-4'>
   <p className='Enitylinepara'>Progress</p>
  <p className='Enitylinepara offset-4'>63%</p>
   <Line
   percent="70"
   style={{
     borderRadius: "2rem",
     width: "70%",
   
   }}
  
   strokeWidth="4"
   trailColor="#480812"
   trailWidth="4"
   strokeColor="red"
 />
   </div>
  
 
  </div>*/}
   <div className='col-lg-3 col ml-3'>
   <div className='row'>
   <p className='Enitylinepara'>{level}</p>
   <p className='Enitylinepara offset-5'>{challenge.timelimit}</p>
<Line
   percent={challenge.timelimit*20}
   style={{
     borderRadius: "2rem",
     width: "70%",
   
   }}
   strokeWidth="3"
   trailColor="grey"
   trailWidth="3"
   strokeColor={color}
  />
 <div className=' justify-content-center'><p className='offset-7 Enitylinepara2'>Difficulty</p></div>
   
 </div>
   </div>
   
   </div>
   </div>
   <div className='col-lg-3  ml-5 mt-5 offset-1'>
 <img src={challenge.cover_image}/> 
  {/* <ReactPlayer url={challengevid}  loop={true} playing={true} width={280} height={280}/>*/}




 

   
   </div>
    
    </div>
   
    </div>
    
   </div>
<div className='container p-5'>
<div className='row '>
<div className='col-lg-8 col-md-9 col-12'>
<div className='row col-lg-12 p-2'>


</div>

<div className='row justify-content-center'>

{/*<button onClick={() => setShown(!shown)}>Access</button>*/}
</div>
<div className='row '>
<div className='col-lg-12'>
{challenge.features && challenge.features.map((feature,id)=>(
  <p className='Entitycommonheadpara3'>{feature.feature}</p>
))}
</div>

</div>


{shown ? <iframe
  allowFullScreen
  frameBorder="10"
  height="315"
  src={challenge.flag}
  width="560"
/> : null}
<div className="row  justify-content-center mt-5 mb-5">
<div
  className="col-lg-12 col-md-12 col-11"
  id="netwrkinterfacediv"
>
<div className='row justify-content-center'>
    <h2 className="netwrkprehead mb-5 mt-2">Points to Remember</h2>

    </div>
    
    {subs===true ? <ul >

      <li className="labnetwrkpara2 mb-4" >
      
      WEBPWN challenges are designed to be executed within a shared environment where multiple users may be accessing and working on them simultaneously.</li>
      <li className="labnetwrkpara2 mb-4"  >
  
                                 
      Usernames and Passwords you create while solving the challenges are not stored permanently. Once you close the challenge or log out, the usernames and passwords you created during the challenge will be removed from the system.</li>
      
      <li className="labnetwrkpara2 mb-4"  >
  
                                 
      
      The challenges are designed to provide a hands-on learning experience and encourage users to experiment, explore, and practice their skills in a controlled environment. Feel free to try different approaches, test various techniques, and learn from both successful attempts and potential failures.</li>

      <li className="labnetwrkpara2 mb-4"  >
  
                                 
      Remember to adhere to ethical guidelines and respect the shared environment by refraining from malicious activities or disrupting the experience for other users.</li>
      
      <li className="labnetwrkpara2 mb-4" >
  
                                 
      On successful submission of flag score will be added to user's profile only once.</li>
      <li className="labnetwrkpara2 mb-4" >
    
      If the user submits wrong flag, then the submission will fail.</li>
      </ul>: <ul >

    <li className="labnetwrkpara2 mb-4" >
    <span>
                                
                              </span>
    Free challenges run in the shared environment.</li>
    <li className="labnetwrkpara2 mb-4" >
    <span>
                               
                              </span>
    User will Receive {challenge.score} points on successful completion of this challenge.</li>
    <li className="labnetwrkpara2 mb-4" >
    <span>
                               
                              </span>
    Going back to the "Home page" will terminate the challenge. </li>
    <li className="labnetwrkpara2 mb-4" >
    <span>
                                
                              </span>
    On successful submission of flag score will be added to user's profile only once.</li>
    <li className="labnetwrkpara2 mb-4" >
    <span>
                               
                              </span>
    If the user submits wrong flag, then the submission will fail.</li>
    </ul>}
   

  
</div>

</div>

<div className='row mt-5 p-4'>
<p className='labreviewshead'>Reviews</p>
<hr className='reviewshr'></hr>
{challenge.reviews && challenge.reviews.map((reviw,id)=>(
<div className='col-lg-12 col-md-10 col-12'>
<div className='row'>
<div className='col-lg-5 col'>
<div className='row'>
<div className='col-lg-9 col p-3'>

<img
  src={ reviw.photo || User2}
  style={{ height: "4rem",borderRadius:"50%" }}
  alt="user"
/>
<span className="labreviewhead">{reviw.uid}</span>
<span className="labreviewhead2">{moment(reviw.date).fromNow()}</span>

</div>
</div>
</div>
<div className='col-lg-3 col-6 offset-lg-4 p-5'>
<ReactStars
    count={5}
    edit={false}
    size={18}
    isHalf={true}
    emptyIcon={<i className="far fa-star"></i>}
    halfIcon={<i className="fa fa-star-half-alt"></i>}
    fullIcon={<i className="fa fa-star"></i>}
    activeColor="#ffa534"
    value={reviw.rating}
    color="lightgray"
   
 
  />
</div>

</div>
<div className="row col-lg-12">
<p className="labreviewpara">{reviw.review}</p>


</div>
<div><hr className='reviewshr'></hr></div>
</div>

))}










</div>
</div>
<div className='col-lg-4 col-md-3 col-12  justify-content-center mt-5 mb-5 pb-5 pl-5 pr-5'>

<div className='row  justify-content-center mt-4 p-2 mb-3' >
<div className='col-lg-11 submitflagbtn'  id={challenge._id} onClick={gotolink} >
<span className='mr-3'> <img src={Gotolink} style={{height:"1.5rem"}} alt="write"/></span>Start Your Challenge 
</div>
</div> 


   
<div className='row justify-content-center p-2 ' >

<div className='col-lg-11 ' id='writereviewbtn'  onClick={() => setModalOpen(true)}><span className='mr-3'><BsFlagFill color='white' size={14}/> </span> SUBMIT FLAG</div>
</div>
<div className='row justify-content-center mt-4 p-2' >

<div className='col-lg-11 ' id='writereviewbtn' catid={challenge.category} onClick={() => setModalOpen2(true)}><span className='mr-3'><img src={Edit} style={{height:"1.5rem"}} alt="write"/></span>Write a Review</div>
</div>
<div className='row   justify-content-center mt-4 p-2' >
<div className='col-lg-11 submitflagbtn'  onClick={() => setModalOpen3(true)}><span className='mr-3'><img src={Challengeicon} style={{height:"1.5rem"}} alt="write"/></span>Rate Difficulty</div>
</div>
<div className='row mb-5 mt-5' id='resourcecard'>
   <div className='col-lg-12 col-md-12 col-12 Raviewscard2' >
   <div className='row '>
   <div className='col-lg-3 col'>
   <h1 className='resourcehead pt-4 pb-2'>Resources</h1>
   </div>
   <div className='col-lg-1 col pt-3 pb-2 offset-7'>
   <div className='Labbtn2 ' onClick={() => setResource(!Resource)}>
   <MdKeyboardArrowDown className='span' color='white' size={15} style={{marginTop:"-2.7rem"}} />
   </div>
   
  
   </div>

  {Resource &&
<div className='row   pl-5 pr-5 pb-4 pt-2 '>

{challenge.features && challenge.features.map((feature,id)=>(
  <div className='col-3'>
  {feature.image===""?<a href={feature.image}  > <img src={Doc} alt="doc" /></a>:<a href={feature.image} download > <img src={Doc} alt="doc" /></a>}
  
  </div>

))}




</div>
}
   </div></div>
   </div>
   <div className='row mb-5 mt-5' id='resourcecard'>
   <div className='col-lg-12 col-md-12 col-12 scorecard2' >
   <div className='row '>
   <div className='col-lg-3 col'>
   <h1 className='resourcehead pt-4 pb-2'>Score</h1>
   </div>
   <div className='col-lg-1 col pt-3 pb-2 offset-6'>
  {/* <div className='Labbtn2 '>
 <BsArrowUp className='span' color='red' size={10} style={{marginTop:"-2.7rem"}} />
   
   </div> */}
   
  
   </div>
   
  
 
   </div>
   <div className='row'>
   <h1 className='challscore pt-2 pb-3 pl-4 ml-3'>{challenge.score}</h1></div>
   </div>
   </div>
   {/*<div className='row mb-5 mt-5' id='resourcecard'>
   <div className='col-lg-12 col-md-12 col-12 ratingcard2' >
   <div className='row '>
   <div className='col-lg-3 col'>
   <h1 className='resourcehead pt-4 pb-2'>Rating</h1>
   </div>
   <div className='col-lg-1 col pt-3 pb-2 offset-6'>
   
   
  
   </div>
   
  
 
   </div>

   <LineChart
      width={250}
      height={50}
      data={data}
     
    >
      

      <Line type="monotone" dataKey="uv" stroke="#e9282b" />
    </LineChart>

   </div>
</div>*/}
  

</div>
</div>
</div>

   </div>
    </div>
  )
}

export default  (Challengedesc)





