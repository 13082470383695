
import Scan from '../../Assets/courses/scan.png'
import Web from '../../Assets/courses/web exploit.png'
import AD from '../../Assets/courses/AD 1.png'
import Gifvideo from '../../Assets/courses/gif21.mp4'
import topcyver from '../../Assets/courses/topcyber.png'
import Webwpn from '../../Assets/Dashboard/webpwn.png'
import Sploit from '../../Assets/Dashboard/stack x.png'
import { IoCheckmarkDoneOutline } from "react-icons/io5";
import feature6 from "../../Assets/courses/certificateacw2.png"

import { FaHackerNews, FaTools, FaRocket, FaNetworkWired, FaServer, FaStar } from 'react-icons/fa';
import review1 from '../../Assets/Review/amit cybersecurity course in chandigarh.png'
import review2 from '../../Assets/Review/Ashutosh cybersecurity course in chandigarh.png'
import review3 from '../../Assets/Review/mohit cyber security course in chandigarh.png'
import review4 from '../../Assets/Review/sarmistha cybersecurity course in chandigarh.png'
import review5 from '../../Assets/Review/shailab cybersecurity course in chandigarh.png'
import review6 from '../../Assets/Review/shubham cybersecurity course in chandigarh.png'
import review7 from '../../Assets/Review/soni cybersecurity course in chandigarh.png'
import review8 from '../../Assets/Review/manish.jpg'











export const DummyData = [
    {
        title: 'Advance Cyber Warfare',
        description: 'Learn essential scanning, enumeration, and exploitation techniques to maintain robust, proactive network security,and  threat detection.',
        img: Web,
        icons: <FaHackerNews />,
        prize: "₹20,000",
        Relink:"/acw-cyber-security-certification-course"
        
        // Level: "Beginner Level"

    },
    {
        title: 'StackXploit',
        description: 'An immersive self-paced learning experience focused on mastering Linux exploitation techniques, including stack-based methodologies.',
        img: Sploit,
        icons: <FaTools />,
        prize: "₹ 24,850",
        Relink:"/stack-xploit-cyber-security-course"

    },
    {
        title: 'WebWPN',
        description: 'A professional web hacking course focused on mastering the OWASP Top 10 vulnerabilities, including techniques to exploit, prevent, and real-world threats.',
        img: Webwpn,
        icons: <FaRocket />,
        prize: "₹ 7200",
        Relink:"/webpwn-professional-web-hacking-course"

    },

    {
        title: 'Advance Cyber Warfare',
        description: 'Learn essential scanning, enumeration, and exploitation techniques to maintain robust, proactive network security,and  threat detection.',
        img: Web,
        icons: <FaHackerNews />,
        prize: "₹20,000",
        Relink:"/acw-cyber-security-certification-course"
        // Level: "Beginner Level"

    },
    {
        title: 'StackXploit',
        description: 'An immersive self-paced learning experience focused on mastering Linux exploitation techniques, including stack-based methodologies.',
        img: Sploit,
        icons: <FaTools />,
        prize: "₹ 24,850",

    },




];
// dummyData.js
export const courses = [
    {
        id: 1,
        title: "Revex Course",
        description: "Learn the fundamentals of Revex.",
        image: "https://via.placeholder.com/150"
    },
    {
        id: 2,
        title: "Stackxploit",
        description: "Master the art of stack exploitation.",
        image: "https://via.placeholder.com/150"
    },
    {
        id: 3,
        title: "Webpn Essentials",
        description: "Deep dive into Webpn technology.",
        image: "https://via.placeholder.com/150"
    },
    {
        id: 4,
        title: "Webdrub Basics",
        description: "Get started with Webdrub.",
        image: "https://via.placeholder.com/150"
    },
    {
        id: 5,
        title: "Revex Advanced",
        description: "Advanced techniques in Revex.",
        image: "https://via.placeholder.com/150"
    },
    {
        id: 6,
        title: "Stackxploit Intermediate",
        description: "Intermediate stack exploitation techniques.",
        image: "https://via.placeholder.com/150"
    },
    {
        id: 7,
        title: "Webpn Advanced",
        description: "Become a pro with Webpn.",
        image: "https://via.placeholder.com/150"
    },
    {
        id: 8,
        title: "Webdrub Professional",
        description: "Professional Webdrub techniques.",
        image: "https://via.placeholder.com/150"
    },
    {
        id: 9,
        title: "Revex Pro",
        description: "Professional level Revex course.",
        image: "https://via.placeholder.com/150"
    },
    {
        id: 10,
        title: "Stackxploit Mastery",
        description: "Achieve mastery in stack exploitation.",
        image: "https://via.placeholder.com/150"
    }
];


export const overview = [
    {
        id: 1,
        title: 'Advance Cyber Warfare Program',
        details: 'Introducing Hackersprey Advance Cyber Warfare (ACW) Program, the ultimate all-in-one Cybersecurity Certification Course  designed for both beginners and experienced. This comprehensive Online Cybersecurity course curriculum covers everything from web penetration testing, cybersecurity networking, web app pentesting, Active Directory training, and network penetration testing. providing real-world hacking challenges to hone your skills and making it an unparalleled resource for those looking to Advance their careers in cybersecurity. The ACW Program is more than just a course it is a complete Cybersecurity Certification Course experience designed to prepare you for the real-world challenges of the industry. This online cybersecurity course offers the knowledge, skills, and practical experience you need to excel in this fast-evolving field.',

    },
    {
        id: 2,
        title: 'Overview Of Our ACW Cybersecurity Certification course',
        details: 'This Advance Cyber Warfare (ACW) <a href="https://www.hackersprey.com/acw-cyber-security-certification-course" target="_blank" style="color: red; text-decoration: underline; letter-spacing: 0px;">Cybersecurity Certification Course</a> combines theory and practical hacking challenges to provide a comprehensive education in cybersecurity. The ACW Online Cybersecurity course is divided into three core parts: Web Exploitation, Active Directory, and Network Security, each covering vital aspects of cybersecurity, and is created for individuals seeking to enhance their skills in penetration testing, ethical hacking, and network defense.Whether you are aiming to enhance your current skills or establish a strong foundation in cybersecurity, this online cybersecurity course is designed to meet your needs, providing the essential tools and knowledge to advance in the field. Through this Cybersecurity Certification Course, participants will gain practical, real-world experience, making it an indispensable resource for those aspiring to excel in the ever-evolving cybersecurity landscape.'
    }


];



export const overview2 = [
    {
        id: 1,
        title: 'Web Exploitation Challenges',
        details: 'The Web Exploitation module focuses on web penetration testing, teaching you how to identify, exploit, and mitigate vulnerabilities in web applications. As part of our online cybersecurity course, this section covers a wide range of vulnerabilities and attack techniques, providing you with the knowledge to secure web applications against sophisticated threats. Through a blend of theoretical knowledge and hands-on exercises, you\'ll master various web exploitation techniques, ensuring you are well-prepared to defend against complex web security challenges.',
        syllabus: [
            {
                subtile: "HTTP Basics and Headers:",
                content: "HTTP Options, Connection Contamination, Request Handling, Hop-by-Hop Header Abuse"
            },
            {
                subtile: "Injection Vulnerabilities:",
                content: " Including SQL Injection, LDAP Injection, Parameter Pollution, and NoSQL Injection."
            },
            {
                subtile: "Authentication and Session Management:",
                content: "CSRF"
            },
            {
                subtile: "Cross-Site Scripting and Related Attacks:",
                content: "Reflected XSS, CSP Bypass, OpenRedirect to XSS"
            },

            {
                subtile: "HTTP Request/Response Smuggling:",
                content: "Includes Request Smuggling and Upgrade Header Smuggling."
            },

            {
                subtile: "Access Control and Misconfigurations:",
                content: "LFI to Account Takeover, CORS Misconfiguration"
            },
            {
                subtile: "Advance and Complex Vulnerabilities:",
                content: "Insecure Deserialization, Race Condition, Rate Limit Bypass, Command Injection, Path Traversal, SSRF, HTTP Parameter Pollution, Clickjacking, DOM-based Vulnerabilities, IDOR, and Email Header Injection."
            },

        ]
    },
    {
        id: 2,
        title: 'Active Directory Challenges',
        details: 'The Active Directory module provides in-depth active directory training on Active Directory security, from enumeration to Advance exploitation techniques. This section is critical for understanding and securing Active Directory environments. As part of our online cybersecurity course, you will gain comprehensive knowledge of active directories, enabling you to identify and mitigate vulnerabilities effectively.',
        syllabus: [
            {
                subtile: "Active Directory Enumeration:",
                content: " Using Powerview, Using BloodHound."
            },
            {
                subtile: "Local Privilege Escalation (LPE):",
                content: "Escalating Privileges on a single system within a network, PowerUp Exploits"
            }, {
                subtile: "Domain Privilege Escalation (DPE):",
                content: "DCShadow Attack, Trust-Abuse-MSSQL Server, Across Forest using krbtgt Ticket, Across Trusts using Trust Ticket, Constrained Delegation"
            },
            {
                subtile: "Lateral Movement:",
                content: "ACL Attacks, Pass the Hash, Pass the Ticket, Overpass the Hash, Credentials Spraying, PowerShell Remoting, RDP Hijacking"
            },
            {
                subtile: "Domain Persistence:",
                content: "Covers Golden Ticket, Silver Ticket, DCSync Attack, Custom SSP, DSRM Abuse, Skeleton Key, and AdminSDHolder."
            },
            {
                subtile: "Intra-Forest Attacks:",
                content: "Exploiting Trust Relationship between Domains"
            },
            {
                subtile: "Cross Forest Attacks:",
                content: "Exploiting Trust Relationship between Separate Active Directory Forests"
            },
            {
                subtile: "Additional Attacks:",
                content: "LLMNR Poisoning, SMB Relay, Shell Access, IPv6 Attack (DNS Takeover), Domain Enumeration, Pass the Hash/Password, Kerberoasting, Token Impersonation, URL Attack, Mimikatz (Golden Ticket, Silver Ticket, Diamond Ticket), AS-REP Roasting, Abusing AD CS (certipy, CVE), ACL Exploits'"
            },
        ]
    },
    {
        id: 3,
        title: 'Network Security Challenges',
        details: 'The Network Security module offers an in-depth understanding of network penetration testing and network security, equipping you with the skills needed to protect and secure networks effectively. You will learn various scanning, enumeration, and exploitation techniques essential for maintaining robust network security. This is also a key part of our online cybersecurity course, designed to equip you with the expertise needed to safeguard networks against potential threats.',
        syllabus: [
            {
                subtile: "Network Scanning and Enumeration:",

                content: " SMB Scanning, SNMP Enumeration, MYSQL"
            },
            {
                subtile: "Advance Network Exploits:",

                content: " Services and Protocols: Jenkins, FTP, SMTP, POP, IMAP, Ident Protocol (113), NTP, RTSP, IPP"
            },

            {
                subtile: "Advance Network Exploits:",

                content: " Redis RCE, Apache Jserv Protocol (CVE-2020-1938), Dumping Memcache Keys, Fast CGI (RCE)"
            },
            {
                subtile: "Practical Applications:",

                content: "Jenkins, SSH Tunneling, Bypass SSH Restriction by Port Relay, WMIC."
            },
        ]
    }
];

export const description = [
    {
        id: 1,
        title: 'Benefits Of Doing Advance Cyber Warfare Program',
        details: 'Enrolling in the Hackersprey (ACW) Cybersecurity Certification Course Program offers significant benefits to enhance your cybersecurity skills and career. Through practical ans real-world challenges, you will master web penetration testing, active directory training and network penetration testing. This is also a core part of our online cybersecurity course, allowing you to join a community of professionals, stay updated with the latest trends, and get job-ready with the Hackersprey ACW Program.',
        syllabus: [
            {
                subtile: "Comprehensive Certification:",
                content: "Earn a prestigious Cybersecurity Certification Course."
            },
            {
                subtile: "Hands-on Training:",
                content: " Engage in practical hacking challenges."
            },
            {
                subtile: "Active Directory Expertise:",
                content: "Master active directory security techniques."
            },
            {
                subtile: "Network Security Skills:",
                content: "Learn network penetration testing and security."
            },

            {
                subtile: "Web Exploitation Skills:",
                content: "Gain expertise in web app and web penetration testing."
            },

            {
                subtile: "Networking Opportunities: ",
                content: "Connect with cybersecurity professionals."
            },
            {
                subtile: "Career Advancement:",
                content: " Enhance your job prospects with recognized certification."
            },
            {
                subtile: "Real-World Challenges:",
                content: " Tackle actual hacking scenarios."
            },
            {
                subtile: "Job-Ready Skills:",
                content: " Prepare for cybersecurity roles."
            },
            {
                subtile: "Problem-Solving:",
                content: "Improve your ability to solve security issues."
            },
            {
                subtile: "Practical Application:",
                content: " Apply theoretical concepts to real-world situations."
            },

        ]
    },

    {
        id: 2,
        title: 'Why Hackersprey is Your Gateway to Success in Cybersecurity',
        details: 'Hackersprey offers a top-tier Cybersecurity Certification course with an updated curriculum that aligns with industry standards. Our comprehensive instructional videos and real-world hands-on challenges provide practical experience in cybersecurity networking, web penetration testing, and active directory. ',
        syllabus: [
            {
                subtile: "Curriculum:",

                content: " Updated and Relevant as per industry requirements."
            },
            {
                subtile: "Comprehensive Instructional Videos:",

                content: "Detailed instructional videos with practical demos for deeper learning."
            },

            {
                subtile: "Real-Word Hands-On Challenges",

                content: "Engage in comprehensive practical challenges included in each section for hands-on practice."
            },
            {
                subtile: "Advanced Content",

                content: "Explore a wealth of advanced content meticulously curated to propel your expertise to new heights."
            },
            {
                subtile: "Exclusive Access",
                content: "Dedicated Exploitation Machines for Hands-On Practice."
            },
            {
                subtile: "Affordable Excellence",
                content: "Genuine Pricing for the Program."
            }
        ]
    }
];

export const certificate = [
    {
        id: 1,
        title: "Hackersprey Certification and Accreditation",
        description: `Upon successful completion of your ACW Cybersecurity Certification Course Program, you will be awarded a prestigious certification from Hackersprey that validates your expertise in cybersecurity. This Cybersecurity Certification Course not only enhances your professional profile but also demonstrates your Advance skills in cybersecurity networking, penetration testing, and active directory. Recognized globally, this certification signifies a high level of competence and commitment to cybersecurity excellence. Our Online Cybersecurity courses serve as a testament to your ability to tackle complex cybersecurity challenges and implement effective security measures. By achieving thisACW <a href="https://www.hackersprey.com/acw-cyber-security-certification-course" target="_blank" style="color: red;  text-decoration: underline; letter-spacing: 0px">ACW Cybersecurity Certification Course</a> , you gain access to Advance career opportunities and become part of an elite group of professionals dedicated to protecting and securing digital environments.`,

        buttonText: "Learn More",
        image: feature6, // Assuming this is imported or defined elsewhere
        reverseOrder: false,
        icon: <IoCheckmarkDoneOutline />,

        title2: "Certification and Accreditation",


    },
    {
        id: 2,
        title: "Top Cybersecurity Careers in Demand for 2024",
        description: `Earning a Cybersecurity Certification Course is essential for advancing in the fast-evolving field of cybersecurity. With the rise of cyber threats, companies are increasingly seeking professionals skilled in cybersecurity networking, penetration testing training, and more. Through our Online Cybersecurity course, you’ll gain the expertise needed to excel in these areas. In 2024, roles such as network security engineers, web penetration testers, and ethical hackers will be highly in demand. Mastering key areas like network security, web app pentesting, and active directory training will open doors to exciting and impactful career opportunities in the cybersecurity industry.`,
        listItems: [
            "Chief Information Security Officer",
            "Information Security Officer",
            "Cyber Security Architect",
            "Security Engineer",
            "Security Analyst",
            "Incident Response Coordinator",
            "Cyber Security Consultant",
            "Security Awareness Trainer",
            "Vulnerability Management Lead",
            "Cyber Security Project Manager",
            "Penetration Tester",
            "Ethical Hacker",


        ],
        buttonText: "Learn More",
        image: topcyver,
        reverseOrder: true,
        icon: <IoCheckmarkDoneOutline />
    },

]


export const Review = [
    {
        star: <FaStar />,
        img: review1,
        passage: "The course videos are exceptionally well-made, making it easy to learn cybersecurity, the best platform to learn cybersecurity online.",
        Name: "Amit"
    },
    {
        star: <FaStar />,
        img: review2,
        passage: "The Online Cybersecurity Course at Hackersprey offers outstanding training in network penetration testing and web penetration testing. With practical exercises in active directory and cybersecurity networking, it's the perfect program for those serious about their cybersecurity career.",
        Name: "Ashutosh"
    },

    {
        star: <FaStar />,
        img: review3,
        passage: "Hackersprey cybersecurity and ethical hacking courses in Chandigarh are top-notch also their monthly CTF event challenges for college students enhance skills and foster growth of the students.",
        Name: "Mohit"
    },
    {
        star: <FaStar />,
        img: review5,
        passage: "One of the top cybersecurity course in Chandigarh, with a well-structured curriculum from basic to advanced levels        .",
        Name: "shailab Binjola"
    },
    {
        star: <FaStar />,
        img: review4,
        passage: "Hackersprey Online Cybersecurity course is perfect for mastering advanced web and network security techniques. The practical approach makes it ideal for anyone serious about cybersecurity.",
        Name: "Sarmistha"
    },
    {
        star: <FaStar />,
        img: review6,
        passage: "The Cybersecurity Certification Course at Hackersprey offers an exceptional blend of cybersecurity networking and hands-on penetration testing training. This online cybersecurity course provides in-depth web app pentesting and active directory training, making it ideal for advancing your network security skills.",
        Name: "Shubham"
    }
    ,
    {
        star: <FaStar />,
        img: review7,
        passage: "Gain essential skills in web and network security with the Hackersprey cybersecurity course in Chandigarh. It’s a great way to enhance your expertise and advance your career in cybersecurity.",
        Name: "soni"
    },
    {
        star: <FaStar />,

        img: review8,
        passage: "I have had the pleasure of using Hackersprey for my ethical hacking endeavors, and it has exceeded my expectations. This cyber security course online platform offers an extensive range of hacking challenges that are both educational and enjoyable. The 24/7 access to practice labs is a fantastic feature, allowing me to work on my skills at my own pace. The point system and global rank on Hackersprey provide an added incentive to push myself further and continuously improve. Hackersprey is a remarkable platform that I highly recommend to all aspiring who are searching for the best ethical hacking course online.",
        Name: "Manish Sharma "
    }
]


export const courseInfo = [
    {
        title: "Who Should Join This ACW Cybersecurity Certification Course Program",
        description: "The ACW Cybersecurity Certification Course is perfect for individuals and professionals at all levels who want to strengthen their expertise in cybersecurity. Whether you're just beginning your career, looking to take on advanced roles, or passionate about protecting digital environments, this Cybersecurity Certification Course provides essential skills and knowledge. It caters to a wide audience, including beginners, security enthusiasts, IT professionals, and those seeking a transition into cybersecurity. With practical insights, hands-on training, and a comprehensive curriculum, the Advance Cyber Warfare Program is ideal for anyone aiming to stay ahead in the fast-evolving field of cybersecurity.",

    }
];
export const CareersDemand = [
    {
        title: "Top Cybersecurity Careers in Demand for 2024",
        description: "Earning a Cybersecurity Certification is crucial for advancing in cybersecurity networking, penetration testing, web penetration testing, and overall cybersecurity expertise. As cyber threats continue to grow in complexity and frequency, organizations are placing a higher value on professionals who have demonstrated their skills through rigorous certification programs. These certifications not only validate your knowledge but also provide you with the practical skills needed to protect systems and data from ever-evolving cyber threats."
        ,
        select: "select preferences",
        books: "Book a free counselling",
        CareersDemandlist: [
            "Chief Information Security Officer",
            "Security Engineer",
            "Information Security Officer",
            "Security Analyst",
            "Cyber Security Architect",
            "Ethical Hacker",
            "Incident Response Coordinator",
            "Penetration Tester",
            "Cyber Security Consultant",
            "Security Awareness Trainer",
            "Vulnerability Management Specialist",
            "Cyber Security Project Manager"
        ]
    },

]

