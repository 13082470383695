import React, { useEffect, useState } from "react";

import "./Common.css";

import basic from "../../../Assets/subsplan/Basic.svg";
import Webhack from "../../../Assets/Landing page/webhacklogo.png";
import Dot from "../../../Assets/images/Activity.svg";
import Vip from "../../../Assets/subsplan/VIP.svg";
import Enterprise from "../../../Assets/subsplan/Enterprise.svg";

import { useNavigate, NavLink ,useParams} from "react-router-dom";
import Loading from "../Modals/Loading";
import Susbxriptionsaves from "../Modals/Susbxriptionsaves";
import Fakesubscription from "../Modals/Fakesubscription";

import Subscriptionsucess from "../../Authpages/Subscriptionsucess";
const getColorClassBasedOnValidity = (validity) => {
  switch (validity) {
    case 1:
      return "card-basic";
    case 2:
      return "card-vip";
    case 3:
      return "card-enterprise";
    // Add more cases for other validities as needed
    default:
      return "";
  }
};


const convertValidityToDays = (validity) => {
  switch (validity) {
    case 1:
      return 30;
    case 2:
      return 60;
    case 3:
      return 90;
    default:
      return 0; // You can set a default value or handle it as per your requirement
  }
};
const getColorbuttonClassBasedOnValidity = (validity) => {
  switch (validity) {
    case 1:
      return "basicplanbutton"; // Define your CSS class for yellow color
    case 2:
      return "vipplanbutton";
    case 3:
      return "enterpriseplanbutton"; // Define your CSS class for blue color
    default:
      return ""; // You can set a default value or handle it as per your requirement
  }
};
const getColorspanClassBasedOnValidity = (validity) => {
  switch (validity) {
    case 1:
      return "yellowcolor"; // Define your CSS class for yellow color
    case 2:
      return "bluecolor";
    case 3:
      return "greencolor"; // Define your CSS class for blue color
    default:
      return ""; // You can set a default value or handle it as per your requirement
  }
};
const Subscription = (props) => {
  const params=useParams()
  const API_Key = process.env.REACT_APP_API_URL;
  const API_Key2 = process.env.REACT_APP_RAZORPAY_ID;
  const [initstate, setInitState] = useState("/subscription");

  const [plans, setplans] = useState([]);
  const [modal, setopenmodal] = useState(false);
  const [modal2, setopenmodal2] = useState(false);
  const [modal3, setopenmodal3] = useState(false);
  const [plansid, setplansid] = useState();
  const [plandetail, setplaindetail] = useState([]);
  const navigate = useNavigate();
 
  const getplans = async () => {
   // let courseid = props.match.params.course_id;
  
   let courseid=params.course_id
    setopenmodal(true);
    try {
      const res = await fetch(`${API_Key}/getplan/` + courseid, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": `${API_Key}`,
          "Access-Control-Allow-Credentials": "true",
        },
        credentials: "include",
      });
      const data = await res.json([]);
      console.log(data,"data")

      const order=[1,3,2];
      data.sort((a,b)=>order.indexOf(a.validity)-order.indexOf(b.validity))
      setplans(data)
      setopenmodal(false);

      if (res.status !== 200) {
        const error = new Error(res.error);
        throw error;
      }
    } catch (err) {
      console.log(err);
      navigate({
        // pathname : '/signin',
        //state: initstate
      });
    }
  };

  useEffect(() => {
    getplans();
  }, []);

  const sortedPlans = [
    ...plans.filter((plan) => plan.validity === 1),
    ...plans.filter((plan) => plan.validity === 3),
    ...plans.filter((plan) => plan.validity === 2),
  ];
  return (
    <div>
      {modal && <Loading setOpenModal={setopenmodal} />}
      {modal2 && <Susbxriptionsaves setOpenModal={setopenmodal2} />}
      {modal3 && <Fakesubscription setOpenModal={setopenmodal3} />}

      <div className="background_dashboard p-2">
        <div className="commontopper">
          <div className="container">
            <div className="row justify-content-center">
              <h1 className="commonhead">
                Choose a Plan
                <div className="row justify-content-center">
                  <hr className="commonheadhr"></hr>
                </div>
              </h1>
            </div>
            <div className="row justify-content-center">
              <p className="Commonpara">
                Elevate your hacking skills by selecting the perfect plan to
                unlock advanced knowledge and hands-on experiences !{" "}
              </p>
            </div>
          </div>
        </div>

        <div className="container-fluid mt-5 p-5">
          <div className="row justify-content-center p-5  ">
            {sortedPlans.map((plans, id) => (
                <div className="col-lg-3 col-10 m-5" key={id}>
                  <div
                    className={`p-5 ${getColorClassBasedOnValidity(
                      plans.validity
                    )}`}
                  >
                    <div className="row justify-content-center">
                      <div className="col-lg-5 col">
                        {" "}
                        <img src={plans.image} alt="basic" />
                      </div>
                      <div className="col-lg-7 col pt-3">
                        {" "}
                        <h2 className="subplanhead">{plans.name}</h2>
                        <h6 className="subplanpara ">
                          <span
                            className={`btn  ${getColorspanClassBasedOnValidity(
                              plans.validity
                            )}`}
                          >
                            ({convertValidityToDays(plans.validity)} days)
                          </span>
                        </h6>
                      </div>
                    </div>
                    <div className="row  justify-content-center mt-5">
                      <div className="col-lg-12">
                        <hr className="subplanhr"></hr>
                      </div>
                    </div>
                    <div className="row  justify-content-center mt-1">
                      <div className="col-lg-12 col-12 ">
                        {plans.features &&
                          plans.features.map((feature, id) => (
                            <ul>
                              <li className="subplanfeaturepara">
                                <span className="mr-3">
                                  {" "}
                                  <img
                                    src={Dot}
                                    alt="dot"
                                    style={{ height: "1.5rem" }}
                                  />
                                </span>
                                {feature.feature}
                              </li>
                            </ul>
                          ))}
                      </div>
                    </div>
                    <div className="row justify-content-center ">
                      <div className="col-lg-8">
                        <NavLink to={"/verifysubscription/" + plans._id}>
                          <button
                            className={`btn  ${getColorbuttonClassBasedOnValidity(
                              plans.validity
                            )}`}
                          >
                            RS.{plans.price}/-
                          </button>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default (Subscription);
