import React, { useEffect, useState } from 'react'

import "./Entity.css";
import {  } from 'react-router-dom';
import Videoimg from "../../../Assets/Entity/Videos play.svg"
const Video = (props) => {
  const[video,setvideo]=useState("");
  const API_Key = process.env.REACT_APP_API_URL;
    
const getvideos=async()=>{

  try{
    const res = await fetch(`${API_Key}/getcourses`,{
        method:"GET",
        headers:{
          Accept:"application/json",
              "Content-Type":"application/json",
              "Allow-Credentials":true,
              credentials:"include"
        },
 
       
    });
    const data = await res.json();
   
    setvideo(data);

  

    if(res.status !== 200 ){
        const error = new Error(res.error);
        throw error;
        
    }
    
 


}catch(err){
  console.log(err);
  

} 

}

  useEffect(() => {
    getvideos();

  }, []);
  
  return (
    <div>

    <div className='Entity_background p-1'>
    <div className='Entitycommontopper'>
    <div className='container'>
    <div className='row justify-content-center'>
   <div className='col-lg-8 col-md-8 col-12 p-0'>
   <h1 className='Entitycommonhead'>Videos<span><hr className='Entitycommonheadhr'></hr></span></h1>
   <p className='Entitycommonheadpara'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,</p>
   
   </div>
   <div className='col-lg-3 col-md-4 col-12 p-2 mt-4 mb-4 offset-1'>
   <img src={Videoimg} alt="labs"  ></img>
   
   </div>
    
    </div>
   
    </div>
    
   </div>
  
  <div className='containe mt-5 p-5'>
   <h2 className='coursenamevideo'>WEBPWN</h2>
   
   <div className='row justify-content-center'>

   <div className=' col-lg-3 col-md-3 col-10 ' >
   
   {video.chapters&&video.chapters.map((videoss,id)=>(
     <h1 key={id}>{videoss.chaptername}</h1>
   ))}
   </div>
   <div className=' col-lg-3 col-md-3 col-10 ' >
   <div className='card' id='videocard'></div>
   </div>
   <div className=' col-lg-3 col-md-3 col-10 ' >
   <div className='card' id='videocard'></div>
   </div>
   <div className=' col-lg-3 col-md-3 col-10 ' >
   <div className='card' id='videocard'></div>
   </div>

   </div>

            
 
  
 
  
  
  
  </div>
  
  </div>
  </div>
    
 
  )
}

export default (Video)