import React,{useState,useEffect} from 'react'

import Courseimg from "../../../Assets/Entity/Course/Courses Tri.svg";
import {BsFillGridFill,BsListUl} from "react-icons/bs";

import{RiArrowDropRightLine} from "react-icons/ri";
import vip from "../../../Assets/prodile/VIP.svg"
import free from "../../../Assets/prodile/Asset 4.svg"
import { NavLink ,useNavigate,useParams} from 'react-router-dom';

import Loading from '../Modals/Loading';
import { FaRegPlusSquare } from "react-icons/fa";
import Paymentidverify from '../Modals/Paymentidverify';
const Dashmastercourses = (props) => {
    const params = useParams();
  const [view, setview] = useState("Grid");
  const[mastercourse,setmastercourse]=useState([]);
  const[assocourse,setassocourse]=useState([]);
  const[modal,setopenmodal]=useState(false);
  const[modal2,setopenmodal2]=useState(false);
  const [clicked, setClicked] = useState(null);
  const[style,setstyle]=useState("courselistview");
  const[color,setcolor]=useState("white");
  const Gridview=()=>{
    setview("Grid");
    setcolor("white");
  }
  const Listview = ()=>{
    setview("List");
    setcolor("white");
  }
  const toggle = index => {
    if (clicked === index) {
      //if clicked question is already active, then close it
      
      return setClicked(clicked);
     
     
    }

    setClicked(index);
    
  };
const [courses, setCourses] = useState([]);
const [coursespaid, setCoursespaid] = useState([]);
const [planexpired, setplanexpired] = useState([]);

const API_Key = process.env.REACT_APP_API_URL;
const navigate=useNavigate();
console.log("started hit");

const fetchCoursesexpiry = async () => {
  setopenmodal(true);
  
          
    try{
      const res = await fetch(`${API_Key}/expire_puchase_plan`,{
          method:"GET",
          headers:{
            mode: 'cors',
            'Access-Control-Allow-Origin':`${API_Key}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': true,
          },
          credentials:"include"
        
         
      });
      const data = await res.json();
     
      setplanexpired(data)
    
  
      if(res.status !== 200 ){
          const error = new Error(res.error);
          throw error;
          
      }else{
        setopenmodal(false);
      }
      
   

  
  }catch(err){
    console.log(err);
    //navigate("/signin");
  
  } 
  
  }


  const fetchCourseaccess = async () => {
    setopenmodal(true);
    let course_id = params.course_id;

    try {
      const res = await fetch(`${API_Key}/mastecourseaccess/` + course_id, {
        method: "GET",
        headers: {
          mode: "cors",
          "Access-Control-Allow-Origin": `${API_Key}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
        credentials: "include",
      });
      const data = await res.json();
      console.log(data.associatedCourses,"sukhdata")
      setassocourse(data.associatedCourses);
      //setcourse(data);
     // setpostlist(data.chapters.length);
      setopenmodal(false);

      if (res.status !== 200) {
        const error = new Error(res.error);
        throw error;
      }
    } catch (err) {
      console.log(err);
      //navigate("/signin");
    }
  };
  useEffect(() => {
    fetchCourseaccess();
  }, []);

const addcourse =()=>{
  setopenmodal2(true);
}
useEffect(() => {
    fetchCourseaccess();
},[ ] );

useEffect(() => {
  fetchCoursesexpiry();
},[ ] )

useEffect(() => {
  window.scrollTo(0, 0)
}, [])

  return (
    <div>
    {modal && <Loading setOpenModal={setopenmodal}/>}
    {modal2 && <Paymentidverify setOpenModal={setopenmodal2}/>}
  
    <div className='background_dashboard p-1'>
    <div className='Entitycommontopper'>
    <div className='container'>
    <div className='row justify-content-center p-0'>
   <div className='col-lg-8 col-md-8 col-12 '>
   <h1 className='Entitycommonhead'>ACW<span><hr className='Entitycommonheadhr'></hr></span></h1>
   <p className='Entitycommonheadpara'>We believe in practical knowledge, performing challenges, identifying issues, and above all self-learning. Instead of focusing only on theoretical knowledge in our web penetration testing course, we have come up with real-world challenges to make you a certified professional.     </p>
   
   </div>
   <div className='col-lg-3 col-md-4 col-12  mt-3 offset-1'>
  <img src={Courseimg} alt="Courses"  style={{backgroundSize:"cover"}} ></img>
   
   </div>
    
    </div>
   
    </div>
    
   </div>
  
  
 
  
  
  
 

   <div className='container  p-5 '>
  
    <div className='row float-left '>
   <div className='col-lg-11 ' id='viewdiv'>
  
  

  

   </div> 
   
  
   
   </div>
   <div className='row float-left ml-5'>
   <div className='col-lg-11 ' id='viewdiv'>
  
  

   </div> 
 
   
  
   
   </div>
   <div className='row float-left ml-5'>
   <div className='col-lg-11 ' id='viewdiv'>
  
  

   </div> 
 
   
  
   
   </div>
   <div className='row float-right'>
   <div className='col-lg-11 ' id='viewdiv'>
  
  

   <div className='row justify-content-center '>
   <div className='col-6 justify-content-center'><button className='btn ' onClick={Gridview}><BsFillGridFill color={view==="Grid"?color:"grey"} size={15}/></button> </div>

   <div className='col-6 justify-content-center '> <button  className='btn ' onClick={Listview}><BsListUl   color={view==="List"?color:"grey"}   size={15}/></button> </div>

</div></div> 
 
   
  
   
   </div>
   </div>
  
   <div className='container mt-5 p-5'>
   
 
   {/* Grid View */}
   <div className='container mt-5'>
     {view === "Grid" && (
       <div className='container mt-5 mb-5 p-5'>
         <div className='row'>
          
 
           {assocourse.map((course, id) => (
             <div className='col-lg-6 col-md-6 col-12' key={id}>
               <NavLink to={course.is_mastercourse ? "/setting/" + course._id : "/coursedesc/" + course._id}>
                 <div id='coursegridview'>
                   <div className='row justify-content-center'>
                     <div className='col-lg-5 col-md-10 col-10 pl-5 pt-3 pb-0 mt-0'>
                       <div className='courselogodiv p-3'>
                         <img src={course.cover_image} alt="course" />
                       </div>
                     </div>
                     <div className='col-lg-7 col-md-10 col-10 p-5'>
                       <h1 className='entitycoursegridhead'>{course.coursename}
                         <span className='ml-4'>
                           <img src={vip} style={{ "height": "2.5rem", marginTop: '-1rem' }} />
                         </span>
                       </h1>
                       <p className='entitycoursegridpara pr-5'>{course.desc.substr(0, 250)}</p>
                       <div className='Labbtn offset-9'>
                         <RiArrowDropRightLine className='span' color='white' size={15} style={{ marginTop: "-2.4rem" }} />
                       </div>
                     </div>
                   </div>
                 </div>
               </NavLink>
             </div>
           ))}
         </div>
       </div>
     )}
   </div>
 </div>
 

   

   
 

   
  
    </div>
    </div>
  )
}

export default Dashmastercourses

