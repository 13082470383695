import React,{useState} from 'react'
import Congrats from "../../../../Assets/MASCOT/Mascot SVG/Asset 29.png"


import {ImCross} from "react-icons/im";
import Quitchallenge from '../Quitchallenge';
const Bugbountystart = ({setOpenModal,ip}) => {
    const[modalopen2,setmodalopen2]=useState(false)
    const startlab = ()=>{

        window.open(ip);
       }
  return (
    <div>
    {modalopen2 &&  <Quitchallenge setOpenModal={setmodalopen2}/> }
    
    <section className="modalBackground justify-content-center" onClick={() => {
      setOpenModal(false);
    }}>
  <div className="container  justify-content-center">
  
    <div className="row justify-content-center ">
    
      <div className="col-lg-8 col-md-10 col-10 p-1 modalContainer" >
      
    
        <div className='row p-5'>
       
        <div className='col-lg-4 col-md-5 col-4  '>
       
        <div className='justify-content-center pl-5'><img src={Congrats} style={{height:"45vh"}} alt="sorry"/></div>
        </div>
        <div className='col-lg-8 col-md-5 col-8   '>
        <div className='mt-2 crossbtnarrow'>
        <button
          onClick={() => {
            setOpenModal(false);
          }}
        >
        <div className='text-center'><ImCross style={{height:".6rem",marginLeft:"-1.9rem",marginTop:".5rem"}}/></div> 
        </button>
      </div>
       <div className='justify-content-center'><h2 className='text-center alertmainhead2'>Start  CTF!!<div className='row justify-content-center'><span><hr className='text-center alertcommonhr'></hr></span></div></h2>  
       </div> 
       <p className='resetpara'>Welcome to Hack Master - An Ultimate Web Pentesting CTF! Your mission: seize all 10 flags within 24 hours to achieve victory. No partial wins allowed. Good luck, and may the best hacker prevail.</p>

       
       <div className='row justify-content-center'>
 <button className=' btn saveratebtn' onClick={startlab} > START CTF</button>
 
</div>
        </div></div>
       
       
         
          
       
      </div>
    </div>
  </div>

</section>
    </div>
  )
}

export default Bugbountystart