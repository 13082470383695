import * as ActionTypes from './ActionTypes';
export const USER = (state = { userinfo: null}, action) => {
    switch (action.type) {
        case ActionTypes.USER_LOGGED_IN:
            return { ...state, userinfo: action.payload }
        case ActionTypes.USER_LOGGED_OUT:
            return { ...state, userinfo: null }
        default:
            return state
    }


}