import React, { useEffect, useState } from 'react';
import '../Components/Dyanmicpages/Dynamicpage.css'
import { Helmet } from 'react-helmet';
import Footer from "../Components/Footer/Footer"
import webpwnimg from "../Assets/Entity/webpwnui/mainlogo.png";
import Record from "../Assets/Landing page/arrow 2.png";
import email1 from "../Assets/Dashboard/Mails/Screenshot 2023-06-12 at 9.30.18 AM.png";
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";
import { Faqs5 } from "../Components/Home/Homedata";
import { Link, NavLink } from "react-router-dom";
import Record2 from "../Assets/images/Activity.svg";
import Dot from "../Assets/images/Activity.svg";
import "../Components/Dashboard/Feedback/Common.css";
import Webpwncard from "../Components/Dyanmicpages/Webpwncard";
import '../Components/Dyanmicpages/BinaryCurriculum/BinaryCurriculum.css';
import { FaChevronRight,FaChevronDown } from "react-icons/fa";
import { TfiDownload } from "react-icons/tfi";


const faqs=[
    {
        question:"What's your cyber security online course name?",
        answer:"Our cyber security online course is named WEBPWN: A Professional Web Hacking Course. It offers comprehensive training in ethical hacking and web penetration testing to equip individuals with the skills needed to navigate today's cyber landscape effectively."
    },
    {
        question:"What Will I Learn in this Cyber Security Online Course?",
        answer:"In our cyber security online course, you will learn essential skills such as ethical hacking techniques, web penetration testing, and how to identify and mitigate common vulnerabilities like SQL injection, Cross-Site Scripting (XSS), and Server-Side Request Forgery (SSRF). Additionally, you'll gain insights into web protocols like Hypertext Transfer Protocol (HTTP), session management, and the Document Object Model (DOM), preparing you to tackle real-world cyber security challenges with confidence."
    },
    {
        question:"For whom is this Ethical Hacking Course Online Designed?",
        answer:"Our ethical hacking course online is designed for individuals from various backgrounds, including cyber security enthusiasts, IT professionals, software developers, and beginners interested in exploring the field of ethical hacking. Whether you're looking to advance your career or develop essential skills to protect digital assets, this online course for cyber security caters to a diverse range of learners with varying levels of experience in cyber security."
    },
    {
        question:"How long does it typically take to complete this Cyber Security Online Course?",
        answer:"Our Cyber Security Online Course, WebPwn, is designed to be self-paced, offering flexibility in program duration. You can complete it within 1 to 3 months, depending on your schedule and learning pace. While all durations are available, we recommend the 3-month option for a more thorough learning journey in this online cyber security course."
    },
    {
        question:"Is There an Exam Associated with this cyber security online course?",
        answer:"Yes, there is a 24-hour exam that you need to appear for within one month after you subscribe to this cyber security online course. The exam tests your knowledge and practical skills in web penetration testing. To pass the exam, you must score at least 80%."
    },
    {
        question:"Will I Receive a Certificate Upon Completion of this online course for cyber security? ",
        answer:"Yes, upon completing this online course for cyber security, WebPwn, you will receive a certificate demonstrating your proficiency in understanding web hacking concepts. However, please note that this certificate is not the same as the professional certification awarded upon passing the certification exam in our cyber security course online program."
    },
    {
        question:"Do I need prior knowledge for this ethical hacking course online?",
        answer:"While prior knowledge is not mandatory, it's advisable to have a basic understanding of JavaScript, Linux, web technologies, and networking concepts. Acquiring these skills will enrich your comprehension of the course material, enabling you to approach challenges with greater confidence. This cyber security online course provides detailed explanations and examples to facilitate easy learning and progression."
    },
    {
        question:" How does this cyber security online course contribute to career advancement?",
        answer:"This cyber security online course significantly enhances career prospects by equipping individuals with specialized skills highly sought after in today's digital landscape. Participants gain expertise in ethical hacking, web penetration testing, and identifying security vulnerabilities, positioning them as valuable assets in the cybersecurity industry. "
    },
    {
        question:" What support options are available during the cyber security course online?",
        answer:"Throughout this cyber security course online, you'll have access to various support options, including dedicated forums and email support. Our team of experienced instructors and support staff are available to address any questions or concerns you may have, ensuring a smooth learning experience. "
    },
    

]
const curriculum = [
    {
      sectionName: "Understanding Hypertext Transfer Protocol",
      sectionDesc:
        "Gain insight into the fundamental protocol used for transmitting data on the web with our cyber security online course. Understanding Hypertext Transfer Protocol is essential for comprehending web security mechanisms.",
    },
    {
      sectionName: "A Word About Session Management",
      sectionDesc:
        "Explore the intricacies of managing user sessions securely to prevent unauthorized access and protect sensitive data.",
    },
    {
      sectionName: "Document Object Model (DOM)",
      sectionDesc:
        "In our cyber security online course, you'll learn about the Document Object Model (DOM) and how attackers can manipulate and exploit the DOM structure of web pages to execute malicious scripts.",
    },
    {
      sectionName: " Same Origin Policy (SOP)",
      sectionDesc:
        "Learn about the security policy enforced by web browsers to prevent scripts from one origin from accessing resources from another origin. Understanding SOP is crucial for preventing cross-site scripting attacks, and you can delve into this topic in our ethical hacking course online.",
    },
    {
      sectionName: " Cross-Origin Resource Sharing (CORS)",
      sectionDesc:
        "In this cyber security online course Delve into the mechanisms allowing web servers to specify which origins have access to resources, mitigating the risk of cross-origin attacks.",
    },
    {
      sectionName: "A Word About Information Gathering",
      sectionDesc:
        "In our ethical hacking course online, you'll explore techniques for gathering information passively, laying the groundwork for effective reconnaissance in penetration testing. ",
    },
    {
      sectionName: "Passive Information Gathering",
      sectionDesc:
        "In this cyber security online course, participants will learn the art of passive information gathering, a vital aspect of ethical hacking. This technique involves gathering intelligence about target systems and networks without direct interaction, essential for effective reconnaissance in ethical hacking.",
    },
    {
      sectionName: "A word about domain name system",
      sectionDesc:
        "In this cyber security online course, gain an understanding of the role of DNS in translating domain names to IP addresses and its significance in cyber security.",
    },
    {
      sectionName: "DNS Enumeration",
      sectionDesc:
        "Learn methods for extracting valuable information from DNS servers to identify potential vulnerabilities and weaknesses.",
    },
    {
      sectionName: "SQL Injection",
      sectionDesc:
        "In this cyber security online course, you'll acquire the skills to detect and exploit SQL injection vulnerabilities. This prevalent and dangerous attack vector in web applications can be effectively mitigated by understanding its mechanics and learning proper detection and exploitation techniques.",
    },
    {
      sectionName: "Cross-Site Request Forgery (CSRF)",
      sectionDesc:
        "Explore how attackers can manipulate authenticated users into performing unintended actions on web applications, leading to potential security breaches.",
    },
    {
      sectionName: "Cross-Site Scripting (XSS)",
      sectionDesc:
        "In our ethical hacking course online, you'll learn how to identify and mitigate XSS vulnerabilities. These vulnerabilities allow attackers to inject malicious scripts into web pages viewed by other users.",
    },
    {
        sectionName: " Insecure Design",
        sectionDesc:
          "Understand the consequences of poor design choices in web applications and how they can lead to security vulnerabilities.",
      },
      {
        sectionName: "Server-Side Request Forgery (SSRF)",
        sectionDesc:
          "This cyber security online course explores the risks associated with SSRF attacks, where attackers exploit vulnerable servers to access internal resources or perform actions on behalf of the server.",
      },
      {
        sectionName: "Insecure Direct Object References (IDOR)",
        sectionDesc:
          "In our cyber security course online also learned about IDOR vulnerabilities, where attackers manipulate references to access unauthorized data or resources directly.",
      },
      {
        sectionName: "XML External Entity Injection (XXE)",
        sectionDesc:
          " In our cyber security online course, you can master the techniques used to exploit XXE vulnerabilities, enabling attackers to read sensitive data or execute arbitrary code on the server.",
      },
      {
        sectionName: "Insecure Deserialization",
        sectionDesc:
          " Understand the dangers of insecure deserialization in this cyber security course online where attackers exploit vulnerabilities to execute arbitrary code or manipulate application logic.",
      },
      {
        sectionName: "Insecure Direct Object References (IDOR)",
        sectionDesc:
          "In our cyber security course online also learned about IDOR vulnerabilities, where attackers manipulate references to access unauthorized data or resources directly.",
      }
  ];


const getColorClassBasedOnValidity = (validity) => {
    switch (validity) {
      case 1:
        return "card-basic";
      case 2:
        return "card-vip";
      case 3:
        return "card-enterprise";
      // Add more cases for other validities as needed
      default:
        return "";
    }
  };
  const convertValidityToDays = (validity) => {
    switch (validity) {
      case 1:
        return 30;
      case 2:
        return 60;
      case 3:
        return 90;
      default:
        return 0; // You can set a default value or handle it as per your requirement
    }
  };
  const getColorbuttonClassBasedOnValidity = (validity) => {
    switch (validity) {
      case 1:
        return "basicplanbutton"; // Define your CSS class for yellow color
      case 2:
        return "vipplanbutton";
      case 3:
        return "enterpriseplanbutton"; // Define your CSS class for blue color
      default:
        return ""; // You can set a default value or handle it as per your requirement
    }
  };
  const getColorspanClassBasedOnValidity = (validity) => {
    switch (validity) {
      case 1:
        return "yellowcolor"; // Define your CSS class for yellow color
      case 2:
        return "bluecolor";
      case 3:
        return "greencolor"; // Define your CSS class for blue color
      default:
        return ""; // You can set a default value or handle it as per your requirement
    }
  };
  const API_Key = process.env.REACT_APP_API_URL;


const WebPwnStatic = () => {

    const [clicked, setClicked] = useState(false);
    const [plans, setplans] = useState([]);
    const [selectedState, setSelectedState] = useState("");
    const handleClick=(index)=>{
  if(index===selectedState){
      setSelectedState("")
  }
  else{
      setSelectedState(index)
  }
  }
    
  
    const toggle = (index) => {
      if (clicked === index) {
        //if clicked question is already active, then close it
        return setClicked(null);
      }
  
      setClicked(index);
    };
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
    useEffect(() => {}, []);
    const getplans = async () => {
      let courseid = "64dc4e396e36a3edf9682146";
      try {
        const res = await fetch(`${API_Key}/getplanwithoutauth/` + courseid, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": `${API_Key}`,
            "Access-Control-Allow-Credentials": "true",
          },
          credentials: "include",
        });
        const data = await res.json([]);
       
        const order = [1, 3, 2];
        data.sort(
          (a, b) => order.indexOf(a.validity) - order.indexOf(b.validity)
        );
        setplans(data);
        if (res.status !== 200) {
          const error = new Error(res.error);
          throw error;
        }
      } catch (err) {
        console.log(err);
      }
    };
    useEffect(() => {
      getplans();
    }, []);
  
    const sortedPlans = [
      ...plans?.filter((plan) => plan.validity === 1),
      ...plans?.filter((plan) => plan.validity === 3),
      ...plans?.filter((plan) => plan.validity === 2),
    ];

  return (
    <div>
    <Helmet>
    <title>Best Cyber Security Online Course with Certification and Training</title>
    
         <meta name="description" content=" Our Cyber Security Online Course offers flexibility & convenience Access high-quality content, interactive modules, practice labs, 70 real-world hacking challenges & more."/>
         <meta name="keywords" content="cyber security online course, ethical hacking course online, cyber security course online, online course for cyber security, cyber security online training, web penetration testing"/>
    <meta name="author" content="Hackersprey"/>
   



  
    <link rel="canonical" href="https://www.hackersprey.com/best-cyber-security-online-course"/>


    <meta property="og:title" content="Best Cyber Security Online Course with Certification and Training"/>
    <meta property="og:description" content="cyber security online course, ethical hacking course online, cyber security course online, online course for cyber security, cyber security online training, web penetration testing."/>
    <meta property="og:url" content="https://www.hackersprey.com/best-cyber-security-online-course"/>
    <meta property="og:image" content="/"/>
    <meta property="og:type" content="website"/>

    
    <meta name="twitter:card" content="summary"/>
    <meta name="twitter:title" content="Best Cyber Security Online Course with Certification and Training"/>
    <meta name="twitter:description" content="cyber security online course, ethical hacking course online, cyber security course online, online course for cyber security, cyber security online training, web penetration testing."/>
    <meta name="twitter:image" content=""/>

     

    </Helmet>
    <section className="webpwnmaindiv">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-lg-7 col-md-7 col-10 p-5">
            <h1 className="webpwn-head">
            Cyber Security Online Course   </h1>
             
           
            <p className="webpwn-para2">
            The field of Cybersecurity entails conducting research and developing the required frameworks to protect sensitive information, improving security protocols, and establishing a secure IT environment. We are providing the Best Cyber Security Online Courses and ethical hacking course online with Certification. This Cyber Security Course module is created by Industry experts and faculty with our unique offering of 70 Real World Hacking Challenges and practical insights to develop the next generation of cybersecurity professionals capable of protecting websites and applications. Our Course Also helps individuals to learn at their own pace that's why we offer this cyber security course online only. Enroll today and take the first step toward a more secure future.

            </p>
          </div>
          <div className="col-lg-5 col-md-5 col-10 p-2">
            <img src={webpwnimg} alt="Professional web hacking course" />
          </div>
        </div>
      </div>
    </section>
  
    <section className="Dynamicbackground">
      <div className="container-fluid">
        <div className="row justify-content-center ">
          <h2 className="webpwnhead2 ">Why To Choose Webpwn ?</h2>
        </div>
        <div className=" row justify-content-center mt-2 ">
          <hr className="universityhr"></hr>
        </div>

        <div className="row justify-content-center ">
          <Webpwncard/>
          </div>

       
      </div>
    </section>
    

   
    <section className="Dynamicbackground">
      <div className="container mt-0">
        <div className="row">
          <div className="col-lg-7 col-md-7 col-12 mt-5 intershipdetaildiv2">
            <h3 className="whatwewilllearnhead">What you'll learn</h3>
            <hr className="coursehr mb-3"></hr>
            <h4 className="text-font-white mt-2">
              In our WEBPWN cyber security online course, you will learn -{" "}
            </h4>

            <ul className="intershipbullet mt-4" style={{ margin: "1rem" }}>
              <li className="internbulletpara">
                <span>
                  <img
                    src={Record}
                    alt="cyber security online course"
                    style={{ height: "18px", marginRight: "2rem" }}
                  />
                </span>
                Understanding Hypertext Transfer Protocol
              </li>
              <li className="internbulletpara">
                <span>
                  <img
                    src={Record}
                    alt="cyber security online certification"
                    style={{ height: "18px", marginRight: "2rem" }}
                  />
                </span>
                A Word About Session Management
              </li>
              <li className="internbulletpara">
                <span>
                  <img
                    src={Record}
                    alt="Professional web hacking course"
                    style={{ height: "18px", marginRight: "2rem" }}
                  />
                </span>
                Document Object Model (DOM)
              </li>
              <li className="internbulletpara">
                <span>
                  <img
                    src={Record}
                    alt="cyber security online course"
                    style={{ height: "18px", marginRight: "2rem" }}
                  />
                </span>
                Same Origin Policy (SOP)
              </li>
              <li className="internbulletpara">
                <span>
                  <img
                    src={Record}
                    alt="cyber security online certification"
                    style={{ height: "18px", marginRight: "2rem" }}
                  />
                </span>
                Cross Origin Resource Sharing (CORS)
              </li>
              <li className="internbulletpara">
                <span>
                  <img
                    src={Record}
                    alt="Professional web hacking course"
                    style={{ height: "18px", marginRight: "2rem" }}
                  />
                </span>
                A Word About Information Gathering
              </li>
              <li className="internbulletpara">
                <span>
                  <img
                    src={Record}
                    alt="cyber security online course"
                    style={{ height: "18px", marginRight: "2rem" }}
                  />
                </span>
                Passive Information Gathering
              </li>
              <li className="internbulletpara">
                <span>
                  <img
                    src={Record}
                    alt="cyber security online certification"
                    style={{ height: "18px", marginRight: "2rem" }}
                  />
                </span>
                A Word About Domain Name System
              </li>

              <li className="internbulletpara">
                <span>
                  <img
                    src={Record}
                    alt="Professional web hacking course"
                    style={{ height: "18px", marginRight: "2rem" }}
                  />
                </span>
                DNS Enumeration
              </li>
              <li className="internbulletpara">
                <span>
                  <img
                    src={Record}
                    alt="cyber security online course"
                    style={{ height: "18px", marginRight: "2rem" }}
                  />
                </span>
                SQL Injection
              </li>
              <li className="internbulletpara">
                <span>
                  <img
                    src={Record}
                    alt="cyber security online certification"
                    style={{ height: "18px", marginRight: "2rem" }}
                  />
                </span>
                Cross Site Request Forgery (CSRF) 
              </li>
              <li className="internbulletpara">
                <span>
                  <img
                    src={Record}
                    alt="Professional web hacking course"
                    style={{ height: "18px", marginRight: "2rem" }}
                  />
                </span>
                Cross Site Scripting (XSS) 
              </li>

              <li className="internbulletpara">
                <span>
                  <img
                    src={Record}
                    alt="cyber security online course"
                    style={{ height: "18px", marginRight: "2rem" }}
                  />
                </span>
                Insecure Design
              </li>
              <li className="internbulletpara">
                <span>
                  <img
                    src={Record}
                    alt="cyber security online certification"
                    style={{ height: "18px", marginRight: "2rem" }}
                  />
                </span>
                Server-Side Request Forgery (SSRF)
              </li>
              <li className="internbulletpara">
                <span>
                  <img
                    src={Record}
                    alt="Professional web hacking course"
                    style={{ height: "18px", marginRight: "2rem" }}
                  />
                </span>
                Insecure Direct Object References (IDOR)
              </li>
              <li className="internbulletpara">
                <span>
                  <img
                    src={Record}
                    alt="cyber security online course"
                    style={{ height: "18px", marginRight: "2rem" }}
                  />
                </span>
                XML External Entity Injection (XXE)
              </li>
              <li className="internbulletpara">
                <span>
                  <img
                    src={Record}
                    alt="cyber security online certification"
                    style={{ height: "18px", marginRight: "2rem" }}
                  />
                </span>
                Insecure Deserialization{" "}
              </li>
            </ul>
          </div>
          <div className="col-lg-4 col-md-4 col-10 offset-1 mt-5 intershipdetaildiv2">
            <div className="row">
              <div className="course-feature">

             
              <h3 className="whatwewilllearnhead">Course Features</h3>
                <hr className="coursehr mb-3"></hr>
                </div>
              
            </div>
            <div className="row">
              <ul className="intershipbullet mt-1" style={{ margin: "1rem" }}>
              <h4 className="text-font-white mt-1 mb-1">
                Discover the features of our cyber security online course-
              </h4>
                <li className="internbulletpara">
                  <span>
                    <img
                      src={Record2}
                      alt="Professional web hacking course"
                      style={{ height: "18px", marginRight: "2rem" }}
                    />
                  </span>
                  Self Paced Learning
                </li>

                <li className="internbulletpara">
                  <span>
                    <img
                      src={Record2}
                      alt="cyber security online course"
                      style={{ height: "18px", marginRight: "2rem" }}
                    />
                  </span>
                  70 Real World Hacking Challenges
                </li>
                <li className="internbulletpara">
                  <span>
                    <img
                      src={Record2}
                      alt="cyber security online certification"
                      style={{ height: "18px", marginRight: "2rem" }}
                    />
                  </span>
                  Course Videos
                </li>
                <li className="internbulletpara">
                  <span>
                    <img
                      src={Record2}
                      alt="Professional web hacking course"
                      style={{ height: "18px", marginRight: "2rem" }}
                    />
                  </span>
                  Course Materials
                </li>

                <li className="internbulletpara">
                  <span>
                    <img
                      src={Record2}
                      alt="cyber security online course"
                      style={{ height: "18px", marginRight: "2rem" }}
                    />
                  </span>
                  Doubt Clearing Through Mail{" "}
                </li>

                <li className="internbulletpara">
                  <span>
                    <img
                      src={Record2}
                      alt="cyber security online certification"
                      style={{ height: "18px", marginRight: "2rem" }}
                    />
                  </span>
                  Access To PreyGround
                </li>
                <li className="internbulletpara">
                  <span>
                    <img
                      src={Record2}
                      alt="Professional web hacking course"
                      style={{ height: "18px", marginRight: "2rem" }}
                    />
                  </span>
                  Three Practice Labs{" "}
                </li>
                <li className="internbulletpara">
                  <span>
                    <img
                      src={Record2}
                      alt="cyber security online course"
                      style={{ height: "18px", marginRight: "2rem" }}
                    />
                  </span>
                  Access To Discord Channel
                </li>
                <li className="internbulletpara">
                  <span>
                    <img
                      src={Record2}
                      alt="cyber security online certification"
                      style={{ height: "18px", marginRight: "2rem" }}
                    />
                  </span>
                  One Exam Attempt{" "}
                </li>
                <li className="internbulletpara">
                  <span>
                    <img
                      src={Record2}
                      alt="Professional web hacking course"
                      style={{ height: "18px", marginRight: "2rem" }}
                    />
                  </span>
                  Participation Certificate
                </li>
                <li className="internbulletpara">
                  <span>
                    <img
                      src={Record2}
                      alt="cyber security online course"
                      style={{ height: "18px", marginRight: "2rem" }}
                    />
                  </span>
                  Global Ranking
                </li>
                {/* <li className='internbulletpara'><span><img src={Record2} style={{height:"18px",marginRight:"2rem"}} /></span>Global Ranking Through Leaderboard</li>*/}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-0">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12 mt-5 intershipdetaildiv2">
            <h3 className="whatwewilllearnhead">Requirements</h3>
            <hr className="coursehr"></hr>
            <p className="text-font-white2">
              Get started with our cyber security course by ensuring you meet
              the following requirements -{" "}
            </p>
            <ul className="intershipbullet mt-3" style={{ margin: "1rem" }}>
              <li className="internbulletpara">
                <span>
                  <img
                    src={Record}
                    alt="cyber security online certification"
                    style={{ height: "18px", marginRight: "2rem" }}
                  />
                </span>
                System with minimum i5 processor or better
              </li>
              <li className="internbulletpara">
                <span>
                  <img
                    src={Record}
                    alt="Professional web hacking course"
                    style={{ height: "18px", marginRight: "2rem" }}
                  />
                </span>
                At least 8 GB of RAM
              </li>
              <li className="internbulletpara">
                <span>
                  <img
                    src={Record}
                    alt="cyber security online course"
                    style={{ height: "18px", marginRight: "2rem" }}
                  />
                </span>
                Basic knowledge of Linux & Javascript
              </li>
              <li className="internbulletpara">
                <span>
                  <img
                    src={Record}
                    alt="cyber security online certification"
                    style={{ height: "18px", marginRight: "2rem" }}
                  />
                </span>
                Working Internet Connection
              </li>
              <li className="internbulletpara">
                <span>
                  <img
                    src={Record}
                    alt="Professional web hacking course"
                    style={{ height: "18px", marginRight: "2rem" }}
                  />
                </span>
                Dedication to Learn
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section className="webpwnmaindiv2">
      <div className="container">
        <div className="row justify-content-center ">
          <h2 className="webpwnhead10 ">Choose the right plan for you</h2>
        </div>
        <div className=" row justify-content-center mt-2 ">
          <hr className="universityhr"></hr>
        </div>
        <br></br>
        <br></br>
        <br></br>

        <div className="row justify-content-center p-1  ">
          {sortedPlans?.map((plans, id) => (
            <div className="col-lg-3 col-10 m-2" key={id}>
              <div
                className={`p-3 ${getColorClassBasedOnValidity(
                  plans?.validity
                )}`}
              >
                <div className="row justify-content-center">
                  <div className="col-lg-5 col mt-3">
                    {" "}
                    <img
                      src={plans?.image}
                      alt="cyber security online course"
                    />
                  </div>
                  <div className="col-lg-7 col pt-3 mt-3">
                    {" "}
                    <h2 className="subplanhead">{plans.name}</h2>
                    <h6 className="subplanpara ">
                      <span
                        className={`btn  ${getColorspanClassBasedOnValidity(
                          plans?.validity
                        )}`}
                      >
                        ({convertValidityToDays(plans.validity)} days)
                      </span>
                    </h6>
                  </div>
                </div>
                <div className="row  justify-content-center mt-2">
                  <div className="col-lg-12">
                    <hr className="subplanhr"></hr>
                  </div>
                </div>
                <div className="row  justify-content-center mt-1">
                  <div className="col-lg-12 col-12 ">
                    {plans.features &&
                      plans.features.map((feature, id) => (
                        <ul>
                          <li className="subplanfeaturepara">
                            <span className="mr-3">
                              {" "}
                              <img
                                src={Dot}
                                alt="cyber security online certification"
                                style={{ height: "1.5rem" }}
                              />
                            </span>
                            {feature.feature}
                          </li>
                        </ul>
                      ))}
                  </div>
                </div>
                <div className="row justify-content-center ">
                  <div className="col-lg-8 mb-5">
                    <NavLink to={"/checkout/" + plans._id}>
                      <button
                        className={`btn  ${getColorbuttonClassBasedOnValidity(
                          plans.validity
                        )}`}
                      >
                        RS.{plans.price}/-
                      </button>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
    <section className="Dynamicbackground">
      <div className="container mt-5">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12 mt-5 intershipdetaildiv2">
            <div
              className="row justify-content-center"
              style={{ position: "relative" }}
            >
              <h2 className="justify-content-center mainfaqhead">FAQ'S</h2>

              <div className="row mt-5">
                {" "}
                <hr className="faqhr"></hr>
              </div>
            </div>
            <div className="row justify-content-center ">
              <div className="col-lg-10 col-md-10 col mt-3">
                <p className="mainfaqpara">
                  Please note that these FAQs provide general information
                  regarding this professional web hacking course. For more
                  detailed information and specific queries, it is recommended
                  to reach out to us at&nbsp;
                  <img
                    src={email1}
                    alt="Professional web hacking course"
                    style={{
                      height: "30px",
                      marginLeft: ".3rem",
                      marginTop: "-1px",
                    }}
                  />
                </p>
              </div>
            </div>

            <div className="row justify-content-center mt-5">
              <div className="col-lg-10 col-md-10 col-10">
                {faqs.map((faq, index) => (
                  <div className="row mt-5 mb-5" key={index} id="faqcards">
                    <div
                      className="col-lg-10 col-md-10 col-8"
                      onClick={() => toggle(index)}
                      key={index}
                      style={{ cursor: "pointer" }}
                    >
                      <p className="faqcardshead">{faq.question}</p>
                    </div>
                    <div
                      className="col-lg-1 col-md-1 col-1 offset-1"
                      onClick={() => toggle(index)}
                      key={index}
                      style={{ cursor: "pointer" }}
                    >
                      {clicked === index ? (
                        <AiOutlineMinusCircle color="#e32f1c" size={30} />
                      ) : (
                        <AiOutlinePlusCircle color="#e32f1c" size={30} />
                      )}
                    </div>
                    {clicked === index ? (
                      <>
                        {" "}
                        <div className="container mt-1 p-3">
                          <div className="row">
                            <div className="col-lg-11 col-md-11 col-11 ">
                              <p className="faqansercard">{faq.answer}</p>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="">
      <div className="container-fluid mt-8">
        <div className="row justify-content-center">
          <div className="col-lg-10 col-md-9 col-10 p-5">
          <h2  style={{textAlign:'center', color:"white",marginBottom:"2rem",fontSize:"4rem"}}>
          Inside Our Cyber Security Online Course

                <span>
                  <hr style={{ background: "red", display: "inline" }}></hr>
                </span>
              </h2>
              <div className=" row justify-content-center">
            <hr className="binaryhr2"></hr>
          </div>
           
           
            <p className="webpwn-para2">
            Our Cyber Security Online Course covers a complete coverage of topics to help you learn Cyber Security and ethical hacking concepts from basic to advanced levels. Throughout this online course for cyber security, you will learn an array of subjects including Understanding Hypertext Transfer Protocol, A Word About Session Management, Document Object Model (DOM), Same Origin Policy (SOP),  Word About Information Gathering, Cross-Site Request Forgery (CSRF), Passive Information Gathering, A Word About Domain Name System, Cross-Origin Resource Sharing (CORS), DNS Enumeration, Cross-Site Scripting (XSS), SQL Injection, Insecure Design, Server-Side Request Forgery (SSRF), Insecure Direct Object References (IDOR), XML External Entity Injection (XXE), Insecure Deserialization. Overall, this cyber security online training is one of the best course modules to learn and prepare for ethical hacking and cyber security and to provide you with the necessary knowledge and skills to excel in the fields of ethical hacking and cyber security, culminating in job assistance upon completion of this cyber security course online.


            </p>
          </div>
          
        </div>
      </div>
    </section>
  
    <section className="">
      <div className="container-fluid mt-8">
        <div className="row justify-content-center">
          <div className="col-lg-10 col-md-9 col-10 p-5">
              <h2  style={{textAlign:'center', color:"white",marginBottom:"2rem",fontSize:"4rem"}}>
              Benefits of doing our cyber security online course
                <span>
                  <hr style={{ background: "red", display: "inline" }}></hr>
                </span>
              </h2>
              <div className=" row justify-content-center">
            <hr className="binaryhr2"></hr>
          </div>
           
            <p className="webpwn-para2">
            By joining our cyber security online course, WEBPWN, you'll unlock a world of unparalleled learning opportunities. This ethical hacking course online program surpasses others in the realm of ethical hacking and cyber security online training. With over 70 practical challenges, you'll navigate self-paced learning to become proficient in web penetration testing, essential for today's cyber landscape. Gain exclusive access to our playground, a real-world hacking environment, to apply your skills in authentic scenarios. With supplementary videos, course materials, and three practice labs, you'll be equipped for success. Upon completion of this cyber security course online receive certificates and earn your place among the global ranking of cyber security professionals. 
            <h3>Additionally in this cyber security online course, you'll benefit from-</h3>
                Web penetration testing: Learn to ethically penetrate web applications and identify potential security weaknesses before they can be compromised.
                Ethical hacking course online: Master the ethical hacking techniques used to discover vulnerabilities before malicious actors can exploit them.<br/>
                Global ranking: Track your progress and compare yourself to other students from around the world.<br/>
          
             </p>
          </div>
          
        </div>
      </div>
    </section>
    <section className="">
      <div className="container-fluid mt-8">
        <div className="row justify-content-center">
          <div className="col-lg-10 col-md-9 col-10 p-5">
          <h2  style={{textAlign:'center', color:"white",marginBottom:"2rem",fontSize:"4rem"}}>
          Who Can Apply For this Cyber Security Online Course?
                <span>
                  <hr style={{ background: "red", display: "inline" }}></hr>
                </span>
              </h2>
              <div className=" row justify-content-center">
            <hr className="binaryhr2"></hr>
          </div>
           
            <p className="webpwn-para2">
            Cyber security enthusiasts eager to enhance their knowledge and skills.
IT professionals seeking to specialize in ethical hacking and web penetration testing.
Software developers interested in understanding and mitigating cyber security vulnerabilities.
Individuals passionate about safeguarding digital assets and combating cyber threats.
Beginners looking to enter the field of cyber security and explore career opportunities in ethical hacking and web security.


            </p>
          </div>
          
        </div>
      </div>
    </section>
    <section className="">
      <div className="container-fluid mt-8">
        <div className="row justify-content-center">
          <div className="col-lg-10 col-md-9 col-10 p-5">
          <h2  style={{textAlign:'center', color:"white",marginBottom:"2rem",fontSize:"4rem"}}>
        Certificate
                <span>
                  <hr style={{ background: "red", display: "inline" }}></hr>
                </span>
              </h2>
              <div className=" row justify-content-center">
            <hr className="binaryhr2"></hr>
            </div>
           
            <p className="webpwn-para2">
            Upon successful completion of this cyber security online course, individuals will earn a certification in ethical hacking course online, solidifying their expertise in web penetration testing and cyber security. This cyber security course online certification validates proficiency in understanding and mitigating common vulnerabilities such as SQL injection, Cross-Site Scripting (XSS), and Server-Side Request Forgery (SSRF). With this certification, individuals demonstrate mastery in areas crucial for safeguarding web applications and networks against cyber threats. Join the ranks of certified professionals and enhance your career prospects in the dynamic field of cyber security with our cyber security online course.




            </p>
          </div>
          
        </div>
      </div>
    </section>
    
  
    <div className="curr-container p-5">
      <div className="course-cur-head">
      
        <div className="bg-curriculum">
        <div className="course-cur-head margin-class" style={{color:"white"}}><p>Course Curriculum</p></div>
        <div className=" row justify-content-center">
            <hr className="binaryhr2"></hr>
          </div>
          <main className="curriculum-container">
            {curriculum.map((section, index) => (
              <div className="section-container">
                <div
                  onClick={() => handleClick(index)}
                  className="section-name" style={{color:"white"}}
                >
                  {section.sectionName}
                  {selectedState === index   ? <FaChevronDown className="icon" size={13} />:  <FaChevronRight className="icon"  size={13}/>
}</div>
                {selectedState === index  ? (
                  <p className="section-description">{section.sectionDesc}</p>
                ) : null}
              </div>
            ))}
          </main>
        
        </div>
      
      </div>
     
    </div>
    <Footer />
  </div>
  )
}

export default WebPwnStatic
