import React, { useEffect, useState } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Login from './Components/Authpages/Login';
import { addUserDetails } from './redux/ActionCreators';
import Topbar from './Components/Dashboard/Topbar/Topbar';
const API_Key = process.env.REACT_APP_API_URL;
const ProtectedRoute = () => {
  console.log(useLocation(),"location in protected")
  const navigate=useNavigate()
  const dispatch=useDispatch()
  const [isLoading,setIsLoading]=useState(true);
  const location=useLocation();
  console.log(location,"useLocation")
const user=useSelector((state)=>state.user);
console.log(user.userinfo,"userinfo");

useEffect(()=>{
  const fetchuser = async () => {    
    console.log("hshahsahsahsahsahsahshahshashashahsahshashasha")
    try{
      const res = await fetch(`${API_Key}/Username`,{
          method:"GET",
          headers:{
            mode: 'cors',
            'Access-Control-Allow-Origin':`${API_Key}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': true,
            
          },
          credentials:"include",
          //withCredentials: true,
      });
      const data = await res.json();
      console.log("userinfo",data);
      let newData;
      if(data.university_name){
        newData={
          email:data.email,
          name:data.fname,
          universityname:data.university_name
        }
      }
      else{
        newData={
          email:data.email,
          name:data.fname,
        }
      }
     
      dispatch(addUserDetails(newData))
      
      if(res.status !== 200 ){
          const error = new Error(res.error);
          throw error;
          
      }
  }catch(err){
    console.log(err,"ERRORORORRORORORORORO");
    navigate("/signin");
  } 
  }
  if(user.userinfo===null){
    fetchuser()
  }
},[])
if(user.userinfo===null)
{
  if(isLoading){
    return <p>Loading ...</p>
  }
  else{
    console.log("1")
    return <Login/>
  }
  
}
else{
  console.log("2")
  return(
    
<><Topbar /> <Outlet/></>
  )
}


}
export default ProtectedRoute;
