import React,{useState} from 'react'
import "./Sorry.css"
import {FaArrowRight} from "react-icons/fa";
import {ImCross} from "react-icons/im";

import HashLoader from  "react-spinners/HashLoader";

const Loading = ({setOpenModal}) => {
  const [color, setColor] = useState("#e32f1c");
  return (
    <section className="modalBackground3 justify-content-center">
      <div className="container  justify-content-center">
      
        <div className="row justify-content-center " >
        
        <HashLoader color={color} cssOverride={{transform:"translatey(200%)"}} size={150} />
      </div>
      </div> 
    </section>
  )
}

export default Loading