import React from 'react'

import Congrats from "../../../Assets/Modals/Deactivate Account.png"
import {FaArrowRight} from "react-icons/fa";
import {ImCross} from "react-icons/im";
import { NavLink } from 'react-router-dom';

const Passmismatch = ({setOpenModal}) => {
  return (
    <div> <section className="modalBackground justify-content-center" onClick={() => {
        setOpenModal(false);
      }}>
    <div className="container  justify-content-center">
    
      <div className="row justify-content-center ">
      
        <div className="col-lg-8 col-md-10 col-10 p-1 modalContainer" >
        
      
          <div className='row p-5'>
         
          <div className='col-lg-4 col-md-5 col-4  '>
         
          <div className='justify-content-center pl-5'><img src={Congrats} style={{height:"45vh"}} alt="sorry"/></div>
          </div>
          <div className='col-lg-8 col-md-5 col-8   '>
          <div className='mt-2 crossbtnarrow'>
          <button
            onClick={() => {
              setOpenModal(false);
            }}
          >
          <div className='text-center'><ImCross style={{height:".6rem",marginLeft:"-1.9rem",marginTop:".5rem"}}/></div> 
          </button>
        </div>
         <div className='justify-content-center'><h2 className='text-center alertmainhead2'>Password Mismatch!!<div className='row justify-content-center'><span><hr className='text-center alertcommonhr'></hr></span></div></h2>  
         </div> 
         <p className='resetpara'>Password and Confirm Password doesnot match. </p>

         
         <div className='row justify-content-center'>
        <NavLink to="/signup"> <button className='Arrowbtn' onClick={() => {
            setOpenModal(false);
          }}>
         <FaArrowRight color="white" size={20}/>
         
         </button>
         </NavLink>
         </div>
          </div></div>
         
         
           
            
         
        </div>
      </div>
    </div>

  </section>
    </div>
  )
}

export default Passmismatch