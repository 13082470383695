import React, { useState, useEffect } from "react";
import "./Navbar.css";
import Logo from "../../Assets/Landing page/Group 36.svg";
import { NavLink, useNavigate } from "react-router-dom";
import Loading from "../Dashboard/Modals/Loading";
import "./Navbar.css";

const Navbar = () => {
  const API_Key = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [showSlider, setShowSlider] = useState(false);
  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState("");

  const fetchUser = async () => {
    try {
      const res = await fetch(`${API_Key}/Username`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": `${API_Key}`,
          "Access-Control-Allow-Credentials": true,
        },
        credentials: "include",
      });
      const data = await res.json();
      setLoading(false);
      setUsername(data);

      if (res.status !== 200) {
        setLoading(false);
        throw new Error(res.error);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const toggleSlider = () => {
    setShowSlider(!showSlider);
  };

  if (loading) {
    return <Loading />;
  } else {
    return (
      <div className="navnav">
        <nav className="navbar navbar-expand-lg navbar-dark">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="my-4 mx-4 close ">
              <img src={Logo} alt="src" />{" "}
            </span>
            <span className="navbar-toggler-icon"> </span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div className="navbar-nav mx-auto">
              <NavLink className="nav-link" to="/" exact={true}>
                Home
              </NavLink>
              {/* Dropdown for Training */}
              <div
                className="nav-link-menu-container"
                onClick={toggleSlider}
              >
                <NavLink to="/summer-training-in-cybersecurity" className="nav-link">Training</NavLink>
               
              </div>

              <NavLink className="nav-link" to="/events">
                EVENTS
              </NavLink>

              <NavLink className="d-none d-sm-block" to="/" id="g">
                <img src={Logo} alt="logo" />
              </NavLink>

              <NavLink className="nav-link" to="/course">
                COURSES
              </NavLink>
              <NavLink className="nav-link" to="/university">
                UNIVERSITY
              </NavLink>

              <NavLink className="nav-link" to="/signin">
                {username.name || "GET STARTED"}{" "}
                <span className="sr-only">(current)</span>
              </NavLink>
            </div>
          </div>
        </nav>
      </div>
    );
  }
};

export default Navbar;