import React,{useState,useEffect,useRef} from 'react'
import { useForm } from "react-hook-form";

import Camera from "../../../Assets/Profile/Cover Camera.svg"
import {BiImageAlt} from "react-icons/bi";
import User from "../../../Assets/images/dp3.png";
import user1 from "../../../Assets/Profile/anastase-maragos-rtsvifRfBJk-unsplash.jpg";
import {AiFillCamera} from "react-icons/ai";
import { RiImageFill } from "react-icons/ri";
import {IoMdLock,IoIosArrowForward} from "react-icons/io";
import{RiArrowDropRightLine} from "react-icons/ri";
import Edit from "../../../Assets/Entity/Lab/Edit.svg";
import Vip from "../../../Assets/prodile/VIP.svg";
import { NavLink, } from 'react-router-dom';
import axios from 'axios';
import Plan from "../../../Assets/subsplan/VIP.svg"
import score from "../../../Assets/prodile/speed meter.png"
import { useNavigate } from 'react-router-dom';
import Loading from '../Modals/Loading';
import badge1 from "../../../Assets/prodile/badge 2.png";

const Profile = (props) => {
  
  const[modal,setopenmodal]=useState(false);
  const navigate=useNavigate();
  const[user,setuser]=useState([]);
  const[rank,setrank]=useState([]);
  const[challcount,setchallcount]=useState([]);
  const[plan,setplan]=useState("Basic")
  const [previewUrl, setPreviewUrl] = useState();
  const[file,setfile]=useState("");
  const API_Key = process.env.REACT_APP_API_URL;

const uploadimages=()=>{
  window.alert("upload")
}
  const filePickerRef = useRef();
  const handleFilePicker = () => {
    filePickerRef.current.click();
  };
  useEffect(() => {
    if (!file) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewUrl(fileReader.result);
    };
    fileReader.readAsDataURL(file);
  }, [file]);

  
  const handleImageUpload = (event) => {
    event.preventDefault();

    if (event.target.files) {
      const pickedFile = event.target.files[0];
      setfile(pickedFile);

      return;
    }
  };

  const  fetchchallengecount = async () => {
    setopenmodal(true);
          //setModalOpen3(true);
                  
              try{
                const res = await fetch(`${API_Key}/challengetakenuser`,{
                  method:"GET",
                  headers:{
                    mode: 'cors',
                    'Access-Control-Allow-Origin':`${API_Key}`,
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': true,
                  },
                  credentials:"include"
                   
                });
                const data = await res.json([]);
            
                setchallcount(data.challenge);
              
             
                if(data.status===200){
                  setopenmodal(false);
                
                } 
             
            
                if(res.status !== 200 ){
                    const error = new Error(res.error);
                    throw error;
                    
                }else{
                  setopenmodal(false);
                
                }
                
             
            
            
            }catch(err){
              console.log(err);
              navigate("/signin");
            
            } 
            
            }
          
            useEffect(() => {
              fetchchallengecount();
            },[ ] );
  const fetchuser = async () => {
setopenmodal(true);

        
    try{
      const res = await fetch(`${API_Key}/profiledata`,{
          method:"GET",
          headers:{
            mode: 'cors',
            'Access-Control-Allow-Origin':`${API_Key}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': true,
          },
          credentials:"include"
         
      });
      const data = await res.json();
   
      setuser(data);
     
      if(data.courses!==0){
        setplan("VIP")
      
      }
    
  
      if(res.status !== 200 ){
          const error = new Error(res.error);
          throw error;
          
      }else{
        setopenmodal(false);
      }
      
   
  
  
  }catch(err){
    console.log(err);
    navigate("/signin");
  
  } 
  
  }

  
  const fetchrank = async () => {


        
    try{
      const res = await fetch(`${API_Key}/finduserrank`,{
          method:"GET",
          headers:{
            mode: 'cors',
            'Access-Control-Allow-Origin':`${API_Key}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': true,
          },
          credentials:"include"
         
      });
      const data = await res.json([]);
   
      setrank(data);
    
  
      if(res.status !== 200 ){
          const error = new Error(res.error);
          throw error;
          
      }
      
   
  
  
  }catch(err){
    console.log(err);
    //navigate("/signin");
  
  } 
  
  }
  
  useEffect(() => {
    fetchuser();
  },[ ] );




  useEffect(()=>{
    fetchrank();
  },[])

const uploadimage = async()=>{

  const fd=new FormData();
  fd.append("file",file);
  fetch('/updatecoverphoto', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
              'Access-Control-Allow-Origin':`${API_Key}`,
              'Content-Type': 'application/json',
              'Access-Control-Allow-Credentials': 'true',
    },
    credentials:"include",
    body: fd,
  })
  .then((response) => { })

  
  //Then with the error genereted...
  .catch((error) => {
    console.error('Error:', error);


  });

}
  useEffect(()=>{
uploadimage();
  },[])
  return (
    <div>
    {modal && <Loading setOpenModal={setopenmodal}/>}

    <div className='Entity_background p-1'>
    <div className='Entitycommontopper'>
    <div className='container'>
    <div className='row  p-5 mb-5'>
  <div className=' col-lg-12 col'>
  <div className='row float-right mb-5'>
  <input
     name="revphoto"
     ref={filePickerRef}
     style={{ display: "none" }}
     type="file"
     onChange={handleImageUpload}
    
   />
   
   {/*<img src={Camera} alt="camera" style={{height:"4rem",cursor:"pointer"}}  onClick={handleFilePicker} />*/}
  </div>
  <div className='row justify-content-center mt-5 mb-5 pt-5 pb-5'>
{/*<BiImageAlt color='white' size={100} />*/}
<br></br><br></br>

  </div>
  </div>
  
   
  
  
   </div>
  
   </div>
   
  
   
   </div>
   <div className='container'>
   <div className='row justify-content-center '>
  <div className='col-lg-3 col-md-4 col-8' id="profilediv">
 
  <img src={user.photo || User} alt="userimage" className='editprofileimage' />

  {/*<div className='useruploadimage '>

                <input
                  name="revphoto"
                  ref={filePickerRef}
                  style={{ display: "none" }}
                  type="file"
                  onChange={handleImageUpload}
                />
 
<AiFillCamera className='camera' onClick={handleFilePicker}/></div>*/}
  <br></br>
  <br></br>
 <div className='row mt-5'>
 <div className='col-lg-6' >
 
 <div className='card' id="profilecards">
 <div></div>
 <div className='row '>
 <h2 id='profilecardshead' >Ranking</h2>

 </div>
 <div className='row-justify-content-center'>
 <h1 className='totalchallengehead'>{rank.userRank || "--"}</h1>
 </div>
 
 </div></div>
 <div className='col-lg-6 ' >
 <div className='card' id="profilecards">
 <div className='row '>
 <h2 id='profilecardshead' >Score </h2>
 
 </div>
 <div className='row-justify-content-center'>
 <div className='col-lg-10  col-md-10 col-12 justify-content-center'> {/*<img src={score} alt="score" style={{height:"4rem",marginLeft:".2rem",marginTop:"-1.8rem"}}/>*/}
 <div className='row-justify-content-center'>
 <p className='totalscore text-center'>{user.total_score || "0"}</p>
 </div>
 </div>


 </div>
 <div></div>
 
 
 
 </div></div>
 <div className='col-lg-6 ' >
 <div className='card' id="profilecards">
 <div className='row '>
 <h2 id='profilecardshead' >Challenges</h2>

 </div>
 <div className='row-justify-content-center'>
 <h1 className='totalchallengehead'>{user.total_challenges || "0"}</h1>
 </div>
 
 </div></div>
 <div className='col-lg-6 ' >
 <div className='card' id="profilecards">
 <div className='row '>
 <h2 id='profilecardshead' >Plan</h2>
 <div className='col-lg-6 col-3 justify-content-center'><img src={Plan}  alt="plan"/></div>

 </div>

 <div className='row-justify-content-center'>
 
 <h1  className='userplanhead'>{plan}</h1>
 </div>
 
 </div></div>
 </div>
 </div>
 <div className='col-lg-1 col-1 col-md-1'></div>
  <div className='col-lg-8 col-md-9 col-11 p-5 justify-content-center' id='userprofile2'>
  <div className='row'>
 <div className='col-lg-9 col-9'>
 <h1  className='Editprofilehead2 mb-5'>{user.fname} <span><hr className='commonhr'></hr></span></h1>
<div className='row'>
<div className='col-lg-12 col'>
<div className='row pl-5'>
<div className='col-lg-5 col'>
<p className='profileheadname'>Name</p>
</div>
<div className='col-lg-7 col'><p className='profileheadname2'>{user.fname} {user.lname}</p></div>
</div>
<div className='row mt-3 pl-5'>
<div className='col-lg-5 col'>
<p className='profileheadname'>Email</p>
</div>
<div className='col-lg-7 col'><p className='profileheadname2'>{user.email}</p></div>
</div>
<div className='row mt-3 pl-5'>
<div className='col-lg-5 col'>
<p className='profileheadname'>Contact</p>
</div>
<div className='col-lg-7 col'><p className='profileheadname2'>{user.phone || "-------"}</p></div>
</div>
<div className='row mt-3 pl-5'>
<div className='col-lg-5 col'>
<p className='profileheadname'>Address</p>
</div>
<div className='col-lg-7 col'><p className='profileheadname2'>{user.address || "-------"}</p></div>
</div>
<div className='row mt-3 pl-5'>
<div className='col-lg-5 col'>
<p className='profileheadname'>Zipcode</p>
</div>
<div className='col-lg-7 col'><p className='profileheadname2'>{user.zipcode || "-------"}</p></div>
</div>



</div>
</div>
 </div>
 <div className='col-lg-3 col-2   p-5'>
 <div className='row float-right'><div className='Editdiv'>
 <NavLink to="/editprofile"><img src={Edit} style={{height:"1.3rem",marginTop:"-1.7rem"}} alt="edit"/></NavLink> 
 </div></div>
 {/*<div className='row mt-5'>
 <img src={badge1}   style={{height:"10.3rem",marginTop:"5rem"}} alt="edit"/>
</div>*/}
 
 </div>
  </div>
  </div>
  </div>
   </div>
   </div>
    </div>
  )
}

export default (Profile)