import React,{useState,useEffect,useRef} from 'react'

import { useForm } from "react-hook-form";
import"./Profile.css";

import User from "../../../Assets/images/dp3.png";
import {AiFillCamera} from "react-icons/ai";
import {IoMdLock,IoIosArrowForward} from "react-icons/io";
import { BiUpload } from "react-icons/bi";
import { RiImageFill } from "react-icons/ri";
import{RiArrowDropRightLine} from "react-icons/ri";
import { NavLink,useNavigate, } from 'react-router-dom';
import axios from 'axios';
import Profileupdated from "../Modals/Profileupdated";
import Wentwrong from "../Modals/Wentwrong";
import Loading from '../Modals/Loading';
import Forbidden from '../Modals/Forbidden';

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { faL } from '@fortawesome/free-solid-svg-icons';


const Editprofile = (props) => {
  
  const {register,handleSubmit,formState: { errors },reset,watch,trigger,} = useForm();
  const schema = yup.object().shape({
    file: yup.mixed()
          .test('required', "You need to provide a file", (value) =>{
            return value && value.length
          } )
          .test("fileSize", "The file is too large", (value, context) => {
            return value && value[0] && value[0].size <= 200000;
          })
          .test("type", "We only support jpeg", function (value) {
            return value && value[0] && value[0].type === "image/jpeg";
          }),
      });
  const {
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [modalOpen3, setModalOpen3] = useState(false);
  const [modalOpen4, setModalOpen4] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [iserror, setIserror] = useState(false);
  const[showbtn,setshowbtn]=useState(false);
  const navigate=useNavigate();
  const filePickerRef = useRef();
  const [file, setFile] = useState("");
  const [previewUrl, setPreviewUrl] = useState();
  const[user,setuser]=useState([]);
const[fname,setfname]=useState("");
const[lname,setlname]=useState("");
const[email,setemail]=useState("");
const[phone,setphone]=useState("");
const[address,setaddress]=useState("");
const[city,setcity]=useState("");
const[country,setcountry]=useState("");
const[zipcode,setzipcode]=useState("");
const handleImageUpload = (event) => {
  event.preventDefault();
  
  const imageFile = event.target.files[0];
  if (event.target.files) {
   
    const pickedFile = event.target.files[0];
    setFile(pickedFile);



  const MIN_FILE_SIZE = 512 // 1MB
    const MAX_FILE_SIZE = 1024 // 5MB
  
  
    const fileSizeKiloBytes = file.size / 1024
  
   
    if(fileSizeKiloBytes > MAX_FILE_SIZE){
      setErrorMsg("File size is greater than maximum limit");
      setIsSuccess(false)
      return
    }
    if(fileSizeKiloBytes < MIN_FILE_SIZE){
      setErrorMsg("File size is less than minimum limit");
      setIsSuccess(false)
      return
    }
  
    setErrorMsg("")
    setIsSuccess(true)
    setIserror(true);
    
    
setshowbtn(true);
    return;
  }
  
 
  
  
};
useEffect(() => {
  if (!file) {
  
    return;
   
  }

  const fileReader = new FileReader();
  fileReader.onload = () => {
    setPreviewUrl(fileReader.result);
    
  };
  fileReader.readAsDataURL(file);
}, [file]);
const handleFilePicker = (event) => {
  filePickerRef.current.click();
 
};

const API_Key = process.env.REACT_APP_API_URL;

{/*const handlesubmit = async(event)=>{
  setModalOpen3(true);
  event.preventDefault();
  const data = { fname,lname,email,phone,address,zipcode,city,country,file:file};
  fetch(`${API_Key}/updateuserprofile`, {
    method: 'POST',
    headers:{
      Accept: "application/json",
      mode: 'no-cors',
      'Access-Control-Allow-Origin':`${API_Key}`,
      //'Content-type': 'application/x-www-form-urlencoded',
      "type": "formData",
      'Access-Control-Allow-Credentials': true,
    },
    credentials:"include",
    body: JSON.stringify(data),
  })
  .then((response) => { 
    if(response.status===200){
    
    setModalOpen(true);
  setModalOpen3(false);
    }
  })
  
  //Then with the error genereted...
  .catch((error) => {
    console.error('Error:', error);
    setModalOpen2(true);
  });
}*/}
const uploadprofile = async(event)=>{


  setModalOpen3(true);
  event.preventDefault();
  //setFile(data.target.file)
  
  const fd = new FormData();
  fd.append("file",file);
  
  fetch(`${API_Key}/updateProfilePhoto`, {
    method: 'POST',
    headers:{
      Accept: "image/png",
      mode: 'cors',
      'Access-Control-Allow-Origin':`${API_Key}`,
      //'Content-type': 'application/json',
      "type": "formData",
      'Access-Control-Allow-Credentials': true,
    },
    credentials:"include",
    body:fd,
  })
  .then((response) => { 
    if(response.status===200){
      window.location.reload(true);
    setModalOpen(true);
    setTimeout(() => {
      setModalOpen(false);
    },3000)
  setModalOpen3(false);
const noti={message:"Your Profile has been successfully Updated. ",category:"profile",querytype:"Profile Update"}

    fetch(`${API_Key}/notification`, {
      method: 'POST',
      headers:{
        Accept: "application/json",
        mode: 'cors',
        'Access-Control-Allow-Origin':`${API_Key}`,
      'Content-type': 'application/json',
        
        'Access-Control-Allow-Credentials': true,
      },
      credentials:"include",
      body: JSON.stringify(noti),
    })
    .then((response) => {
 if(response.status === 200){
   window.alert("saved noti")
 }

     })

      }else if (response.status===406){
setModalOpen4(true);
setModalOpen3(false);
      }else if(response.status===500){
        setModalOpen4(true);
setModalOpen3(false);
      }
  }).catch((error) => {
    console.error('Error:', error);
    setModalOpen2(true);
   
  
     
   });

}
const onSubmit = async(data)=>{
  setModalOpen3(true);
  reset({fname:"",lname:"",phone:"",address:"",city:"",zipcode:"",country:"",file:""})
 // event.preventDefault();
  //const data={fname,lname,email,country,phone,address,zipcode,city,country}
  /*const fd = new FormData();
  fd.append("fname",fname);
  fd.append("lname",lname);
  fd.append("email",email);
  fd.append("phone",phone);
  fd.append("address",address);
  fd.append("zipcode",zipcode);
  fd.append("city",city);
  fd.append("country",country);
  fd.append("file",file);*/
  fetch(`${API_Key}/updateuserprofile`, {
    method: 'POST',
    headers:{
      Accept: "application/json",
      mode: 'cors',
      'Access-Control-Allow-Origin':`${API_Key}`,
      'Content-type': 'application/json',
      //"type": "formData",
      'Access-Control-Allow-Credentials': true,
    },
    credentials:"include",
    body:JSON.stringify(data),
  })
  .then((response) => { 
    if(response.status===200){
    
    setModalOpen(true);
    window.location.reload(true);
        setTimeout(() => {
      setModalOpen(false);
    },3000)
  setModalOpen3(false);
const noti={message:"Your Profile has been successfully Updated. ",category:"profile",querytype:"Profile Update"}

    fetch(`${API_Key}/notification`, {
      method: 'POST',
      headers:{
        Accept: "application/json",
        mode: 'cors',
        'Access-Control-Allow-Origin':`${API_Key}`,
      'Content-type': 'application/json',
        
        'Access-Control-Allow-Credentials': true,
      },
      credentials:"include",
      body: JSON.stringify(noti),
    })
    .then((response) => {
 if(response.status === 200){
   //window.alert("saved noti")
 }

     })

      }
  }).catch((error) => {
    console.error('Error:', error);
    setModalOpen2(true);
    setModalOpen3(false);
    setTimeout(() => {
      setModalOpen2(false);
    },3000);
  
     
   });

}


  const fetchuserdetails = async () => {

setModalOpen3(true);
        
    try{
      const res = await fetch(`${API_Key}/profiledata`,{
        method:"GET",
        headers:{
          mode: 'cors',
          'Access-Control-Allow-Origin':`${API_Key}`,
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': true,
        },
        credentials:"include"
         
      });
      const data = await res.json();
   
      setuser(data);
    
  
      if(res.status !== 200 ){
          const error = new Error(res.error);
          throw error;
          
      }else{
        setModalOpen3(false);
      }
      
   
  
  
  }catch(err){
    console.log(err);
    navigate("/signin");
  
  } 
  
  }

  useEffect(() => {
    fetchuserdetails();
  },[] );
  return (
    <div>
    {modalOpen && (
      <Profileupdated
       
        setOpenModal={setModalOpen}
      />
    )}
    {modalOpen2 && (
      <Wentwrong
       
        setOpenModal={setModalOpen2}
      />
    )}
    {modalOpen3 && (
      <Loading
       
        setOpenModal={setModalOpen3}
      />
    )}
    {modalOpen4 && (
      <Forbidden
       
        setOpenModal={setModalOpen4}
      />
    )}
   
    <div className='profile_dashboard' >
     <div className='profile_dashboard2'>
  </div> {/*main profile container start*/}
     <div className='container-fluid'>
     <div className='row '>
     {/*image container of profile image */}
     <div className='col-lg-4 col-md-3 col-11 p-5 mt-5 justify-content-center'>
  {/*} <img src={user.photo || User} alt="userimage" className='editprofileimage2' />*/}
<form >
   <div className='row justify-content-center'>
   {!previewUrl ? (
    <div className="testdummydiv">
    <img src={user.photo || User} alt="preview" className='editprofileimage' />
    </div>
  ) : (
    <img src={previewUrl} alt="preview"  className='editprofileimage'/>
  )}
   </div>
   {errors2.file && (
    <small className="errormes ml-2 ">{errors2.file.message}</small>
  )} 
   <div className='useruploadimage '>

   <input
  

     name="file"
     ref={filePickerRef}
     style={{ display: "none" }}
     type="file"
     onChange={handleImageUpload}
     
     accept="image/png, image/jpeg,image/jpg"
   />
   

<AiFillCamera className='camera' onClick={handleFilePicker} style={{cursor:"pointer"}}/>

</div>
{showbtn ? <div className='row justify-content-center ml-5 mb-0'>
<button className='btn savebtn ' onClick={uploadprofile}>Upload Image</button></div> :null}


   
</form>
    <div className='changeprofilediv'>
    <NavLink to="/changepassword" style={{textDecoration:"none"}}> <div className='row'>
       <div className='col-lg-2 col-md-2 col-2 pl-lg-5'>
       <IoMdLock color='white' size={20}/>
       </div>
       <div className='col-lg-7 col-md-8 col-8'>
       <p className='editprofilepara'>Change Password</p>
       </div>
       <div className='col-lg-1 col-md-2 col-2 pl-lg-5 mt-2'>
   <div className='arrowbutton'> <RiArrowDropRightLine className='icon'/></div> 
       </div>
       </div></NavLink>
    </div>
    
     </div>
     
     {/* edit profile form */}
    
     
     <div className='col-lg-8 col-md-8 col-11 pl-lg-5  ' id='userprofile'>
       <h1  className='Editprofilehead'>Edit Profile <span><hr className='commonhr'></hr></span></h1>
       <form onSubmit={handleSubmit(onSubmit)} >
       <div className="form-input col-lg-12">
       <div className="row">
       <div className="col">
       <label>First Name</label>
       <input
         name="fname"
         type="text"
     
        autoComplete='off'
         placeholder={user.fname || "Enter Firstname"}
         onChange={(e)=>setfname(e.target.value)}
         className={`form-control mb-1 ${errors.fname && "invalid"}`}
         
         {...register("fname", { required: false,
         pattern: {
          value: /^[a-zA-Z ]*$/,
          message: "Please Enter Valid Name",
        },
           
         })}
           
           onKeyUp={() => {
             trigger("fname");
           }}/>
       {errors.fname && (
        <small className="errormes ml-2 ">{errors.fname.message}</small>
      )} 
       </div>
       <div className="col">
       <label>Last Name</label>
       <input
         name="lname"
         type="text"
         autoComplete='off'
         placeholder={user.lname || "Enter LastName"}
         onChange={(e)=>setlname(e.target.value)}
         className={`form-control mb-1 ${errors.lname && "invalid"}`}
         
         {...register("lname", { required:false,
          pattern: {
            value: /^[a-zA-Z ]*$/,
            message: "Please Enter Valid Name",
          },
         })}
           
           onKeyUp={() => {
             trigger("lname");
           }}
        
       />
       {errors.lname && (
        <small className="errormes ml-2 ">{errors.lname.message}</small>
      )} 
       </div>
       </div>
      
     </div>
     <div className="form-input col-lg-12">
       <div className="row">
       <div className="col">
       <label>Email</label>
       <input
         name="version"
         type="text"
         readonly='readonly'
         className="form-control"
         placeholder={user.email || "Enter Email"} 
         onChange={(e)=>setemail(e.target.value)}
         autoComplete='off'
       /></div>
       <div className="col">
       <label>Phone</label>
       <input
         name="phone"
         type="text"
         className={`form-control mb-1 ${errors.phone && "invalid"}`}
         autoComplete='off'
         placeholder={user.phone || "Enter Phone"}
         onChange={(e)=> setphone(e.target.value)}
         {...register("phone", { required:false,
         pattern: {
           value: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
           message: "Invalid phone no",
         },
        })}
        onKeyUp={() => {
         trigger("phone");
       }}
        
       />
       {errors.phone && (
        <small className="errormes ml-2">{errors.phone.message}</small>
      )}
       </div>
       </div>
      
     </div>
     <div className="form-input col-lg-12">
       <div className="row">
       <div className="col">
       <label>Address</label>
       <input
         name="address"
         type="text"
         onChange={(e)=>setaddress(e.target.value)}
         autoComplete='off'
         placeholder={user.address || "Enter Address"}
         className={`form-control mb-1 ${errors.address && "invalid"}`}
         
         {...register("address", { required:false,
        
          pattern:/^[a-zA-Z .,\n 0-9 #]*$/,
          message:"Review body is Required",
         
          
         })}
           
           onKeyUp={() => {
             trigger("address");
           }}
        
       />
       {errors.address && (
        <small className="errormes ml-2">{errors.address.message}</small>
      )}
       </div>
       <div className="col">
       <label>City/Town</label>
       <input
         name="city"
         type="text"
         autoComplete='off'
         placeholder={ user.city || "Enter Your City/Town"}
         onChange={(e)=>setcity(e.target.value)}
         className={`form-control mb-1 ${errors.city && "invalid"}`}
         
         {...register("city", { required:false,
          pattern: {
            value: /^[a-zA-Z ]*$/,
            message: "Please Enter Valid City",
          },
         })}
           
           onKeyUp={() => {
             trigger("city");
           }}
         
       />
       {errors.city && (
        <small className="errormes ml-2">{errors.city.message}</small>
      )}
       </div>
       </div>
      
     </div>
     <div className="form-input col-lg-12">
       <div className="row">
       <div className="col">
       <label>Country</label>
       <input
         name="country"
         type="text"
         autoComplete='off'
        
         placeholder={ user.country|| "Enter Country"}
         onChange={(e)=>setcountry(e.target.value)}
         className={`form-control mb-1 ${errors.country && "invalid"}`}
         
         {...register("country", { required:false,
          pattern: {
            value: /^[a-zA-Z ]*$/,
            message: "Please Enter Valid country",
          },
         })}
           
           onKeyUp={() => {
             trigger("country");
           }}
       />
       {errors.country && (
        <small className="errormes ml-2">{errors.country.message}</small>
      )}
       </div>
       <div className="col">
       <label>Zipcode</label>
       <input
         name="zipcode"
         type="text"
         autoComplete='off'
         placeholder={ user.zipcode || "Enter Zipcode"}
         onChange={(e)=>setzipcode(e.target.value)}
         className={`form-control mb-1 ${errors.zipcode && "invalid"}`}
         
         {...register("zipcode", { required:false,
          pattern: {
            value: /^[0-9]{6}(?:-[0-9]{4})?$/,
            message: "Please Enter Valid zipcode",
          },
         })}
           
           onKeyUp={() => {
             trigger("zipcode");
           }}
       />
       {errors.zipcode && (
        <small className="errormes ml-2">{errors.zipcode.message}</small>
      )}
       </div>
       </div>
      
     </div>
     <div className='row justify-content-center'>
     <button className='btn savebtn'>SAVE</button></div>
   
       </form>
       <br></br>
     </div>
     
     </div>
   </div>
    </div>
    
    </div>
  )
}

export default Editprofile