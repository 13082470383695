import { createStore, combineReducers, applyMiddleware } from "redux";
import logger from "redux-logger";


import { USER } from "./user";

export const configureStore = () => {
  const store = createStore(
    combineReducers({
      user: USER
    }),
    applyMiddleware( logger)
  );
  return store;
};
