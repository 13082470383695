import React, { useState, useEffect, useRef } from "react";
import "./Authpage.css";
import bcrypt from "bcryptjs";
import Signinpic from "../../Assets/login/Group 5474.svg";
import { FaEye, FaEyeSlash, FaFacebookF } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import Footer from "../Footer/Footer";
import Wentwrong from "../Dashboard/Modals/Wentwrong";
import Emailnotexist from "../Dashboard/Modals/Emailnotexist";
import Inavalidcred from "../Dashboard/Modals/Inavalidcred";
import Loading from "../Dashboard/Modals/Loading";
import Toomanyattempts from "../Dashboard/Modals/Toomanyattempts";
import Twofactorlogin from "../Dashboard/Modals/Twofactorlogin";
import Otpalreadysent from "../Dashboard/Modals/Otpalreadysent";
import { encrypt, decrypt, compare } from "n-krypta";
//const eye = <FontAwesomeIcon icon={faEye} />;
const salt = bcrypt.genSaltSync(12);
const Login = (props) => {
  const [subscriptionid, setsubscriptionid] = useState();
  /*const[previouslink,setprreviouslink]=useState(props.location.state.link);
console.log(previouslink);*/

  {
    /*} useEffect(() => {
    function start() {
        gapi.client.init({
            clientId: "1044708826625-5n7pefshemj824pfqr83cdpcj698ia7n.apps.googleusercontent.com",
            scope: 'email',
        });
    }

    gapi.load('client:auth2', start);
}, []);*/
  }

  const navigate = useNavigate();
  const location = useLocation();

  const faeye = <FaEye />;
  const faeye2 = <FaEyeSlash />;
  const [passwordShown, setPasswordShown] = useState(false);
  const [actiemail, setactiemail] = useState("");
  const [eye, seteye] = useState(faeye2);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
    seteye(faeye);
  };
  const [credential, setcredential] = useState({ email: "", password: "" });
  const passwordInputRef = useRef();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    trigger,
  } = useForm();
  const initialValues = { email: "", password: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [modalOpen3, setModalOpen3] = useState(false);
  const [modalOpen4, setModalOpen4] = useState(true);
  const [modalOpen5, setModalOpen5] = useState(false);
  const [modalOpen6, setModalOpen6] = useState(false);
  const [modalOpen7, setModalOpen7] = useState(false);
  // const captchaRef = useRef(ReCAPTCHA)
  //hash password

  const [loginame, setloginname] = useState("LOGIN");
  const [username, setusername] = useState("");
  const handleinput = (event) => {
    event.preventDefault();
    setcredential((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const API_Key = process.env.REACT_APP_API_URL;

  const fetchuser = async () => {
    try {
      const res = await fetch(`${API_Key}/userdata`, {
        method: "GET",
        headers: {
          mode: "cors",
          "Access-Control-Allow-Origin": `${API_Key}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
        credentials: "include",
        //withCredentials: true,
      });
      const data = await res.json();

      setusername(data);
      setloginname("Go To Dashboard");
      navigate("/userhome");

      if (res.status !== 200) {
        const error = new Error(res.error);
        throw error;
      }
    } catch (err) {
      setModalOpen4(false);
      navigate("/signin");
    }
  };
  useEffect(() => {
    fetchuser();
  }, []);

  const onSubmit = (data) => {
    setModalOpen4(true);
    const hashedPassword = bcrypt.hashSync(data.password, 12);

    const last = bcrypt.compare(hashedPassword, data.password);

    const secretKey = "ResecSystemsPVTLTD";
    const encrpt = encrypt(data.password, secretKey);

    fetch(`${API_Key}/logintoken`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        mode: "cors",
        "Access-Control-Allow-Origin": `${API_Key}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      credentials: "include",
      body: JSON.stringify({ email: data.email, password: encrpt }),
    })
      .then((response) => {
        if (response.status === 200) {
          setModalOpen4(false);
          setModalOpen6(
            true,
            setactiemail(data.email),
            setsubscriptionid(subscriptionid)
          );
        } else if (response.status === 400) {
          setModalOpen2(true);
          setModalOpen4(false);
        } else if (response.status === 429) {
          setModalOpen5(true);
          setModalOpen4(false);
        } else if (response.status === 403) {
          setModalOpen7(true);
          setModalOpen4(false);
        }
      })

      .catch((error) => {
        console.error("Error:", error);
        setModalOpen3(true);
      });
  };
  if(modalOpen4){
    return <Loading setOpenModal={setModalOpen4}/>
  }
else{
  return (
    <div>
      {modalOpen && <Emailnotexist setOpenModal={setModalOpen} />}
      {modalOpen2 && <Inavalidcred setOpenModal={setModalOpen2} />}
      {modalOpen3 && <Wentwrong setOpenModal={setModalOpen3} />}
      {modalOpen5 && <Toomanyattempts setOpenModal={setModalOpen5} />}
      {modalOpen6 && (
        <Twofactorlogin
          email={actiemail}
          subid={subscriptionid}
          setOpenModal={setModalOpen6}
        />
      )}
      {modalOpen7 && <Otpalreadysent setOpenModal={setModalOpen7} />}
      <section className="authbackground">
        <div id="authpagelinediv">
          <div className="container-fluid">
            <span className="Authpagespan">Login</span>

            <div className="row">
              <div className="col-lg-5 col-md-6 col-12 offset-md-2 mt-5   ">
                <img src={Signinpic} alt="signup" className="img-fluid" />
              </div>
              <div className="col-lg-5 col-md-5 col-12 mt-2  mb-5 ">
                <form onSubmit={handleSubmit(onSubmit)} autocomplete="off">
                  <div className="formdiv">
                    <h2 className="text-center authhead">Get Set Go!</h2>
                    <div className="row justify-content-center">
                      <hr className="signuphr"></hr>
                    </div>

                    <div class="input-group">
                      <label>Email</label>
                    </div>
                    <div class="input-group_field">
                      <input
                        type="text"
                        name="email"
                        autocomplete="off"
                        onChange={handleinput}
                        placeholder="Enter Username or Email"
                        className={`mb-2 ${errors.email && "invalid"}`}
                        {...register("email", {
                          required: "Email is Required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid email address",
                          },
                        })}
                        onKeyUp={() => {
                          trigger("email");
                        }}
                      />
                      {errors.email && (
                        <small className="errormes ml-2">
                          {errors.email.message}
                        </small>
                      )}
                    </div>

                    <div class="input-group">
                      <label>Password</label>
                    </div>
                    <div class="input-group_field">
                      <input
                        type={passwordShown ? "text" : "password"}
                        name="password"
                        autocomplete="new-password"
                        onChange={handleinput}
                        placeholder="Enter Password"
                        className={`mb-2 ${errors.password && "invalid"}`}
                        {...register("password", {
                          required: "Password is Required",
                          pattern: {
                            value:
                              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                            message: "Password is Required",
                          },
                        })}
                        onKeyUp={() => {
                          trigger("password");
                        }}
                      />
                      {errors.password && (
                        <small className="errormes ml-2">
                          {errors.password.message}
                        </small>
                      )}
                      <span>
                        {" "}
                        <i className="eye" onClick={togglePasswordVisiblity}>
                          {eye}
                        </i>{" "}
                      </span>
                    </div>

                    <div className="row justify-content-center"></div>

                    <div className="row justify-content-center ">
                      <button className="btn authbutn">{loginame}</button>
                    </div>
                    <br></br>

                    <div className="row justify-content-center mt-1"></div>
                    <div className="row justify-content-center  mt-5">
                      <div className="col">
                        <NavLink
                          to="/signup"
                          style={{ textDecoration: "none" }}
                        >
                          {" "}
                          <p className="spanhrtext2">Not a Member? Signup</p>
                        </NavLink>
                      </div>

                      <div className="col offset-1">
                        <NavLink
                          to="/forgotpass"
                          style={{ textDecoration: "none" }}
                        >
                          <p className="spanhrtext2">Forgot Password?</p>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </section>
    </div>
  );
}

 
};

export default Login;
