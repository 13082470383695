import React, { useState } from 'react';
import ReactDOM from "react-dom";
import { IoMdClose } from "react-icons/io";
import {Formik,Field,ErrorMessage,Form} from 'formik';
import * as Yup from "yup";
import axios from 'axios';
import 'yup-phone'
const initialValues={
    name:'',
    email:'',
    mobilenumber:'',
    query:''
  }
  const validationSchema = Yup.object({
    name: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    mobilenumber:Yup.string().phone('IN',true,"Enter valid Mobile Number").required("Required"),
    query:Yup.string().required("Required"),
   
  }); 

const SummerTrainingModal = ({onClick}) => {
  const [successData,setSuccessData]=useState(false)
  const handleSubmit=async(values)=>{
    const data={
        fullname:values.name,
        email:values.email,
        phoneNumber:values.mobilenumber,
        query:values.query
    }
const response=await axios.post(`${process.env.REACT_APP_API_URL}/query_about_course_training`,data);
if(response.data.success){
  setSuccessData(true)
}
  }
   
  return ReactDOM.createPortal(
    <div className='summer-training-modal'>
      <div className='summer-training-cont'>
        <IoMdClose className='summer-training-icon' onClick={()=>onClick()}/>
        <p className='summer-training-modal-heading'>Speak to a counsellor</p>
        {successData ? <p className='message-text'>Message Sent Successfully</p> :null}
        <Formik initialValues={initialValues}  onSubmit={handleSubmit}  validationSchema={validationSchema}>
          {(formik)=>{
            console.log(formik.errors) 
            return(
            <Form  className='summer-modal-form'>
            {/*  {register ? <p className="webinar-success-component">Registration Successfull!!</p> : null} */}
            <div>
                <label className='summer-modal-label-field'>Name</label>
            <Field className="summer-modal-input-field"  type="text" id="name" name="name" placeHolder="Name"/>
            <ErrorMessage component="div" className="summer-modal-error" name="name" ></ErrorMessage>
            </div>
            <div>
                <label className='summer-modal-label-field'>Email</label>
                <Field  className="summer-modal-input-field"  type="text" id="email" name="email" placeHolder="Email"/>
              <ErrorMessage component="div" className="summer-modal-error" name="email" ></ErrorMessage>
            </div>
            <div>
                <label className='summer-modal-label-field'>Mobile Number</label>
                <Field  className="summer-modal-input-field"  type="text" id="mobilenumber" name="mobilenumber" placeHolder="Mobile Number"/>
              <ErrorMessage component="div" className="summer-modal-error" name="mobilenumber" ></ErrorMessage>
            </div>
            <div>
                <label className='summer-modal-label-field'>Query</label>
                <Field as="textarea" className="summer-modal-input-field"  id="query" name="query" placeHolder="Query"/>
                <ErrorMessage component="div" className="summer-modal-error" name="query" ></ErrorMessage>
            </div>
            <button type="submit" className="web-register-btn3" disabled={!formik.isValid}>Schedule a call</button>
              
            </Form>
          )}}
        </Formik>
      </div>
    </div>, document.getElementById("modal-root")
  )
}

export default SummerTrainingModal
