import React, { useState } from 'react'
import { RatingStar } from "rating-star";
import{AiTwotoneStar} from "react-icons/ai";
import ReactStars from "react-rating-stars-component";
import {ImCross} from "react-icons/im";
import Reviewsaved from "../Modals/Reviewsaved";
import Wentwrong from './Wentwrong';
import Loading from './Loading';
import { useForm } from "react-hook-form";
const AddReview = ({setOpenModal,id,category}) => {
const[modalOpen,setModalOpen]=useState(false);
const[modalOpen2,setModalOpen2]=useState(false);
const[modalOpen3,setModalOpen3]=useState(false);
const[review,setreview]=useState("");
const[rating,setrating]=useState("3.0");
const[exa,setexa]=useState("Good");
const {register,handleSubmit,formState: { errors },reset,watch,trigger,} = useForm();

const API_Key = process.env.REACT_APP_API_URL;

 
const ratingChanged = (newRating) => {
setrating(newRating);

if(newRating <2){
  setexa("Average")
}else if(newRating <4){
  setexa("Good")
}else if(newRating <=4.5){
  setexa("Excellent")
}
}

const onSubmit = (data)=>{
  setModalOpen3(true);


  const res =  fetch(`${API_Key}/postreview`, {
    method: "POST",
    body: JSON.stringify({
    
      review:data.review,
     rating:rating,
    cateid:id,
 category:category
      
    }),
    headers: {
     
      mode: 'cors',
      'Access-Control-Allow-Origin':`${API_Key}`,
      'Content-Type': 'application/json',
      'Access-Control-Allow-Credentials': true,
    },
    credentials:"include",
   
  }).then((res) => {
 setModalOpen3(false)
    if(res.status===200){
  setModalOpen(true);
  setTimeout(() => {
    setOpenModal(false);
  },3000);
  
    }
    const noti={message:"Your Review has been successfully Saved. ",category:"Entity",querytype:"Review Saved"}

    fetch(`${API_Key}/notification`, {
      method: 'POST',
      headers:{
        Accept: "application/json",
        mode: 'cors',
        'Access-Control-Allow-Origin':`${API_Key}`,
      'Content-type': 'application/json',
        
        'Access-Control-Allow-Credentials': true,
      },
      credentials:"include",
      body: JSON.stringify(noti),
    })
    .then((response) => {
 if(response.status === 200){
   //window.alert("saved noti")
 }

     })
     .catch((error)=>{
      console.error('Error:', error);
     })

      
  


  }).catch((error) => {
    console.error('Error:', error);
    setModalOpen2(true);

  });
  
 
}

  return (
    <div>

    {modalOpen && (
      <Reviewsaved
       
        setOpenModal={setModalOpen}
      />
    )}
    {modalOpen2 && (
      <Wentwrong
       
        setOpenModal={setModalOpen2}
      />
    )}
    {modalOpen3 && (
      <Loading
       
        setOpenModal={setModalOpen3}
      />
    )}
    <section className="modalBackground justify-content-center"  
      >
    <div className="container  justify-content-center">
    
      <div className="row justify-content-center ">
      
      
        <div className="col-lg-8 col-md-10 col-10  modalContainer" >
       
        <div className='mt-5 crossbtnarrow mr-3'>
        <button
          onClick={() => {
            setOpenModal(false);
          }}
        >
        <div className='text-center'><ImCross style={{height:".6rem",marginLeft:"-1.9rem",marginTop:".5rem"}}/></div> 
        </button>
      </div>
<h2 className='text-center alertmainhead2'>ADD A REVIEW <div className='row justify-content-center'><span><hr className='text-center alertcommonhr'></hr></span></div></h2>  


<div className='row justify-content-center'>

<div className='col-lg-10 col-md-6  col-9 '>
<form  className='col-lg-12' onSubmit={handleSubmit(onSubmit)} >
<div className='row justify-content-center'>



<ReactStars
    count={5}
    onChange={ratingChanged}
    size={40}
    isHalf={true}
    emptyIcon={<i className="far fa-star"></i>}
    halfIcon={<i className="fa fa-star-half-alt"></i>}
    fullIcon={<i className="fa fa-star"></i>}
    activeColor="#ffa534"
    value={3.5}
    
    color="lightgray"
    name="rating"
   
  
  />,
</div>
<div className='row justify-content-center'>
<h1 className='ratinghead'>{rating|| "Select rating"}</h1>
</div>
<div className='row justify-content-center'>
<h1 className='ratinghead1'>{exa}</h1>
</div>




<div className="form-input ">
    
<div className="col">

<textarea
  name="review"
  type="text"
  //value={review}
  //onChange={(e)=>setreview(e.target.value)}
  className={`form-control  ${errors.review && "invalid"}`}
  placeholder="Enter Your Review"
  rows="4" cols="50"
  {...register("review", { required: "Review body is Required",
       pattern:/^[a-zA-Z .,\n 0-9]*$/,
         message:"Review body is Required",
        
         
       })}
       onKeyUp={() => {
        trigger("review");
      }}
 />
 {errors.review && (
  <small className="errormes ml-2 ">{errors.review.message}</small>
)}
 </div>
 
<div className='row justify-content-center'>
<button className=' btn saveratebtn' > SUBMIT REVIEW</button>
</div>

</div>
</form>
</div>

</div>

        </div></div></div></section></div>
  )
}

export default AddReview