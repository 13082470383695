import React, { useState, useEffect } from 'react'

import Courseimg from "../../../Assets/Entity/Course/Courses Tri.svg";
import { BsFillGridFill, BsListUl } from "react-icons/bs";

import { RiArrowDropRightLine } from "react-icons/ri";
import vip from "../../../Assets/prodile/VIP.svg"
import free from "../../../Assets/prodile/Asset 4.svg"
import { NavLink, useNavigate } from 'react-router-dom';

import Loading from '../Modals/Loading';
import { FaRegPlusSquare } from "react-icons/fa";
import Paymentidverify from '../Modals/Paymentidverify';
const DashCoursesAcw = (props) => {
    const [view, setview] = useState("Grid");

    const [modal, setopenmodal] = useState(false);
    const [modal2, setopenmodal2] = useState(false);
    const [clicked, setClicked] = useState(null);
    const [style, setstyle] = useState("courselistview");
    const [color, setcolor] = useState("white");
    const Gridview = () => {
        setview("Grid");
        setcolor("white");
    }
    const Listview = () => {
        setview("List");
        setcolor("white");
    }
    const toggle = index => {
        if (clicked === index) {
            //if clicked question is already active, then close it

            return setClicked(clicked);


        }

        setClicked(index);

    };

    const [coursespaid, setCoursespaid] = useState([]);
    const [planexpired, setplanexpired] = useState([]);

    const API_Key = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();


    const fetchCoursesexpiry = async () => {
        setopenmodal(true);


        try {
            const res = await fetch(`${API_Key}/expire_puchase_plan`, {
                method: "GET",
                headers: {
                    mode: 'cors',
                    'Access-Control-Allow-Origin': `${API_Key}`,
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': true,
                },
                credentials: "include"


            });
            const data = await res.json();

            setplanexpired(data)


            if (res.status !== 200) {
                const error = new Error(res.error);
                throw error;

            } else {
                setopenmodal(false);
            }




        } catch (err) {
            console.log(err);
            navigate("/signin");

        }
    }


    const fetchpaidCourses = async () => {
        setopenmodal(true);


        try {
            const res = await fetch(`${API_Key}/acwcourse`, {
                method: "GET",
                headers: {
                    mode: 'cors',
                    'Access-Control-Allow-Origin': `${API_Key}`,
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': true,
                },
                credentials: "include"


            });
            const data = await res.json();
            setCoursespaid(data.allcourseData);
            if (res.status !== 200) {
                const error = new Error(res.error);
                throw error;

            } else {
                setopenmodal(false);
            }




        } catch (err) {
            console.log(err);
            navigate("/signin");

        }

    }



    const addcourse = () => {
        setopenmodal2(true);
    }

    useEffect(() => {
        fetchpaidCourses();
    }, []);
    useEffect(() => {
        fetchCoursesexpiry();
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            {modal && <Loading setOpenModal={setopenmodal} />}
            {modal2 && <Paymentidverify setOpenModal={setopenmodal2} />}

            <div className='background_dashboard p-1'>
                <div className='Entitycommontopper'>
                    <div className='container'>
                        <div className='row justify-content-center p-0'>
                            <div className='col-lg-8 col-md-8 col-12 '>
                                <h1 className='Entitycommonhead'>Courses<span><hr className='Entitycommonheadhr'></hr></span></h1>
                                <p className='Entitycommonheadpara'>We believe in practical knowledge, performing challenges, identifying issues, and above all self-learning. Instead of focusing only on theoretical knowledge in our web penetration testing course, we have come up with real-world challenges to make you a certified professional.     </p>

                            </div>
                            <div className='col-lg-3 col-md-4 col-12  mt-3 offset-1'>
                                <img src={Courseimg} alt="Courses" style={{ backgroundSize: "cover" }} ></img>
                            </div>

                        </div>

                    </div>
                </div>



                <div className='container  p-5 '>
                    <div className='row float-right'>
                        <div className='col-lg-11 ' id='viewdiv'>



                            <div className='row justify-content-center '>
                                <div className='col-6 justify-content-center'><button className='btn ' onClick={Gridview}><BsFillGridFill color={view === "Grid" ? color : "grey"} size={15} /></button> </div>

                                <div className='col-6 justify-content-center '> <button className='btn ' onClick={Listview}><BsListUl color={view === "List" ? color : "grey"} size={15} /></button> </div>

                            </div></div>


                    </div>
                </div>

                <div className='container mt-5 p-5'>
                    {view === "List" && <div>
                        <div className='container mt-2 p-5'>



                            {coursespaid.length ? coursespaid.map((course, index) => (



                                <div className='row justify-content-center' >
                                    <div className='col-lg-12 col-md-10 col-12 p-4 ' id={style} onClick={() => toggle(index)} key={index} >
                                        <NavLink to={"/coursedesc/" + course._id} style={{ textDecoration: "none" }}>  <div className='row'>
                                            <div className='col-lg-1 pt-2'>

                                                <img src={course.cover_image} alt="logo" style={{ width: "7rem" }} />

                                            </div>
                                            <div className='col-lg-2 pt-4'><h1 className='entitylabhead2'>{course.coursename}</h1></div>
                                            <div className='col-lg-6 pt-4'><p className='lablistpara'>{course.desc}</p>
                                            </div>
                                            <div className='col-lg-2 col-8 pt-2'>
                                                <div className='row justify-content-center'>
                                                    <img src={vip} style={{ "height": "2.5rem", marginTop: '1.5rem' }} /></div>

                                            </div>
                                            <div className='col-lg-1 col pt-4'>
                                                <div className='Labbtn'>
                                                    <RiArrowDropRightLine className='span' color='white' size={15} style={{ marginTop: "-2.4rem" }} />
                                                </div>
                                            </div>


                                        </div>
                                        </NavLink>
                                    </div>


                                    {clicked === index ? (
                                        <>
                                            <section className='hoverdiv3'>
                                                <div className='hoverdiv'>

                                                    <div className='hoverdiv2'>
                                                        {course.desc}</div></div>
                                            </section>




                                        </>) : null}


                                </div>)) : null}


                        </div>

                    </div>}
                    <div className='container mt-5 '>
                        {view === "Grid" && <div>
                            <div className='container mt-5 mb-5'>

                                <div className='row'>
                                    {coursespaid.map((course, id) => (
                                        <div className='col-lg-4 col-md-6 col-12' key={id}>
                                            <NavLink to={"/coursedesc/" + course._id} style={{ textDecoration: "none" }}>
                                                <div id='coursegridviewsw' className='course-cards'>
                                                    <div className='course-image'>
                                                        <img src={course.cover_image} alt="course" />
                                                    </div>
                                                    <div className='course-content'>
                                                        <h1 className='entitycoursegridheades'>
                                                            {course.coursename}

                                                        </h1>
                                                        <p className='entitycoursegridparagrp'>{course.desc}</p>
                                                        <div className='Labbtns'>
                                                            <RiArrowDropRightLine className='span' color='white' size={15} style={{ marginTop: "-2.4rem" }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    ))}
                                </div>




                            </div>
                        </div>}
                    </div>


                </div>








            </div>
        </div>
    )
}




export default DashCoursesAcw