import React from "react";

const BinaryDifferenceSection = () => {
  const rows = [
    { row1: ["", "Hackersprey", "Others"] },
    {
      row1: [
        "Curriculum",
        " Updated and Relevant as per industry requirements.",
        "Not Updated",
      ],
    },
    {
      row1: [
        "Comprehensive Instructional Videos",
        "Detailed instructional videos with practical demos for deeper learning.",
        "Not Detailed",
      ],
    },
    {
      row1: [
        "Real-Word Hands-On Challenges",
        "Engage in comprehensive practical challenges included in each section for hands-on practice.",
        "Limited or No Practice Challenges",
      ],
    },
    {
      row1: [
        "Advanced Content",
        "Explore a wealth of advanced content meticulously curated to propel your expertise to new heights.",
        "Basic",
      ],
    },
    {
      row1: [
        "Exclusive Access",
        "Dedicated Exploitation Machines for Hands-On Practice.",
        "Shared Environment",
      ],
    },
    {
      row1: [
        "Affordable Excellence",
        "Genuine Pricing for the Program.",
        "Overpriced",
      ],
    },
  ];
  return (
    <div className="binary-container-section">
      <div className="binary-heading-div">
        <p className="binary-heading">
          Why <span className="color-red">Hackersprey </span> is Your Gateway to
          Success in <span className="color-red">Stack Exploitation</span>
        </p>
      </div>
      <div className="binary-wrap">
       
          <div className="binary-table-component">
            {rows.map((row, rowindex) => (
              <div
                className={
                  rowindex % 2 === 0 ? "binary-row-desc" : "binary-row-desc2"
                }
              >
                <>
                  {row.row1.map((col, colindex) => {
                    if (rowindex === 0) {
                      if (colindex === 0) {
                        return (
                          <div className="binary-col-desc">
                            <p></p>
                          </div>
                        );
                      }
                      if (colindex === 1) {
                        return (
                          <div className="binary-col-desc-border5 border-top-class">
                            <p>{col}</p>
                          </div>
                        );
                      } else {
                        return (
                          <div className="binary-col-desc">
                            <p>{col}</p>
                          </div>
                        );
                      }
                    } else if (rowindex === 6) {
                      if (colindex === 1) {
                        return (
                          <div className="binary-col-desc-border border-bottom-class">
                            <p>{col}</p>
                          </div>
                        );
                      } else {
                        return (
                          <div className="binary-col-desc">
                            <p>{col}</p>
                          </div>
                        );
                      }
                    } else {
                      if (colindex === 1) {
                        return (
                          <div className="binary-col-desc-border">
                            <p>{col}</p>
                          </div>
                        );
                      } else {
                        return (
                          <div className="binary-col-desc">
                            <p>{col}</p>
                          </div>
                        );
                      }
                    }
                  })}
                </>
              </div>
            ))}
          </div>
       
      </div>
    </div>
  );
};

export default BinaryDifferenceSection;
