import React from "react";
import ReactDOM from "react-dom";
import "./BinaryExploit.css";
import SectionImage from "../../Assets/binary/newtest2.png";
import { GiHoneycomb } from "react-icons/gi";
import { FaPlay } from "react-icons/fa";
import { FaArrowRightLong } from "react-icons/fa6";
import { IoCloseSharp } from "react-icons/io5";
const points = [
  "Introduction to Assembly Language",
  "Assembly Instruction Format",
  "Basic Assembly Instructions",
  "Basic Assembly Instructions",
];

const BinaryCourseModal = ({closeModal}) => {
  return ReactDOM.createPortal(
    <div className="binary-modal-container">
      <div className="binary-modal-wrapper">
          <div className="modal-left ">
            <img src={SectionImage} className="modal-img" />
          </div>
          <div className="modal-right">
          <IoCloseSharp className="close-button" onClick={()=>closeModal()}/>
            <p className="modal-heading">Assembly Language</p>
           
            <p className="modal-description">
              Understand the basics of assembly language and how it relates to
              binary exploitation.
            </p>
            <div className="modal-right-list">
              {points.map((x) => (
                <p className="list-icon">
                  <FaArrowRightLong className="play-icon" />
                  {x}
                </p>
              ))}
            </div>
          </div>
       
      </div>
    </div>,
    document.getElementById("modal-root")
  );
};

export default BinaryCourseModal;
