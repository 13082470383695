import React, { useEffect, useState,useRef } from 'react'

import "./Entity.css";

import{AiOutlineTrophy,AiOutlinePieChart,AiOutlineFieldTime,AiOutlineFlag, AiOutlineTeam} from "react-icons/ai"
import{RiPriceTag3Line} from "react-icons/ri"

import Videoimg from "../../../Assets/Entity/Webfare/innerphoto3.png"
import{SlLocationPin} from "react-icons/sl"
import{TbChartInfographic} from "react-icons/tb"
import{CgCommunity} from "react-icons/cg"
import havoclogo from "../../../Assets/Entity/Webfare/innerphoto3.png"


import User2 from "../../../Assets/images/dp3.png"


import Edit from "../../../Assets/Entity/Lab/Edit.svg"
import { NavLink, useNavigate } from 'react-router-dom';
import Gotolink from "../../../Assets/Entity/Lab/Go To Link (1).svg";



const Havocleaderboard = (props) => {
    const [bugbounty,setbugbounty]=useState([]);
    const [teamglobalrank,setteamglobalrank]=useState([]);
    const navigate=useNavigate();
    const API_Key = process.env.REACT_APP_API_URL;
    const[item,setitem]=useState("C")
    const[item2,setitem2]=useState("A")
    const[item3,setitem3]=useState("")
    const[modalOpen,setModalOpen]=useState(false);
    const[adminlogin,setadminlogin]=useState("");
    const[invitebyteam,setinvitebyteam]=useState([]);
    const fetchbugbounty = async () => {
   
   
  
        try{
          const res = await fetch(`${API_Key}/getbugbounty`,{
            method:"GET",
            headers:{
              mode: 'cors',
              'Access-Control-Allow-Origin':`${API_Key}`,
              'Content-Type': 'application/json',
              'Access-Control-Allow-Credentials': true,
            },
            credentials:"include"
          
           
      
             
          });
          const data = await res.json();
          setbugbounty(data);
      
        
      
          if(res.status !== 200 ){
              const error = new Error(res.error);
              throw error;
              
          }
          
       
      
      
      }catch(err){
        console.log(err);
        //navigate("/signin");
      
      } 
      
      }
      useEffect(() => {
        fetchbugbounty();
      },[ ] );
      const fetchglobalrank = async () => {
   
   
  
            try{
              const res = await fetch(`${API_Key}/universityteamranks`,{
                method:"GET",
                headers:{
                  mode: 'cors',
                  'Access-Control-Allow-Origin':`${API_Key}`,
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Credentials': true,
                },
                credentials:"include"
              
               
          
                 
              });
              const data = await res.json();
              setteamglobalrank(data);
          
          
            
          
              if(res.status !== 200 ){
                  const error = new Error(res.error);
                  throw error;
                  
              }
              
           
          
          
          }catch(err){
            console.log(err);

            //history.push("/signin");

            //navigate("/signin");

          
          } 
          
          }
          useEffect(() => {
            fetchglobalrank();
          },[ ] );
  return (
    <div>
    

    <div className='Entity_background2 p-1'>
    <div className='Entitycommontopper3'>
 
    <div className='container'>
    <div className='row justify-content-center'>
   <div className='col-lg-8 col-md-8 col-12 p-5'>
   <h1 className='Entitycommonhead'>{bugbounty.title}<span><hr className='Entitycommonheadhr'></hr></span></h1>
   <p className='Entitycommonheadpara'>Welcome to Hack Master Series, a web pentesting CTF. Unleash your cybersecurity skills, collaborate with peers, and conquer challenges in this exciting event. Our objective is to foster ethical hacking, teamwork, and knowledge-sharing, while offering a realistic and challenging CTF experience. See your live rankings in the CTF leaderboard and showcase your talent in the world of cybersecurity!   </p>
   
   </div>
   <div className='col-lg-1'></div>
   <div className='col-lg-3 col-md-4 col-12 p-2 mt-4 mb-4'>
   <img src={Videoimg} alt="labs"  ></img>
   
   </div>
    
    </div>
   
    </div>
    
   </div>
 
  <div className='container mt-5 p-1'>
 
   <div className='row p-5'>
   
  {/*</div> <div className='col-lg-3 col-md-3 col-10'>
   <div className='row justify-content-center mt-2 mb-5 p-4 havocbtn'   ><span className='mr-3'><AiOutlineSearch size={30}/></span><h1 className='enterpreyhead'> OverView</h1></div>

   </div>
   <div className='row'>
   
   <div className='col-lg-3 col-md-3 col-10'>
   <div className='row justify-content-center mt-2 mb-5 p-4 havocbtn'   ><span className='mr-3'><AiOutlinePlusCircle size={30}/></span><h1 className='enterpreyhead'> Create Your Team</h1></div>
   </div>
   </div>
   <div className='row'>
   
   <div className='col-lg-3 col-md-3 col-10'>
   <div className='row justify-content-center mt-2 mb-5 p-4 havocbtn2'   ><span className='mr-3'><SlGraph size={30}/></span><h1 className='enterpreyhead'> Ranking</h1></div>
  </div>*/}
  <div className='col-lg-12 col-md-9 col-12 '>
 <div className='row p-2'>


  <div className=' col-lg-2 col-md-12 col-12 m-0' style={{background:"none"}} onClick={() => setitem("A")}>
  <NavLink to="/hackmasterseries" style={{textDecoration:"none"}}><div className={item ==="A" ? "row justify-content-center mt-2 mb-5 p-4 topbtn2":"row justify-content-center mt-2 mb-5 p-4 topbtn"}    > <h1 className='enterpreyhead'> OverView</h1></div></NavLink>
  </div>





  
  <div className=' col-lg-2 col-md-12 col-12 m-0' style={{background:"none"}} onClick={() => setitem("B")}>
  <NavLink to="/hackmasterseries/team" style={{textDecoration:"none"}}> <div className={item ==="B" ? "row justify-content-center mt-2 mb-5 p-4 topbtn2":"row justify-content-center mt-2 mb-5 p-4 topbtn"}   ><h1 className='enterpreyhead'> Team</h1></div></NavLink>

  </div>




  <div className=' col-lg-2 col-md-12 col-12 m-0' style={{background:"none"}} onClick={() => setitem("C")}>
  <NavLink to="/hackmasterseries/leaderboard" style={{textDecoration:"none"}}> <div className={item ==="C" ? "row justify-content-center mt-2 mb-5 p-4 topbtn2":"row justify-content-center mt-2 mb-5 p-4 topbtn"}   ><h1 className='enterpreyhead'> Leaderboard</h1></div></NavLink>
  </div>
 
  </div>
  </div>
   </div>

 
  
  
  

  
 

   

            
 
  
 
  
  
  
  </div>
 
  <div className='container mt-0 p-1'>
  <div className='row'>

  {item === "C" && 
  <div className='container'>
  
  
 <div className='row justify-content-center ' id='teamrow'>
        <div className='col-lg-2 col-md-2 col-1'  ><h1 className='leaderboardrank'>RANK</h1></div>

        <div className='col-lg-4 col-md-2 col-4'  ><h1 className='leaderboardrank'>TEAM Name</h1></div>
        <div className='col-lg-2 col-md-4 col-2'  > <h1 className='leaderboardrank'>Score</h1></div>
        <div className='col-lg-2 col-md-2 col-3'  ><h1 className='leaderboardrank'>Flags submitted</h1></div>
        <div className='col-lg-2 col-md-2 col-1'  ><h1 className='leaderboardrank leaderboard-text-center'>Country</h1></div>
       
       
        </div>
       {teamglobalrank.length===0 ? <div className='row justify-content-center p-5' >
       <div className='col-lg-8 col-md-12 col-10' id='teamprofilecard'>
       <div className='row'>
       <div className='col-lg-4 col-md-6 col p-5'>
       <img src={havoclogo} />
       </div>
       
       <div className='col-lg-8 col-md-6 col p-5 pb-5'>
       <div className='justify-content-center'><h2 className='text-center alertmainhead2'>Live Ranking<div className='row justify-content-center'><span><hr className='text-center alertcommonhr'></hr></span></div></h2>  
       <p className='resetpara'>Live Rankings Begin on Hack Master Date! Track Your Team's Progress as the CTF Unfolds. Stay Tuned for Real-time Leaderboard Updates!</p>
       </div>
       
       
       </div>
       </div>
       </div> 
        </div>:  teamglobalrank.map((team,id)=>(<div className='row justify-content-center ' id='teamrow2' key={id}>
        <div className='col-lg-2 col-md-2 col-1'  ><h1 className='teamrowhead3 pt-4'>RANK {id+1}</h1></div>

        <div className='col-lg-4 col-md-4 col-4'  ><h1 className='teamrowhead3'><span><img src={team.photo || User2} style={{height:"3rem", width:"3rem",marginRight:"1rem",borderRadius:"50%"}} /></span>{team.team_name}</h1></div>
       
        <div className='col-lg-2 col-md-2 col-2'  > <h1 className='teamrowhead3 pt-4 '  >{team.score || "--"}</h1></div>
        <div className='col-lg-2 col-md-2 col-2'  ><h1 className='teamrowhead3 pt-4 '>{team.score/20 || "--"}</h1></div>
        <div className='col-lg-2 col-md-2 col-3'  > <h1 className='teamrowhead3 pt-4 leaderboard-text-center'>{team.country}</h1></div>

  
  </div>)) } 
  
 
 
 
 
  
  
  
  
  </div>
  
  
  }

   </div>
  </div>
  </div>
  </div>

  )
}

export default Havocleaderboard