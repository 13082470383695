import React,{useState,useEffect} from 'react'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import {FaUniversity} from "react-icons/fa";
import {AiOutlineGlobal} from "react-icons/ai"
import {BsPinMapFill} from "react-icons/bs";
import User from  "../../Assets/images/dp3.png"
import Loading from '../Dashboard/Modals/Loading';

const Leaderboard = () => {
  const[modal,setmodal]=useState(false);
    const [item, setItem] = useState("A");
    const [Globalrank,setGlobalrank]=useState([]);
    const [search,setsearch]=useState();
    const [searchdata,setsearchdata]=useState([])
    const[countrydata,setcountrydata]=useState([]);
    const[initialcountry,setinitialcountry]=useState([]);
    const [item2,setitem2]=useState("A");
    const [searchInput, setSearchInput] = useState("");
    const [filteredResults, setFilteredResults] = useState([]);





    const API_Key = process.env.REACT_APP_API_URL;
    const searchItems = (searchValue) => {
      setSearchInput(searchValue);
      if (searchInput !== "") {
        const filteredData = Globalrank.filter((item) => {
          return Object.values(item.fname)
            .join("")
            .toLowerCase()
            .includes(searchInput.toLowerCase());
        });
    
        setFilteredResults(filteredData);
       
      } else {
        setFilteredResults(Globalrank);
       
      }
    };


    useEffect(() => {
    
        fetch(`${API_Key}/countries/india`)
        .then(async(res) =>{
        const data = await res.json();
   setcountrydata(data);

        
        })
       .catch((err)=>
       console.log(err)
       )
    
   
      
      
        
      }, [])

    const handleChallengeDetails = (event) => {
      event.preventDefault();
     
     setsearch((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value,
      }));
    };
   
const seearchcounrt = (e)=>{
  e.preventDefault();
setmodal(true);
  fetch(`${API_Key}/countries/order` , {
    method: 'POST',
    headers: {
      Accept: 'application/json',
            mode:"cors",
                    'Access-Control-Allow-Origin':`${API_Key}`, 
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': true
          },
          credentials:"include",
    body: JSON.stringify(search),
  })
  .then(async(response) => { 

    const data=await response.json();
     
      setcountrydata(data)

      setmodal(false);
    if(response.status===200){
    //navigate("/welcome")
    //setModalOpen(false);
    //setModalOpen2(true);
    setTimeout(() => {
     //setModalOpen2(false);
   },3000);

    }else if(response.status===400){
      //setModalOpen3(true);
      //setModalOpen(false);
      setTimeout(() => {
       //setModalOpen3(false);
     },3000);

    }
  
  
  })
  
  .catch((error) => {
    console.error('Error:', error);
    //setModalOpen3(true);
  });
}




   useEffect(() => {

   if(search !==""){
     fetch("https://restcountries.com/v3.1/all")
     .then((res) =>res.json())
     .then((data) =>{ setsearchdata(data)
    }
     );

   }
   
     
   }, [])
   
   







    useEffect(() => {
      const getgloberank = async() => {
        setmodal(true);
        try{
          const res = await fetch(`${API_Key}/Globalrank`,{
              method:"GET",
              headers:{
                mode: 'cors',
                'Access-Control-Allow-Origin':`${API_Key}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': true,
              },
              credentials:"include"
             
          });
          const data = await res.json([]);
         
          setGlobalrank(data);
          setmodal(false);
     
          
      
    
         
          
     
        
      
          if(res.status !== 200 ){
              const error = new Error(res.error);
              throw error;
              
          }
          
       
      
      
      }catch(err){
        console.log(err);
        //navigate("/signin");
      
      } 
      
      
      };
  
      getgloberank();
    }, []);

    const getLevelName =(total_score)=>{
      if (total_score >= 0 && total_score <= 300) {
        return   "Rookie" 
    }
    else if (total_score >= 301 && total_score <= 600) {
        return  "Ace" 
    }
    if (total_score >= 601 && total_score <= 900) {
        return   "Head" 
    }
    else if (total_score >= 901 && total_score <= 1000) {
        return   "Boss" 
    }

    if (total_score >= 1001 && total_score <= 1300) {
        return   "Preycounter" 
    }
    else if (total_score >= 1301 && total_score <= 1500) {
        return   "Preruler" 
    }
    if (total_score >= 1501 && total_score <= 1800) {
        return   "Preymaster" 
    }
    else if (total_score >= 1801 && total_score <= 2100) {
        return   "Chief" 
    }
    if (total_score >= 2101 && total_score <= 2400) {
        return   "Bigchief" 
    }
    else if (total_score >= 2401 && total_score <= 2700) {
        return   "Lord" 
    }
    }
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])
  return (
    <div>
    {modal && <Loading  setOpenModal={setmodal} />}
    
      <section className="Dynamicbackground">
 

        <div className="container mt-5">
          <div class="Heading-main">
            <span className='spanleaderboard'>LEADERBOARD</span>
            <h2>LEADERBOARD</h2>
            <hr className="offset-md-5 offset-4"></hr>
          </div>
          <div  className="row justify-content-center">
         
          </div>
        </div>
      </section>
      <div className="mainconsection">
      <div id='leaderboardrow'>
      <div className='container'>
      <div className='row justify-content-center '>
      <div className='col-lg-6 col-md-6 col-10 mt-5 mb-5'>
      <button onClick={() => setItem("A")} style={{cursor:"pointer"}}  className={item ==="A" ?"enrollbtntwo mt-2 p-5":"enrollbtntwo2 mt-2 p-5"}> <AiOutlineGlobal size={30}/> GLOBAL</button>
      </div>
      <div className='col-lg-6 col-md-6 col-10 mt-5 mb-5'>
      <button onClick={() => setItem("B")} style={{cursor:"pointer"}} className={item ==="B" ?"enrollbtntwo mt-2 p-5":"enrollbtntwo2 mt-2 p-5"}> <BsPinMapFill size={30}/> COUNTRIES</button>
      </div>
    { /* <div className='col-lg-4 col-md-6 col-10 mt-5 mb-5'>
      <button onClick={() => setItem("C")} style={{cursor:"pointer"}} className={item ==="C" ?"enrollbtntwo mt-2 p-5":"enrollbtntwo2 mt-2 p-5"}> <FaUniversity size={30}/> UNIVERSITY</button>
  </div>*/}
      </div>
      </div>
     

      {item === "A" &&
      <div className='container-fluid mt-5 mb-5'>
      <div className='row justify-content-center'> 
      <form>
      <div className='col mb-5 ' >
     <input type='text' className='form-control2'  placeholder='Search Users....'   onChange={(e) => searchItems(e.target.value)}/>
                           </div>
     {/* <input type='select' className='form-control2' onChange={handlechange} placeholder='Enter Your Country' />*/}

      </form>
    
       
                          
    </div>




      
      <div className='row justify-content-center ' id='leaderboardrow2'>
      <div className='col-lg-2 col-md-2 col-1'  ><h1 className='leaderboardrank'>RANK</h1></div>
      <div className='col-lg-2 col-md-2 col-3'  ><h1 className='leaderboardrank'>Rank name</h1></div>
      <div className='col-lg-2 col-md-2 col-2'  ><h1 className='leaderboardrank'>Name</h1></div>
      <div className='col-lg-2 col-md-2 col-2'  > <h1 className='leaderboardrank ml-3'>score</h1></div>
      <div className='col-lg-2 col-md-2 col-3'  > <h1 className='leaderboardrank'>challenges</h1></div>
      <div className='col-lg-2 col-md-2 col-1'  ><h1 className='leaderboardrank'>Labs</h1></div>
     
     
      </div>
      {searchInput.length > 1 ?  filteredResults.map((rank,id)=>( <div className='row justify-content-center ' id='leaderboardrow3'>
<div className='col-lg-2 col-md-2 col-1'  ><h1 className='leaderboardrank3'>RANK {id+1}</h1></div>
<div className='col-lg-2 col-md-2 col-3'  ><h1 className='leaderboardrank3'>{getLevelName(rank.total_score)}</h1></div>
<div className='col-lg-2 col-md-2 col-2'  ><h1 className='leaderboardrank3'><span><img src={rank.photo || User} style={{height:"30px",width:"30px",borderRadius:"50%",marginRight:"10px"}} /></span>{rank.fname}</h1></div>
<div className='col-lg-2 col-md-2 col-2'  > <h1 className='leaderboardrank3 ml-5'  >{rank.total_score || 0}</h1></div>
<div className='col-lg-2 col-md-2 col-3 '  > <h1 className='leaderboardrank3 ml-5'>{rank.total_challenges || 0}</h1></div>
<div className='col-lg-2 col-md-2 col-1 '  ><h1 className='leaderboardrank3 ml-5'>{rank.lab_taken_user ||0}</h1></div>


</div>)):Globalrank && Globalrank.slice(0,20).map((rank,id)=>( <div className='row justify-content-center ' id='leaderboardrow3'>
<div className='col-lg-2 col-md-2 col-1'  ><h1 className='leaderboardrank3'>RANK {id+1}</h1></div>
<div className='col-lg-2 col-md-2 col-3'  ><h1 className='leaderboardrank3'>{getLevelName(rank.total_score)}</h1></div>
<div className='col-lg-2 col-md-2 col-2'  ><h1 className='leaderboardrank3'><span><img src={rank.photo || User} style={{height:"30px",width:"30px",borderRadius:"50%",marginRight:"10px"}} /></span>{rank.fname}</h1></div>
<div className='col-lg-2 col-md-2 col-2'  > <h1 className='leaderboardrank3 ml-5'  >{rank.total_score || 0}</h1></div>
<div className='col-lg-2 col-md-2 col-3 '  > <h1 className='leaderboardrank3 ml-5'>{rank.total_challenges || 0}</h1></div>
<div className='col-lg-2 col-md-2 col-1 '  ><h1 className='leaderboardrank3 ml-5'>{rank.lab_taken_user ||0}</h1></div>


</div>))}
     
     
    
      
      
      
     
     
     
    
      <br></br>   <br></br>
      </div>}

      {item==="B" && 
   

      <div className='container-fluid mt-5 mb-5'>
      <div className='row justify-content-center'> 
     <form>
     <div className='col ' >
     <select   className='selectrank'   name="country"   onChange={handleChallengeDetails}    >
    
     <option className='option'>India</option>
     {searchdata.map((course,id)=>(
      <option key={id} value={course.name.common} className='option'> {course.name.common}</option>
   
    ))}
                         
                          </select>
                          </div>
    {/* <input type='select' className='form-control2' onChange={handlechange} placeholder='Enter Your Country' />*/}
    <div className='row justify-content-center mt-5'><button className='btn leaderbtn' onClick={seearchcounrt} >SEARCH</button></div> 
     </form>
   
      
                         
   </div>
      <div className='row justify-content-center ' id='leaderboardrow2'>
      <div className='col-lg-2 col-md-2 col-1'  ><h1 className='leaderboardrank'>RANK</h1></div>
      <div className='col-lg-2 col-md-2 col-3'  ><h1 className='leaderboardrank'>Rank name</h1></div>
      <div className='col-lg-2 col-md-2 col-2'  ><h1 className='leaderboardrank'>Name</h1></div>
      <div className='col-lg-2 col-md-2 col-2'  > <h1 className='leaderboardrank'>score</h1></div>
      <div className='col-lg-2 col-md-2 col-3'  > <h1 className='leaderboardrank'>challenges</h1></div>
      <div className='col-lg-2 col-md-2 col-1'  ><h1 className='leaderboardrank'>Labs</h1></div>
     
     
      </div>

     {item2 ==="A" ? initialcountry && countrydata.map((country,id)=>( <div className='row justify-content-center ' id='leaderboardrow3'>
     <div className='col-lg-2 col-md-2 col-1'  ><h1 className='leaderboardrank3'>RANK {id+1}</h1></div>
     <div className='col-lg-2 col-md-2 col-3'  ><h1 className='leaderboardrank3'>{getLevelName(country.total_score)}</h1></div>
     <div className='col-lg-2 col-md-2 col-2'  ><h1 className='leaderboardrank3'><span><img src={country.photo || User} style={{height:"30px",width:"30px",borderRadius:"50%",marginRight:"10px"}} /></span>{country.fname}</h1></div>
     <div className='col-lg-2 col-md-2 col-2'  > <h1 className='leaderboardrank3'>{country.total_score ||0}</h1></div>
     <div className='col-lg-2 col-md-2 col-3'  > <h1 className='leaderboardrank3'>{country.total_challenges||0}</h1></div>
     <div className='col-lg-2 col-md-2 col-1'  ><h1 className='leaderboardrank3'>{country.lab_taken_user ||0}</h1></div>
    
    
     </div>))   : countrydata && countrydata.map((country,id)=>( <div className='row justify-content-center ' id='leaderboardrow3'>
     <div className='col-lg-2 col-md-2 col-1'  ><h1 className='leaderboardrank3'>RANK {id+1}</h1></div>
     <div className='col-lg-2 col-md-2 col-3'  ><h1 className='leaderboardrank3'>{getLevelName(country.total_score)}</h1></div>
     <div className='col-lg-2 col-md-2 col-2'  ><h1 className='leaderboardrank3'><span><img src={country.photo || User} style={{height:"30px",width:"30px",borderRadius:"50%",marginRight:"10px"}} /></span>{country.fname}</h1></div>
     <div className='col-lg-2 col-md-2 col-2'  > <h1 className='leaderboardrank3'>{country.total_score ||0}</h1></div>
     <div className='col-lg-2 col-md-2 col-3'  > <h1 className='leaderboardrank3'>{country.total_challenges||0}</h1></div>
     <div className='col-lg-2 col-md-2 col-1'  ><h1 className='leaderboardrank3'>{country.lab_taken_user ||0}</h1></div>
    
    
     </div>)) }

     
     
      
     
      
      <br></br>   <br></br>
      </div>

    }
    {item==="C" && 

    <div className='container-fluid mt-5 mb-5'>
    <div className='row justify-content-center'> 
    <form>
<input type='text'  className='form-control2' placeholder='Enter Your University' />
  
   <div className='row justify-content-center mt-5'><button className='btn leaderbtn' >SEARCH</button></div> 
    </form>
     
                        
  </div>
      <div className='row justify-content-center ' id='leaderboardrow2'>
      <div className='col-lg-2 col-md-2 col-1'  ><h1 className='leaderboardrank'>RANK</h1></div>
      <div className='col-lg-2 col-md-2 col-3'  ><h1 className='leaderboardrank'>Rank name</h1></div>
      <div className='col-lg-2 col-md-2 col-2'  ><h1 className='leaderboardrank'>Name</h1></div>
      <div className='col-lg-2 col-md-2 col-2'  > <h1 className='leaderboardrank'>score</h1></div>
      <div className='col-lg-2 col-md-2 col-3'  > <h1 className='leaderboardrank'>challenges</h1></div>
      <div className='col-lg-2 col-md-2 col-1'  ><h1 className='leaderboardrank'>Labs</h1></div>
     
     
      </div>
      <div className='row justify-content-center ' id='leaderboardrow3'>
      <div className='col-lg-2 col-md-2 col-1'  ><h1 className='leaderboardrank3'>1</h1></div>
      <div className='col-lg-2 col-md-2 col-3'  ><h1 className='leaderboardrank3'>Robin</h1></div>
      <div className='col-lg-2 col-md-2 col-2'  ><h1 className='leaderboardrank3'>Ashish</h1></div>
      <div className='col-lg-2 col-md-2 col-2'  > <h1 className='leaderboardrank3'>367</h1></div>
      <div className='col-lg-2 col-md-2 col-3'  > <h1 className='leaderboardrank3'>1</h1></div>
      <div className='col-lg-2 col-md-2 col-1'  ><h1 className='leaderboardrank3'>3</h1></div>
     
     
      </div>
      <div className='row justify-content-center ' id='leaderboardrow3'>
      <div className='col-lg-2 col-md-2 col-1'  ><h1 className='leaderboardrank3'>2</h1></div>
      <div className='col-lg-2 col-md-2 col-3'  ><h1 className='leaderboardrank3'>Prey Master</h1></div>
      <div className='col-lg-2 col-md-2 col-2'  ><h1 className='leaderboardrank3'><span><img src={User} style={{height:"30px",width:"30px",borderRadius:"50%",marginRight:"10px"}} /></span>JohnDoe</h1></div>
      <div className='col-lg-2 col-md-2 col-2'  > <h1 className='leaderboardrank3'>543</h1></div>
      <div className='col-lg-2 col-md-2 col-3'  > <h1 className='leaderboardrank3'>2</h1></div>
      <div className='col-lg-2 col-md-2 col-1'  ><h1 className='leaderboardrank3'>4</h1></div>
     
     
      </div>
      <div className='row justify-content-center ' id='leaderboardrow3'>
      <div className='col-lg-2 col-md-2 col-1'  ><h1 className='leaderboardrank3'>3</h1></div>
      <div className='col-lg-2 col-md-2 col-3'  ><h1 className='leaderboardrank3'>Prey Master</h1></div>
      <div className='col-lg-2 col-md-2 col-2'  ><h1 className='leaderboardrank3'><span><img src={User} style={{height:"30px",width:"30px",borderRadius:"50%",marginRight:"10px"}} /></span>JohnDoe</h1></div>
      <div className='col-lg-2 col-md-2 col-2'  > <h1 className='leaderboardrank3'>543</h1></div>
      <div className='col-lg-2 col-md-2 col-3'  > <h1 className='leaderboardrank3'>2</h1></div>
      <div className='col-lg-2 col-md-2 col-1'  ><h1 className='leaderboardrank3'>4</h1></div>
     
     
      </div>
      <div className='row justify-content-center ' id='leaderboardrow3'>
      <div className='col-lg-2 col-md-2 col-1'  ><h1 className='leaderboardrank3'>4</h1></div>
      <div className='col-lg-2 col-md-2 col-3'  ><h1 className='leaderboardrank3'>Prey Master</h1></div>
      <div className='col-lg-2 col-md-2 col-2'  ><h1 className='leaderboardrank3'><span><img src={User} style={{height:"30px",width:"30px",borderRadius:"50%",marginRight:"10px"}} /></span>JohnDoe</h1></div>
      <div className='col-lg-2 col-md-2 col-2'  > <h1 className='leaderboardrank3'>543</h1></div>
      <div className='col-lg-2 col-md-2 col-3'  > <h1 className='leaderboardrank3'>2</h1></div>
      <div className='col-lg-2 col-md-2 col-1'  ><h1 className='leaderboardrank3'>4</h1></div>
     
     
      </div>
      <br></br>   <br></br>
      </div>
  }
      </div></div>
      <Footer/>
    </div>
  )
}

export default Leaderboard