import React from 'react'
import { GrFormCheckmark } from "react-icons/gr";
const benefits=[
   "Open to students and professionals with a keen interest in cybersecurity",
   "Suitable for beginners looking to enter the cybersecurity field",
   "Ideal for IT professionals seeking to upskill or transition into cybersecurity",
   "Relevant for individuals with a background in computer science or related fields",
   


]
const SummerTrainingIntro = () => {
    return (
        <div className='training-highlight-section2'>
        <div className='training-highlight-section-left'> 
        <h3 className='training-heading'>Introduction To 6 Weeks Summer Training In Cybersecurity</h3>
        <p className='training-desc'>Welcome to our premier 6 weeks of summer training in cybersecurity, where we bridge the gap between education and industry demands, created to address the increasing demand for skilled cybersecurity professionals. This 6 Week summer internship in cyber security, provides a complete and immersive learning experience. This summer Internship in Cyber Security covers essential aspects such as threat detection, network security, ethical hacking, and more. This internship enables the students to understand and learn the current trends in the job market. students pursue internships to boost their resumes for future jobs and to further their academic pursuits. Our company will provide an online cyber security summer internship with a dynamic learning environment to prepare you for the challenges of the cyber world. Join us this summer and start on a journey toward a rewarding and impactful career in cybersecurity.

    </p>
        </div>
        <div className='training-highlight-section-right'> 
        <p  className='hero-subheading margin-bottom text-center'>Who can Apply</p>
        
        {benefits.map((x)=>(
    <div className="display-flex margin-bottom-2">
        
    <div className="lab-desc-list-icon-wrapper">
    <GrFormCheckmark className="lab-desc-list-icon" />
    </div>
    <p className="training-list">{x}</p>
    </div>  
        ))}
       
               
        </div>
    
    </div>
      )
}

export default SummerTrainingIntro
