import React,{useState,useEffect} from 'react'

import { LuUsers } from "react-icons/lu";
import { HiOutlineInformationCircle } from "react-icons/hi2";
import "./Dynamicpage.css";

import Congrats from "../../Assets/Modals/Deactivate Account.png";

import { json, Link, NavLink , useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import Footer from '../Footer/Footer';
import image1 from "../../Assets/Dashboard/tieup.png";

import Tooltip from "@material-ui/core/Tooltip";
import pic2 from "../../Assets/Dashboard/hackmaster.png"
import pic3 from "../../Assets/Dashboard/Dashboard3.jpg";
import pic4 from "../../Assets/Dashboard/walongctf.png";
import pic5 from "../../Assets/Dashboard/Dashboard3 3.jpg"
import { format } from 'date-fns';
import moment from 'moment';
const useStyles = makeStyles({
  tooltip: {
    background: 'rgba(249, 245, 245, 0.09)',
    color:"white",
    padding:"1rem",
    fontSize:"1.2rem",

    fontFamily: "Saira, sans-serif"
  },
});
const Eventsctf = () => {
  
    const { pathname } = useLocation();
    const [item, setItem] = useState("C");
    const classes = useStyles();
    const[style,setstyle]=useState("courseupper1");
    const [clicked, setClicked] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const[events,setevents]=useState([]);
    const [eventType, setEventType] = useState("");
    const API_Key = process.env.REACT_APP_API_URL;
    const [hoveredCards, setHoveredCards] = useState([]);
    useEffect(() => {
      const type = pathname.split("/")[2];
      setEventType(type);
    }, [pathname]); 
    const handleMouseEnter = (index) => {
      setHoveredCards((prevState) => {
        const newState = [...prevState];
        newState[index] = true;
        return newState;
      });
    };
  
    const handleMouseLeave = (index) => {
      setHoveredCards((prevState) => {
        const newState = [...prevState];
        newState[index] = false;
        return newState;
      });
    };
    useEffect(() => {
      const getallevents = async () => {
        try {
          const res = await fetch(`${API_Key}/getModebaseWebinar`, {
            method: "POST",
            headers: {
              mode: 'cors',
              'Access-Control-Allow-Origin': `${API_Key}`,
              'Content-Type': 'application/json',
              'Access-Control-Allow-Credentials': true,
            },
            credentials: "include",
            body:JSON.stringify({type:eventType})
          });
          const data = await res.json();
          setevents(data.listOfWebinar);
    
          if (res.status !== 200) {
            const error = new Error(res.error);
            throw error;
          }
        } catch (err) {
          console.log(err);
          //navigate("/signin");
        } 
      };
       // Fetch events when eventType changes
    if (eventType) {
     getallevents()
    }
    
      getallevents();
    }, [eventType]);
    
    
 


    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  return (
 <>
 <section className="Dynamicbackground">
  

 <div className="container mt-5">
 {/*<div class="Heading-main">
<span>INTERNSHIP</span>
<h2>WE ARE OFFERING!</h2>
<hr className="offset-md-5"></hr>

</div>*/}
<div class="Heading-main">

     <h2 style={{textTransform:"uppercase"}}> {eventType === "all" && "All Events"}
     {eventType === "ctf" && "Capture the Flag"}
     {eventType === "webinar" && "Webinar"}</h2>
     <hr className="offset-md-5 offset-4"></hr>
   </div>
   <div  className="row justify-content-center">
  
   </div>


 </div>
 <div className="container mt-1">
 <div className="row justify-content-center mt-1">

</div>

 </div>

</section>
<div className="mainconsection">
<div className="coursesection">
    
<div className="container " >
<div className='row justify-content-center'> 


 
                    
</div>
 <div className="row  justify-content-center "  >

 <div className='col-lg-8 col-md-8 col eventallsections'>
 <div className='row justify-content-center'>
 <div onClick={() => setItem("A")} style={{cursor:"pointer"}}  className={item ==="A" ? "col-lg-3 col-md-3 col-3 justify-content-center eventbtunactive":"col-lg-3 col-md-3 col-3 justify-content-center eventbtndeacti"} >
 <h2 className="text-center" >Ongoing</h2>
</div>



<div onClick={() => setItem("B")} style={{cursor:"pointer"}}  className={item ==="B" ? "col-lg-3 col-md-3 col-3 justify-content-center eventbtunactive":"col-lg-3 col-md-3 col-3 justify-content-center eventbtndeacti"} >
<h2 className="text-center" >Upcoming</h2>
</div>
<div onClick={() => setItem("C")} style={{cursor:"pointer"}}  className={item ==="C" ? "col-lg-3 col-md-3 col-3 justify-content-center eventbtunactive":"col-lg-3 col-md-3 col-3 justify-content-center eventbtndeacti"}>
<h2 className="text-center" >Past</h2>
</div>
 </div>
 </div>
    
        


        
        
        
    
    

       
    

        
 

   
     

  
     </div>
     </div>
     {item === "A" &&  
     <div className='container-fluid mt-5'>
     <div className='row justify-content-center mt-5'>
     {events && events.filter(event => event.status === "ongoing").length > 0 ? (
      events.filter(event => event.status === "ongoing").map((event, index) => (
        <div
          key={index}
          className={`col-lg-3 col-md-3 col-10 m-3 eventcard ${hoveredCards[index] ? 'hovered' : ''}`}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={() => handleMouseLeave(index)}
        >
          <NavLink to={{ pathname: "/ctfhackmaster/" + event._id }} style={{ textDecoration: "none" }}>
            <div className='row'>
              <img src={event.cover_image} alt="img" style={{ width: "100%" }} />
            </div>
            <div className='row' id='eventcardcontent'>
              <h1 className='eventcardhead'>{event.title}</h1>
              <h5 className='eventcarddate'>
                {format(new Date(event.startDate), "MMMM dd, yyyy")} , {format(new Date(event.endDate), "MMMM dd, yyyy")}
              </h5>
              <h5 className='eventcardplayers'>
                <span className='iconsback'><LuUsers /></span> {event.playersjoined} Players Joined
              </h5>
            </div>
          </NavLink>
          {hoveredCards[index] && (
            <NavLink to={{ pathname: "/ctfhackmaster/" + event._id }} style={{ textDecoration: "none" }}>
              <div className='row'>
                <div className='col-lg-9 col-md-9'>
                  <div className='row'>
                    <div className='col-lg-6 col-md-6'>
                      <button className='btn eventsignupbtn'>Signup</button>
                    </div>
                    <div className='col-lg-6 col-md-6'>
                      <Tooltip title="Overview" placement="top" classes={classes}>
                        <h5 className='eventcardplayers2'>
                          <span className='iconsback2'><HiOutlineInformationCircle size={25} /></span>
                        </h5>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </NavLink>
          )}
        </div>
      ))
    ) : (
      <div className="row justify-content-center ">
      
      <div className="col-lg-8 col-md-8 col-10 p-1 mt-4 " id="istancebtndiv" >
      
    
        <div className='row p-5'>
       
        <div className='col-lg-4 col-md-5 col-4  '>
       
        <div className='justify-content-center pl-5'><img src={Congrats} style={{height:"45vh"}} alt="sorry"/></div>
        </div>
        <div className='col-lg-7 col-md-7 col-6   '>
        <div className='mt-2 crossbtnarrow'>
       
      
      </div>
       <div className='justify-content-center'><h2 className='text-center alertmainhead2'>No Ongoing Events!!<div className='row justify-content-center'><span><hr className='text-center alertcommonhr'></hr></span></div></h2>  
       </div> 
       <p className='resetpara'>As of now, there are currently no Ongoing events with HackersPrey, but stay tuned for our upcoming lineup of engaging cybersecurity CTF's, Webinars, and competitions designed to deepen your knowledge and skills in the field. </p>

       
      
    
      
        </div></div>
       
       
         
          
       
      </div>
    </div>
    )}
    
   </div>
     </div>}
     
{item === "B" && <div className='container-fluid mt-5'>
<div className='container-fluid mt-5'>
<div className='row justify-content-center mt-5'>
{events && events.filter(event => event.status === "upcoming").length > 0 ? (
 events.filter(event => event.status === "upcoming").map((event, index) => (
   <div
     key={index}
     className={`col-lg-3 col-md-3 col-10 m-3 eventcard ${hoveredCards[index] ? 'hovered' : ''}`}
     onMouseEnter={() => handleMouseEnter(index)}
     onMouseLeave={() => handleMouseLeave(index)}
   >
     <NavLink to={{ pathname: event.type === 'ctf' ? "/ctfhackmaster/" + event._id : "/webinar/"  }} style={{ textDecoration: "none" }}>
       <div className='row'>
         <img src={event.cover_image} alt="img" style={{ width: "100%" }} />
       </div>
       <div className='row' id='eventcardcontent'>
         <h1 className='eventcardhead'>{event.title}</h1>
         <h5 className='eventcarddate'>
           {format(new Date(event.startDate), "MMMM dd, yyyy")} , {format(new Date(event.endDate), "MMMM dd, yyyy")}
         </h5>
         <h5 className='eventcardplayers'>
           <span className='iconsback'><LuUsers /></span> {event.playersjoined} Players Joined
         </h5>
       </div>
     </NavLink>
     {hoveredCards[index] && (
       <NavLink to={{ pathname: event.type === 'ctf' ? "/ctfhackmaster/" + event._id : "/webinar/"  }} style={{ textDecoration: "none" }}>
         <div className='row'>
           <div className='col-lg-9 col-md-9'>
             <div className='row'>
               <div className='col-lg-6 col-md-6'>
                 <button className='btn eventsignupbtn'>Signup</button>
               </div>
               <div className='col-lg-6 col-md-6'>
                 <Tooltip title="Overview" placement="top" classes={classes}>
                   <h5 className='eventcardplayers2'>
                     <span className='iconsback2'><HiOutlineInformationCircle size={25} /></span>
                   </h5>
                 </Tooltip>
               </div>
             </div>
           </div>
         </div>
       </NavLink>
     )}
   </div>
 ))
) : (
 <div className="row justify-content-center ">
 
 <div className="col-lg-8 col-md-8 col-10 p-1 mt-4 " id="istancebtndiv" >
 

   <div className='row p-5'>
  
   <div className='col-lg-4 col-md-5 col-4  '>
  
   <div className='justify-content-center pl-5'><img src={Congrats} style={{height:"45vh"}} alt="sorry"/></div>
   </div>
   <div className='col-lg-7 col-md-7 col-6   '>
   <div className='mt-2 crossbtnarrow'>
  
 
 </div>
  <div className='justify-content-center'><h2 className='text-center alertmainhead2'>No upcoming Events!!<div className='row justify-content-center'><span><hr className='text-center alertcommonhr'></hr></span></div></h2>  
  </div> 
  <p className='resetpara'>As of now, there are currently no Upcoming events with HackersPrey, but stay tuned for our upcoming lineup of engaging cybersecurity CTF's, Webinars, and competitions designed to deepen your knowledge and skills in the field.</p>

  
 

 
   </div></div>
  
  
    
     
  
 </div>
</div>
)}

</div>
</div>
     
</div>}
{item === "C" &&  <div className='container mt-5'>
<div className='row  justify-content-center eventallsections' >
<div className='col-lg-12 col-md-12 col mt-3 mb-1'>
<div className='row'>
<div className='col-lg-2 col'><h3 className='eventleaderhead'>Event Series</h3></div>
<div className='col-lg-3 col'><h3 className='eventleaderhead'>University</h3></div>
<div className='col-lg-2 col'><h3 className='eventleaderhead'>Event Type</h3></div>
<div className='col-lg-3 col'><h3 className='eventleaderhead'>Total Participants</h3></div>
<div className='col-lg-2 col'><h3 className='eventleaderhead'>Date</h3></div>
</div>

</div>































</div>
{events
  ?.filter(event => event.status === "past")
  ?.map((event,index)=>(
  <div className='col-lg-12 col-md-12 col mt-3'   key={index}>
  <NavLink to={{
    pathname: "/eventdetails/" + event._id,
    
  }} style={{textDecoration:"none"}}> 
  <div className='row' id='eventlist' >
<div className='col-lg-2 col'><h3 className='eventleaderhead3'>{event.title}</h3></div>
<div className='col-lg-3 col'><h3 className='eventleaderhead2'>{event.hostuni}</h3></div>
<div className='col-lg-2 col'><h3 className='eventleaderhead2'>{event.eventName}</h3></div>
<div className='col-lg-3 col'><h3 className='eventleaderhead2'>{event.playersjoined}</h3></div>
<div className='col-lg-2 col'><h3 className='eventleaderhead2'>{moment(event.startDate, 'YYYY-MM-DD').format('DD/MM/YY')}</h3></div>
</div></NavLink>

</div>
))}
</div>}




 


 </div>

</div>
 <Footer/>
 </>
  )
}

export default Eventsctf

