import React,{useEffect} from 'react'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import Congrats from "../../Assets/MASCOT/Mascot SVG/Asset 29.png"

import {FaArrowRight} from "react-icons/fa";
import {ImCross} from "react-icons/im";
import { NavLink } from 'react-router-dom';
const Registrationsucess = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  return (
    <div>
    
<section className="commonbackground">
<div id='registrationdivwaves'>

<div className='container' >
<div className='row justify-content-center '>
<div className=' col-lg-8 col-md-8 col-8 p-5 mt-5 mb-5' id='registerdiv'>
<div className='row p-5'>
         
          <div className='col-lg-4 col-md-5 col-4  '>
         
          <div className='justify-content-center pl-5'><img src={Congrats} style={{height:"45vh"}} alt="sorry"/></div>
          </div>
          <div className='col-lg-8 col-md-5 col-8   '>
         
         <div className='justify-content-center'><h2 className='text-center alertmainhead2'>Email Verification Complete!<div className='row justify-content-center'><span><hr className='text-center alertcommonhr'></hr></span></div></h2>  
         </div> 
         <p className='resetpara'>Your email has been successfully verified. Prepare yourself to enhance your ethical hacking skills with HackersPrey, where the world of cybersecurity awaits your expertise! </p>

         
         <div className='row justify-content-center'>
         <NavLink to="/signin"> <button className='Arrowbtn' >
        <FaArrowRight color="white" size={20}/>
         
         </button>
         </NavLink>
         </div>
          </div></div>

</div>


</div>

</div>
</div>
</section>
    <Footer/>
    </div>
  )
}

export default Registrationsucess