import React, { useState,useEffect } from 'react'

import chanddigarh from "../../../Assets/Entity/Website Havoc/Iiit-una-logo.png";
import Totalslots from "../../../Assets/Entity/University/total.png"
import  allocated from "../../../Assets/Entity/University/freeslots.png"
import Freeslots from "../../../Assets/Entity/University/allocated.png"
import Record from "../../../Assets/images/Activity.svg";
import { NavLink, useNavigate } from "react-router-dom";
import Loading from '../Modals/Loading';

import fileDownload from 'react-file-download';
import Createvpn from "../../../Assets/Entity/University/createvpn.png"
import downloadvpns from "../../../Assets/Entity/University/downloadvpn.png"
import Removevpn from "../../../Assets/Entity/University/removevpn.png"
import Vpncreatedsucess from '../Modals/Vpncreatedsucess';
import Vpndownloaderror from '../Modals/Vpndownloaderror';
import Vpnremovedsuccess from '../Modals/Vpnremovedsuccess';


const Uni = (props) => {
  const[modal,setopenmodal]=useState(false);
  const[modalopen2,setModalOpen2]=useState(false);
  const[modalopen3,setModalOpen3]=useState(false);
  const[modalopen4,setModalOpen4]=useState(false);
  const[unicourse,setunicourse]=useState([]);
  const[courseid,setcourseid]=useState("");
  const [item, setItem] = useState("A");
  const navigate=useNavigate();
    const API_Key = process.env.REACT_APP_API_URL;
    const[unidetails,setunidetails]=useState("");

    const fetchunicourse = async () => {
      setopenmodal(true);
      
              
        try{
          const res = await fetch(`${API_Key}/getuniversitycourse`,{
              method:"GET",
              headers:{
                mode: 'cors',
                'Access-Control-Allow-Origin':`${API_Key}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': true,
              },
              credentials:"include"
            
             
          });
          const data = await res.json();
         
          setunicourse(data);
          setcourseid(data.course._id);
        
        
      
          if(res.status !== 200 ){
              const error = new Error(res.error);
              throw error;
              
          }else{
            setopenmodal(false);
          }
          
       
      
      
      }catch(err){
        console.log(err);
      
      
      } 
      
      }
      useEffect(() => {
        fetchunicourse();
      },[ ] )
   
      const createvpn = async () => {
        setopenmodal(true);
        const course={
courseid:"dsdsdsdsdsd"
        }
       fetch(`${API_Key}/stopexaminstance`, {
          method: 'GET',
          headers:{
            mode: 'cors',
           'Content-Type': 'application/json',
           'Access-Control-Allow-Credentials': `${API_Key}`,
           'Access-Control-Allow-Credentials': true,
          },
          credentials:"include",
        })
        .then((response) => { 
      if(response.status===200){
        setModalOpen2(true);
        setopenmodal(false);
      }
      
        }).catch((error) => {
          console.error('Error:', error);
         // setModalOpen2(true);
         
        
           
         });
       };
       const downloadvpn = async () => {
        setopenmodal(true);
        fetch(`${API_Key}/downloadvpn`, {
          method: 'GET',
          headers:{
            mode: 'cors',
           'Content-Type': 'application/json',
           'Access-Control-Allow-Credentials': `${API_Key}`,
           'Access-Control-Allow-Credentials': true,
            
          },
      credentials:"include"
        })
        .then(async(res) =>{ 
        const data=await res.json();
        
        if(res.status===200){
          setopenmodal(false);
      
          fileDownload(data, 'filename.ovpn');
        }else if(res.status===400){
   setopenmodal(false);
        
          setModalOpen3(true);
        }
        })
        .catch((err) => console.log(err));
       };
      
       const removevpn = async () => {
        setopenmodal(true);



        
        fetch(`${API_Key}/removevpn`, {
          method: 'GET',
          headers:{
            mode: 'cors',
           'Content-Type': 'application/json',
           'Access-Control-Allow-Credentials': `${API_Key}`,
           'Access-Control-Allow-Credentials': true,
          },
          credentials:"include",
       
        })
        .then((response) => { 
    if(response.status===200){
      setopenmodal(false);
      setModalOpen4(true)   
     }
      
        }).catch((error) => {
          console.error('Error:', error);
         // setModalOpen2(true);
         
        
           
         });
       };
      
  
    useEffect (() =>{
      setopenmodal(true);

        fetch(`${API_Key}/getuniversitybyuser`,{
            method:'GET',
            headers:{
                Accept: 'application/json',
                mode:"cors",
                        'Access-Control-Allow-Origin':`${API_Key}`, 
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Credentials': true
              },
              credentials:"include",
           
        }).then(async(res)=>{
          setopenmodal(false);
          const data= await res.json();
          setunidetails(data);
      
//navigate('/signin')
if(res.status !==200){
    const Error = new Error (res.Error);
    throw Error;
}
        }).catch((err) =>{
//navigate("/unilogin")

console.log(err)
        })

    },[]);

  return (
    <>
    {modal && <Loading setOpenModal={setopenmodal}/>}
    {modalopen2 && <Vpncreatedsucess setOpenModal={setModalOpen2}/>}
    {modalopen3 && <Vpndownloaderror setOpenModal={setModalOpen3}/>}
    {modalopen4 && <Vpnremovedsuccess setOpenModal={setModalOpen4}/>}

    <div className='Entity_background p-1'>
    <div className='Entitycommontopper'>
    <div className='container pb-2'>
   <div className='row justify-content-center pb-5'>
   <div className='col-lg-8 col-md-8 col-12 '>
   <h1 className='Entitycommonhead'>{unidetails.universityname}<span><hr className='Entitycommonheadhr'></hr></span></h1>
   <p className='Entitycommonheadpara'> {unidetails.universitydesc}</p>
   
   </div>
   <div className='col-lg-3 col-md-4 col-12 offset-md-1 '>
<img src={chanddigarh} className="img-fluid" style={{paddingTop:"4rem",marginTop:"4rem"}} alt="src"/>
   </div>
   </div>
   </div>
  
   </div>

   <div className='container pb-2 mt-5'>
   <div className='row p-5'>
   <h1 className='overviewhead2'>University Slot Information<span><hr className='commonheadhr'></hr></span></h1>
   <p className='labdescpara'> {unidetails.slotinfo}</p>
   </div>
   <div className='row justify-content-center p-5'>

   <div className='col-lg-3' id='unislotscard'>
<div className='row justify-content-center'>
<div className='unicardphoto p-2'><img src={Totalslots}  style={{"height":"5rem",marginTop:"1rem"}} alt="total"/></div>
</div>
<div className='row justify-content-center'>
<h1 className='unislotshead'>Total Slots</h1>
</div>
<div className='row justify-content-center'>
<h1 className='unislotshead2'>{unidetails.totalSlots}</h1>
</div>

 
   </div>
   <div className='col-lg-3' id='unislotscard'>
   <div className='row justify-content-center'>
   <div className=' unicardphoto p-2'><img src={Freeslots}  style={{"height":"5rem",marginTop:"1rem"}} alt="total"/></div>
   </div>
   <div className='row justify-content-center'>
   <h1 className='unislotshead'>Free Slots</h1>
   </div>
   <div className='row justify-content-center'>
   <h1 className='unislotshead2'>{unidetails.freeSlots}</h1>
   </div>
   
    
      </div>
      <div className='col-lg-3' id='unislotscard'>
      <div className='row justify-content-center'>
      <div className='unicardphoto p-2'><img src={allocated}  style={{"height":"5rem",marginTop:"1rem"}} alt="total"/></div>
      </div>
      <div className='row justify-content-center'>
      <h1 className='unislotshead'>Allocated Slots</h1>
      </div>
      <div className='row justify-content-center'>
      <h1 className='unislotshead2'>{unidetails.slotsOccupied}</h1>
      </div>
      
       
         </div>
   
   </div>
   </div>
   <div className='container'>
   <div className='row mt-4 justify-content-center'>
   <h1 className='overviewhead2'>Access Your Program<div className='row justify-content-center'><hr className='commonheadhr'></hr></div></h1>
<p className='unislotshead3'>Embark on an immersive journey through our curated video tutorials and real-world challenges, designed to equip you with the skills to detect, assess, and fortify web vulnerabilities.</p>
<div className='row justify-content-center mt-2'>
<NavLink to={"/coursedesc/"+ courseid}><button className='btn submitflagbtn mt-2 pl-5 pr-5'>  Access Your Course</button></NavLink>
</div>
   </div>
   </div>
   <div className='container p-0 mt-5'>
   

 
   <div className='row justify-content-center mt-5 p-5'>
   <div className='col-lg-3'>
   <div className='row mt-5 justify-content-center pt-0 mb-0' onClick={() => setItem("A")}  style={{cursor:"pointer"}}>
   <div className={item ==="A" ? "col-lg-10 pt-5 pb-0 pr-5 pl-5 justify-content-center settingdivchange":"col-lg-10 pt-5 pb-0 pr-5 pl-5 justify-content-center settingdiv1"}>
  
    <img src={Createvpn}  style={{
      height: "6rem",
      width:"6rem"
     
    
      
     
    }} className="genraldiv2"  alt="Genral"  />
  
    
  
  
  
  <h2 className="settinghead mb-2 pt-2 pb-5 mt-3 "  >Create VPN</h2>
  
  
  
  
  </div>
  </div>
  <div className='row mt-5 justify-content-center p-0'   onClick={() => setItem("B")} style={{cursor:"pointer"}}>
  <div className={item ==="B" ? "col-lg-10 pt-5 pb-0 pr-5 pl-5 justify-content-center settingdivchange":"col-lg-10 pt-5 pb-0 pr-5 pl-5 justify-content-center settingdiv1"}>
  
    <img src={downloadvpns}  style={{
      height: "6rem",
      width:"6rem"
     
    
      
     
    }} className={item === "B"?"genraldiv2":"genraldiv"} alt="Genral"  />
  
    
  
  
  
  <h2 className= ' settinghead mb-2 pt-2 pb-5 mt-3' >Download VPN File</h2>
  
  
  
  
  </div>
  </div>
  <div className='row mt-5 justify-content-center p-0'   onClick={() => setItem("C")} style={{cursor:"pointer"}}>
  <div className={item ==="C" ? "col-lg-10 pt-5 pb-0 pr-5 pl-5 justify-content-center settingdivchange":"col-lg-10 pt-5 pb-0 pr-5 pl-5 justify-content-center settingdiv1"}>
  
    <img src={Removevpn}  style={{
      height: "6rem",
      width:"6rem"
     
    
      
     
    }} className={item === "C"?"genraldiv2":"genraldiv"} alt="Genral"  />
  
    
  
  
  
  <h2 className= ' settinghead mb-2 pt-2 pb-5 mt-3' >Remove VPN File</h2>
  
  
  
  
  </div>
  </div>
   </div>
  
   <div className='col-lg-9 settingdiv2 mt-5 p-5' >
   {item==="A" &&<div className='row p-5'>
   <div className='col-lg-12 col-md-12'>
   <div className='row justify-content-center'>

   <h1 className='commonhead2'>Create A VPN File<div className='row justify-content-center'><hr className='commonheadhr'></hr></div></h1>
</div>
</div>

<p className='setttingdiv2para1'>   <span><img src={Record} className="mr-3" style={{height:"2rem",width:"2rem"}} alt="record"/></span>To access the challenges, simply click on the "Create VPN File" button within the platform.</p>
<p className='setttingdiv2para1'>   <span><img src={Record} className="mr-3" style={{height:"2rem",width:"2rem"}} alt="record"/></span>A VPN file will be generated for your use. </p>
<p className='setttingdiv2para1'>   <span><img src={Record} className="mr-3" style={{height:"2rem",width:"2rem"}} alt="record"/></span> Please keep in mind that this VPN file is valid for a 24-hour duration.</p>
<div className='col-lg-12 col-md-12'>
<div className='row justify-content-center mt-5'>
<button className='btn submitflagbtn mt-5' onClick={createvpn}>  Create VPN File</button>
</div>
</div>


</div>}
   {item==="B" &&<div className='row p-5'>
   <div className='col-lg-12 col-md-12'>
   <div className='row justify-content-center'>
   <h1 className='commonhead2'>Connecting to the VPN<div className='row justify-content-center'><hr className='commonheadhr'></hr></div></h1>
</div></div>
<p className='setttingdiv2para1'>   <span><img src={Record} className="mr-3" style={{height:"2rem",width:"2rem"}} alt="record"/></span>Once you've generated the VPN file, proceed to download it to your device.</p>
<p className='setttingdiv2para1'>   <span><img src={Record} className="mr-3" style={{height:"2rem",width:"2rem"}} alt="record"/></span>Utilize OpenVPN to establish a connection using the downloaded VPN file.</p>

<p className='setttingdiv2para1'>   <span><img src={Record} className="mr-3" style={{height:"2rem",width:"2rem"}} alt="record"/></span>Download file with Below Button.</p>

<div className='col-lg-12 col-md-12'>
<div className='row justify-content-center mt-5'>
<button className='btn submitflagbtn mt-5 pl-5 pr-5' onClick={downloadvpn}>  Download VPN File</button>
</div>
</div>


</div>}
   {item==="C" &&<div className='row p-5'>
   <div className='col-lg-12 col-md-12'>
   <div className='row justify-content-center '>
   <h1 className='commonhead2'>Responsible Usage<div className='row justify-content-center'><hr className='commonheadhr'></hr></div></h1>
</div></div>
<p className='setttingdiv2para1'>   <span><img src={Record} className="mr-3" style={{height:"2rem",width:"2rem"}} alt="record"/></span>To ensure fair access for all users, Disconnect from VPN after completing tasks for fairness.</p>
<p className='setttingdiv2para1'>   <span><img src={Record} className="mr-3" style={{height:"2rem",width:"2rem"}} alt="record"/></span>By doing so, you'll free up licenses, making them available for other users to use and access Program.</p>
<p className='setttingdiv2para1'>   <span><img src={Record} className="mr-3" style={{height:"2rem",width:"2rem"}} alt="record"/></span>Click "Remove VPN" to delete the file, but note you'll need to create a new one for challenge access.</p>
<div className='col-lg-12 col-md-12'>
<div className='row justify-content-center mt-5'>
<button className='btn submitflagbtn mt-5' onClick={removevpn}>  Remove VPN </button>
</div>
</div>


</div>}
   </div>


 
   </div>
   </div>
   </div>
    </>
  )
}

export default Uni