import React from 'react';
import { GrFormCheckmark} from "react-icons/gr";
import { FaStar } from "react-icons/fa";
import { MdOutlineLibraryBooks } from "react-icons/md";
import { LiaHandshakeSolid,LiaBookSolid } from "react-icons/lia";
import { FaCalendar } from "react-icons/fa";
import {Formik,Field,ErrorMessage,Form} from 'formik';
import Vip from "../../Assets/Dashboard/logovip.png";
import { useNavigate } from "react-router-dom";
import './SummerTraining.css'
import './SummerTrainingPage.css'
import axios from "axios";
import * as Yup from "yup";
import 'yup-phone'
import { FaAws } from "react-icons/fa";
function loadScript(src) {
	return new Promise((resolve) => {
		const script = document.createElement('script')
		script.src = src
		script.onload = () => {
			resolve(true)
		}
		script.onerror = () => {
			resolve(false)
		}
		document.body.appendChild(script)
	})
}

const SummerTrainingHeroSection = () => {
    const navigate=useNavigate();
    const API_Key = process.env.REACT_APP_API_URL;
    const API_Key2=process.env.REACT_APP_RAZORPAY_ID;
    const initialValues={
        firstname:'',
        email:'',
        mobilenumber:'',
        institution:'',
       
      }
      const validationSchema = Yup.object({
        firstname: Yup.string().required("Required"),
        email: Yup.string().email("Invalid email").required("Required"),
        mobilenumber:Yup.string().phone('IN',true,"Enter valid Mobile Number").required("Required"),
        institution:Yup.string().required("Required"),
     
      }); 
      const handleSubmit=async(values)=>{
 
        const data={
          fullname:values.firstname,
          email:values.email,
          mobilenumber:values.mobienumber,
          institution:values.institution
        }
        const response=await axios.post(`${process.env.REACT_APP_API_URL}/training_Course_Createorder`,data);
        console.log(response.data)
        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')
              if (!res) {
                  alert('Razorpay SDK failed to load. Are you online?')
                  return
              }
          const options = {
            key:  `${API_Key2}` ,
            currency: "INR",
            amount: response.data.amount,
            order_id: response.data.id,
            name: 'Hackersprey',
            image: Vip,
            handler: function (response) {
              
              const money={order_id:response.razorpay_order_id,payment_id:response.razorpay_payment_id};
              console.log(money,"money")
           
             const signature = {
              'x-razorpay-signature': [response.razorpay_signature],
            }
         
          fetch(`${API_Key}/verifyPayment_enrollCource`, {
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                          'Access-Control-Allow-Origin':`${API_Key}`,
                          'Content-Type': 'application/json',
                          'Access-Control-Allow-Credentials': 'true',
                          'x-razorpay-signature':[response.razorpay_signature]
                         
                },
                
                credentials:"include",
                body: JSON.stringify(money),
                
                
              })
              .then(async(response) => {
                if(response.status===200){
                  navigate("/trainingsuccess")
                }
              }  ) .catch((error) => {
                console.error('Error:', error);
                //setModalOpen2(true);
              
              })  
              .catch((error) => {
                console.error('Error:', error);
                //setModalOpen2(true);
              
              })
            },
            prefill: {
              name:"none",
              email: 'none',
              phone_number: 'none'
            }
          }
          const paymentObject = new window.Razorpay(options)
          paymentObject.open()
      }
      
  return (
    <div className='training-hero-section'> 
    <div className='hero-section-left'>
    <h6 className="webinar-hero-left-heading">
         Certified Online Training
        </h6>
      <p className='hero-heading'>6 Weeks Summer Training In Cybersecurity 2024</p>
      <p className='hero-subheading'>Get Job-Ready in just 6 weeks with our Summer Training Program</p>
      <div className="summer-training-feat">
       <p ><FaStar className="herosection-icon "/>4.1</p>
    
          <p><MdOutlineLibraryBooks className="herosection-book "/>Practial Learning</p>
          
          
          <p><LiaHandshakeSolid className="herosection-place "/>Placement Assistance</p>
          <p><FaCalendar className="herosection-calicon "/>6 Weeks, 3hrs/day</p>
          <p><FaAws className="herosection-aws "/>Cloud Access For Hacking Challenges</p>

        </div>
  
   </div>
   <div className="hero-section-right ">
   <p className='hero-section-price'>Batches Starting from 3rd June,2024</p>
       <Formik initialValues={initialValues}  onSubmit={handleSubmit}  validationSchema={validationSchema}>
         {(formik)=>{
           console.log(formik.errors) 
           return(
           <Form className="webinar-form">
           {/*  {register ? <p className="webinar-success-component">Registration Successfull!!</p> : null} */}
             <Field className="w-input-field2" type="text" id="firstname" name="firstname" placeHolder="Name"/>
             <ErrorMessage component="div" className="webinar-error-component" name="firstname" ></ErrorMessage>
             <Field className="w-input-field2"  type="text" id="email" name="email" placeHolder="Email"/>
             <ErrorMessage component="div" className="webinar-error-component" name="email" ></ErrorMessage>
             <Field className="w-input-field2" type="text" id="mobilenumber" name="mobilenumber" placeHolder="Mobile Number"/>
             <ErrorMessage component="div" className="webinar-error-component" name="mobilenumber" ></ErrorMessage>
             <Field className="w-input-field2" type="text" id="institution" name="institution" placeHolder="Institution/Organisation"/>
             <ErrorMessage component="div" className="webinar-error-component" name="institution" ></ErrorMessage>
           
           
             <ErrorMessage component="div" className="webinar-error-component" name="experience" ></ErrorMessage>
            <p className='hero-section-price'>₹6000+Tax  <strike>₹15,000</strike></p>
             <button type="submit" className="web-register-btn3" disabled={!formik.isValid}>Pay & Register</button>
             
           </Form>
         )}}
       </Formik>
       </div>  

    </div>
  )
}

export default SummerTrainingHeroSection
