import React from 'react';
import './CourseHighlights.css';
import { LuPenSquare } from "react-icons/lu";
import { PiCertificate } from "react-icons/pi";
import { BsBarChartFill } from "react-icons/bs";
import { PiNotebook } from "react-icons/pi";
import { GiBullseye } from "react-icons/gi";
import { FaAws } from "react-icons/fa";
import { FaArrowRight,FaGraduationCap,FaLaptopCode } from "react-icons/fa";
import { CiCreditCard2 } from "react-icons/ci";
import { CiUser } from "react-icons/ci";
import { MdOutlineSpaceDashboard } from "react-icons/md";
import { IoPlayCircleOutline } from "react-icons/io5";
import { BiDoorOpen } from "react-icons/bi";
import { TbTargetArrow } from "react-icons/tb";
import { FaChartLine,FaSchool,FaCloud, FaGlobe  } from "react-icons/fa";
import { BsBriefcaseFill } from "react-icons/bs";

const CourseAccess = () => {
  return (
    <div className='background-color-how-training'>
    
   <div className='course-access-new'>
   <div className='summer-highlights-industrial'>
       
       <div className='training-highlights-section'>
       <FaGraduationCap   className='summer-highlights-icon2'/>
       <p className='training-highlights-desc'>Industry-Recognized Certification</p>
       </div>
      
      
      
       <div className='training-highlights-section'>
       <FaLaptopCode  className='summer-highlights-icon2'/>
       <p className='training-highlights-desc'>Real-World Hacking Challenges</p>
      
       </div>
       
 
       <div className='training-highlights-section'>
       <TbTargetArrow  className='summer-highlights-icon2'/>
       <p className='training-highlights-desc'>Placement opportunities</p>
       </div>
      
 
       <div className='training-highlights-section'>
       <FaChartLine  className='summer-highlights-icon2'/>
       <p className='training-highlights-desc'>Career Growth Acceleration</p>
       
       </div>
       <div className='training-highlights-section'>
       <FaSchool  className='summer-highlights-icon2'/>
       <p className='training-highlights-desc'>Online / Offline classes</p>
       
       </div>
       <div className='training-highlights-section'>
       <FaCloud  className='summer-highlights-icon2'/>
       <p className='training-highlights-desc'>Cloud-Based Hacking Exercises</p>
       
       </div>
       <div className='training-highlights-section'>
       <BsBriefcaseFill  className='summer-highlights-icon2'/>
       <p className='training-highlights-desc'>Job-Ready Skills</p>
       
       </div>
       <div className='training-highlights-section'>
       <FaGlobe color=''  className='summer-highlights-icon2'/>
       <p className='training-highlights-desc'>Global Recognition</p>
       
       </div>
       
 
     </div>
     <div className='summer-highlights-industrial'>
       
       <div className='training-highlights-section'>
       <FaGraduationCap   className='summer-highlights-icon2'/>
       <p className='training-highlights-desc'>Industry-Recognized Certification</p>
       </div>
      
      
      
       <div className='training-highlights-section'>
       <FaLaptopCode  className='summer-highlights-icon2'/>
       <p className='training-highlights-desc'>Real-World Hacking Challenges</p>
      
       </div>
       
 
       <div className='training-highlights-section'>
       <TbTargetArrow  className='summer-highlights-icon2'/>
       <p className='training-highlights-desc'>Placement opportunities</p>
       </div>
      
 
       <div className='training-highlights-section'>
       <FaChartLine  className='summer-highlights-icon2'/>
       <p className='training-highlights-desc'>Career Growth Acceleration</p>
       
       </div>
       <div className='training-highlights-section'>
       <FaSchool  className='summer-highlights-icon2'/>
       <p className='training-highlights-desc'>Online / Offline classes</p>
       
       </div>
       <div className='training-highlights-section'>
       <FaCloud  className='summer-highlights-icon2'/>
       <p className='training-highlights-desc'>Cloud-Based Hacking Exercises</p>
       
       </div>
       <div className='training-highlights-section'>
       <BsBriefcaseFill  className='summer-highlights-icon2'/>
       <p className='training-highlights-desc'>Job-Ready Skills</p>
       
       </div>
       <div className='training-highlights-section'>
       <FaGlobe color=''  className='summer-highlights-icon2'/>
       <p className='training-highlights-desc'>Global Recognition</p>
       
       </div>
       
 
     </div>
   </div>
    
    </div>
  )
}

export default CourseAccess
