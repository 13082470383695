import React,{useState,useEffect} from 'react'
import "./Welcome.css";
import Welcomepic from "../../../Assets/Landing page/Group 153.png"
import { FaArrowRight } from 'react-icons/fa';
import { NavLink,useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
const Welcome = () => {
  const userDetails=useSelector((state)=>state.user)
  const navigate=useNavigate();
  const[username,setusername]=useState("");
  const API_Key = process.env.REACT_APP_API_URL;

 const fetchip = async () => {


        
    try{
      const res = await fetch("https://httpbin.org/ip",{
          method:"GET",
          headers:{
            //mode: 'no-cors',
            //'Access-Control-Allow-Origin':"*",
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': true,
            
          },
          //credentials:"include",
          //withCredentials: true,
      });
      const data = await res.json();
    
      console.log(data,"PUblic ip")
    
  
      if(res.status !== 200 ){
          const error = new Error(res.error);
          throw error;
          
      }
      
   
  
  
  }catch(err){
    
   // navigate("/signin");
  
  } 
  
  }
  useEffect(() => {
    fetchip();
  },[] );
  return (
    <div>
    <div>
   <div className=' container-fluid welcomedasboard'>
   <div className='row justify-content-center'>
  
   <img src={Welcomepic} alt=""  style={{height:"60vh"}} className='img-fluid'/>
  

   </div>
   <div className='row justify-content-center'>
  
   <h1 className='welcomehead'>Welcome   {userDetails?.userinfo.name} !</h1>
  
  

   </div>
   <div className='row justify-content-center'>
  
   <p className='welcomepara'>You're on board now!</p>
  
  

   </div>
   <div className='row mt-3 justify-content-center'>
   <button className='RecentchalArrowbtn '>
    <NavLink to="/userhome"><FaArrowRight color="white" size={20}/></NavLink> 
   
   </button>
  
  

   </div>
 
   </div>
    </div>
    </div>
  )
}

export default Welcome