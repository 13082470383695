import React, { useState } from "react";

import FAQ from "./FAQ12";

export default function Faq1() {
    const [faqs, setFaqs] = useState([
        {
            question: "Who should enroll in this ACW Cybersecurity Certification Course?",
            answer:
                "This ACW Cybersecurity Certification course is ideal for security enthusiasts, professionals seeking advancement, current or aspiring cybersecurity professionals, graduates, diploma holders, beginners with an interest in cybersecurity, and IT professionals.",
            open: true
        },
        {

            question: "What skills will I gain from this program?",
            answer: "You will gain Advance skills in network security, web penetration testing, active directory training, cybersecurity networking, and other critical areas of cybersecurity."
        },
        {
            question: "What are the key features of this ACW Cybersecurity Certification Course",
            answer: "Key features of our  ACW Cybersecurity Certification Course include an updated curriculum, comprehensive instructional videos, real-world hands-on challenges, Advance content, exclusive access to dedicated exploitation machines, and affordable pricing.",
            open: false
        },
        {
            question:
                "Is there any hands-on practice included?",
            answer: "Yes in this ACW Cybersecurity Certification Course Program includes real-world hands-on challenges and exclusive access to dedicated exploitation machines for practical learning.",
            open: false
        },

        {
            question: "How can I enroll in the ACW Cybersecurity Certification Course?",
            answer:
                "You can enroll in the program by visiting our website and following the registration process. For more details, contact our support team.",
            open: false
        },
        {
            question: "What will be covered in the Active Directory training?",
            answer: "The Active Directory training covers Active Directory Enumeration, Local Privilege Escalation, Lateral Movement, Domain Privilege Escalation, Domain Persistence, Intra-Forest Attacks, Cross Forest Attacks, and various hands-on challenges related to active directory security.",
            open: false
        },
        {
            question:
                "Will I receive a certificate upon completion?",
            answer: "Yes, upon successful completion of this ACW Cybersecurity Certification Course, you will receive a certification that validates your expertise and skills in network security, web penetration testing, active directory, and more.",
            open: false
        },
        {
            question: "How does the course address web app pentesting?",
            answer:
                "The course includes comprehensive modules on web penetration testing and web app pentesting, covering HTTP basics and headers, injection vulnerabilities (such as SQL Injection and LDAP Injection), authentication and session management, cross-site scripting (XSS), HTTP request/response smuggling, access control, and misconfigurations, and Advance vulnerabilities like insecure deserialization and server-side request forgery (SSRF).",
            open: false
        },
        {
            question: " What topics are covered in network penetration testing?",
            answer: "The network penetration testing module includes SMB scanning, SNMP enumeration, MySQL enumeration, pentesting Telnet, SSH scanning, scanning CouchDB databases, and more, providing a thorough understanding of network security practices.",
            open: false
        },
        {
            question:
                "What kind of real-world hands-on challenges are included?",
            answer: "Each section of the course includes real-world hands-on challenges that allow you to apply theoretical knowledge to practical scenarios, covering areas such as network security, web penetration testing, and active directory exploits.Cybersecurity Certification Course, cybersecurity networking, penetration testing training, active directory, Online Cybersecurity course, network penetration testing, network security, web penetration testing, active directory training, web app pentesting,",
            open: false
        },

    ]);

    const toggleFAQ = index => {
        setFaqs(
            faqs.map((faq, i) => {
                if (i === index) {
                    faq.open = !faq.open;
                } else {
                    faq.open = false;
                }
                return faq;
            })
        );
    };

    return (
        <div className="container-fluid" >
            
            <div className="row justify-content-center">
            <div className="col-lg-11 col-md-11 col-11" id="faqbackground_coloe">
            <h2 className='faqheader'>(ACW) FAQ's</h2>
    

                {faqs.map((faq, index) => (
                    <div className="faqs">
                        <div className="col-md-12 col-lg-12 col-xl-8" key={index}>
                            <FAQ faq={faq} index={index} toggleFAQ={toggleFAQ} />
                        </div>
                    </div>
                ))}

            </div>
            </div>
        </div>

    );
}
