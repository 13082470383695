import { v4 as uuidv4 } from "uuid";
import Feature from "../../../Assets/Dashboard/Dashboard7.jpg"
import feature1 from "../../../Assets/Dashboard/hackmaster.png"
import feature2 from "../../../Assets/Dashboard/tieup.png"
import feature3 from "../../../Assets/Dashboard/MicrosoftTeams-image (3).png"
import feature4 from "../../../Assets/Dashboard/Dashboard6.jpg"
import feature5 from "../../../Assets/Dashboard/MicrosoftTeams-image (5).png"
import feature7 from "../../../Assets/Dashboard/Dashboard7.png"

const dataSlider = [

  {
    id: uuidv4(),
    title: "Lorem ipsum",
    subTitle: "Lorem",
    photo:feature1,
    
  },
  {
    id: uuidv4(),
    title: "Lorem ipsum",
    subTitle: "Lorem",
    photo:feature2,
    
  },
  {
    id: uuidv4(),
    title: "Lorem ipsum",
    subTitle: "Lorem",
    photo:feature5,
    
  },
 
  {
    id: uuidv4(),
    title: "Lorem ipsum",
    subTitle: "Lorem",
    photo:feature7,

  },
  {
    id: uuidv4(),
    title: "Lorem ipsum",
    subTitle: "Lorem",
    photo:feature3,

  },
  {
    id: uuidv4(),
    title: "Lorem ipsum",
    subTitle: "Lorem",
    photo:feature4,

  },
  
];

export default dataSlider;