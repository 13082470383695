
import React, { useEffect, useState } from 'react'
import { MdOutlineDescription } from "react-icons/md";
import { SiNintendogamecube } from "react-icons/si";
import { FaFacebook } from "react-icons/fa";
import { FaSquareTwitter } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa6";
import { FaSquareWhatsapp } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { IoIosArrowRoundForward } from "react-icons/io";
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { FaStar } from "react-icons/fa";
import { DummyData, overview2, overview, description, certificate, Review, courseInfo, CareersDemand } from "./dummydata"
import Faq1 from './Faq1';
import ImageData from "../../Assets/courses/acwp_1.mp4"
import { FaLinkedin } from "react-icons/fa";
import Footer from '../Footer/Footer';
import { VscPreview } from "react-icons/vsc";
import { GrOverview } from "react-icons/gr";
import CourseSlider from './CourseSlider';
import { IoIosCheckmark } from "react-icons/io";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { FaUserSecret } from "react-icons/fa6";
import { LiaUniversitySolid } from "react-icons/lia";
import { BiSolidOffer } from "react-icons/bi";
import { PiStudentBold } from "react-icons/pi";
import Feeimage from '../../Assets/Review/group-females.jpg'
import AwcCerti from '../../Assets/courses/ACW12.png'



import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { Helmet } from 'react-helmet';
import AcwformModel from './AcwformModel';
import TopCourse from './TopCourse';
import logo from '../../Assets/hplogo.png';



function AdvanceCyberWarfare() {
    const API_Key = process.env.REACT_APP_API_URL;
    const [loading, setLoading] = useState(true)
    const [item2, setItem2] = useState("A");
    const [username, setusername] = useState("");
    const [activeIndex, setActiveIndex] = useState(0);
    const [plans, setplans] = useState([]);

    const handleToggle = (index) => {
        setActiveIndex(activeIndex === index ? 0 : index);
    };


    const navigate = useNavigate();

    const [showForm, setShowForm] = useState(false);
    useEffect(() => {
        const timerId = setTimeout(() => {
            setShowForm(true);
        }, 5000);
        return () => clearTimeout(timerId);
    }, []);









    const getplans = async () => {
        let courseid = "66dbdea0b35516d17bdead56";
        try {
            const res = await fetch(`${API_Key}/getplanwithoutauth/` + courseid, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": `${API_Key}`,
                    "Access-Control-Allow-Credentials": "true",
                },
                credentials: "include",
            });
            const data = await res.json([]);

            const order = [1, 3, 2];
            data.sort(
                (a, b) => order.indexOf(a.validity) - order.indexOf(b.validity)
            );
            setplans(data);
            if (res.status !== 200) {
                const error = new Error(res.error);
                throw error;
            }
        } catch (err) {
            console.log(err);
        }
    };
    console.log(plans, "dinan data", plans[0]);
    useEffect(() => {
        getplans();
    }, []);


    const hasPlan = plans.length > 0 && plans[0]._id;

    return (
        <div className='hackback'>

            <Helmet>

                {/* Meta Title */}
                <title>Advance Cyber Warfare Program - Cybersecurity Certification</title>

                {/* Meta Description */}
                <meta name="description" content="Join our Advance Cyber Warfare Program for top Cybersecurity Certification Course. Get practical training, Master in cybersecurity networking, and many more." />

                {/* Meta Keywords */}
                <meta name="keywords" content="Cybersecurity Certification Course, cybersecurity networking, penetration testing training, active directory, Online Cybersecurity course, network penetration testing, network security, web penetration testing, active directory training, web app pentesting" />

                {/* Canonical URL */}
                <link rel="canonical" href="https://www.hackersprey.com/acw-cyber-security-certification-course" />

                {/* Locale and Geographical Meta Tags */}
                <meta name="locale" content="en_IN" />
                <meta name="geo.region" content="IN-CH" />
                <meta name="geo.region" content="IN" />
                <meta name="geo.placename" content="Chandigarh, India" />
                <meta name="geo.position" content="30.73265000183138, 76.80071801068048" />
                <meta name="ICBM" content="30.73265000183138, 76.80071801068048" />

                {/* Author and Copyright */}
                <meta name="author" content="Hackersprey" />
                <meta name="copyright" content="Hackersprey" />

                {/* Classification and Distribution */}
                <meta name="classification" content="cybersecurity courses online and offline" />
                <meta name="distribution" content="global" />

                {/* Robots and Crawlers */}
                <meta name="robots" content="all, follow" />
                <meta name="googlebot" content="all, follow" />
                <meta name="bingbot" content="all, follow" />
                <meta name="yahoo-slurp" content="all, follow" />
                <meta name="googlebot-image" content="all" />

                {/* Standard Meta Tags */}
                <meta name="identifier-URL" content="https://www.hackersprey.com/acw-cyber-security-certification-course" />
                <meta name="url" content="https://www.hackersprey.com/acw-cyber-security-certification-course" />

                {/* Open Graph Meta Tags */}
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Advance Cyber Warfare Program - Cybersecurity Certification Course" />
                <meta property="og:description" content="Cybersecurity Certification Course, cybersecurity networking, penetration testing training, active directory, Online Cybersecurity course, network penetration testing, network security, web penetration testing, active directory training, web app pentesting." />
                <meta property="og:url" content="https://www.hackersprey.com/acw-cyber-security-certification-course" />
                <meta property="og:image" content={logo} />
                <meta property="og:image:alt" content="Advance Cyber Warfare Program - Cybersecurity Certification Course" />
                <meta property="og:site_name" content="Hackersprey" />

                {/* Twitter Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@hackerspre30858" />
                <meta name="twitter:title" content="Advance Cyber Warfare Program - Cybersecurity Certification Course" />
                <meta name="twitter:description" content="Cybersecurity Certification Course, cybersecurity networking, penetration testing training, active directory, Online Cybersecurity course, network penetration testing, network security, web penetration testing, active directory training, web app pentesting." />
                <meta name="twitter:image" content={logo} />
                <meta name="twitter:url" content="https://www.hackersprey.com/acw-cyber-security-certification-course" />


            </Helmet>

            <div className="formcallbtn" onClick={() => setShowForm(true)}>
                <p>Submit us a query</p>
            </div>
            {showForm && <AcwformModel setShowForm={setShowForm} />}


            <div className='container'>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="embed">
                            <video height="auto" autoPlay loop muted>
                                <source src={ImageData} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>

                </div>

            </div>

            <div className='container'>
                <div className='row'>
                    <div className='col-lg-8 col-md-12' id='check_key_point'>
                        <div className='row justify-content-start mb-0'>
                            <div className='col-lg-12 col-md-12 col-12'>
                                <div className='row justify-content-start' id='tollers'>
                                    <div className='col-lg-3 col-md-6 col-6' onClick={() => setItem2("A")}>
                                        <div className={item2 === "A" ? "row justify-content-center mt-2 mb-5 p-4 newhavocbtn3" : "row justify-content-center mt-2 mb-5 p-4 newhavocbtn"}>
                                            <h1 className='enterpreyhead1'>
                                                <GrOverview className="iconhead" />
                                                overview
                                            </h1>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-6 col-6' onClick={() => setItem2("B")}>
                                        <div className={item2 === "B" ? "row justify-content-center mt-2 mb-5 p-4 newhavocbtn3" : "row justify-content-center mt-2 mb-5 p-4 newhavocbtn"}>
                                            <h1 className='enterpreyhead1'>
                                                <MdOutlineDescription className="iconhead" />
                                                description
                                            </h1>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-6 col-6' onClick={() => setItem2("C")}>
                                        <div className={item2 === "C" ? "row justify-content-center mt-2 mb-5 p-4 newhavocbtn3" : "row justify-content-center mt-2 mb-5 p-4 newhavocbtn"}>
                                            <h1 className='enterpreyhead1'>
                                                <SiNintendogamecube className="iconhead" />
                                                curriculum
                                            </h1>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-6 col-6' onClick={() => setItem2("D")}>
                                        <div className={item2 === "D" ? "row justify-content-center mt-2 mb-5 p-4 newhavocbtn3" : "row justify-content-center mt-2 mb-5 p-4 newhavocbtn"}>
                                            <h1 className='enterpreyhead1'>
                                                <VscPreview className="iconhead" />
                                                Review
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {item2 === "A" && (
                            <div className="container">
                                <div className="row newcareer">
                                    <div className="col-sm-12 col-lg-12 col-md-12" id='take-step'>
                                        {overview.map((course) => (
                                            <div key={course.id} className="course-card">
                                                <h5 className='titlecourse'>{course.title}</h5>
                                                <p
                                                    className='coursedata'
                                                    id="paragraphs"
                                                    dangerouslySetInnerHTML={{ __html: course.details }}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}


                        {item2 === "B" && (
                            <div className="container">
                                <div className="row newcareer">
                                    <div className="col-sm-12 col-lg-12 col-md-12">

                                        {description.map((course, idx) => (
                                            <div key={course.id} className="accordion-item">
                                                <div className="accordion-header" onClick={() => handleToggle(idx)}>
                                                    <h5 className='titlecourse'>{`${course.id}. ${course.title}`}</h5>
                                                    <button className="accordion-button" style={{ textAlign: "end" }}>
                                                        {activeIndex === idx ? (
                                                            <IoIosArrowUp style={{ color: "red", fontSize: "3rem" }} />
                                                        ) : (
                                                            <IoIosArrowDown style={{ color: "white", fontSize: "3rem" }} />
                                                        )}
                                                    </button>
                                                </div>
                                                <div className="accordion-body" style={{ display: activeIndex === idx ? 'block' : 'none' }}>
                                                    <p className='coursedata' id="paragraphs">{course.details}</p>
                                                    <ul className="list-unstyled">
                                                        {course.syllabus.map((item, index) => (
                                                            <p key={index} className="custom-bullet">

                                                                <strong style={{ fontWeight: '500', fontSize: '1.8rem', color: "#ee2963" }} id="paragraphs">{item.subtile}</strong>
                                                                <span style={{ fontSize: '1.5rem' }} id="subtitlePara">{item.content}</span>
                                                            </p>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>

                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}


                        {item2 === "C" && (
                            <div className="container">
                                <div className="row newcareer">
                                    <div className="col-sm-12 col-lg-12 col-md-12" id='take-step'>
                                        {overview2.map((course, idx) => (
                                            <div key={course.id} className="accordion-item">
                                                <div
                                                    className="accordion-header"
                                                    onClick={() => handleToggle(idx)}
                                                >
                                                    <h5 className='titlecourse'>{`${course.id}. ${course.title}`}</h5>
                                                    <button className="accordion-button" style={{ textAlign: "end", }}>
                                                        {activeIndex === idx ? (
                                                            <IoIosArrowUp style={{ color: "red", fontSize: "3rem" }} />
                                                        ) : (
                                                            <IoIosArrowDown style={{ color: "white", fontSize: "3rem" }} />
                                                        )}
                                                    </button>
                                                </div>
                                                <div
                                                    className="accordion-body"
                                                    style={{ display: activeIndex === idx ? 'block' : 'none' }}
                                                >
                                                    <p className='coursedata' id="paragraphs">{course.details}</p>
                                                    <ul className="list-unstyled">
                                                        {course.syllabus.map((item, index) => (
                                                            <p key={index} className="custom-bullet" id="paragraphs">

                                                                <strong style={{ fontWeight: '500', fontSize: '1.5rem', color: "#ee2963" }} >{item.subtile}</
                                                                strong> <span style={{ fontSize: '1.4rem', }} > {item.content}</span>
                                                            </p>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}


                        {item2 === "D" && (
                            <div className="container">
                                <div className="row ">
                                    <div className="col-12">
                                        <div className='row mt-1 ' id='custom-bullet'>
                                            {/* Top Rating and Progress Bar Section */}
                                            <div className="col-md-4 col-lg-4 topbox mt-5">
                                                <p>Top Rating</p>
                                                <div className="star-rating">
                                                    <FaStar className='icon_pics' />
                                                    <FaStar className='icon_pics' />
                                                    <FaStar className='icon_pics' />
                                                    <FaStar className='icon_pics' />
                                                    <FaStar className='icon_pics' />
                                                </div>
                                                <p>5 out of 5</p>
                                            </div>

                                            {/* Active User Section */}
                                            <div className="col-md-4 col-lg-7">
                                                <div className="row active_user mt-5">
                                                    <div className='col-6'>
                                                        <div className='d-flex flex-column align-items-center text-center'>
                                                            <p className='activeduser'><FaUserSecret /></p>
                                                            <span className="activedusers">Active Users <b id='numberid'>2600+</b></span>
                                                        </div>
                                                    </div>
                                                    <div className='col-6'>
                                                        <div className='d-flex flex-column align-items-center text-center'>
                                                            <p className='activeduser'><LiaUniversitySolid /></p>
                                                            <span className="activedusers">University Tie-ups <b id='numberid'>12+</b></span>
                                                        </div>
                                                    </div>
                                                    <div className='col-6'>
                                                        <div className='d-flex flex-column align-items-center text-center'>
                                                            <p className='activeduser'><BiSolidOffer /></p>
                                                            <span className="activedusers">Internships Offered <b id='numberid'>1100+</b></span>
                                                        </div>
                                                    </div>
                                                    <div className='col-6'>
                                                        <div className='d-flex flex-column align-items-center text-center'>
                                                            <p className='activeduser'><PiStudentBold /></p>
                                                            <span className="activedusers">Workshops & CTF Conducted <b id='numberid'>89+</b></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Scroller Section */}
                                        <div className="col-12 mt-4 newcareer" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                            {Review.map((lab, index) => (
                                                <div className="center" key={index}>
                                                    <div className="row">
                                                        <hr className="horizon" />
                                                        <div className="row col-12 mt-5">
                                                            <div className="image-capture">
                                                                <img src={lab.img} alt="Reviewer" />
                                                            </div>
                                                            <div className="col-5 user_data">
                                                                <p>{lab.Name}</p>
                                                                <span>
                                                                    {lab.star} {lab.star} {lab.star} {lab.star} {lab.star}
                                                                </span>
                                                            </div>
                                                            <div className="col-12 passageuser">
                                                                <p id="paragraphs">{lab.passage}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='col-lg-4 col-md-12' id='card_top'>
                        <div className="cardresec">
                            <img
                                src={Feeimage}
                                alt="Course"
                                className="card_image"
                            />
                            <div className="card-content">
                                <h1 className="pricese">
                                    ₹{hasPlan ? plans[0].price : '60,000'}
                                </h1>
                                <p className="seats-left">11 Seats left at this price</p>

                                {/* Conditionally render NavLink only if `_id` is available */}
                                {hasPlan ? (
                                    <NavLink to={`/checkout/${plans[0]._id}`}>
                                        <button className="buy-button">Buy Now</button>
                                    </NavLink>
                                ) : (
                                    <button className="buy-button" disabled>Buy Now</button>
                                )}

                                <hr className='horizontalline'></hr>
                                <div className="course-details mt-5">
                                    <h2>This Course included</h2>
                                    <p><IoCheckmarkCircleOutline className='iconpoint m-1' /> Real-World Hacking Challenges</p>
                                    <p><IoCheckmarkCircleOutline className='iconpoint m-1' /> Industry Recognized Certifications</p>
                                    <p><IoCheckmarkCircleOutline className='iconpoint m-1' /> Internship Opportunities</p>
                                    <p><IoCheckmarkCircleOutline className='iconpoint m-1' /> Hands-On Practical Labs</p>
                                </div>
                                <div className="training-info">
                                    <h2>2800+ Students Trained and Certified</h2>
                                    <hr className='horizontalline'></hr>
                                    <p>Join over 2,800+ students who have been trained and certified in our cybersecurity programs.</p>
                                </div>
                                <hr className='horizontalline'></hr>
                                <div className="share-course">
                                    <h2>Share this course</h2>
                                    <div className="social-icons">
                                        <a href='https://www.facebook.com/profile.php?id=100070945567430' target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
                                        <a href='https://x.com/hackerspre30858' target="_blank" rel="noopener noreferrer"> <FaSquareTwitter /></a>
                                        <a href='https://www.youtube.com/channel/UCJpuZSqPAKLKKzwp1XE9nRA' target="_blank" rel="noopener noreferrer"><FaYoutube /></a>
                                        <a href='https://www.instagram.com/hackersprey/' target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
                                        <a href='https://www.linkedin.com/company/hackersprey' target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
                                        <a href='https://api.whatsapp.com/send?phone=917888995975' target="_blank" rel="noopener noreferrer"><FaSquareWhatsapp /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>



                <div className='row container_info' id='hackcer'>
                    <CourseSlider />
                </div>



                <div className="row" id='hackcertificate'>
                    {certificate.map((section, index) => (
                        <div key={section.id} className="row align-items-center" id='contentimage' >
                            {!section.reverseOrder && (
                                <>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="physical_class mt-5" >
                                            <h2 id='physical_title'>{section.title}</h2>
                                            <p id="paragraphs" dangerouslySetInnerHTML={{ __html: section.description }} />
                                            {section.additionalDescription && (
                                                <>
                                                    <br />
                                                    <p
                                                        id="paragraphs"
                                                        dangerouslySetInnerHTML={{ __html: section.additionalDescription }}
                                                    />
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="physical_classimage" id="ceritificste">
                                            <img src={section.image} alt={section.title} className="img-fluid" />
                                        </div>
                                    </div>
                                </>
                            )}
                            {section.reverseOrder && (
                                <>
                                    <div className="col-lg-6 col-md-12" id=''>
                                        <div className="physical_classimage">
                                            <div className="physical_classimage12 p-5" >
                                                <img src={section.image} alt={section.title} className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12" id='hackreverse'>
                                        <div className="physical_class" id="ceritificate">
                                            <h2 className=''>{section.title} </h2>
                                            <p className="topsecurity" id="paragraphs">{section.description}</p>
                                            {section.additionalDescription && (
                                                <>
                                                    <br />
                                                    <p>{section.additionalDescription}</p>
                                                </>
                                            )}

                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    ))}
                </div>

            </div>
            <div className='container' id='hackcertificate'>
                <div className='row'>
                    {courseInfo.map((item, index) => (
                        <div key={index} className="awccerti mb-5" id='awccerti'>
                            <div className='row'>
                                <div className="col-lg-6 col-md-6  instrutorcourse">
                                    <div className='instrutortitle'>
                                        <h2 className="card-titles">{item.title}</h2>
                                    </div>
                                    <div className='pragraph'>
                                        <p className="cardtext">{item.description}</p>
                                    </div>

                                </div>
                                <div className='col-lg-6 col-md-6 mt-1'>
                                    <div className="physical_classacw mt-3" id="ceritificste">
                                        <img src={AwcCerti} className="img-fluid" />
                                    </div>
                                </div>

                            </div>
                        </div>
                    ))}
                </div>


            </div>

            
            <Faq1 />
            <Footer />
        </div>


    )
}

export default AdvanceCyberWarfare
