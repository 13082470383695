import React, { useEffect, useState } from "react";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import webpwnimg from "../../Assets/Entity/webpwnui/mainlogo.png";
import Record from "../../Assets/Landing page/arrow 2.png";
import email1 from "../../Assets/Dashboard/Mails/Screenshot 2023-06-12 at 9.30.18 AM.png";
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";
import { Faqs5 } from "../Home/Homedata";
import { Link, NavLink } from "react-router-dom";
import Record2 from "../../Assets/images/Activity.svg";
import Dot from "../../Assets/images/Activity.svg";
import "../Dashboard/Feedback/Common.css";
import { Helmet } from "react-helmet";
import Webpwncard from "./Webpwncard";
const getColorClassBasedOnValidity = (validity) => {
  switch (validity) {
    case 1:
      return "card-basic";
    case 2:
      return "card-vip";
    case 3:
      return "card-enterprise";
    // Add more cases for other validities as needed
    default:
      return "";
  }
};
const convertValidityToDays = (validity) => {
  switch (validity) {
    case 1:
      return 30;
    case 2:
      return 60;
    case 3:
      return 90;
    default:
      return 0; // You can set a default value or handle it as per your requirement
  }
};
const getColorbuttonClassBasedOnValidity = (validity) => {
  switch (validity) {
    case 1:
      return "basicplanbutton"; // Define your CSS class for yellow color
    case 2:
      return "vipplanbutton";
    case 3:
      return "enterpriseplanbutton"; // Define your CSS class for blue color
    default:
      return ""; // You can set a default value or handle it as per your requirement
  }
};
const getColorspanClassBasedOnValidity = (validity) => {
  switch (validity) {
    case 1:
      return "yellowcolor"; // Define your CSS class for yellow color
    case 2:
      return "bluecolor";
    case 3:
      return "greencolor"; // Define your CSS class for blue color
    default:
      return ""; // You can set a default value or handle it as per your requirement
  }
};
const API_Key = process.env.REACT_APP_API_URL;
const Webpwn = () => {
  const [clicked, setClicked] = useState(false);
  const [plans, setplans] = useState([]);

  const toggle = (index) => {
    if (clicked === index) {
      //if clicked question is already active, then close it
      return setClicked(null);
    }

    setClicked(index);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {}, []);
  const getplans = async () => {
    let courseid = "64dc4e396e36a3edf9682146";
    try {
      const res = await fetch(`${API_Key}/getplanwithoutauth/` + courseid, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": `${API_Key}`,
          "Access-Control-Allow-Credentials": "true",
        },
        credentials: "include",
      });
      const data = await res.json([]);
      console.log(data, "data");
      const order = [1, 3, 2];
      data.sort(
        (a, b) => order.indexOf(a.validity) - order.indexOf(b.validity)
      );
      setplans(data);
      if (res.status !== 200) {
        const error = new Error(res.error);
        throw error;
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getplans();
  }, []);

  const sortedPlans = [
    ...plans?.filter((plan) => plan.validity === 1),
    ...plans?.filter((plan) => plan.validity === 3),
    ...plans?.filter((plan) => plan.validity === 2),
  ];

  return (
    <div>
      <Helmet>
        <title>Hackersprey Professional Web Hacking Course – WEBPWN</title>
        <link
          rel="canonical"
          href="https://www.hackersprey.com/webpwn-professional-web-hacking-course"
        />
        <meta
          name="description"
          content="Hackersprey Professional Web Hacking Course | Cyber security online course | Cyber security online certification | Web hacking course | Cyber security course"
        />
        <meta name="author" content="Hackersprey" />
        <meta name="robots" content="index, follow" />
        <meta name="distribution" content="global" />
        <meta name="WebCrawlers" content="All" />
        <meta name="MSNBots" content="All, FOLLOW" />
        <meta name="Googlebot-Image" content="All" />
        <meta name="BINGBots" content="All, FOLLOW" />
        <meta name="YAHOOBots" content="All, FOLLOW" />
        <meta name="GoogleBots" content="All, FOLLOW" />
        <meta name="copyright" content="Hackersprey" />
        <meta
          name="keywords"
          content="Professional web hacking course, cyber security online course, cyber security online certification, web hacking course, cyber security course, cyber security course online "
        ></meta>
        <meta name="author" content="Hackersprey" />
        <meta
          name="identifier-URL"
          content="https://www.hackersprey.com/webpwn-professional-web-hacking-course"
        />
        <meta
          name="url"
          content="https://www.hackersprey.com/webpwn-professional-web-hacking-course"
        />
        <meta
          property="og:title"
          content=" cyber security online course, cyber security online certification"
        />
        <meta
          property="og:description"
          content=" Professional web hacking course,cyber security online course,cyber security online certification,web hacking course,cyber security course,cyber security course online"
        />
        <meta property="og:image" content={webpwnimg} />
        <meta
          property="og:url"
          content="https://www.hackersprey.com/webpwn-professional-web-hacking-course"
        />
        <meta
          name="instagram:title"
          content=" cyber security online course, cyber security online certification"
        />
       
 
      </Helmet>
      <section className="webpwnmaindiv">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-md-7 col-10 p-5">
              <p className="webpwn-head">
                Webpwn </p>
                <h1 className="webpwn-para">
                  A Professional Web Hacking Course
                  <span>
                    <hr style={{ background: "red", display: "inline" }}></hr>
                  </span>
                </h1>
             
              <p className="webpwn-para2">
                Introducing WEBPWN, a premier professional web hacking course.
                Specially designed for students, cybersecurity professionals,
                ethical hackers, and web security enthusiast. Program equips you
                with the skills to identify and mitigate critical web
                vulnerabilities. By covering the OWASP Top 10 and incorporating
                hands-on challenges, WEBPWN ensures a practical learning
                experience. Why wait! elevate your web hacking journey with this
                cyber security online course and earn a cyber security online
                certification from Hackersprey.{" "}
              </p>
            </div>
            <div className="col-lg-5 col-md-5 col-10 p-2">
              <img src={webpwnimg} alt="Professional web hacking course" />
            </div>
          </div>
        </div>
      </section>
      <section className="Dynamicbackground">
        <div className="container-fluid">
          <div className="row justify-content-center ">
            <h2 className="webpwnhead2 ">Why To Choose Webpwn ?</h2>
          </div>
          <div className=" row justify-content-center mt-2 ">
            <hr className="universityhr"></hr>
          </div>

          <div className="row justify-content-center ">
            <Webpwncard/>
            </div>

         
        </div>
      </section>
      <section className="Dynamicbackground">
        <div className="container mt-0">
          <div className="row">
            <div className="col-lg-7 col-md-7 col-12 mt-5 intershipdetaildiv2">
              <h3 className="whatwewilllearnhead">What you'll learn</h3>
              <hr className="coursehr mb-3"></hr>
              <h4 className="text-font-white mt-2">
                In our WEBPWN cyber security online course, you will learn -{" "}
              </h4>

              <ul className="intershipbullet mt-4" style={{ margin: "1rem" }}>
                <li className="internbulletpara">
                  <span>
                    <img
                      src={Record}
                      alt="cyber security online course"
                      style={{ height: "18px", marginRight: "2rem" }}
                    />
                  </span>
                  Understanding Hypertext Transfer Protocol
                </li>
                <li className="internbulletpara">
                  <span>
                    <img
                      src={Record}
                      alt="cyber security online certification"
                      style={{ height: "18px", marginRight: "2rem" }}
                    />
                  </span>
                  A Word About Session Management
                </li>
                <li className="internbulletpara">
                  <span>
                    <img
                      src={Record}
                      alt="Professional web hacking course"
                      style={{ height: "18px", marginRight: "2rem" }}
                    />
                  </span>
                  Document Object Model (DOM)
                </li>
                <li className="internbulletpara">
                  <span>
                    <img
                      src={Record}
                      alt="cyber security online course"
                      style={{ height: "18px", marginRight: "2rem" }}
                    />
                  </span>
                  Same Origin Policy (SOP)
                </li>
                <li className="internbulletpara">
                  <span>
                    <img
                      src={Record}
                      alt="cyber security online certification"
                      style={{ height: "18px", marginRight: "2rem" }}
                    />
                  </span>
                  Cross Origin Resource Sharing (CORS)
                </li>
                <li className="internbulletpara">
                  <span>
                    <img
                      src={Record}
                      alt="Professional web hacking course"
                      style={{ height: "18px", marginRight: "2rem" }}
                    />
                  </span>
                  A Word About Information Gathering
                </li>
                <li className="internbulletpara">
                  <span>
                    <img
                      src={Record}
                      alt="cyber security online course"
                      style={{ height: "18px", marginRight: "2rem" }}
                    />
                  </span>
                  Passive Information Gathering
                </li>
                <li className="internbulletpara">
                  <span>
                    <img
                      src={Record}
                      alt="cyber security online certification"
                      style={{ height: "18px", marginRight: "2rem" }}
                    />
                  </span>
                  A Word About Domain Name System
                </li>

                <li className="internbulletpara">
                  <span>
                    <img
                      src={Record}
                      alt="Professional web hacking course"
                      style={{ height: "18px", marginRight: "2rem" }}
                    />
                  </span>
                  DNS Enumeration
                </li>
                <li className="internbulletpara">
                  <span>
                    <img
                      src={Record}
                      alt="cyber security online course"
                      style={{ height: "18px", marginRight: "2rem" }}
                    />
                  </span>
                  SQL Injection
                </li>
                <li className="internbulletpara">
                  <span>
                    <img
                      src={Record}
                      alt="cyber security online certification"
                      style={{ height: "18px", marginRight: "2rem" }}
                    />
                  </span>
                  Cross Site Request Forgery (CSRF) 
                </li>
                <li className="internbulletpara">
                  <span>
                    <img
                      src={Record}
                      alt="Professional web hacking course"
                      style={{ height: "18px", marginRight: "2rem" }}
                    />
                  </span>
                  Cross Site Scripting (XSS) 
                </li>

                <li className="internbulletpara">
                  <span>
                    <img
                      src={Record}
                      alt="cyber security online course"
                      style={{ height: "18px", marginRight: "2rem" }}
                    />
                  </span>
                  Insecure Design
                </li>
                <li className="internbulletpara">
                  <span>
                    <img
                      src={Record}
                      alt="cyber security online certification"
                      style={{ height: "18px", marginRight: "2rem" }}
                    />
                  </span>
                  Server-Side Request Forgery (SSRF)
                </li>
                <li className="internbulletpara">
                  <span>
                    <img
                      src={Record}
                      alt="Professional web hacking course"
                      style={{ height: "18px", marginRight: "2rem" }}
                    />
                  </span>
                  Insecure Direct Object References (IDOR)
                </li>
                <li className="internbulletpara">
                  <span>
                    <img
                      src={Record}
                      alt="cyber security online course"
                      style={{ height: "18px", marginRight: "2rem" }}
                    />
                  </span>
                  XML External Entity Injection (XXE)
                </li>
                <li className="internbulletpara">
                  <span>
                    <img
                      src={Record}
                      alt="cyber security online certification"
                      style={{ height: "18px", marginRight: "2rem" }}
                    />
                  </span>
                  Insecure Deserialization{" "}
                </li>
              </ul>
            </div>
            <div className="col-lg-4 col-md-4 col-10 offset-1 mt-5 intershipdetaildiv2">
              <div className="row">
                <div className="course-feature">

               
                <h3 className="whatwewilllearnhead">Course Features</h3>
                  <hr className="coursehr mb-3"></hr>
                  </div>
                
              </div>
              <div className="row">
                <ul className="intershipbullet mt-1" style={{ margin: "1rem" }}>
                <h4 className="text-font-white mt-1 mb-1">
                  Discover the features of our cyber security online course-
                </h4>
                  <li className="internbulletpara">
                    <span>
                      <img
                        src={Record2}
                        alt="Professional web hacking course"
                        style={{ height: "18px", marginRight: "2rem" }}
                      />
                    </span>
                    Self Paced Learning
                  </li>

                  <li className="internbulletpara">
                    <span>
                      <img
                        src={Record2}
                        alt="cyber security online course"
                        style={{ height: "18px", marginRight: "2rem" }}
                      />
                    </span>
                    70 Real World Hacking Challenges
                  </li>
                  <li className="internbulletpara">
                    <span>
                      <img
                        src={Record2}
                        alt="cyber security online certification"
                        style={{ height: "18px", marginRight: "2rem" }}
                      />
                    </span>
                    Course Videos
                  </li>
                  <li className="internbulletpara">
                    <span>
                      <img
                        src={Record2}
                        alt="Professional web hacking course"
                        style={{ height: "18px", marginRight: "2rem" }}
                      />
                    </span>
                    Course Materials
                  </li>

                  <li className="internbulletpara">
                    <span>
                      <img
                        src={Record2}
                        alt="cyber security online course"
                        style={{ height: "18px", marginRight: "2rem" }}
                      />
                    </span>
                    Doubt Clearing Through Mail{" "}
                  </li>

                  <li className="internbulletpara">
                    <span>
                      <img
                        src={Record2}
                        alt="cyber security online certification"
                        style={{ height: "18px", marginRight: "2rem" }}
                      />
                    </span>
                    Access To PreyGround
                  </li>
                  <li className="internbulletpara">
                    <span>
                      <img
                        src={Record2}
                        alt="Professional web hacking course"
                        style={{ height: "18px", marginRight: "2rem" }}
                      />
                    </span>
                    Three Practice Labs{" "}
                  </li>
                  <li className="internbulletpara">
                    <span>
                      <img
                        src={Record2}
                        alt="cyber security online course"
                        style={{ height: "18px", marginRight: "2rem" }}
                      />
                    </span>
                    Access To Discord Channel
                  </li>
                  <li className="internbulletpara">
                    <span>
                      <img
                        src={Record2}
                        alt="cyber security online certification"
                        style={{ height: "18px", marginRight: "2rem" }}
                      />
                    </span>
                    One Exam Attempt{" "}
                  </li>
                  <li className="internbulletpara">
                    <span>
                      <img
                        src={Record2}
                        alt="Professional web hacking course"
                        style={{ height: "18px", marginRight: "2rem" }}
                      />
                    </span>
                    Participation Certificate
                  </li>
                  <li className="internbulletpara">
                    <span>
                      <img
                        src={Record2}
                        alt="cyber security online course"
                        style={{ height: "18px", marginRight: "2rem" }}
                      />
                    </span>
                    Global Ranking
                  </li>
                  {/* <li className='internbulletpara'><span><img src={Record2} style={{height:"18px",marginRight:"2rem"}} /></span>Global Ranking Through Leaderboard</li>*/}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-0">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12 mt-5 intershipdetaildiv2">
              <h3 className="whatwewilllearnhead">Requirements</h3>
              <hr className="coursehr"></hr>
              <p className="text-font-white2">
                Get started with our cyber security course by ensuring you meet
                the following requirements -{" "}
              </p>
              <ul className="intershipbullet mt-3" style={{ margin: "1rem" }}>
                <li className="internbulletpara">
                  <span>
                    <img
                      src={Record}
                      alt="cyber security online certification"
                      style={{ height: "18px", marginRight: "2rem" }}
                    />
                  </span>
                  System with minimum i5 processor or better
                </li>
                <li className="internbulletpara">
                  <span>
                    <img
                      src={Record}
                      alt="Professional web hacking course"
                      style={{ height: "18px", marginRight: "2rem" }}
                    />
                  </span>
                  At least 8 GB of RAM
                </li>
                <li className="internbulletpara">
                  <span>
                    <img
                      src={Record}
                      alt="cyber security online course"
                      style={{ height: "18px", marginRight: "2rem" }}
                    />
                  </span>
                  Basic knowledge of Linux & Javascript
                </li>
                <li className="internbulletpara">
                  <span>
                    <img
                      src={Record}
                      alt="cyber security online certification"
                      style={{ height: "18px", marginRight: "2rem" }}
                    />
                  </span>
                  Working Internet Connection
                </li>
                <li className="internbulletpara">
                  <span>
                    <img
                      src={Record}
                      alt="Professional web hacking course"
                      style={{ height: "18px", marginRight: "2rem" }}
                    />
                  </span>
                  Dedication to Learn
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="webpwnmaindiv2">
        <div className="container">
          <div className="row justify-content-center ">
            <h2 className="webpwnhead10 ">Choose the right plan for you</h2>
          </div>
          <div className=" row justify-content-center mt-2 ">
            <hr className="universityhr"></hr>
          </div>
          <br></br>
          <br></br>
          <br></br>

          <div className="row justify-content-center p-1  ">
            {sortedPlans?.map((plans, id) => (
              <div className="col-lg-3 col-10 m-2" key={id}>
                <div
                  className={`p-3 ${getColorClassBasedOnValidity(
                    plans?.validity
                  )}`}
                >
                  <div className="row justify-content-center">
                    <div className="col-lg-5 col mt-3">
                      {" "}
                      <img
                        src={plans?.image}
                        alt="cyber security online course"
                      />
                    </div>
                    <div className="col-lg-7 col pt-3 mt-3">
                      {" "}
                      <h2 className="subplanhead">{plans.name}</h2>
                      <h6 className="subplanpara ">
                        <span
                          className={`btn  ${getColorspanClassBasedOnValidity(
                            plans?.validity
                          )}`}
                        >
                          ({convertValidityToDays(plans.validity)} days)
                        </span>
                      </h6>
                    </div>
                  </div>
                  <div className="row  justify-content-center mt-2">
                    <div className="col-lg-12">
                      <hr className="subplanhr"></hr>
                    </div>
                  </div>
                  <div className="row  justify-content-center mt-1">
                    <div className="col-lg-12 col-12 ">
                      {plans.features &&
                        plans.features.map((feature, id) => (
                          <ul>
                            <li className="subplanfeaturepara">
                              <span className="mr-3">
                                {" "}
                                <img
                                  src={Dot}
                                  alt="cyber security online certification"
                                  style={{ height: "1.5rem" }}
                                />
                              </span>
                              {feature.feature}
                            </li>
                          </ul>
                        ))}
                    </div>
                  </div>
                  <div className="row justify-content-center ">
                    <div className="col-lg-8 mb-5">
                      <NavLink to={"/checkout/" + plans._id}>
                        <button
                          className={`btn  ${getColorbuttonClassBasedOnValidity(
                            plans.validity
                          )}`}
                        >
                          RS.{plans.price}/-
                        </button>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="Dynamicbackground">
        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12 mt-5 intershipdetaildiv2">
              <div
                className="row justify-content-center"
                style={{ position: "relative" }}
              >
                <h2 className="justify-content-center mainfaqhead">FAQ'S</h2>

                <div className="row mt-5">
                  {" "}
                  <hr className="faqhr"></hr>
                </div>
              </div>
              <div className="row justify-content-center ">
                <div className="col-lg-10 col-md-10 col mt-3">
                  <p className="mainfaqpara">
                    Please note that these FAQs provide general information
                    regarding this professional web hacking course. For more
                    detailed information and specific queries, it is recommended
                    to reach out to us at&nbsp;
                    <img
                      src={email1}
                      alt="Professional web hacking course"
                      style={{
                        height: "30px",
                        marginLeft: ".3rem",
                        marginTop: "-1px",
                      }}
                    />
                  </p>
                </div>
              </div>

              <div className="row justify-content-center mt-5">
                <div className="col-lg-10 col-md-10 col-10">
                  {Faqs5.map((faq, index) => (
                    <div className="row mt-5 mb-5" key={index} id="faqcards">
                      <div
                        className="col-lg-10 col-md-10 col-8"
                        onClick={() => toggle(index)}
                        key={index}
                        style={{ cursor: "pointer" }}
                      >
                        <p className="faqcardshead">{faq.question}</p>
                      </div>
                      <div
                        className="col-lg-1 col-md-1 col-1 offset-1"
                        onClick={() => toggle(index)}
                        key={index}
                        style={{ cursor: "pointer" }}
                      >
                        {clicked === index ? (
                          <AiOutlineMinusCircle color="#e32f1c" size={30} />
                        ) : (
                          <AiOutlinePlusCircle color="#e32f1c" size={30} />
                        )}
                      </div>
                      {clicked === index ? (
                        <>
                          {" "}
                          <div className="container mt-1 p-3">
                            <div className="row">
                              <div className="col-lg-11 col-md-11 col-11 ">
                                <p className="faqansercard">{faq.answer}</p>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Webpwn;
