import React from "react";
import "./IndustrialTraining.css";
import { motion } from "framer-motion";

const benefits = [
  {
    subtitle: "01",
    title: "Industry Recognised Cybersecurity Certification",
    description: "Upon completing our 6 Months Industrial Training in Cybersecurity, you’ll receive an industry-recognized certification. This credential from our Cybersecurity Training in Chandigarh validates your skills and boosts your employability.",
  },
  {
    subtitle: "02",
    title: "Enhanced Learning Resources",
    description: "Our Cybersecurity Industrial Training in Chandigarh offers videos and tools to enrich your learning experience. These resources complement your 6 Months Industrial Training in Cybersecurity, ensuring comprehensive knowledge acquisition.",
  },
  {
    subtitle: "03",
    title: "Cutting-Edge Curriculum",
    description: "The 6 Months Industrial Training in Cybersecurity at Hackersprey features an updated curriculum, reflecting the latest advancements in technology. Our Cybersecurity Training in Chandigarh keeps you ahead with the newest industry developments.",
  },
  {
    subtitle: "04",
    title: "Real-World Hacking Challenges",
    description: "Engage in real-world hacking challenges as part of our Cybersecurity Industrial Training in Chandigarh. This hands-on experience is integral to this 6 Months Industrial Training in Cybersecurity, preparing you for real-world scenarios.",
  },
  {
    subtitle: "05",
    title: "Practice labs",
    description: "Our Cybersecurity Training in Chandigarh includes practice labs that allow you to apply theoretical knowledge practically. These labs are a core component of the 6 Months Industrial Training in Cybersecurity, fostering hands-on learning.",
  },
  {
    subtitle: "06",
    title: "Career Growth Acceleration",
    description: "Hackersprey 6 Months Industrial Training in Cybersecurity is designed to accelerate your career growth. Our Cybersecurity Industrial Training in Chandigarh equips you with the skills needed to advance rapidly in the cybersecurity field.",
  },
  {
    subtitle: "07",
    title: "Personalized Mentorship",
    description: "Receive personalized mentorship throughout our Cybersecurity Industrial Training in Chandigarh. Our 6 Months Industrial Training in Cybersecurity ensures you have the guidance needed to succeed in your cybersecurity career.",
  },
  {
    subtitle: "08",
    title: "Cloud-Based Hacking Exercises",
    description: "Gain cloud access to hacking challenges as part of our Cybersecurity Training in Chandigarh. This feature of our 6 Months Industrial Training in Cybersecurity provides flexibility and practical experience in a modern learning environment.",
  },
  {
    subtitle: "09",
    title: "Job-Ready Skills Development",
    description: "Develop job-ready skills with Hackersprey 6 Months Industrial Training in Cybersecurity. Our Cybersecurity Industrial Training in Chandigarh ensures you acquire the competencies necessary for a successful career in cybersecurity.",
  },
];

const IndustrialBenefits = () => {
  return (
    <div  className="industrial-benefits-container int-observe ind-highlights"  data-name="2">
      <motion.div  initial={{ opacity: 0.1,y:50 }}
      viewport={{once:true}}
          whileInView={{ opacity: 1,y:0 }}
          transition={{ duration: 0.7 }} className="heading-container">
        
        <p className="heading">
        Benefits of Doing This 6 Months Industrial Training In Cybersecurity
        </p>
      </motion.div>
      <motion.p initial={{ opacity: 0.1,y:50 }}
          whileInView={{ opacity: 1,y:0 }}
          viewport={{once:true}}
          transition={{ duration: 0.7 }} className="description">
        Our 6 Months Industrial Training in Cybersecurity, based in Chandigarh
        and available also offline offers significant career advantages. You'll
        gain hands-on experience through practical lab sessions and real-world
        hacking scenarios, guided by industry experts. This Cybersecurity
        Training In Chandigarh covers everything from basic to advanced
        cybersecurity techniques, earning you a highly regarded Cybersecurity
        certification. Additionally, this 6 Months Industrial Training In
        Cybersecurity provides valuable placement opportunities, giving you a
        competitive edge in the rapidly growing cybersecurity field. Engaging in
        our Cybersecurity Industrial Training In Chandigarh offers numerous
        benefits.
      </motion.p>
      <motion.div initial={{ opacity: 0.1,y:50 }}
      viewport={{once:true}}
          whileInView={{ opacity: 1,y:0 }}
          transition={{ duration: 0.7 }} className="card-container">
        {benefits.map((x,index) => (
          <div className="card">
            <div className={`${index%2===0 ? 'bg-flip-purple' :'bg-flip-red'} card__content`}>
              <div className="card__front">
                <p className="card__subtitle">{x.subtitle}</p>
                <h3 className="card__title">{x.title}</h3>
              </div>

              <div className={`${index%2===0 ? 'bg-flip-red' :'bg-flip-purple'} card__back`}>
                <p className="card__body">
                {x.description}
                </p>
              </div>
            </div>
          </div>
        ))}
      </motion.div>
    </div>
  );
};

export default IndustrialBenefits;
