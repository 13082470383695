import React from 'react'
import { GrFormCheckmark } from "react-icons/gr";
import IMAGES from '../../Assets/summer.png'
const SummerTrainingCertificate = () => {
    return (
        <div className='training-highlight-section3'>
             <div className='training-highlight-section-right'> 
        <img src={IMAGES} className='certi-image'/>
               
        </div>
        <div className='training-highlight-section-left'> 
        <h3 className='training-heading'>Industry Recognized Cybersecurity Certification</h3>
        <p className='training-desc'>Upon completing the summer training in cybersecurity with a Project-Based Internship at Hackersprey, participants earn a prestigious certification. This credential acknowledges their expertise and dedication to cybersecurity, recognized widely by industry professionals and employers. Covering critical areas like network security, cryptography, ethical hacking, web application security, mobile security, and cloud security, this summer internship in cyber security certification enhances employability, unlocks career opportunities, and underscores commitment to ongoing professional development. It validates practical skills acquired through rigorous, industry-aligned training, giving graduates a competitive edge in the cybersecurity field.
    </p>
        </div>
       
    
    </div>
      )
}

export default SummerTrainingCertificate
