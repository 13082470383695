import React, { useState, useEffect } from 'react'
import "./Commonpages.css";

import { Helmet } from "react-helmet"
import { Faqs } from '../Home/Homedata';

import Navbar from '../Navbar/Navbar';

import Footer from '../Footer/Footer';
import { RiArrowDropRightLine } from "react-icons/ri";









const Faq = () => {
  const [clicked, setClicked] = useState(false);

  const toggle = index => {
    if (clicked === index) {
      //if clicked question is already active, then close it
      return setClicked(null);
    }

    setClicked(index);
  };
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <Helmet>
        <title>Frequently Asked Questions | Hackers Prey</title>

        <meta name="description" content="Check out our all Hacking Online Course Frequently Asked Questions on this page. Still, if you have any concerns or queries. Feel free to contact us now." />
      </Helmet>
      <section className="commonbackground" >

        <div className="container" id="Heading_container">
          <div className="row justify-content-center">
            <br></br>
            <br></br>
            <br></br>
            <span className="Faq_span">FAQS</span>
            <h2 className="Faq_head">FAQs</h2>




          </div>


        </div>
      </section>
      <section className="content_faq">
        <div className="container mt-4 p-3">

          {Faqs.map((faq, index) => (<div className='row justify-content-center  mt-5' key={index}>
            <div className='col-lg-10 col-md-10 col-10' id='faqcard' onClick={() => toggle(index)} key={index} style={{ cursor: "pointer" }} >
              <div className='row'>
                <div className='col-lg-8 col-9'> <h1 className='Faq_question'>{faq.question}</h1></div>
                <div className='col-lg-1 col-1  offset-3'><div className='Labbtn'  >
                  <RiArrowDropRightLine className='span' color='white' size={20} style={{ marginTop: ".2rem" }} />
                </div></div>
              </div>

            </div>
            {clicked === index ? (
              <> <div className="container mt-1 p-3">
                <div className='row'>
                  <div className='col-lg-10 col-md-10 col-10 offset-1'>
                    <p className='faqanser'>{faq.answer}</p>
                  </div>
                </div>
              </div>





              </>) : null}
          </div>

          ))}
        </div>
        <Footer />
      </section>
    </div>

  )
}

export default Faq
