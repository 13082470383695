import React,{useState} from 'react'
import { ImCross } from 'react-icons/im';
import Submitflagsucess from "./Submitflagsucess";
import Loading from './Loading';
import Wentwrong from './Wentwrong';
import Wrongotp from './Wrongotp';
import { useForm } from "react-hook-form";
import { useNavigate,useLocation } from 'react-router-dom';
import Toomanyattempts from './Toomanyattempts';
import { addUserDetails } from '../../../redux/ActionCreators';
import { useDispatch } from 'react-redux';
import {jwtDecode} from 'jwt-decode';


const Twofactorloginforui = ({setOpenModal,email}) => {
    const navigate=useNavigate();
    const disptach=useDispatch()

    const[modalopen,setmodalopen]=useState(false);
    const[modalopen2,setmodalopen2]=useState(false);
    const[modalopen3,setmodalopen3]=useState(false);
    const[modalopen4,setmodalopen4]=useState(false);
    const[wrongotperror,setwrongotperror]=useState(false);
    const [errormsg,seterrmsg]=useState("")
    const[otp,setotp]=useState("")

    const API_Key = process.env.REACT_APP_API_URL;
    const handleSubmit = event => {
        event.preventDefault();
        setmodalopen(true);
        
      
       
        const res =  fetch(`${API_Key}/universities_token_verify`, {
            method: "POST",
            body: JSON.stringify({
                email:email,asign_token:otp
            }),
            headers: {
              "Content-Type": "application/json",
              mode: 'cors',
              'Access-Control-Allow-Origin':`${API_Key}`,
              'Content-Type': 'application/json',
              'Access-Control-Allow-Credentials': true,
            },
            credentials:"include",
           
          }).then(async(res) => {
            
             if(res.status===200){
              const resp=await res.json()
              const user={
                email:resp.email,
                name:resp.name,
                universityname:resp.university_name

              }
              console.log("hi2")
              disptach(addUserDetails(user))








     navigate("/welcome")
        
          setOpenModal(true);
            
          
              
             }else if(res.status===404){
setwrongotperror(true);
seterrmsg("Wrong License")
setmodalopen(false);

             }
             else if(res.status===403){
                setwrongotperror(true);
                seterrmsg("License is already in use")
                setmodalopen(false);
                
                             }
             
             else if(res.status===429){
              setmodalopen4(true);
               setmodalopen(false);
              
             }


         
          
      
        }
          ).catch((err)=>{
            setmodalopen3(true);
            setTimeout(() => {
              setOpenModal(false)
              },3000);
                      })}
  return (
    <div >
    {modalopen && <Loading setOpenModal={setmodalopen} />}
    {modalopen2 && <Wrongotp setOpenModal={setmodalopen2} />}
    {modalopen3 && <Wentwrong setOpenModal={setmodalopen3} />}
    {modalopen4 && <Toomanyattempts setOpenModal={setmodalopen4} />}
 
    <section className="modalBackground justify-content-center"  >
    <div className="container  justify-content-center" >
    
      <div className="row justify-content-center">
      
        <div className="col-lg-8 col-md-10 col-10  modalContainer" >
        
<h2 className='text-center alertmainhead2'>Login with License   <div className='row justify-content-center'><span><hr className='text-center alertcommonhr'></hr></span></div></h2>  


<div className='row justify-content-center'>

<div className='col-lg-10 col-md-6  col-9 '>
<p className='resetpara'>License has been sent to your registered email address</p>

<div className='row justify-content-center mt-2'>
<form  className='col-lg-8' onSubmit={handleSubmit} >
<div className="form-input justify-content-center ">
    
<div className="col-12 justify-content-center ">

<input
  name="otp"
  type="password"
  className="form-control"
  placeholder="Enter Your License"
  onChange={(e) => {
    setotp(e.target.value);
}}

                       />
    

 {wrongotperror ?   <small className="errormes ml-2">{errormsg}</small>:null}

 </div></div>
 <div className='row justify-content-center mt-5'>
<button className=' btn saveratebtn ' type='submit' > SUBMIT</button>
</div>
</form>
</div>

</div>

</div>

        </div></div></div></section></div>
  )
}

export default Twofactorloginforui