import React, { useState,useEffect} from 'react'
import { ImCross } from 'react-icons/im';
import { useNavigate } from 'react-router-dom';
import Wentwrong from './Wentwrong';
import Loading from './Loading';
import { useForm } from "react-hook-form";
import Inviteafriendsuccesfuly from "./Inviteafriendsuccesfuly"
import Cantsendmail from './Cantsendmail';
import { faL } from '@fortawesome/free-solid-svg-icons';
const Invitefriend = ({setOpenModal}) => {
  const[modalOpen,setModalOpen]=useState(false);
  const[modalOpen2,setModalOpen2]=useState(false);
  const[modalOpen3,setModalOpen3]=useState(false);
  const[modalOpen4,setModalOpen4]=useState(false);
    const navigate=useNavigate();
  
  
    const {register,handleSubmit,formState: { errors },reset,watch,trigger,} = useForm();
  const[useremail,setuseremail]=useState("")
  const fetchuser = async () => {
    setModalOpen3(true)
    
            
        try{
          const res = await fetch(`${API_Key}/profiledata`,{
              method:"GET",
              headers:{
                mode: 'cors',
                'Access-Control-Allow-Origin':`${API_Key}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': true,
              },
              credentials:"include"
             
          });
          const data = await res.json();
        
          setuseremail(data.email);

        
      
          if(res.status !== 200 ){
              const error = new Error(res.error);
              throw error;
              
          }else{
            setModalOpen3(false)
          }
          
       
      
      
      }catch(err){
        console.log(err);
        navigate("/signin");
      
      } 
      
      }
      useEffect(() => {
        fetchuser();
      },[ ] );
    
    const API_Key = process.env.REACT_APP_API_URL;
    const onSubmit = (data)=>{
      setModalOpen3(true);
        //e.preventDefault();
        
        //const data = { friendmail};
        fetch(`${API_Key}/inviteaFriend`, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              mode: 'cors',
              'Access-Control-Allow-Origin':`${API_Key}`,
              'Content-Type': 'application/json',
              'Access-Control-Allow-Credentials': true,
            },
            credentials:"include",
            body: JSON.stringify(data),
          }).then((res) => {
 
            if(res.status===200){
          setModalOpen(true);
          setModalOpen3(false);
          setTimeout(() => {
            setOpenModal(false);
          },3000);
          const noti={message:"Your Invitation has been  Sent to Your Friend Successfully ",category:"Settings",querytype:"Invitation Sent Successfully"}

          fetch(`${API_Key}/notification`, {
            method: 'POST',
            headers:{
              Accept: "application/json",
              mode: 'cors',
              'Access-Control-Allow-Origin':`${API_Key}`,
            'Content-type': 'application/json',
              
              'Access-Control-Allow-Credentials': true,
            },
            credentials:"include",
            body: JSON.stringify(noti),
          })
          
            }else if(res.status===400){
              setModalOpen4(true);
              //setModalOpen3(false)
            }
        
        
          }).catch((error) => {
            console.error('Error:', error);
            setModalOpen2(true);
        
          });        

    }
  return (
    <div>
    {modalOpen && (
      <Inviteafriendsuccesfuly
       
        setOpenModal={setModalOpen}
      />
    )}
    {modalOpen2 && (
      <Wentwrong
       
        setOpenModal={setModalOpen2}
      />
    )}
    {modalOpen3 && (
      <Loading
       
        setOpenModal={setModalOpen3}
      />
    )}
    {modalOpen4 && (
      <Cantsendmail
       
        setOpenModal={setModalOpen4}
      />
    )}
    <section className="modalBackground justify-content-center" 
   
  >
    <div className="container  justify-content-center">
    
      <div className="row justify-content-center ">
      
        <div className="col-lg-8 col-md-10 col-10  modalContainer" >
        <div className='mt-5 crossbtnarrow '>
        <button
          onClick={() => {
            setOpenModal(false);
          }}
        >
        <div className='text-center'><ImCross style={{height:".6rem",marginLeft:"-1.9rem",marginTop:".5rem"}}/></div> 
        </button>
      </div>
<h2 className='text-center alertmainhead2'>Invite a Friend! <div className='row justify-content-center'><span><hr className='text-center alertcommonhr'></hr></span></div></h2>  


<div className='row justify-content-center'>

<div className='col-lg-10 col-md-6  col-9 '>
<p className='resetpara'> Share, boost, and invite a friend to the preyground and ask them to test their skills! We have a lot waiting for everyone!</p>

<div className='row justify-content-center mt-2'>
<form  className='col-lg-12' onSubmit={handleSubmit(onSubmit)}>
<div className="form-input ">
    
<div className="col">

<input
  name="friendmail"
  type="text"
  autoComplete='off'
  className={`form-control   ${errors.friendmail && "invalid"}`}
  placeholder="johndoe@gmail.com"
  {...register("friendmail", { required: "Email is Required" ,
  pattern: {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    message: "Invalid email address",
  },
  validate:(value)=> value!==useremail ||"Oops! You can’t send invitations to your own email account. Please enter another existing email account!",
})}
  onKeyUp={() => {
    trigger("email");
  }}/>

 {errors.friendmail && (
  <small className="errormes ml-2 ">{errors.friendmail.message}</small>
)}
 </div></div>
 <div className='row justify-content-center'>
<button className=' btn saveratebtn mt-5' > Invite</button>
</div>
</form>
</div>

</div>

</div>

        </div></div></div></section></div>
  )
}

export default Invitefriend