import React from 'react'
import "./Sorry.css"

import {FaArrowRight} from "react-icons/fa";
import {ImCross} from "react-icons/im";
import { NavLink,useNavigate } from 'react-router-dom';

const Wentwrong = ({setOpenModal}) => {
  const navigate=useNavigate();
  const redirection=()=>{
    setOpenModal(false);
    navigate("/")
  }
  return (
    <div>
    <section className="modalBackground3 justify-content-center" onClick={redirection}>
    <div className="container  justify-content-center">
    
      <div className="row justify-content-center " >
      
        <div className="col-lg-7 col-md-10 col-10 p-2" id='somethingwrongdiv'>
        
      <div className="titleCloseBtn">
        
           
          </div>
          <div className='row p-5'>
         
        
          <div className='col-lg-12 col-md-5 col   '>
          <div className='mt-2 crossbtnarrow'>
          <button
            onClick={() => {
              setOpenModal(false);
            }}
          >
          <div className='text-center'><ImCross style={{height:".6rem",marginLeft:"-1.9rem",marginTop:".5rem"}}/></div> 
          </button>
        </div>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <div className='justify-content-center '>
        <div className='oopsdiv2'>
        <p>Wait, Something went wrong. There may be some technical issues. Try again later. </p>
        </div>
        </div>
        
     
   
         <div className='row justify-content-center mt-5'>
        <NavLink to="/" style={{textDecoration:"none"}}><button className='wentwrnbtn' onClick={() => {
          setOpenModal(false);
        }}>
         <FaArrowRight color="white" size={20}/>
         
         </button></NavLink> 
         </div>
          </div></div>
         
         
           
            
         
        
      </div>
    </div>
    </div> 
  </section>
    </div>
  )
}

export default Wentwrong