import React,{useState,useEffect} from 'react'
import {useParams} from 'react-router-dom'
import axios from 'axios';
import Loading from '../Dashboard/Modals/Loading';
import Wentwrong from '../Dashboard/Modals/Wentwrong';
import Acoountactivatesuccesffuly from '../Dashboard/Modals/Acoountactivatesuccesffuly';
import Emailalready from '../Dashboard/Modals/Emailalready';
import { useNavigate } from 'react-router-dom';
const Activateemail = () => {
  const[modalopen,setModalOpen]=useState(false);
  const[modalopen2,setModalOpen2]=useState(false);
  const[modalopen3,setModalOpen3]=useState(false);
  const[modalopen4,setModalOpen4]=useState(false);
const navigate=useNavigate();
    const {token} = useParams();
    const API_Key = process.env.REACT_APP_API_URL;
    useEffect(() => {
      setModalOpen(true);
      if(token){
          const activationEmail = async () => {
              try {
                  const res = await axios.post(`${API_Key}/activeregister2`, {token})
          if(res.status===200){
          
            navigate("/success")
            setModalOpen(false);
          
          }else if(res.status===422){
            setModalOpen4(true);
            setModalOpen(false);
          }else{
            setModalOpen4(true);
            setModalOpen(false);
          }
              } catch (err) {
               
                setModalOpen3(true)
                setModalOpen(false);
                 
              }
          }
          activationEmail()
      }
  },[token])
  return (
    <div>
    {modalopen && <Loading setOpenModal={setModalOpen}/> }  
    {modalopen2 && <Acoountactivatesuccesffuly setOpenModal={setModalOpen2}/> }  
    {modalopen3 && <Wentwrong setOpenModal={setModalOpen3}/> } 
    {modalopen4 && <Emailalready setOpenModal={setModalOpen4}/> }   
    </div>
  )
}

export default Activateemail