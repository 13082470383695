import React,{useEffect} from 'react'
import "./Commonpages.css";

import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import Record from "../../Assets/images/Activity.svg";
import Australia from "../../Assets/flags/australia.png"
import {Helmet} from "react-helmet";
import email1 from "../../Assets/Dashboard/Mails/Screenshot 2023-06-12 at 9.29.39 AM.png"
import email2 from "../../Assets/Dashboard/Mails/Screenshot 2023-06-12 at 9.30.08 AM.png"

const Refundpolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    

    return (
        <div>
<Helmet>
<title>Privacy Policy | Hackers Prey</title>

<meta name="description" content="Read out this page to know about the Hackers Prey privacy policy and to know about how we collect and use data. Details information about our legal details."></meta>
</Helmet>

            <section className="commonbackground " >
    
            
          <br></br>     <br></br>
          <div className="container mt-5" id="Heading_container">
              <div className="row justify-content-center">
             
              <span className="Privacy_span">PRIVACY POLICY</span>
                  <h2 className="Privacy_head">PRIVACY POLICY</h2>
                  <hr className="commonpagehr"></hr>
               
            
              </div>
        
             
          </div>
          </section>
          <section className="content_faq">
          

        <div className="container-fluid">
             <div className="row d-flex flex-column justify-content-center">
                 <div className="col-lg-10 col-md-10 col-12 offset-md-1 mt-5">
                 <p className="privacypara"> The meaning of Privacy Policy: Privacy Policy is an internal statement that governs the handling of personal information. It is a manual on the collection and the use of the data, as well as any specific rights the data subjects may have.

</p>
 <br>
            
            </br>
            <br>
            
            </br>
            <br>
            
            </br>
    <p className="privacypara">The privacy of our visitors is important to us. This page represents our
    commitment on that issue and informs you how we use personal information
    collected on this site. At Hackersprey, we value your trust & respect your
    privacy. This privacy statement (“Privacy Statement”) applies to the
    treatment of personally identifiable information submitted by, or otherwise
    obtained from, you in connection with the associated application
    (”Application”). By using or otherwise accessing the Application, you
    acknowledge that you accept the practices and policies outlined in this
    Privacy Statement.

</p>
  <br>
            
            </br>
            <br>
            
            </br>
            <br>
            
            </br>
            <p className="privacypara"> This Policy has been drafted in accordance with the applicable data
            protection laws, rules and regulations but not limited to General Data
            Protection Regulation, 2018 (“GDPR”), the Information Technology Act, 2000
            (“IT Act”) and the Information Technology (Reasonable Security Practices
            and Procedures And Sensitive Personal Data or Information) Rules, 2011
            (“IT Rules”), collectively referred to as “Data Protection Laws”

</p>

  <br>
            
            </br>
    
            <br>
            
            </br>
            <br>
            
            </br>
            <h1 className='disclaimerhead'>Data We Collect</h1>
            <p className="privacypara">We collect the following Data about you when you use or otherwise interact
            with our Products:

</p>
<h1 className='disclaimerhead2 mt-3'>Personal data</h1>
<div className='row'>
<div className='col-lg-6'>
<ul >

<li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Name (first name/last
                          name/full name)</li>
<li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Email ID (both personal and
                            professional)</li>
</ul>

</div>
<div className='col-lg-6'>

<ul>
<li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Title</li>
                          </ul>

</div>
</div>
<h1 className='disclaimerhead2 mt-3'>Additional Data</h1>
<div className='row'>
<div className='col-lg-6'>
<ul >

<li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Profile/display picture</li>
<li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Country, state, postal code,
or city of residence.</li>
<li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                        Telephone number
(home/work/mobile number)</li>
<li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Telephone number
                          (home/work/mobile number)</li>
<li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Postal or other physical
                          addresses</li>
<li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Date of birth</li>
</ul>

</div>
<div className='col-lg-6'>

<ul>
<li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Gender
                         
                          
                           
                          
                          </li>
                          <li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Name(s) of the
                          school/university (including
                          grades and graduation year)</li>
                          <li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Name(s) of the workplace</li>
                          <li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Job position/designation
                          (including salary)</li>
                          <li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Resume/CV</li>
                          <li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Information related to social
                          profiles, such as Facebook,
                          Google, GitHub, etc.</li>
                          </ul>

</div>
</div>
<h1 className='disclaimerhead2 mt-3'>Passive Collection </h1>
<div className='row'>
<div className='col-lg-6'>
<ul >

<li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Cookies</li>
<li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Page-view activity</li>
<li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Geolocation data</li>
<li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Internet Protocol (IP)
                          addresses</li>
<li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Browser type</li>
<li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Internet Service Provider
                          (ISP)</li>
</ul>

</div>
<div className='col-lg-6'>

<ul>
<li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Referring/exit pages
                         
                          
                           
                          
                          </li>
                          <li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Files viewed on our site
                          (e.g., HTML pages, graphics,
                          etc.)</li>
                          <li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Operating system</li>
                          <li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Date/time stamp</li>
                          <li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>Clickstream data 
                          </li>
                          </ul>

</div>
</div>
<p className='privacypara'>Passively collected data does not have an expiry date and we don't delete
this data. It is saved with us in log form or on tools such as Google
Analytics, LinkedIn Insights, Facebook Audience Insights, and more.
Additionally, when you use some of our Products, network information such
as product-usage details are transmitted back to us.</p>
<p className='privacypara'>The User may choose whether to provide Personal Data to Hackersprey.
However, you may not be able to access certain options, offers, and services 
if you have not provided your Personal Data. You can signup, and therefore
consent to receiving emails or newsletters from us. If you would like to
discontinue receiving this communication, you can unsubscribe by using
the Unsubscribe link at the end of such emails or by writing to us
at  <a href="mailto:privacy@hackersprey.io"><b><img src={email1} alt="email" style={{height:"45px",marginTop:"-1rem"}}/></b> </a></p>
<p className='privacypara'>Hackersprey's use of information received from Google APIs will adhere to
the Google API Services User Data Policy, including the Limited Use
requirements.</p>
<h1 className='disclaimerhead mt-5'>Processing Your Personal Data</h1>
<h1 className='disclaimerhead2 mt-5'>Purpose of Collecting Personal
Data</h1>
<div className='row'>
<div className='col-lg-12'>
<ul >

<li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Providing reports based on
information collected from
use of our Products</li>
<li className="privacypolicybullets mb-4 mt-4" style={{listStyleType:"none"}}  >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Keeping you up-to-date
                          about latest Product
                          announcements, software
                          updates, software upgrades,
                          system enhancements,
                          special offers, and other
                          information</li>
<li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Providing support and
                          assistance for our Products</li>
<li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Providing the ability to create
                          personal profile</li>
<li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Providing the ability to
contact you if required</li>
<li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Providing customer feedback
and support</li>
<li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Providing and administering
                          opt-in events or other
                          marketing or promotional
                          activities on Hackersprey</li>
                          <li className="privacypolicybullets mb-4 mt-4" >
                          <span>
                                                      <img
                                                        src={Record}
                                                        className="labdescspan mr-5"
                                                        style={{ height: "1.5rem" }}
                                                        alt="img"
                                                      />
                                                    </span>
                                                    Being able to conduct
                                                    surveys in order to provide
                                                    you with better products and
                                                    services</li>
                                                    <li className="privacypolicybullets mb-4 mt-4" >
                          <span>
                                                      <img
                                                        src={Record}
                                                        className="labdescspan mr-5"
                                                        style={{ height: "1.5rem" }}
                                                        alt="img"
                                                      />
                                                    </span>
                                                    Supporting recruitment
inquiries</li>

<li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Supporting recruitment
inquiries</li>
<li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5 "
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Personalizing marketing
                          communication and website
                          content based on your
                          preferences such as in 
                          response to your request for
                          specific information on
                          products and services that
                          may be of interest to you
                          meeting contract or legal
                          obligations</li>
<li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Use of certain Product
                          features</li>
                          <li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Creating or taking tests</li>
                          <li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Requesting service and
support for our Products</li>
                          <li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Registering for an event
(challenge, hackathon, etc.)</li>
                          <li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Participating in discussion
groups or forums</li>
<li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Registering for newsletter
                          subscriptions</li>
                          




                 
</ul>
<h1 className='disclaimerhead2 mt-5'>Additional Grounds for
Processing Personal Data</h1>
<ul>
<li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Complying with our legal
obligations, resolving
disputes with users, and
enforcing our agreements</li>
<li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Protecting, investigating, and
                          deterring against fraudulent,
                          harmful, unauthorized, or
                          illegal activities</li>
</ul>
<h1 className='disclaimerhead2 mt-5'>General Data Protection Regulation</h1>
<p className='privacypara'>It is relevant to mention that Hackersprey does not capture any HR data.
This data comprises of details about any aspects of a person or HR
management system. Examples of HR data are legal records, health records,
and so on. However, we do capture non-HR data such as name, title, email
id, and so on.</p>
<p className='privacypara'>Hackersprey is the Controller (for the purposes of the GDPR) of your
Personal Data that you provide via our product. When providing Hackersprey
services for our Customers, Hackersprey is the Processor (for the purposes
of the GDPR) of your Personal Data provided by and on behalf of the
Customer, who is the Controller.</p>
<p className='privacypara'>If you reside in the European Union (EU), then you may have additional
rights with respect to your Personal Data, as further outlined below. These
rights may include rights under the EU's General Data Protection Regulation
(hereinafter referred as GDPR).</p>
<p className='privacypara'>GDPR is designed to give EU Citizens more control over their data. It aims to
use an all-encompassing privacy and security law to safeguard personal
data. Irrespective of their location, GDPR applies to all the relevant
controllers or processors who deal with the Personal Data of EU citizens.</p>
</div>
<h1 className='disclaimerhead2 mt-5'>California Online Privacy Protection Act (CalOppa)</h1>
<p className='privacypara'>CalOppa requires websites doing business with Californians to disclose their
Do Not Track policies, specifically whether the website does or does not
acknowledge Do Not Track settings. The data collected by Hackersprey
classifies as personal data  under the CalOppa and hence, the data collected
is subject to the CalOppa.</p>
<p className='privacypara'>Hackersprey has also given optional Do Not Track Facility.
The user can control how they use cookies and tracking technologies. Our
products currently do not support Do Not Track requests. </p>
<h1 className='disclaimerhead2 mt-5'>Personal Information Protection Law (PIPL)</h1>
<p className='privacypara'>The PIPL, regarded as China’s version of the EU General Data Protection
Regulation (GDPR), lays out a comprehensive set of rules for how business
operators should collect, use, process, share, and transfer personal
information in China. In addition to activities within China, the PIPL exerts
certain exterritorial jurisdiction over data processing activities that happen
outside China if the purpose is to provide products or services to individuals
located in China, or to analyze or assess the behaviors of individuals located
in China.</p>

<p className='privacypara'>The PIPL in China specifies additional lawful bases as binding law and
provides that consent is not required for processing personal information
disclosed by data subjects or other legally disclosed personal information
within a reasonable scope. Hackersprey only collects personal information to
the minimum requirement in order to avail our service.</p>
</div>
<p className='privacypara'>Hackersprey will obtain a separate consent of data subjects in China before,
if required:</p>
<ul>
<li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          providing personal information to third parties, which for
employers includes any entity outside the employing entity,</li>
<li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          publicly disclosing the personal information,</li>
                          <li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          processing sensitive personal information,</li>
                          <li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          using personal information that was collected for public security
                          for other purposes, or</li>
                          <li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          transferring personal information outside China.</li>
</ul>
<p className='privacypara'>
When Hackersprey provides personal information outside China, it will
inform the data subjects of the name of the overseas recipient, contact
information, processing purpose, processing method, and types of personal
information, as well as ways and procedures for data subjects to exercise
the rights provided under the PIPL with the overseas recipients, and it shall
obtain the data subjects separate consent.
</p>
<p className='privacypara'>PIPL in China emphasizes the right of data subjects to withdraw their
consent and the right to restrict or refuse the processing of their personal
information, and the relevant rights to refuse automated decision making
which is provided by Hackersprey.</p>
<h1 className='disclaimerhead2 mt-5'>Data Protection Law in Russia</h1>
<p className='privacypara'>According to Federal Law No. 152-FZ on Personal Data, which is the key law
governing Personal Data in Russia. Hackersprey will obtain customers’
permission before sharing data with third parties or abroad.
Hackersprey shall record, systemize, accumulate, store, specify or retrieve
the Personal Data of Russian Citizens by using any database located in
Russian Federation.
in China.</p>
<h1 className="disclaimerhead2 mt-5">Data Protection Legislations in Pacific Economies</h1>

<div className='row'>
<div className='col-lg-3'><h1 className="disclaimerhead3 mt-5">Australia </h1></div>
<div className='col-lg-1'></div>
</div>

<p className='privacypara'>Complying with Australian Privacy
Act of 1988, Hackersprey will only
use personal information for the
original purpose it was collected for.
If we use or disclose personal
information for a secondary purpose,
the individual will be provided with a
written notice of use or disclosure.</p>
<h1 className="disclaimerhead3 mt-5">Japan  </h1>
<p className='privacypara'>According to Act on the Protection of
Personal Information (“APPI”) 2003,
Hackersprey will use Personal
information for a specific purpose
stipulated at the time of collection.
However, if personal information is
used for a new purpose, consent will
be obtained from the principal.</p>
<h1 className="disclaimerhead3 mt-5">New Zealand   </h1>
<p className='privacypara'>In compliance with Privacy Act 1993,
Hackersprey will not use or disclose
personal information without taking
reasonable steps to validate
that it is accurate, complete relevant,
up to date, and not misleading, it will
not use the information for a purpose
other than the one it was collected
for Personal information will not be
disclosed unless:</p>
<ul>
<li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Associated with, or directly
                          related to the original purpose of
                          collection</li>
                   
                          <li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Information was obtained from a
publicly available publication</li>
                          <li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          It is directed to and approved by
                          the individual concerned; and</li>
                          <li className="privacypolicybullets mb-4 mt-4" >
                          <span>
                                                      <img
                                                        src={Record}
                                                        className="labdescspan mr-5"
                                                        style={{ height: "1.5rem" }}
                                                        alt="img"
                                                      />
                                                    </span>
                                                    Approved by the Privacy
                          Commissioner</li>
</ul>
<h1 className="disclaimerhead3 mt-5">Republic of Korea   </h1>
<p className='privacypara'>According to Personal Information
Protection Act (‘PIPA’); Act on the
Promotion of Information and
Communications Network Utilization
and Information Protection (‘Network
Act’); and, Act on the Use and
Protection of Credit Information
(‘Credit Information Act’),
Hackersprey will process personal
information:</p>  


<ul>
<li className="privacypolicybullets mb-4 mt-4" >
                          <span>
                                                      <img
                                                        src={Record}
                                                        className="labdescspan mr-5"
                                                        style={{ height: "1.5rem" }}
                                                        alt="img"
                                                      />
                                                    </span>
                                                    in a lawful and fair manner</li>
                                                    <li className="privacypolicybullets mb-4 mt-4" >
                                                    <span>
                                                                                <img
                                                                                  src={Record}
                                                                                  className="labdescspan mr-5"
                                                                                  style={{ height: "1.5rem" }}
                                                                                  alt="img"
                                                                                />
                                                                              </span>
                                                                              in accordance with the specified
                                                                              and intended purpose.</li>
                                                    
                                                    </ul>
<p className='privacypara'>Provided the information is
unidentifiable and consent was
provided for an intended purpose,
exceptions apply where:</p>
<ul>
<li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Purpose is likely to infringe upon
the data subject’s interest; is
required for legal proceedings or
used as part of statistics and/or
academic research..</li>
<li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Provided the information is
                          unidentifiable and consent was
                          provided for that purpose.</li>
</ul>
<h1 className="disclaimerhead2 mt-5">Data Protection Laws of ASEAN Member Nations  </h1>
<h1 className="disclaimerhead3 mt-5">Malaysia </h1>
<p className='privacypara'>As per Malaysia’s Personal Data
Protection Act of 2010, the
processing of personal data by a
Hackersprey will be in compliance
with the following Personal Data
Protection Principles:</p>
<ul>
<li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          lawful purpose directly related
                          to an activity of the data user</li>
                          <li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          necessary for or directly related
                          to that purpose; and</li>
                          <li className="privacypolicybullets mb-4 mt-4" >
                          <span>
                                                      <img
                                                        src={Record}
                                                        className="labdescspan mr-5"
                                                        style={{ height: "1.5rem" }}
                                                        alt="img"
                                                      />
                                                    </span>
                                                    adequate but not excessive in
                                                    relation to that purpose</li>
</ul>
<h1 className="disclaimerhead3 mt-5">Singapore   </h1>
<p className='privacypara'>In respect of Singapore’s Personal
Data Protection Act of 2012,
Hackersprey may use personal data
about an individual collected before
the appointed day for the purposes
for which the personal data was
collected unless (a) consent for such
use is withdrawn.</p>
<h1 className="disclaimerhead3 mt-5">Philippines   </h1>
<p className='privacypara'>Philippines’ Data Privacy Act of 2012,
Hackersprey will process personal
information, subject to compliance
with the requirements of this Act and
other laws allowing disclosure of
information to the public and
adherence to the principles of
transparency, legitimate purpose and
proportionality.</p>
<h1 className="disclaimerhead3 mt-5">Thailand    </h1>
<p className='privacypara'>As per Thailand’s Personal Data
Protection Act (2019), Hackersprey
will not collect, use, or disclose
Personal Data, unless the data
subject has given consent prior to or
at the time of such collection, use, or
disclosure, except the case where it
is permitted. The collection of
Personal Data shall be limited to the
extent necessary in relation to the
lawful purpose. In collecting the
Personal Data, Hackersprey will
inform the data subject, prior to or at
the time of such collection, except
the case where the data subject
already knows of such details.
Hackersprey will not collect Personal
Data from any other source, apart
from the data subject directly.</p>
<h1 className="disclaimerhead3 mt-5">Mali    </h1>
<p className='privacypara'>In accordance with Law No. 2013-
015 of Protection on the Personal
Data, Hackersprey will notify of data
processing before processing and will
ensure data security.</p>
<h1 className="disclaimerhead3 mt-5">Mauritius </h1>
<p className='privacypara'>As per Data Protection Act 2017,
Hackersprey will register with
agency, and all processing processor
required. </p>
<h1 className="disclaimerhead3 mt-5">Togo  </h1>
<p className='privacypara'>Hackersprey with respect to Law
2019-014 Data of Personal Protection
the Relating to the Protection of
Personal Data Controller will ensure
that entity of third parties to whom
data are transmitted can be verified
to prevent unauthorized disclosures. </p>
<h1 className="disclaimerhead3 mt-5">Nigeria </h1>
<p className='privacypara'>In compliance with Nigeria Data
Protection Regulation 2019,
Hackersprey will take adequate
measures to protect data. </p>
<h1 className="disclaimerhead2 mt-5">Eligibility </h1>
<p className='privacypara'>Services on the site would be available globally. Persons who are
“incompetent to contact” within the meaning of the Indian Contract Act,
1872 including minors, undischarged insolvents, persons of unsound mind,
persons involved in sale and purchase of goods banned in their territory, or
persons who are involved in activities barred by law.</p>
<p className='privacypara'>Services on the Site would be available to only select geographies in India.
Persons who are “incompetent to contract” within the meaning of the Indian
Contract Act, 1872 including minors, un-discharged insolvents etc. are not
eligible to use the Site. </p>
<p className='privacypara'>However, if you are a minor, i.e. under the age of 18 years and over the age
of 13 years, you may use the Site under the supervision of a parent or legal
guardian who agrees to be bound by these Terms of Use.</p>

<p className='privacypara'>Hackersprey reserves the right to terminate or refuse your registration, or
refuse to permit access to the Site, if it is discovered or brought to its notice
that you are a minor.</p>
<h1 className="disclaimerhead2 mt-5">Children Privacy Policy </h1>
<p className='privacypara'>Our services are not directed to children below 16 years of age. We do not
knowingly collect Personal Information from children. If we become aware
that a child has provided us with Personal Information, we will take steps to
delete such information. If you become aware that a child has provided us
with Personal Information, please contact us through our Grievance Officer.</p>

<h1 className="disclaimerhead2 mt-5"> Security </h1>
<p className='privacypara'>Transactions on the Website are secure and protected. Any information
entered by the User when transacting on the Website is encrypted to protect
the User against unintentional disclosure to third parties. The User’s credit
and debit card information is not received, stored by or retained by the
Company/ Website in any manner. This information is supplied by the User
directly to the relevant payment gateway which is authorized to handle the
information provided, and is compliant with the regulations and
requirements of various banks and institutions and payment franchisees that
it is associated with.</p>
<h1 className="disclaimerhead2 mt-5"> License and Access </h1>
<p className='privacypara'>Hackersprey grants you a limited sub-license to access and make personal
use of the Site, but not to download (other than page caching) or modify it,
or any portion of it, except with express written consent of a Company. Such
limited sub-license does not include/ permit any resale or commercial use of
the Site or its contents; any collection and use of any product listings,
descriptions, or prices; any derivative use of the Site or its contents; any
downloading or copying of information for the benefit of another merchant;
or any use of data mining, robots, or similar data gathering and extraction
tools. Any portion of the Site may not be reproduced, duplicated, copied,
sold, resold, visited, or otherwise exploited for any commercial purpose
without express written consent of the Company. You may not frame or
utilize framing techniques to enclose any trademark, logo, or other
proprietary information (including images, text, page layout, or form) of the
Website or of the Company and/ or its affiliates without the express written
consent of the Company. You may not use any meta tags or any other
“hidden text” utilizing the Company’s name or trademarks without the
express written consent of the Company.</p>
<p className='privacypara'>You shall not attempt to gain unauthorized access to any portion or feature
of the Site, or any other systems or networks connected to the Site or to any
server, computer, network, or to any of the services offered on or through
the Site, by hacking, ‘password mining’ or any other illegitimate means. </p>

<h1 className="disclaimerhead2 mt-5">Directions to the User </h1>
<p className='privacypara'>Hackersprey is not liable for any offence committed by any of its user from
any geographical location or jurisdiction as mentioned under Information
Technology Act (amended upto date) 2000 or any other law in force.</p>
<p className='privacypara'>You hereby agree and undertake not to host, display upload, modify,
publish, transmit, update or share any information which:</p>
<ul>
<li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                           Belongs to another person and to which you do not have any right;</li>
                           <li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Is grossly harmful, harassing, blasphemous, defamatory, obscene,
                          pornographic, paodophilic, libelous, invasive of another’s privacy,
                          hateful, or racially, ethnically objectionable, disparaging, relating or
                          encouraging money laundering or gambling, or otherwise unlawful in
                          any manner whatever</li>
                           <li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Harms minors in any way</li>
                           <li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Infringes any patent, trademark, copyright or other proprietary/
intellectual property rights;</li>
                           <li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Violates any law for the time being in force</li>

                           <li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Deceives or misleads the addressee about the origin of such messages
                          communicates any information which is grossly offensive or menacing
                          in nature</li>
                           <li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Impersonates another person</li>
                           <li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Contains software viruses or any other computer code, files or
programs designed to interrupt, destroy or limit the functionality of
any computer resource</li>
<li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Any tool provided or used by you in the curriculum will not be misused
                          by the User in any manner</li>


</ul>
<h1 className="disclaimerhead2 mt-5"> Cookies and tracking technologies </h1>
<p className='privacypara'>Hackersprey uses cookies or similar technologies to analyze trends,
administer its website, track users’ movements around the website, and
gather information about the user base, such as location information based
on IP addresses</p>
<p className='privacypara'>Users can control the use of cookies in the browsers that they use. Your
browser may offer you a Do Not Track option that allows you to signal to
operators of websites and web applications and services (including
behavioral advertising services) that you do not want them to track your
online activities across different websites.</p>
<p className='privacypara'>Our products do not support Do Not Track requests at this time, which
means that we will collect information about your online activity while you
are using our Products and after you leave our properties.
Most web browsers automatically accept cookies but provide controls that
allow you to block or delete them. For example, in most modern browsers,
you can block or delete cookies by clicking Settings = Privacy =Cookies.
Instructions for blocking or deleting cookies in other browsers may be
available in a browser’s privacy or help documentation. </p>
<p className='privacypara'>To read more about cookies, including how to see what cookies have been
set and how to manage and delete them, visit www.aboutcookies.org. </p>
<h1 className="disclaimerhead2 mt-5">Tools </h1>
<p className='privacypara'>Hackersprey will be demonstrating the use of certain open source tools
which are used by ethical hackers and are already available in the public
domain. Along with that we will be also introducing our tools such as Remote
Administration Tools (RATS), Crypters, Malware Delivery Techniques, and
Server to listen for multiple incoming connections from RATs. etc. which are
designed by the Hackersprey team.</p>
<p className='privacypara'>Bug Bounty or CTF (Capture-the-flag) Challenge is part of the promotional
activities of the Hackersprey platform. In this, we will be offering certain
challenges to the individuals and will ask them to hack a particular website
which will be created and hosted on the Hackersprey platform. The
duration of this challenge will be 2 hours if an individual can solve or capture
the flag within a given time frame and he/she submits the flag at the earliest
that individual will be declared as the winner of that bug bounty or CTF
Challenge.</p>
<p className='privacypara'>Once a person is declared a winner of the CTF or bug bounty his payment
will be made clear within one month. Suppose we kept the bounty of $400
we will only pay $400 all international conversions should be included in this
amount.</p>
<p className='privacypara'>Bug Bounty or CTF (Capture-the-flag) Challenge will be conducted twice a
month on the HackersPrey web portal.</p>
<h1 className="disclaimerhead2 mt-5">Third Party Tracking </h1>
<p className='privacypara'>We do not sell your Personal Information and will not share or disclose any
of your Personal Information with third parties except as described in this
policy. We do not share Personal Information about you with third parties for
their marketing purposes (including direct marketing purposes).</p>
<h1 className="disclaimerhead2 mt-5">Registration </h1>
<p className='privacypara'>To use the services provided on the website and make purchases, it is
compulsory to create an account. A user may create an account on the
Website which shall collect only your basic information. To create an
account, you need to choose a username and password. You also have the
option of linking your social media accounts, such as Your Facebook or
Google Plus account with the Hackersprey account, to create your
Hackersprey Account. You must keep your account and registration details
current and correct for communications related to your purchases from the
website</p>
<p className='privacypara'>At the time of registration, the Company shall collect the following personally
identifiable information about you: Name- including first and last name,
email address, mobile phone number and other contact details, demographic
profile (like your age, gender, address, etc.,). If you choose to link your
social media account with your Hackersprey account, we collect basic
information about you from those social media platforms, such as: name,
age, gender, location and e-mail address. Information collected about you is
subject to the Privacy Policy of the Company, which may be read as part and
parcel of these Terms of Use. </p>
<p className='privacypara'>You are solely responsible for protecting the confidentiality of your username
and password and any activity under the account will be deemed to have
been done by you. In the event you provide us with false/ inaccurate details
or the Company has a reasonable ground to believe that false and inaccurate
information has been furnished, we hold the right to permanently suspend
your account. If any false/ misleading information is provided by any user 
during the registration process, then it will be the sole responsibility of the
account holder only and Hackersprey will not be liable in any manner. </p>
<h1 className="disclaimerhead2 mt-5">Payment </h1>
<p className='privacypara'>Hackersprey will be charging  advance payment for Paid Programs.
The following payment options are available on the application:</p>
<ul>
<li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Domestic and international credit cards issued by banks and financial
institutions that are part of the Visa, Master Card & Amex Card
networks;</li>
<li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Visa & Master Card Debit cards;</li>
                          <li className="privacypolicybullets mb-4 mt-4" >
                          <span>
                                                      <img
                                                        src={Record}
                                                        className="labdescspan mr-5"
                                                        style={{ height: "1.5rem" }}
                                                        alt="img"
                                                      />
                                                    </span>
                                                    Net banking/ Direct Debit payments from select banks in Indian. A list
                                                    of available options will be made available at the time of the Purchase.</li>
</ul>
<p className='privacypara'>As prescribed by the financial institutions issuing the credit or debit cards
affiliated with Visa/ Master Card/ Amex, the User will be required to submit
his/ her 16- digit card number, card expiry date and 3-digit CVV number
(usually on the reverse of the card) while making an online transaction. The
User must also have enrolled his/ her card with VNV (Verified by Visa), MSC
(MasterCard Secure Code) or any other applicable provider in order to
complete the transaction. The User is hereby expressly made aware that his/
her card statements will reflect that a payment has been made in favour of
the Company. </p>
<p className='privacypara'>The User is further aware that in case of third party statements, including
bank and credit card statements, the merchant name may appear in an
abbreviated format, and the Company has not control over the same. To 
successfully subscribe on the Website, the User is required to complete the
transaction by making the payment for the services opted for.
CANCELLATION AND REFUNDS:</p>
<p className='privacypara'>No refund will be provided by Hackersprey. There is no provision of refund.
Access to the user will be revoked anytime it is found that
course/labs/challenges are being shared with others/multiple users or course
purchaser intends to copy the material/ Content. The user associated with
such an account will be blocked at the same time.</p>

<h1 className="disclaimerhead2 mt-5">Grievance Redressal Mechanism</h1>
<p className='privacypara'>Any complaint with regards to use, processing or disclosure of data
provided by you or breach of these terms can be taken up with
designated grievance redressal officer as mentioned below via post or
by sending an email to <b><img src={email2} alt="img" style={{height:"30px",marginLeft:"5px"}}/></b></p>
<p className='privacypara'>Grievance Redressal Officer: Any complaints or concerns with regards
to breach of these terms shall be immediately communicated to the
designated Grievance Officer as mentioned below in writing or through
email.</p>
<h1 className="disclaimerhead2 mt-5">Grievance Redressal Mechanism</h1>
<ul>
<li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Name: <b>Mr. Vipul Mandhar </b>  </li>
                          <li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Email:  <b><img src={email2} alt="img" style={{height:"28px"}}/></b>  </li>
                          
                          <li className="privacypolicybullets mb-4 mt-4" >
                          <span>
                                                      <img
                                                        src={Record}
                                                        className="labdescspan mr-5"
                                                        style={{ height: "1.5rem" }}
                                                        alt="img"
                                                      />
                                                    </span>
                                                    Address: <b> SCF-4,2nd Floor Sector-7C ,Chandigarh</b> </li>
                                                    <li className="privacypolicybullets mb-4 mt-4" >
                                                    <span>
                                                                                <img
                                                                                  src={Record}
                                                                                  className="labdescspan mr-5"
                                                                                  style={{ height: "1.5rem" }}
                                                                                  alt="img"
                                                                                />
                                                                              </span>
                                                                              Phone: <b>+91-0172 403 8499</b> </li>
</ul>
<h1 className="disclaimerhead2 mt-5">Procedure For Filing Complaint</h1>
<p className='privacypara'>We request the User to provide following information while filing a
complaint:</p>
<ul>
<li className="privacypolicybullets mb-4 mt-4" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
                          Identification of the information provided by you. </li>
                          <li className="privacypolicybullets mb-4 mt-4" >
                          <span>
                                                      <img
                                                        src={Record}
                                                        className="labdescspan mr-5"
                                                        style={{ height: "1.5rem" }}
                                                        alt="img"
                                                      />
                                                    </span>
                                                    Your address, telephone number or e-mail address. </li>
                                                    <li className="privacypolicybullets mb-4 mt-4" >
                                                    <span>
                                                                                <img
                                                                                  src={Record}
                                                                                  className="labdescspan mr-5"
                                                                                  style={{ height: "1.5rem" }}
                                                                                  alt="img"
                                                                                />
                                                                              </span>
                                                                              Statement that you have a good-faith belief that use of the
                                                                              information has been processed incorrectly or disclosed without
                                                                              authorization, as the case may be. </li>

                                                                              <li className="privacypolicybullets mb-4 mt-4" >
                                                                              <span>
                                                                                                          <img
                                                                                                            src={Record}
                                                                                                            className="labdescspan mr-5"
                                                                                                            style={{ height: "1.5rem" }}
                                                                                                            alt="img"
                                                                                                          />
                                                                                                        </span>
                                                                                                        Statement, under penalty of perjury, that the information in the
notice is accurate, and that you are authorized to act on behalf of the
owner of the right that is allegedly infringed. </li>

</ul>
<p className='privacypara'>Hackersprey may reach out to you to confirm or discuss certain details about
your complaint and issues raised and shall not be responsible for any
communication, if addressed, to any non-designated person in this regard.</p>
<br>

            
            </br>
            <br>
            
            </br>
            <br>
            
            </br>
            


  
                 </div>
             </div>
         </div>
         <Footer/>
          </section>
        </div>
    )
}

export default Refundpolicy
