import React,{useState} from 'react'
import "./challengeui.css"
import Congrats from "../../../Assets/MASCOT/Mascot SVG/Asset 29.png"
import {FaArrowRight} from "react-icons/fa";
import {ImCross} from "react-icons/im";
import Account from "../../../Assets/Modals/Deactivate Account.png"
import Quitchallenge from './Quitchallenge';
import { Link } from 'react-router-dom';
const Labui = ({ setOpenModal,ip}) => {
    const[modalopen,setmodalopen]=useState(false)
    const[modalopen2,setmodalopen2]=useState(false)
    const startlab = ()=>{

      window.open(ip);
     }
  return (
    <div>
    {modalopen2 &&  <Quitchallenge setOpenModal={setmodalopen2}/> }
    
    <section className="modalBackground justify-content-center" onClick={() => {
      setOpenModal(false);
    }}>
  <div className="container  justify-content-center">
  
    <div className="row justify-content-center ">
    
      <div className="col-lg-8 col-md-10 col-10 p-1 modalContainer" >
      
    
        <div className='row p-5'>
       
        <div className='col-lg-4 col-md-5 col-4  '>
       
        <div className='justify-content-center pl-5'><img src={Congrats} style={{height:"45vh"}} alt="sorry"/></div>
        </div>
        <div className='col-lg-8 col-md-5 col-8   '>
        <div className='mt-2 crossbtnarrow'>
        <button
          onClick={() => {
            setOpenModal(false);
          }}
        >
        <div className='text-center'><ImCross style={{height:".6rem",marginLeft:"-1.9rem",marginTop:".5rem"}}/></div> 
        </button>
      </div>
       <div className='justify-content-center'><h2 className='text-center alertmainhead2'>Start  Lab!!<div className='row justify-content-center'><span><hr className='text-center alertcommonhr'></hr></span></div></h2>  
       </div> 
       <p className='resetpara'>I have read all Ethical Guidelines of Hackersprey and  accept all without any reservations. </p>

       
       <div className='row justify-content-center'>
 <button className=' btn saveratebtn' onClick={startlab} > START LAB</button>
 
</div>
        </div></div>
       
       
         
          
       
      </div>
    </div>
  </div>

</section>
    </div>
    

  )
}

export default Labui