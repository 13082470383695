import axios from 'axios';
import React, { useState } from 'react';
import { RxCross2 } from "react-icons/rx";

import './acw.css';
import { useNavigate } from 'react-router-dom';

function AcwformModel({ setShowForm }) {
    const API_Key = process.env.REACT_APP_API_URL;
    const [formData, setFormData] = useState({
        fullname: "",
        email: "",
        phoneNumber: "",
        city: "",
        query: ""
    });
    const [successMessage, setSuccessMessage] = useState("");
    const navigate = useNavigate();
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleCloseModal = () => {
        setShowForm(false);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${API_Key}/acw_query_about_course_training`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Access-Control-Allow-Origin": `${API_Key}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Credentials": "true",
                },
                credentials: "include",
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                const data = await response.json();
                console.log(data);

                // Clear form data
                setFormData({
                    fullname: "",
                    email: "",
                    phoneNumber: "",
                    city: "",
                    query: ""
                });


                setSuccessMessage("Your query has been submitted successfully!");

                setTimeout(() => {
                    setSuccessMessage("");
                }, 2000);
            } else {
                console.error("Submission failed.");
            }
        } catch (error) {
            console.error("There was a problem with the fetch operation:", error);
        }
    };

    return (
        <div className='glassBg'>
            <div className="modal-overlay">
                <div className="modal-content">
                    <h2 className='connecthead'>Submit Us a Query</h2>
                    <button className="modal-close-btn" onClick={handleCloseModal}>
                        <RxCross2 />
                    </button>
                    <form onSubmit={handleFormSubmit} className="callback-form">
                        <label>
                            Name:
                            <input
                                type="text"
                                name="fullname"
                                value={formData.fullname}
                                onChange={handleInputChange}
                                required
                                placeholder='Name'
                            />
                        </label>
                        <label>
                            Email:
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                required
                                placeholder='Email'
                            />
                        </label>
                        <label>
                            Mobile:
                            <input
                                type="text"
                                name="phoneNumber"
                                value={formData.phoneNumber}
                                onChange={handleInputChange}
                                required
                                placeholder='Mobile number'
                            />
                        </label>
                        <label>
                            City:
                            <input
                                type="text"
                                name="city"
                                value={formData.city}
                                onChange={handleInputChange}
                                required
                                placeholder='City'
                            />
                        </label>
                        <label>
                            Query:
                            <textarea 
                            className='acw-text-area'
                                name="query"
                                value={formData.query}
                                onChange={handleInputChange}
                                required
                                placeholder='Query'
                            />
                        </label>
                        <div className='buttonsub'>
                            <button type="submit">Submit</button>
                        </div>
                    </form>
                    {successMessage && (
                        <div className="success-message">
                            {successMessage}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default AcwformModel;