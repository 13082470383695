import React from 'react'
import Congrats from "../../../../Assets/MASCOT/Mascot SVG/5.png"
import {FaArrowRight} from "react-icons/fa";
import {ImCross} from "react-icons/im";
const Notparticipateinteam = ({setOpenModal}) => {
  return (
    <div> <section className="modalBackground2 justify-content-center" onClick={() => {
        setOpenModal(false);
      }}>
      <div className="container  justify-content-center">
      
        <div className="row justify-content-center ">
        
          <div className="col-lg-8 col-md-10 col-10 p-1 modalContainer" >
          
        
            <div className='row p-5'>
           
            <div className='col-lg-4 col-md-5 col-4  '>
           
            <div className='justify-content-center pl-5'><img src={Congrats} style={{height:"45vh"}} alt="sorry"/></div>
            </div>
            <div className='col-lg-8 col-md-5 col-8   '>
            <div className='mt-2 crossbtnarrow'>
            <button
              onClick={() => {
                setOpenModal(false);
              }}
            >
            <div className='text-center'><ImCross style={{height:".6rem",marginLeft:"-1.9rem",marginTop:".5rem"}}/></div> 
            </button>
          </div>
           <div className='justify-content-center'><h2 className='text-center alertmainhead2'>Not an Active Member<div className='row justify-content-center'><span><hr className='text-center alertcommonhr'></hr></span></div></h2> 
           
           <p className='resetpara'>We regret to inform you that you are not allowed to download your certificate as you are not an active member of any of the teams. If you believe this is an error or have any inquiries, please reach out to our support team for assistance. </p>
           </div> 
          
  
           
           <div className='row justify-content-center'>
           <button className='Arrowbtn' onClick={() => {
            setOpenModal(false);
          }}>
          <FaArrowRight color="white" size={20}/>
           
           </button>
       
           </div>
            </div></div>
           
           
             
              
           
          </div>
        </div>
      </div>
  
    </section></div>
  )
}

export default Notparticipateinteam