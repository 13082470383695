import React,{useState} from 'react'
import { ImCross } from 'react-icons/im';
import { useForm } from "react-hook-form";
import Loading from './Loading';
import Submitflagsucess from "./Submitflagsucess";

import Nopaymentidfound from './Nopaymentidfound';
import Paymentissuccess from './Paymentissuccess';
const Paymentidverify = ({setOpenModal,id}) => {
    const API_Key = process.env.REACT_APP_API_URL;
    const[modalopen,setmodalopen]=useState(false);
    const[modalopen2,setmodalopen2]=useState(false);
    const[modalopen3,setmodalopen3]=useState(false);
    const[modalopen4,setmodalopen4]=useState(false);
    const {register,handleSubmit,formState: { errors },reset,watch,trigger,} = useForm();
  
     
         
          const onSubmit=  (data)=>{
          setmodalopen(true);
         
          
           
            const res =  fetch(`${API_Key}/submituser_payment_id`, {
                method: "POST",
                body: JSON.stringify({
              
                    
                    payment_id:data.payment_id
                }),
                headers: {
                  "Content-Type": "application/json",
                  mode: 'cors',
                  'Access-Control-Allow-Origin':`${API_Key}`,
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Credentials': true,
                },
                credentials:"include",
               
              }).then((res) => {
                 if(res.status===200){
                 setmodalopen(false);
               setmodalopen4(true);
                 }else if(res.status===404){
                    setmodalopen(false);
             
                       setmodalopen3(true);
                     
                 }
    
           
   
              
          
            }
              ).catch((err)=>{
                //setmodalopen3(true);
                setTimeout(() => {
                  //setOpenModal(false)
                  },3000);
                          })}
  return (
      <div>
      {modalopen && <Loading setOpenModal={setmodalopen} />}
      {modalopen2 && <Submitflagsucess setOpenModal={setmodalopen2} />}
      {modalopen3 && <Nopaymentidfound setOpenModal={setmodalopen3} />}
      {modalopen4  && <Paymentissuccess setOpenModal={setmodalopen4} />}
    <section className="modalBackground justify-content-center"  >
    <div className="container  justify-content-center" >
    
      <div className="row justify-content-center ">
      
        <div className="col-lg-8 col-md-10 col-10  modalContainer" >
        <div className='mt-5 crossbtnarrow '>
        <button
          onClick={() => {
            setOpenModal(false);
          }}
        >
        <div className='text-center'><ImCross style={{height:".6rem",marginLeft:"-1.9rem",marginTop:".5rem"}}/></div> 
        </button>
      </div>
<h2 className='text-center alertmainhead2'>Enter Payment ID <div className='row justify-content-center'><span><hr className='text-center alertcommonhr'></hr></span></div></h2>  


<div className='row justify-content-center'>

<div className='col-lg-10 col-md-6  col-9 '>
<p className='resetpara'>Please enter your payment ID to proceed and gain access to the course, access will not be granted without proper payment ID verification.</p>

<div className='row justify-content-center mt-2'>
<form  className='col-lg-12' onSubmit={handleSubmit(onSubmit)} >
<div className="form-input ">
    
<div className="col">

<input
  name="payment_id"
  type="text"
  className={`form-control  ${errors.payment_id && "invalid"}`}
  placeholder="XXXXX-XXXXX-XXXXX-XXXXX-XXXXX"
  {...register("payment_id", { required: "payment_id is Required",
  pattern:/^[a-zA-Z -.,_\n {} ' 0-9]*$/,
    message:"payment_id is Required",
   
    
  })}
  onKeyUp={() => {
   trigger("payment_id");
 }}
 />
 {errors.payment_id && (
  <small className="errormes ml-2 ">{errors.payment_id.message}</small>
)}
 </div></div>
 <div className='row justify-content-center'>
<button className=' btn saveratebtn' > SUBMIT PAYMENT_ID</button>
</div>
</form>
</div>

</div>

</div>

        </div></div></div></section></div>
  )
}

export default Paymentidverify