import React,{useState,useEffect,useRef} from 'react'
import "./Authpage.css";
import bcrypt from "bcryptjs";
import Signinpic from "../../Assets/login/login.webp";
import {FaEye, FaEyeSlash} from "react-icons/fa";
import { useForm } from "react-hook-form";
import { NavLink,useNavigate, useLocation} from 'react-router-dom';

import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';

import Wentwrong from '../Dashboard/Modals/Wentwrong';
import Emailnotexist from '../Dashboard/Modals/Emailnotexist';
import Inavalidcred from '../Dashboard/Modals/Inavalidcred';

import Loading from '../Dashboard/Modals/Loading';
import Toomanyattempts from '../Dashboard/Modals/Toomanyattempts';

import Twofactorloginforui from '../Dashboard/Modals/Twofactorloginforui';

import Otpalreadysent from "../Dashboard/Modals/Otpalreadysent"

import { encrypt, decrypt, compare } from 'n-krypta';
import Uninotfound from '../Dashboard/Modals/Uninotfound';
import Ipnotfound from "../Dashboard/Modals/Ipnotfound"
import Tokennotavailable from '../Dashboard/Modals/Tokennotavailable';
const UniLogin = (props) => {


      
    const navigate=useNavigate();
    const location=useLocation();

    const faeye=<FaEye/>;
const faeye2=<FaEyeSlash/>
const [passwordShown, setPasswordShown] = useState(false);
const[actiemail,setactiemail]=useState("")
const [eye,seteye]=useState(faeye2);
const togglePasswordVisiblity = () => {
  setPasswordShown(passwordShown ? false : true);
  seteye(faeye);
};

const[credential,setcredential]=useState({email:"",password:""})
const passwordInputRef = useRef()
const {register,handleSubmit,formState: { errors },reset,watch,trigger,} = useForm();
const initialValues = { email:"",password:"" };
const[clientip,setclientip]=useState("");
const [modalOpen, setModalOpen] = useState(false);
const [modalOpen2, setModalOpen2] = useState(false);
const [modalOpen3, setModalOpen3] = useState(false);
const [modalOpen4, setModalOpen4] = useState(false);
const [modalOpen5, setModalOpen5] = useState(false);
const [modalOpen6, setModalOpen6] = useState(false);
const [modalOpen7, setModalOpen7] = useState(false);
const [modalOpen8, setModalOpen8] = useState(false);
const [modalOpen9, setModalOpen9] = useState(false);
const [modalOpen10, setModalOpen10] = useState(false);
const[loginame,setloginname]=useState("LOGIN");
const[username,setusername]=useState("");
const handleinput=(event)=>{
  
  event.preventDefault();
      setcredential((prevState) => ({
          ...prevState,
          [event.target.name]: event.target.value,
        }));
  
  }


const API_Key = process.env.REACT_APP_API_URL;
const fetchuser = async () => {
  setModalOpen4(true);


      
  try{
    const res = await fetch(`${API_Key}/userdata`,{
        method:"GET",
        headers:{
          mode: 'cors',
          'Access-Control-Allow-Origin':`${API_Key}`,
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': true,
          
        },
        credentials:"include",
        //withCredentials: true,
    });
    const data = await res.json();

    setusername(data);
    setloginname("Go To Dashboard")
    navigate("/userhome");
   
  

    if(res.status !== 200 ){
        const error = new Error(res.error);
        throw error;
        
    }
    
 


}catch(err){

  setModalOpen4(false);


} 

}
useEffect(() => {
  fetchuser();
},[] );
    
    const onSubmit = async(data) => {
      setModalOpen4(true);
      const hashedPassword = bcrypt.hashSync(data.password, 12);
      
     const last= bcrypt.compare(hashedPassword,data.password)
   
  
   const secretKey="ResecSystemsPVTLTD";
   const encrpt=encrypt(data.password,secretKey);
   const emails=data.email;
   

   const response = await fetch("https://httpbin.org/ip",{
    method:"GET",
    headers:{
      mode: 'cors',
      'Access-Control-Allow-Origin':`${API_Key}`,
      'Content-Type': 'application/json',
      'Access-Control-Allow-Credentials': true,
    },
    credentials:"include"

  }).then(async(response) => { 
 const data=await response.json();
console.log(data.origin,"data")
setclientip(data.origin);
if(response.status===200){
fetch(`${API_Key}/university_loginwith_token` , {
  method: 'POST',
  headers: {
    Accept: 'application/json',
          mode:"cors",
                  'Access-Control-Allow-Origin':`${API_Key}`, 
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Credentials': true
        },
        credentials:"include",
  body: JSON.stringify({email:emails,password:encrpt,clientip:data.origin}),
})
.then((response) => { 
     
  if(response.status===200){
  
   
    setModalOpen4(false);
  setModalOpen6(true,setactiemail(emails));
  }else if(response.status===400){
    setModalOpen2(true);
    setModalOpen4(false);
  }else if(response.status===429){
setModalOpen5(true);
setModalOpen4(false);
  }else if(response.status===403){
    setModalOpen7(true);
    setModalOpen4(false);
  }else if(response.status===404){
    setModalOpen8(true);
    setModalOpen4(false);
  }else if(response.status===503){
    setModalOpen9(true);
    setModalOpen4(false);
  }else if(response.status===402){
      setModalOpen10(true);
      setModalOpen4(false);
  }


})

.catch((error) => {
  console.error('Error:', error);
  setModalOpen3(true);
});
     
              


}

})

.catch((error) => {
console.error('Error:', error);
//setModalOpen3(true);

});
 
 /*fetch(`${API_Key}/university_loginwith_token` , {
  method: 'POST',
  headers: {
    Accept: 'application/json',
          mode:"cors",
                  'Access-Control-Allow-Origin':`${API_Key}`, 
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Credentials': true
        },
        credentials:"include",
  body: JSON.stringify({email:data.email,password:encrpt,clientip:"16255355"}),
})
.then((response) => { 
     
  if(response.status===200){
 

   
    setModalOpen4(false);
  setModalOpen6(true,setactiemail(data.email),setsubscriptionid(subscriptionid));
  }else if(response.status===400){
    setModalOpen2(true);
    setModalOpen4(false);
  }else if(response.status===429){
setModalOpen5(true);
setModalOpen4(false);
  }else if(response.status===403){
    setModalOpen7(true);
    setModalOpen4(false);
  }else if(response.status===404){
    setModalOpen8(true);
    setModalOpen4(false);
  }else if(response.status===503){
    setModalOpen9(true);
    setModalOpen4(false);
  }else if(response.status===402){
      setModalOpen10(true);
      setModalOpen4(false);
  }


})

.catch((error) => {
  console.error('Error:', error);
  setModalOpen3(true);
});*/
  }
  return (
    <div>
    {modalOpen && <Emailnotexist setOpenModal={setModalOpen}/>}
    {modalOpen2 && <Inavalidcred setOpenModal={setModalOpen2}/>}
    {modalOpen3 && <Wentwrong setOpenModal={setModalOpen3}/>}
    {modalOpen4 && <Loading setOpenModal={setModalOpen4}/>}
    {modalOpen5 && <Toomanyattempts setOpenModal={setModalOpen5}/>}
    {modalOpen6 && <Twofactorloginforui  email={actiemail}   setOpenModal={setModalOpen6}  />}
    {modalOpen7 && <Otpalreadysent setOpenModal={setModalOpen7}/>}
    {modalOpen8 && <Uninotfound setOpenModal={setModalOpen8}/>}
    {modalOpen9 && <Ipnotfound setOpenModal={setModalOpen9}/>}
    {modalOpen10 && <Tokennotavailable setOpenModal={setModalOpen10}/>}
  <section className="authbackground">
          
  
   <div id="authpagelinediv">
       <div className="container-fluid">
       <span className="Authpagespan">Login</span>
           <div className="row">
               <div className="col-lg-5 col-md-6 col-12 offset-md-2 mt-5   ">
                   <img src={Signinpic} alt="signup"  className="img-fluid"/>
               </div>
               <div className="col-lg-5 col-md-5 col-12 mt-2  mb-5 ">
             <form onSubmit={handleSubmit(onSubmit)} autocomplete="off">
               <div className="formdiv">
           
                   <h2 className="text-center authhead">Get Set Go!</h2>
                   <div className="row justify-content-center"><hr className="signuphr"></hr></div>
                   

                   <div class="input-group">
                 <label>Email</label>	 
                     </div>
                    <div class="input-group_field">
                    <input type="text" name='email'  autocomplete="off" onChange={handleinput} placeholder="Enter Username or Email" className={`mb-2 ${errors.email && "invalid"}`}  
                    {...register("email", { required: "Email is Required" ,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Invalid email address",
                      }})}
                      onKeyUp={() => {
                        trigger("email");
                      }}/>	 
                      {errors.email && (
                        <small className="errormes ml-2">{errors.email.message}</small>
                      )}
                       </div>
                      
                       <div class="input-group">
                 <label>Password</label>	 
                     </div>
                    <div class="input-group_field">
                    <input  type={passwordShown ? "text" : "password"}  name='password'  autocomplete="new-password" onChange={handleinput}   placeholder="Enter Password" className={`mb-2 ${errors.password && "invalid"}`}  
                    {...register("password", { required: "Password is Required",
                    pattern: {
                      value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                      message: "Password is Required",
                    },
                    
                   })}
                    
                    onKeyUp={() => {
                      trigger("password");
                    }}/>	 
                    {errors.password && (
                      <small className="errormes ml-2">{errors.password.message}</small>
                    )}
                    <span>     <i className='eye' onClick={togglePasswordVisiblity}>{eye }</i> </span>
                       </div>
     
                     
                       <div className='row justify-content-center'>
                  
                    
                       </div>
                      
<div className="row justify-content-center ">
<button className="btn authbutn">{loginame}</button>



</div><br></br>

             <div className="row justify-content-center mt-1" >
   
    
                 
             </div>
             <div className="row justify-content-center  mt-5">
             <div className='col'><NavLink to="/signup" style={{textDecoration:"none"}} > <p className="spanhrtext2">Not a Member? Signup</p></NavLink></div>
             <div className='col offset-1'><NavLink to="/forgotpass" style={{textDecoration:"none"}}><p className="spanhrtext2">Forgot Password?</p></NavLink></div>
</div>

               </div>
               </form>
               </div>
               
           </div>
           
       </div>
       </div>
     
     


         
     <Footer/>
      </section>
        
    </div>
  )
}

export default UniLogin