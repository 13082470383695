import React, { useState, useEffect } from "react";
import "./Dynamicpage.css";

import { Link } from "react-router-dom";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import {Helmet} from "react-helmet";
import axios from "axios";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  const API_Key = process.env.REACT_APP_API_URL;
  useEffect(() => {
    const getBlogs = async() => {
      try{
        const res = await fetch(`${API_Key}/getallblogs`,{
            method:"GET",
            headers:{
              mode: 'cors',
              'Access-Control-Allow-Origin':`${API_Key}`,
              'Content-Type': 'application/json',
              'Access-Control-Allow-Credentials': true,
            },
            credentials:"include"
           
        });
        const data = await res.json([]);
       
        setBlogs(data);
      
    
        if(res.status !== 200 ){
            const error = new Error(res.error);
            throw error;
            
        }
        
     
    
    
    }catch(err){
      console.log(err);
      //navigate("/signin");
    
    } 
    
    
    };

    getBlogs();
  }, []);
 
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
<Helmet>
<title>Our Latest Updated Blog and News | Hackers Prey</title>

<meta name="description" content="Our blog section page will provide you latest cyber news and knowledgeable information regarding hacking. We update it on regular basis. Check it out now."></meta>
</Helmet>
      <section className="Dynamicbackground">
 

        <div className="container mt-5">
          <div class="Heading-main">
            <span>BLOGS</span>
            <h2>BLOGS</h2>
            <hr className="offset-md-5 offset-4"></hr>
          </div>
          <div  className="row justify-content-center">
         
          </div>
        </div>
      </section>

      <div className="mainconsection">
        <div className="menu-items container-fluid ml-0 mt-0">
          <div className="row">
            <div className="col-10 col-9 mx-auto ">
              <div className="row-lg-12 my-5 ">
                 
             
              <ResponsiveMasonry
                  columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
                >
                  <Masonry columnsCount={4} gutter="10px">
                    {blogs &&
                      blogs.map((elem) => {
                      
                        const {
                          author,
                          title,
                          author_profile,
                          cover_image,
                          designation,
                        } = elem;
                        return (
                          <Link style={{textDecoration:"none"}} to={{
                            pathname: '/'+elem.blogroute,
                            
                          }} > <div className="col-lg-12 col-md-8 col-12 ">
                            <div
                              className="card "
                              id="blogcard"
                              style={{
                                width: `100%`,
                                height: "auto",
                                margin: "2rem",
                              }}
                            >
                              <div className="card-upper">
                                <img
                                  src={cover_image}
                                  className="img-fluid"
                                  alt=""
                                />
                            
                              </div>
                              <div id="card-body">
                                <h6 id="blog-heading">{title}</h6>
                                <img
                                  src={author_profile}
                                  alt="usermage"
                                  className="userimage"
                                />
                                <span className="username_span">{author}</span>
                                <p className="userpara">{designation}</p>
                              </div>
                            </div>
                          </div>
                          </Link>
                        );
                      })}
                  </Masonry>
                </ResponsiveMasonry>
              
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default Blog;