import React from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const FAQ12 = ({ faq, index, toggleFAQ }) => {
    return (
        <div
            className={`faq ${faq.open ? "open" : ""}`}
            key={index}
            onClick={() => toggleFAQ(index)}
        >
            <div className="faq-question">
                {faq.question}
                {/* Toggle arrow icon based on the faq.open state */}
                {faq.open ? <IoIosArrowUp className="faq-icon" /> : <IoIosArrowDown className="faq-icon" />}
            </div>
            <div className="faq-answer">{faq.answer}</div>
        </div>
    );
};

export default FAQ12;
