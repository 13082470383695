import React,{useState,useEffect} from 'react'
import Account from "../../../Assets/Modals/Deactivate Account.png"
import { ImCross } from 'react-icons/im'
import Accountdeactivatedsucces from "../Modals/Accountdeactivatedsucces";
import Wentwrong from './Wentwrong';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { data } from 'jquery';
import {cookies} from "react-cookie";
const DeactivateAccount = ({setOpenModal}) => {
  const[modalOpen,setModalOpen]=useState(false);
  const[modalOpen2,setModalOpen2]=useState(false);
  const navigate=useNavigate();
  const[scrf,setscrf]=useState("");
  const API_Key = process.env.REACT_APP_API_URL;
  useEffect(() => {
    const getCsrfToken = async () => {
        const { data } = await axios.get(`${API_Key}/auth/csrf-token`,{ withCredentials: true });
        axios.defaults.headers.post['X-CSRF-Token'] = data.csrfToken;
      
        setscrf(data.csrfToken);
      
      
     
      
    };
    getCsrfToken();
}, []);
  const Deactiaccount = async(e)=>{
    e.preventDefault();
    fetch(`${API_Key}/deactivateUser`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        mode: 'cors',
         'X-CSRF-TOKEN': scrf,
        'Access-Control-Allow-Origin':`${API_Key}`,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': true,
      },
      credentials:"include",
   
  
    })
      
    //Then with the data from the response in JSON...
    .then((res) => {
     if(res.status===200){
       setModalOpen(true);
       
       navigate("/logout")
       

     }
    })
    //Then with the error genereted...
    .catch((error) => {
      console.error('Error:', error);
      setModalOpen2(true);
    
    });
         
              

}
  return (

    
    <div>
    {modalOpen && (
      <Accountdeactivatedsucces
       
        setOpenModal={setModalOpen}
      />
    )}
    {modalOpen2 && (
      <Wentwrong
       
        setOpenModal={setModalOpen2}
      />
    )}
    <section className="modalBackground justify-content-center" >
    <div className="container  justify-content-center">
    
      <div className="row justify-content-center ">
      
        <div className="col-lg-8 col-md-10 col-10 p-1 modalContainer" >
        <div className='mt-5 crossbtnarrow mr-5'>
        
        <button
          onClick={() => {
            setOpenModal(false);
          }}
        >
        <div className='text-center'><ImCross style={{height:".6rem",marginLeft:"-1.9rem",marginTop:".5rem"}}/></div> 
        </button>
      </div>


<div className='row justify-content-center'>
<div className='col-lg-3 col-md-3 col-3 offset-1 '>
<div className='text-center'><img src={Account} alt="Deactivate Account"  className='img-respoonsive' style={{height:"50vh",width:"80vw",marginBottom:"5rem"}} /></div></div>
<div className='col-lg-7 col-md-6  col-7 '>
<div className='justify-content-center'><h2 className='text-center alertmainhead2'>Deactivate Account!!<div className='row justify-content-center'><span><hr className='text-center alertcommonhr'></hr></span></div></h2>  
</div> 
<p className='resetpara'>
Account deactivation will block your access to watch videos and run challenges. Are you sure you want to deactivate your account?</p>

<div className='row justify-content-center mt-5'>
<div className='col-lg-5 col-md-5 col-6 '>
<div className='canclbtn' onClick={() => setOpenModal(false)}>CANCEL</div></div>
<div className='col-lg-5 col-md-5 col-6 '>
<div className='acceptbtn' onClick={Deactiaccount} >CONFIRM</div></div>
</div>
</div>

</div>

        </div></div></div></section>
    </div>
  )
}

export default DeactivateAccount