import React,{useEffect} from 'react'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import employ from "../../Assets/Entity/HalloffameUI/asset6.png"
import vulnerbility from "../../Assets/Entity/HalloffameUI/icon1.png"
import sharevideo from "../../Assets/Entity/HalloffameUI/icon2.png";
import getintouch from "../../Assets/Entity/HalloffameUI/icon3.png"
import getintuch from "../../Assets/Entity/HalloffameUI/asset5.png";
import Record from "../../Assets/Landing page/arrow 2.png";
import halloffameimage from "../../Assets/Entity/HalloffameUI/explorewinners.png"
import 'swiper/css';

import {Swiper,SwiperSlide} from "swiper/react";



import { Pagination,EffectCoverflow } from "swiper";
import { NavLink } from 'react-router-dom'
const Halloffame = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
    
    <section className="Dynamicbackground">
    <div className="container mt-5">
    <div class="Heading-main">
      <span className='spanleaderboard'>BUG BOUNTY</span>
      <h2>BUG BOUNTY</h2>
      <hr className="offset-md-5 offset-4"></hr>
    </div>
   
  </div>
    </section>
    <div id='mainconsection'>
    <div className='container'>
    <div  className="row justify-content-center p-5">
    <h1 className='univerhead3'>A  phased <span>approach</span></h1>
    <div className='col-lg-12'><p className='univerpara2 mt-3'>At HackersPrey, we value your commitment to the security of our portal. We believe in transparency and collaboration to enhance our portal’s robustness. If you've discovered a potential vulnerability, we encourage you to report it to us following these steps: </p></div>
    
    </div>
    <div className='row justify-content-center mb-5'>
    <div className='col-lg-4 col-md-4 col-10'>
    <div className='card' id='halloffamecard'>
 
    <div className='row justify-content-center' id='uniadvantegphotodic2'>
    
    <img src={employ}  style={{height:"3rem"}}  id="check" alt="curriculu"/>
    </div>
    <div className='row pl-5'><h2 className='advantagehead3'>01</h2></div>
    <div className='row justify-content-center  mb-2 p-0 pl-5' id='uniadvantegphotodic2'>
    
    <img src={vulnerbility}  style={{height:"10rem"}}  id="check" alt="curriculu"/>
    </div>

    <div className='row justify-content-center' >
    
    <h1 className='advantagehead2'>Identify vulnerability & its Impact</h1>
    
    </div>
    <div className='row justify-content-center p-4 mb-5' >
   
    <p className='advantagepara2'>Begin by gaining a comprehensive understanding of the vulnerability and meticulously document all relevant details. Following this, assess the potential security risks associated with it, taking into account the broader impact it may have. </p>
    
    </div>
    <div className='row justify-content-center' id='uniadvantegphotodic2 '>
    
    <img src={employ}  style={{height:"3rem",transform: "rotate(180deg)"}}  id="check" alt="curriculu"/>
    </div>
    </div>
    </div>

    <div className='col-lg-4 col-md-4 col-10'>
    <div className='card' id='halloffamecard'>
 
    <div className='row justify-content-center' id='uniadvantegphotodic2'>
    
    <img src={employ}  style={{height:"3rem"}}  id="check" alt="curriculu"/>
    </div>
    <div className='row pl-5'><h2 className='advantagehead3'>02</h2></div>
    <div className='row justify-content-center  mb-2 p-0 pl-2' id='uniadvantegphotodic2'>
    
    <img src={sharevideo}  style={{height:"10rem"}}  id="check" alt="curriculu"/>
    </div>

    <div className='row justify-content-center ' >
    
    <h1 className='advantagehead2'>Share Video of POC</h1>
    
    </div>
    <div className='row justify-content-center p-4 mb-5' >
    
    <p className='advantagepara2'>Produce a concise, informative video showcasing the exploitation of the vulnerability. Include a clear, step-by-step guide for replicating the issue. Ensure clear communication and careful redaction of sensitive data.</p>
    
    </div>
    <div className='row justify-content-center' id='uniadvantegphotodic2 '>
    
    <img src={employ}  style={{height:"3rem",transform: "rotate(180deg)"}}  id="check" alt="curriculu"/>
    </div>
    </div>
    </div>
    <div className='col-lg-4 col-md-4 col-10'>
    <div className='card' id='halloffamecard'>
 
    <div className='row justify-content-center' id='uniadvantegphotodic2'>
    
    <img src={employ}  style={{height:"3rem"}}  id="check" alt="curriculu"/>
    </div>
    <div className='row pl-5'><h2 className='advantagehead3'>03</h2></div>
    <div className='row justify-content-center  mb-2 p-0 pl-1' id='uniadvantegphotodic2'>
    
    <img src={getintouch}  style={{height:"10rem"}}  id="check" alt="curriculu"/>
    </div>

    <div className='row justify-content-center' >
    
    <h1 className='advantagehead2'>Get In Touch with us</h1>
    
    </div>
    <div className='row justify-content-center p-4 mb-5' >
    
    <p className='advantagepara2'>To initiate contact with the HackersPrey security team, please email us at helpdesk@hackersprey.com. Ensure your report is thorough, encompassing findings, a PoC video, and mitigation recommendations. </p>
    
    </div>
    <div className='row justify-content-center' id='uniadvantegphotodic2 '>
    
    <img src={employ}  style={{height:"3rem",transform: "rotate(180deg)"}}  id="check" alt="curriculu"/>
    </div>
    </div>
    </div>
    </div>
    <div className='row justify-content-center p-5 mt-2'>
   

    <div className='col-lg-8 col-md-8 col-10 mt-5'>
    <h1 className='univerhead3'>Our Course of Action</h1>
    <p className='univerpara2 mt-3'>When you report a security issue and share your Proof of Concept (PoC), our security team will diligently assess it. We will reward bounties for valid, previously unknown vulnerabilities that have not been reported before, with the bounty amount determined by the impact and severity of the vulnerability. </p>
   
    </div>
    <div className='col-lg-4 col-md-4 col-10'>
    <img src={getintuch} alt="gettouch"/>
    </div>
    </div>
    <div className='row justify-content-center mt-5'>
    <h1 className='univerhead2'>Out-Of-Scope Vulnerabilities</h1>
    </div>
    <div className=" row justify-content-center mt-2 ">
    <hr className="universityhr"></hr> 
    
    </div>
    <div className='row justify-content-center mt-5 p-1'>
    <div className='col-lg-7 col-md-10 col-10 mb-5'>
    <ul className='intershipbullet mt-5' style={{margin:"1rem"}}>
    <li className='internbulletpara'><span><img src={Record} style={{height:"18px",marginRight:"2rem"}} /></span>Exploits using runtime changes</li>
    <li className='internbulletpara'><span><img src={Record} style={{height:"18px",marginRight:"2rem"}} /></span>Irrelevant activities/intents exported</li>
    <li className='internbulletpara'><span><img src={Record} style={{height:"18px",marginRight:"2rem"}} /></span>Denial of service attacks</li>
    <li className='internbulletpara'><span><img src={Record} style={{height:"18px",marginRight:"2rem"}} /></span>Social engineering attacks</li>
    <li className='internbulletpara'><span><img src={Record} style={{height:"18px",marginRight:"2rem"}} /></span>Software version disclosure</li>
    <li className='internbulletpara'><span><img src={Record} style={{height:"18px",marginRight:"2rem"}} /></span>Issues requiring exceedingly unlikely user interaction</li>
    <li className='internbulletpara'><span><img src={Record} style={{height:"18px",marginRight:"2rem"}} /></span>Cross-site Request Forgery (CSRF) with minimal security implications </li>
    <li className='internbulletpara'><span><img src={Record} style={{height:"18px",marginRight:"2rem"}} /></span>CSP Weaknesses</li>




    
    </ul>
    </div>
    <div className='col-lg-5 col-md-10 col-10 mb-5'>
    <ul className='intershipbullet mt-5' style={{margin:"1rem"}}>
    <li className='internbulletpara'><span><img src={Record} style={{height:"18px",marginRight:"2rem"}} /></span>Application crashes</li>
    <li className='internbulletpara'><span><img src={Record} style={{height:"18px",marginRight:"2rem"}} /></span>Lack of obfuscation</li>
    <li className='internbulletpara'><span><img src={Record} style={{height:"18px",marginRight:"2rem"}} /></span>Phishing attacks</li>
    <li className='internbulletpara'><span><img src={Record} style={{height:"18px",marginRight:"2rem"}} /></span>Email enumeration</li>
    <li className='internbulletpara'><span><img src={Record} style={{height:"18px",marginRight:"2rem"}} /></span>Missing cookie flags on non-authentication cookies</li>
    <li className='internbulletpara'><span><img src={Record} style={{height:"18px",marginRight:"2rem"}} /></span>Email Spoofing</li>



  
    </ul>
    </div>
  
    
    </div>
   

    
 
    <NavLink to="/halloffame" style={{"textDecoration":"none"}}><div className='row pb-5 mt-5 mb-5 justify-content-center'>
    <div className='col-lg-8 col-md-8 col-10'>   <img src={halloffameimage} style={{borderRadius:"2rem"}} alt="halloffame" /></div>


   </div></NavLink>



   <div className='row justify-content-center mt-5'>
   <br></br>   <br></br>
   <h1 className='univerhead2'>Responsible Disclosure Statement</h1>
   </div>
   <div className=" row justify-content-center mt-2 ">
   <hr className="universityhr"></hr> 
   
   </div>
   <p className='univerpara2 mt-3'>At HackersPrey, we value the insights of Whitehat hackers. However, if you discover a vulnerability that could compromise customer data or system integrity, please refrain from exploiting it. We appreciate your help in safeguarding HackersPrey and our customers' information and may pursue legal action if vulnerabilities are used for illicit purposes. Thank you for aiding us in maintaining a secure environment. </p>

   </div>

    </div>
    <Footer/>
    </>
  )
}

export default Halloffame