import React from 'react';
import { Link } from 'react-router-dom';
const links=[
  {
    'link':'/acw-cyber-security-certification-course',
    'title':'web hacking course'
  },
  {
    'link':'/acw-cyber-security-certification-course',
    'title':'Active directory course'
  },
  {
    'link':'/acw-web-exploitation-course',
    'title':'web app pentesting'
  },
  {
    'link':'/acw-cyber-security-certification-course',
    'title':'Active directory training'
  },
  {
    'link':'/acw-cyber-security-certification-course',
    'title':'Online Cybersecurity course'
  },
  {
    'link':'/acw-cyber-security-certification-course',
    'title':'Network scanning course'
  },
  {
    'link':'/acw-web-exploitation-course',
    'title':'web exploitation course'
  },
  {
    'link':'/industrial-training-in-cybersecurity',
    'title':'6 Months Industrial Training In Cybersecurity'
  },
  {
    'link':'/industrial-training-in-cybersecurity',
    'title':'Cybersecurity Industrial Training In Chandigarh'
  },

  {
    'link':'/industrial-training-in-cybersecurity',
    'title':'Industrial Training In Cybersecurity'
  },
  {
    'link':'/industrial-training-in-cybersecurity',
    'title':'Industrial Training In Chandigarh'
  },
  {
    'link':'/acw-cyber-security-certification-course',
    'title':'cybersecurity course in Chandigarh'
  },

  {
    'link':'/acw-cyber-security-certification-course',
    'title':'network penetration testing training'
  },
  {
    'link':'/industrial-training-in-cybersecurity',
    'title':'Online Cybersecurity course'
  },
  
 
  {
    'link':'/stack-xploit-cyber-security-course',
    'title':'Stack Exploitation Course'
  },
  {
    'link':'/acw-cyber-security-certification-course',
    'title':'Cybersecurity Training In Chandigarh'
  },
  {
    'link':'/industrial-training-in-cybersecurity',
    'title':'Cybersecurity course in Chandigarh'
  },

  {
    'link':'/industrial-training-in-cybersecurity',
    'title':'Summer training in Cybersecurity'
  },
  {
    'link':'/industrial-training-in-cybersecurity',
    'title':'Winter training in Cybersecurity'
  },
  {
    'link':'/acw-cyber-security-certification-course',
    'title':'Cybersecurity Certification Course'
  },
  {
    'link':'/acw-cyber-security-certification-course',
    'title':'cybersecurity networking course'
  },
  {
    'link':'/industrial-training-in-cybersecurity',
    'title':'cybersecurity training in Chandigarh'
  },
  {
    'link':'/acw-cyber-security-certification-course',
    'title':'ethical hacking course in Chandigarh'
  },
  {
    'link':'/stack-xploit-cyber-security-course',
    'title':'StackXploit Cyber Security course'
  },
  {
    'link':'/acw-cyber-security-certification-course',
    'title':'penetration testing training'
  },
  {
    'link':'/acw-web-exploitation-course',
    'title':'web penetration testing'
  },
  
 


]

const InternalLinking = () => {
  return (
<div className='internal-linking-training'>
    <p className='heading'>Popular Courses</p>
    <div className='content'>
      {
        links?.map((x,index)=>(
         <> <Link className='link-title' to={x.link}><span>{x.title}</span></Link>{index+1!==links.length ?'|' : ''}</>
        ))
      }
     
      </div>
</div>
  )
} 

export default InternalLinking;



