import React,{useState,useEffect} from "react";
import "./Webinar.css";
import WebinarImg from "../../Assets/webinar/logo.png";
import StackImg from '../../Assets/webinar/stackxploit.png';
import WebpwnImg from '../../Assets/webinar/webpwn.png'
import { IoMdPlay } from "react-icons/io";
import { FaRegCalendarAlt } from "react-icons/fa";
import { FaRegClock,FaVideo } from "react-icons/fa";


import WebinarCourseCard from "./WebinarCourseCard";
import WebinarImage from '../../Assets/webinar/image.png'
import Footer from "../Footer/Footer";


import axios from "axios";

const API_Key = process.env.REACT_APP_API_URL;
/*const initialValues={
  firstname:'',
  email:'',
  mobilenumber:'',
  city:'',
  state:'',
  experience:'experience',
}
const validationSchema = Yup.object({
  firstname: Yup.string().required("Required!!"),
  email: Yup.string().email("Invalid email").required("Required"),
  mobilenumber:Yup.number("Must be a number").required("Required"),
  city:Yup.string().required("Required"),
  state:Yup.string().required("Required!!")
}); */


const Webinar =() => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [register,setRegister]=useState(false)
  const handleSubmit=async(values,onSubmitProps)=>{
    try{
      onSubmitProps.resetForm()
    
      const eventDetails=await axios.post(`${API_Key}/userEventParticipant`,values);
      setRegister(true);
  
    }
    catch(error){

    }
   
    }
    const stackPoints=['Hands-on experience in bypassing security mechanisms found in linux binaries','Engage in self-paced learning with the StackXploit Cyber Security course','Industry-standard curriculum','Elevate your expertise in cyber security']
    const webPwnPoints=['A Professional Web Hacking Course','Comprehensive coverage and practical exercises on all OWASP Top 10 vulnerabilities','70 Real World Hacking Challenges','Access To PreyGround']
    return (
    <>
      <div className="webinar-container">
        <div className="webinar-hero">
          <div className="webinar-hero-left">
            <h3 className="webinar-hero-left-heading">
              Attend a <span className="">Free Webinar</span> on:
            </h3>
            <p className="webinar-hero-left-subheading">Bypassing Antivirus</p>
          
            <a className="webinar-link2" href='https://forms.gle/wCuTA2gaYgvwn3Z3A'  target="_blank"> <h1 className="calendar-flex3">
                Register Now
              </h1></a>
          </div>
          {/*  
          <div className="webinar-hero-right">
            <p className="form-heading">Book a <span className="text-red">free Webinar now!</span></p>
            <Formik initialValues={initialValues}  onSubmit={handleSubmit}  validationSchema={validationSchema}>
              {(formik)=>{
                console.log(formik.errors) 
                return(
                <Form className="webinar-form">
                 {register ? <p className="webinar-success-component">Registration Successfull!!</p> : null} 
                  <Field className="w-input-field" type="text" id="firstname" name="firstname" placeHolder="Name"/>
                  <ErrorMessage component="div" className="webinar-error-component" name="firstname" ></ErrorMessage>
                  <Field className="w-input-field"  type="text" id="email" name="email" placeHolder="Email"/>
                  <ErrorMessage component="div" className="webinar-error-component" name="email" ></ErrorMessage>
                  <Field className="w-input-field" type="text" id="mobilenumber" name="mobilenumber" placeHolder="Mobile Number"/>
                  <ErrorMessage component="div" className="webinar-error-component" name="mobilenumber" ></ErrorMessage>
                  <div className="w-input-field-cont">
                    <div className="webinar-state">
                    <Field className="w-input-field" type="text" id="city" name="city" placeHolder="City"/>
                    <ErrorMessage component="div" className="webinar-error-component" name="city" ></ErrorMessage>
                    </div>
                    <div className="webinar-state">
                    <Field className="w-input-field" type="text" id="state" name="state" placeHolder="State"/>
                    <ErrorMessage component="div" className="webinar-error-component" name="state" ></ErrorMessage>
                    </div>
                  
                  
                  </div>
                  <Field as="select" className="w-input-field w-select-field" type="text" id="experience" name="experience" placeHolder="Experience">
                    <option className="select-option" value="experience">Experience</option>
                    <option className="select-option" value="professional">Working Professional</option>
                    <option className="select-option" value="college-final">College Student -Final Year</option>
                    <option className="select-option" value="college-prefinal">College Student - 1st to Pre-Final Year</option>
                    <option className="select-option" value="others">Others</option>
                  </Field>
                  <ErrorMessage component="div" className="webinar-error-component" name="experience" ></ErrorMessage>
                  <p className="webinar-form-terms">Please be careful while entering your details while registering since they will go on your Certificates.</p>
                  <button type="submit" className="web-register-btn2" disabled={!formik.isValid}>Register</button>
                  
                </Form>
              )}}
            </Formik>
            </div>  */}

<div className="webinar-hero-right">
<div>
              <h1 className="calendar-flex">
                <FaRegCalendarAlt className="calendar-icon" color="white" />
                20th April, 2024 
              </h1>
              <h1 className="calendar-flex">
                <FaRegClock className="calendar-icon" color="white" />
                Time: 11:00 PM
              </h1>
              <h1 className="calendar-flex">
                <FaVideo className="calendar-icon" color="white" />
                Mode: Online
              </h1>
        
            </div>
            </div>
        </div>
        <section className="webinar-courses-card-section">
          <h1 className="webinar-section-heading">About the Webinar</h1>
          <div className="red-line-class">
            <hr className="binaryhr"></hr>
          </div>
          <div className="about-us-container-wrapper">
            <img className="webinar-img" src={WebinarImage}/>
            
          <h7 className="about-us-container">Welcome to our exclusive online webinar on Bypass (AV) Antivirus . At Hackersprey, we are dedicated to providing a sophisticated perspective on cybersecurity and ethical hacking.<br/>
          <p className="margintop-4"> In this online webinar, we go into a deeper depth of the techniques employed to bypass antivirus (AV) systems, covering topics such as circumventing AV sandboxes, evading static detection mechanisms, and mastering AV unhooking strategies.</p>
          <br/>Whether you're a seasoned cybersecurity professional or an aspiring ethical hacker, join us as we uncover the secrets behind effectively bypassing AV systems, empowering you with invaluable knowledge to enhance your defensive and offensive cybersecurity capabilities. Don’t miss this opportunity to stay ahead of the ever-changing cybersecurity landscape.
         <br/>
</h7>

</div>




        </section>
        <section className="webinar-courses-card-section">
          <h1 className="webinar-section-heading">Benefits for Attendees</h1>
          <div className="red-line-class">
            <hr className="binaryhr"></hr>
          </div>
          <div className="webinar-key">
            <div className="webinar-key-list"><h7>Insights from <span className="text-red2">Industry Experts</span></h7></div>
            <div className="webinar-key-list"><h7><span className="text-red2">E-certificate</span> for Participants</h7></div>
            <div className="webinar-key-list"><h7>Free <span className="text-red2">Career Guidance</span></h7></div>
            <div className="webinar-key-list"><h7><span className="text-red2">Exclusive Offer</span> for Attendees</h7></div>
            <div className="webinar-key-list"><h7><span className="text-red2">Deep Insights</span> on AV Bypass</h7></div>
            <div className="webinar-key-list"><h7>Get Prepared for the <span className="text-red2">cybersecurity job</span></h7></div>
          
            
          </div>




        </section>
        <section className="webinar-courses-card-section">
          <h1 className="webinar-section-heading">Start your path to success with our courses</h1>
          <div className="red-line-class">
            <hr className="binaryhr"></hr>
          </div>
          <div className="webinar-card-container">
            <WebinarCourseCard image={StackImg} title="StackXploit" features={stackPoints} link="/stack-xploit-cyber-security-course" />
            <WebinarCourseCard image={WebpwnImg} title="WEBPWN" features={webPwnPoints} link="/webpwn-professional-web-hacking-course"/>
          </div>
        </section>
      </div>
      <Footer/>
    </>
  );
};

export default Webinar;
