import React, { useState } from "react";
import "./Faq.css";
import { FaChevronRight,FaChevronDown } from "react-icons/fa";
import { TfiDownload } from "react-icons/tfi";



const StackXploit2FAQs = ({faq,heading}) => {
  const [selectedState, setSelectedState] = useState("");
  const handleClick=(index)=>{
if(index===selectedState){
    setSelectedState("")
}
else{
    setSelectedState(index)
}
}
  
  return (
    <div className="curr-container2 ">
      <div className="course-cur-head2">
      
        <div className="bg-faq2">
        <div className="course-cur-head margin-class"><p>{heading}</p></div>
       
        <div className=" row justify-content-center">
            <hr className="binaryhr2"></hr>
          </div>
        
          <main className="faq-container ">
            {faq.map((section, index) => (
              <div className="section-faq-container2">
                <div
                  onClick={() => handleClick(index)}
                  className="section-faq-name"
                >
                  {section.sectionName}
                  {selectedState === index   ? <FaChevronDown className="icon" size={13} />:  <FaChevronRight className="icon" size={13} />
}</div>
                {selectedState === index  ? (
                  <p className="section-faq-description2">{section.sectionDesc}</p>
                ) : null}
              </div>
            ))}
          </main>
        </div>
      
      </div>
     
    </div>
  );
};

export default StackXploit2FAQs;
