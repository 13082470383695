import React,{useState,useEffect} from 'react'
import {useParams} from 'react-router-dom'
import axios from 'axios';
import Loading from '../Dashboard/Modals/Loading';
import Wentwrong from '../Dashboard/Modals/Wentwrong';
import Universityrequestaccept from '../Dashboard/Modals/Universityrequestaccept';

import { useNavigate } from 'react-router-dom';

const Requestaccepted = () => {
    const[modalopen,setModalOpen]=useState(false);
    const[modalopen2,setModalOpen2]=useState(false);
    const[modalopen3,setModalOpen3]=useState(false);
  
    const navigate=useNavigate();
      const {token} = useParams();
      const API_Key = process.env.REACT_APP_API_URL;
      useEffect(() => {
        setModalOpen(true);
        if(token){
            const activationEmail = async () => {
                try {
                    const res = await axios.post(`${API_Key}/university-request-verify`, {token})
            if(res.status===200){
            
              
              setModalOpen(false);
              setModalOpen2(true);
            
            }
                } catch (err) {
                 
                  setModalOpen3(true)
                  setModalOpen(false);
                   
                }
            }
            activationEmail()
        }
    },[token])
  return (
    <div>
    {modalopen && <Loading setOpenModal={setModalOpen}/> }  
    {modalopen2 && <Universityrequestaccept setOpenModal={setModalOpen2}/> }  
    {modalopen3 && <Wentwrong setOpenModal={setModalOpen3}/> } 

    </div>
  )
}

export default Requestaccepted