import React from 'react'
import { GrFormCheckmark } from "react-icons/gr";
import IMAGES from '../../Assets/industrial.png';
import {motion} from 'framer-motion';
const SummerTrainingCertificate = () => {
    return (
        <div className="training-highlight-section3 int-observe ind-certificate" data-name="5">
             <div className='training-highlight-section-right'> 
    <motion.img initial={{scale:0.7,opacity:0.3}} whileInView={{scale:1,opacity:1}} viewport={{once:true}} transition={{duration:0.8}} src={IMAGES} className='certi-image'/>
               
        </div>
        <motion.div initial={{opacity:0.3}} whileInView={{scale:1,opacity:1}} viewport={{once:true}} transition={{duration:2}} className='training-highlight-section-left'> 
        <h3 className='training-heading'>Industry Recognized Certification</h3>
        <p className='training-desc'>Completing the 6 Months Industrial Training in Cybersecurity at Hackersprey awards you with a highly regarded Cybersecurity certification. This credential not only recognizes your cybersecurity expertise but also signals your commitment to the field, making you stand out to industry professionals and employers. The certification from our Cybersecurity Training in Chandigarh boosts your employability, unlocks a range of career opportunities, and reinforces your dedication to continuous professional development. This Cybersecurity certification serves as proof of the practical skills and knowledge you've gained through our rigorous, Cybersecurity Industrial Training In Chandigarh, providing you with a competitive advantage in the cybersecurity landscape.

    </p>
        </motion.div>
       
    
    </div>
      )
}

export default SummerTrainingCertificate
