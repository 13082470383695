
import React,{useState,useEffect,useRef} from 'react'
import { ImCross } from 'react-icons/im';
import Submitflagsucess from "../Submitflagsucess";
import Loading from "../Loading"
import Wentwrong from '../Wentwrong';
import Wrongotp from '../Wrongotp';
import { useForm } from "react-hook-form";
import { useNavigate,useLocation } from 'react-router-dom';
import Toomanyattempts from '../Toomanyattempts';

import Leaveteamsuccess from './Leaveteamsuccess';
import Teamnotfound from './Teamnotfound';

const Leaveteams = ({setOpenModal,email,subid}) => {
    const {register,handleSubmit,formState: { errors },reset,watch,trigger,} = useForm();
    const navigate=useNavigate();
    const[commonstate,setcommonstate]=useState(subid);
    const API_Key = process.env.REACT_APP_API_URL;
    const[modalopen,setmodalopen]=useState(false);
    const[modalopen2,setmodalopen2]=useState(false);
    const[modalopen3,setmodalopen3]=useState(false);
    const[modalopen4,setmodalopen4]=useState(false);
    const[modalopen5,setmodalopen5]=useState(false);
    const[modalopen6,setmodalopen6]=useState(false);
    const[wrongotperror,setwrongotperror]=useState(false);

    const onSubmit=(data)=>{
      setmodalopen(true);
      fetch(`${API_Key}/exit_team_otpverify`, {
        method: 'POST',
        body: JSON.stringify({
          otp:data.otp
      }),
        headers:{
          Accept: "application/json",
          mode: 'cors',
          'Access-Control-Allow-Origin':`${API_Key}`,
        'Content-type': 'application/json',
          
          'Access-Control-Allow-Credentials': true,
        },
        credentials:"include",
      
       
      }).then((res)=>{
        if(res.status===200){
          setmodalopen(false);
          setmodalopen5(true);
        }else if(res.status===500)
        setmodalopen(false);
          setmodalopen6(true);

      })
      .catch((err)=>{
        
      })
       
                  
        
    }
  return (
    <div >
    {modalopen && <Loading setOpenModal={setmodalopen} />}
    {modalopen2 && <Wrongotp setOpenModal={setmodalopen2} />}
    {modalopen3 && <Wentwrong setOpenModal={setmodalopen3} />}
    {modalopen4 && <Toomanyattempts setOpenModal={setmodalopen4} />}
    {modalopen5 && <Leaveteamsuccess setOpenModal={setmodalopen5} />}
    {modalopen6 && <Teamnotfound setOpenModal={setmodalopen6} />}
    <section className="modalBackground justify-content-center"  >
    <div className="container  justify-content-center" >
    
      <div className="row justify-content-center">
      
        <div className="col-lg-8 col-md-10 col-10  modalContainer" >
        
<h2 className='text-center alertmainhead2'>Leave team ? <div className='row justify-content-center'><span><hr className='text-center alertcommonhr'></hr></span></div></h2>  


<div className='row justify-content-center'>

<div className='col-lg-10 col-md-6  col-9 '>
<p className='resetpara'>OTP has been sent to your registered email address</p>

<div className='row justify-content-center mt-2'>
<form  className='col-lg-8' onSubmit={handleSubmit(onSubmit)} >
<div className="form-input justify-content-center ">
    
<div className="col-12 justify-content-center ">

<input
  name="otp"
  type="password"
  className={`form-control  ${errors.otp}`}
  placeholder="Enter Your Otp"

  {...register("otp", { required: "OTP is Required" ,
                        pattern: {
                          value: /^[0-9]{6}(?:-[0-9]{4})?$/,
                        
                        }})}
                        onKeyUp={() => {
                            trigger("otp");
                          }}/>
                          {errors.otp && (
                            <small className="errormes ml-2">{errors.otp.message}</small>
                          )}
{wrongotperror ?<small className="errormes ml-2">You Entered wrong otp</small>:null}

 </div></div>
 <div className='row justify-content-center mt-5'>
<button className=' btn saveratebtn ' > SUBMIT</button>
</div>
</form>
</div>

</div>

</div>

        </div></div></div></section></div>
  )
}

export default Leaveteams