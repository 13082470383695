import React,{useEffect, useState} from 'react'
import "./Authpage.css";
import { useForm } from "react-hook-form";
import Signuppic from "../../Assets/register/Group 6027.svg";

import {useNavigate,NavLink } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import Signupsuccess from '../Dashboard/Modals/Signupsuccess';
import Wentwrong from '../Dashboard/Modals/Wentwrong';
import Emailalready from '../Dashboard/Modals/Emailalready';
import Toomanyattempts from '../Dashboard/Modals/Toomanyattempts';
import Pleaseactivate from '../Dashboard/Modals/Pleaseactivate';
import Loading from '../Dashboard/Modals/Loading';
import {FaEye, FaEyeSlash, FaFacebookF} from "react-icons/fa";
const Signup = () => {
  const faeye=<FaEye/>;
const faeye2=<FaEyeSlash/>
    const navigate=useNavigate();
    const [passwordShown, setPasswordShown] = useState(false);
    const [passwordShown2, setPasswordShown2] = useState(false);
const [eye,seteye]=useState(faeye2);
const [eye2,seteye2]=useState(faeye2);
const togglePasswordVisiblity = () => {
  setPasswordShown(passwordShown ? false : true);
  seteye(faeye);
};
const togglePasswordVisiblity2 = () => {
  setPasswordShown2(passwordShown2 ? false : true);
  seteye2(faeye);
};

    const [modalOpen, setModalOpen] = useState(false);
    const[modalOpen2,setModalOpen2]=useState(false);
    const[modalOpen3,setModalOpen3]=useState(false);
    const[modalOpen4,setModalOpen4]=useState(false);
    const[modalOpen5,setModalOpen5]=useState(false);
    const[modalOpen6,setModalOpen6]=useState(false);
    const {register,handleSubmit,formState: { errors },reset,watch,trigger,} = useForm();
    const password=watch("password")
    const API_Key = process.env.REACT_APP_API_URL;
const[registers,setregister]=useState({
    fname:"",
    email:"",
    phone:"",
    password:"",
    cpassword:"",
    aggrement:""

});
const handleinput=(event)=>{
event.preventDefault();
    setregister((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value,
      }));

}
const onSubmit = async(data)=>{
    //event.preventDefault();
    reset({fname:"",email:"",password:"",cpassword:"",aggrement:false})
    setModalOpen6(true);
    const dataa = { fname:registers.fname,email:registers.email,phone:registers.phone,password:registers.password,cpassword:registers.cpassword,aggrement:registers.aggrement};
    fetch(`${API_Key}/registers`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          mode:"cors",
                  'Access-Control-Allow-Origin':`${API_Key}`,
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Credentials': true
        },
        credentials:"include",
        body: JSON.stringify(data),
      })
      .then((response) => {
     
        if(response.status===200){
          setModalOpen5(true)
          setModalOpen6(false);
        }else if(response.status===422){
          setModalOpen3(true);
          setModalOpen6(false);
        }else if(response.status===429){
          setModalOpen4(true);
          setModalOpen6(false);
        }
      //Then with the data from the response in JSON...
      
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error('Error:', error);
        setModalOpen2(true);
        setModalOpen6(false);
      });
}

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <div>
        {modalOpen && <Signupsuccess setOpenModal={setModalOpen}/>}
        {modalOpen2 && <Wentwrong setOpenModal={setModalOpen2}/>}
        {modalOpen3 && <Emailalready setOpenModal={setModalOpen3}/>}
        {modalOpen4 && <Toomanyattempts setOpenModal={setModalOpen4}/>}
        {modalOpen5 && <Pleaseactivate setOpenModal={setModalOpen5}/>}
        {modalOpen6 && <Loading setOpenModal={setModalOpen6}/>}
            <section className="authbackground">
            
                
         <div id="authpagelinediv">
             <div className="container-fluid">

             <span className="Authpagespan">REGISTER</span>
                 <div className="row justify-content-center">
             
                 <div className="col-lg-6 col-md-6 col-12 offset-md-1  mt-5 ">
                         <img src={Signuppic} alt="signup"  className="img-fluid"/>
                     </div>
                     <div className="col-lg-4 col-md-4 col-12 mt-2  mb-0">
                     <div className="formdiv">
                 
                         <h2 className="text-center authhead">Get Onboard With Us!</h2>
                         <div className="row justify-content-center"><hr className="signuphr"></hr></div>
                         
<form onSubmit={handleSubmit(onSubmit)}>
                         <div class="input-group">
                       <label>Name</label>	 
	                       </div>
                          <div class="input-group_field">
                          <input type="text"  autoComplete='off' onChange={handleinput} name="fname"   placeholder="Enter Your Name"  className={` mb-1 ${errors.fname && "invalid"}`}
                          {...register("fname", { required: "Firstname is Required",
                        pattern:/^[a-zA-Z ]*$/,
                          message:"Please Enter Valid name",
                          
                        })}
                          
                          onKeyUp={() => {
                            trigger("fname");
                          }}/>	
                         
                          {errors.fname && (
                            <small className="errormes ml-2 ">{errors.fname.message}</small>
                          )} 
	                         </div>
                           
                             <div class="input-group">
                       <label>Email</label>	 
	                       </div>
                          <div class="input-group_field">
                          <input type="text" onChange={handleinput} name="email"  autoComplete='off' placeholder="Enter Your Email" className={`mb-1 ${errors.email && "invalid"}`}
                          {...register("email", { required: "Email is Required" ,
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid email address",
                          }})}
                          onKeyUp={() => {
                            trigger("email");
                          }}/>	 
                          {errors.email && (
                            <small className="errormes ml-2">{errors.email.message}</small>
                          )}
                          <span>     <i className='eye2' onClick={togglePasswordVisiblity}>{eye }</i> </span>
	                         </div>
                             {/*<div class="input-group">
                       <label>Phone</label>	 
	                       </div>
                          <div class="input-group_field">
                          <input type="text" maxLength={13} name='phone' autoComplete='off' onChange={handleinput} placeholder="Enter Phone" className={`mb-1 ${errors.phone && "invalid"}`}
                          {...register("phone", { required: "Phone is Required",
                          pattern: {
                            value: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                            message: "Invalid phone no",
                          },
                         })}
                         onKeyUp={() => {
                          trigger("phone");
                        }}/>	 
                        {errors.phone && (
                          <small className="errormes ml-2">{errors.phone.message}</small>
                        )}
                        </div>*/}
                             <div class="input-group">
                       <label>Password</label>	 
	                       </div>
                          <div class="input-group_field">
                          <input type={passwordShown ? "text" : "password"} autoComplete="off" name='password' className={`mb-1 ${errors.password && "invalid"}`}  onChange={handleinput}  placeholder="Enter Password"
                          {...register("password", { required: "Password is Required",
                          pattern: {
                            value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                            message: "Password must have min 8 Characters (A-Z, a-z, 0-9) & Spl Characters",
                          },
                          minLength: {
                            value: 8,
                            message: "Password must have min 8 Characters (A-Z, a-z, 0-9) & Spl Characters",
                          },maxLength:{
                            value: 16,
                            message: "Password should have maximum 16 characters"

                          }
                         })}
                         onKeyUp={() => {
                          trigger("password");
                        }} />	 
                        {errors.password && (
                          <small className="errormes ml-0 ">{errors.password.message}</small>
                        )}
                       
                       
	                         </div>
                             <div class="input-group">
                       <label>Confirm Password</label>	 
	                       </div>
                          <div class="input-group_field">
                          <input type={passwordShown2 ? "text" : "password"} autoComplete="off"  name='cpassword' className={`mb-1 ${errors.cpassword && "invalid"}`} onChange={handleinput} placeholder="Confirm Password"
                          {...register("cpassword", { required: "Confirm password is Required",
                          pattern: {
                            value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                            message: "The Password does not match",
                          },
                          validate:(value)=> value===password ||"the Password does not match",
                         
                         })}
                         onKeyUp={() => {
                          trigger("cpassword");
                        }} />	 
                        {errors.cpassword && (
                          <small className="errormes ml-2">{errors.cpassword.message}</small>
                        )}
                        <span>     <i className='eye3' onClick={togglePasswordVisiblity2}>{eye2 }</i> </span>
	                         </div>
                           <div class="input-group_field mt-4">
                           <input type="checkbox" id="aggrement" className={`mb-1 ${errors.aggrement && "invalid"}`} name="aggrement" 
                           {...register("aggrement",{ required: "please accept aggrement"})}
                           />
                           <label className='acceptagrrehead' for="aggrement"> I accept the <NavLink to="/privacypolicy" style={{textDecoration:"none"}}>Privacy Policy</NavLink>, <NavLink to="/dataprivacy" style={{textDecoration:"none"}}>Data Policy</NavLink> and the  <NavLink to="/termsofservice" style={{textDecoration:"none"}}>Terms of Service</NavLink> </label>
                           
                           </div>
                           {errors.aggrement && (
                            <small className="errormes ml-2">{errors.aggrement.message}</small>
                          )}
 
                     
                           
                       
<div className="row justify-content-center ">
    <button className="btn authbutn">REGISTER</button>
</div>
<div className="row justify-content-center  mt-2">
<div className='col-12'><NavLink to="/signin" style={{textDecoration:"none"}} > <p className="spanhrtext3">Already a Member? Signin</p></NavLink></div>

</div>
</form>
    
                     </div>
                  
                     </div>
                    
                 </div>
             </div>
             </div>
           
 


               <Footer/>
           
            </section>
            
        </div>
    )
}

export default Signup
