import React,{useState} from 'react'
import Footer from '../Footer/Footer'

import ctf from "../../Assets/Events/ctf 1.png";
import weibnar from "../../Assets/Events/webinar 2.png"
import { NavLink } from 'react-router-dom'
const Events = () => {
  const [eventType, setEventType] = useState(null);
  const [hoveredCard, setHoveredCard] = useState(null);
  return (
 <>
<section className='eventbackground'>
<div className="container mt-0">
<div class="Heading-main">

<h3>HACKERSPREY EVENTS</h3>
<div className='row justify-content-center'>

<div className='col-lg-8 col-md-8'>
<p className='eventparagraph'>Explore a plethora of events ranging from web pentesting CTF to webinars covering various hacking topics. These curated cards provide detailed insights into all our upcoming events, ensuring you stay ahead in the ever-evolving realm of cybersecurity.</p>
</div></div>



</div>

</div>
<div className='container'>
      <div className='row justify-content-center'>
        <div className='col-lg-12 col-md-12 col-10 eventallsections p-5'>
          <div className='row justify-content-center'>
            <div className={`col-lg-6 col-md-6 col-10 p-3 ${hoveredCard === "ctf" ? 'zoomed' : ''}`} onMouseEnter={() => setHoveredCard("ctf")} onMouseLeave={() => setHoveredCard(null)}>
              <NavLink to={`/eventsctf/ctf`}>
                <img src={ctf} alt="ctf" />
              </NavLink>
            </div>
            <div className={`col-lg-6 col-md-6 col-10 pt-3 pb-2 ${hoveredCard === "webinar" ? 'zoomed' : ''}`} onMouseEnter={() => setHoveredCard("webinar")} onMouseLeave={() => setHoveredCard(null)}>
              <NavLink to={`/eventsctf/webinar`}>
                <img src={weibnar} alt="webinar" />
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>


</section>
<Footer/>
 </>
  )
}

export default Events