import React, { useState,useEffect } from 'react'

import"./Setting.css";
import Notification from "../../../Assets/Dashboard/Notifications.svg"
import General from "../../../Assets/Settings/Group_400.svg";
import Network from "../../../Assets/Dashboard/Network.svg";
import DownloadFiles from "../../../Assets/Settings/Download Config.svg";
import ResetVpn from "../../../Assets/Settings/Restart VPN.svg"
import{BsShare} from "react-icons/bs";
import axios from 'axios';
import Profileupdated from "../Modals/Profileupdated";
import Wentwrong from "../Modals/Wentwrong";
import Invitefriend from '../Modals/Invitefriend';
import Deactivate from "../Modals/DeactivateAccount";
import Instancesuccess from "../Modals/Instancesuccess"
import { useNavigate, } from 'react-router-dom';
import download from 'js-file-download';
import Resetvpnsucces from "../../Dashboard/Modals/Resetvpnsucces";
import Loading from "../../Dashboard/Modals/Loading";
import Moment from 'react-moment';
import Countdown from 'react-countdown';
const Setting = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [modalOpen3, setModalOpen3] = useState(false);
  const [modalOpen4, setModalOpen4] = useState(false);
  const [modalOpen5, setModalOpen5] = useState(false);
  const [modalOpen6, setModalOpen6] = useState(false);
  const[modalOpen7,setModalOpen7]=useState(false);
  const[buttondisable,setbuttondisable]=useState(false);
  const[usedetail,setuserdetail]=useState("");
  const [item, setItem] = useState("A");
  const [instanceitem, setinstanceItem] = useState("A");
  const[style,setstyle]=useState("settingdiv1");
  const[stylebtns,setstylebtns]=useState("instancebtn");
  const[file,setfile]=useState("");
  const[offer,setoffer]=useState(true);
  const[stackvpn,setstackvpn]=useState("");
  const[instance,setinstance]=useState([]);
  const [instanceinfo,setinstanceinfo]=useState([]);



  const [scrf,setscrf]=useState("");

const offers = ()=>{

}

  useEffect(() => {
    const getCsrfToken = async () => {
        const { data } = await axios.get(`${API_Key}/auth/csrf-token`,{ withCredentials: true });
        axios.defaults.headers.post['X-CSRF-Token'] = data.csrfToken;

        setscrf(data.csrfToken);
   
      
     
      
    };
    getCsrfToken();
}, []);
const navigate=useNavigate();
const API_Key = process.env.REACT_APP_API_URL;
  const fetchuser = async () => {


        
    try{
      const res = await fetch(`${API_Key}/Userloggedin`,{
          method:"GET",
          headers:{
            mode: 'cors',
            'Access-Control-Allow-Origin':`${API_Key}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': true,
          },
          credentials:"include"
         
      });
      const data = await res.json();

      setuserdetail(data);
  setinstance(data?.instance)
 
      
    
  
      if(res.status !== 200 ){
          const error = new Error(res.error);
          throw error;
          
      }
      
   
  
  
  }catch(err){
    console.log(err);
    //navigate("/signin");
  
  } 
  
  }
  useEffect(() => {
    fetchuser();
  },[ ] );
  const fetchusercourse = async () => {


        
    try{
      const res = await fetch(`${API_Key}/getcourse_details`,{
          method:"GET",
          headers:{
            mode: 'cors',
            'Access-Control-Allow-Origin':`${API_Key}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': true,
          },
          credentials:"include"
         
      });
      const data = await res.json();

      setuserdetail(data);
    
      setfile(data.allcourseData[0].score)
    
  
      if(res.status !== 200 ){
          const error = new Error(res.error);
          throw error;
          
      }
      
   
  
  
  }catch(err){
    console.log(err);
    //navigate("/signin");
  
  } 
  
  }
  useEffect(() => {
    fetchusercourse();
  },[ ] );

  
  const ip=usedetail.allocatedip;

    const id=usedetail._id;
   
 
  const Downloadstackvpn =async(event)=>{
    setModalOpen6(true);
    event.preventDefault();
    fetch(`${API_Key}/download-vpn-config`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        mode:"cors",
        'X-CSRF-TOKEN': scrf,
                'Access-Control-Allow-Origin':`${API_Key}`, 
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': true
      },
      credentials:"include",
      
    })
    .then(async(response) => {

      if(response.status===200){
        const blob = await response.blob();

        // Create a link element and trigger the download
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'stack-exploit.ovpn'; // Set the desired file name
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        setModalOpen5(true);
        setModalOpen6(false);
        setTimeout(() => {
          setModalOpen5(false);
        },7000);
        const noti={message:"Your Instance  has been Created Successfully . ",category:"Settings",querytype:"Instance Created"}

        fetch(`${API_Key}/notification`, {
          method: 'POST',
          headers:{
            Accept: "application/json",
            mode: 'cors',
            
            'Access-Control-Allow-Origin':`${API_Key}`,
          'Content-type': 'application/json',
            
            'Access-Control-Allow-Credentials': true,
          },
          credentials:"include",
          body: JSON.stringify(noti),
        })
        
          }


    })
  
    
    //Then with the error genereted...
    .catch((error) => {
      console.error('Error:', error);
      setModalOpen2(true);
  
    });

  }
    const checkinfo = async(event)=>{
      setModalOpen6(true);
      event.preventDefault();
      fetch(`${API_Key}/check_info_instance`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          mode:"cors",
          'X-CSRF-TOKEN': scrf,
                  'Access-Control-Allow-Origin':`${API_Key}`, 
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Credentials': true
        },
        credentials:"include",
        
      })
      .then((response) => {
        if(response.status===200){
          
          setModalOpen5(true);
          setModalOpen6(false);
          setTimeout(() => {
            setModalOpen5(false);
          },7000);
          const noti={message:"Your Instance  has been Created Successfully . ",category:"Settings",querytype:"Instance Created"}
  
          fetch(`${API_Key}/notification`, {
            method: 'POST',
            headers:{
              Accept: "application/json",
              mode: 'cors',
              
              'Access-Control-Allow-Origin':`${API_Key}`,
            'Content-type': 'application/json',
              
              'Access-Control-Allow-Credentials': true,
            },
            credentials:"include",
            body: JSON.stringify(noti),
          })
          
            }
  
  
      })
    
      
      //Then with the error genereted...
      .catch((error) => {
        console.error('Error:', error);
        setModalOpen2(true);
    
      });
    
    
    
    
    
    
    
    
    
    } 
    const stopinstance = async(event)=>{
      setModalOpen6(true);
      event.preventDefault();
      fetch(`${API_Key}/stop_instance`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          mode:"cors",
          'X-CSRF-TOKEN': scrf,
                  'Access-Control-Allow-Origin':`${API_Key}`, 
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Credentials': true
        },
        credentials:"include",
        
      })
      .then((response) => {
        if(response.status===200){
          setModalOpen5(true);
          setModalOpen6(false);
          setTimeout(() => {
            setModalOpen5(false);
          },7000);
          const noti={message:"Your Instance  has been Created Successfully . ",category:"Settings",querytype:"Instance Created"}
  
          fetch(`${API_Key}/notification`, {
            method: 'POST',
            headers:{
              Accept: "application/json",
              mode: 'cors',
              
              'Access-Control-Allow-Origin':`${API_Key}`,
            'Content-type': 'application/json',
              
              'Access-Control-Allow-Credentials': true,
            },
            credentials:"include",
            body: JSON.stringify(noti),
          })
          
            }
  
  
      })
    
      
      //Then with the error genereted...
      .catch((error) => {
        console.error('Error:', error);
        setModalOpen2(true);
    
      });
    
    
    
    
    
    
    
    
    
    } 
    const startinstance = async(event)=>{
      setModalOpen6(true);
      event.preventDefault();
      fetch(`${API_Key}/start_instance`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          mode:"cors",
          'X-CSRF-TOKEN': scrf,
                  'Access-Control-Allow-Origin':`${API_Key}`, 
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Credentials': true
        },
        credentials:"include",
        
      })
      .then((response) => {
        if(response.status===200){
          setModalOpen5(true);
          setModalOpen6(false);
          setTimeout(() => {
            setModalOpen5(false);
          },7000);
          const noti={message:"Your Instance  has been Created Successfully . ",category:"Settings",querytype:"Instance Created"}
  
          fetch(`${API_Key}/notification`, {
            method: 'POST',
            headers:{
              Accept: "application/json",
              mode: 'cors',
              
              'Access-Control-Allow-Origin':`${API_Key}`,
            'Content-type': 'application/json',
              
              'Access-Control-Allow-Credentials': true,
            },
            credentials:"include",
            body: JSON.stringify(noti),
          })
          
            }
  
  
      })
    
      
      //Then with the error genereted...
      .catch((error) => {
        console.error('Error:', error);
        setModalOpen2(true);
    
      });
    
    
    
    
    
    
    
    
    
    } 

 const Createinstance = async(event)=>{
    setModalOpen6(true);
    event.preventDefault();
    fetch(`${API_Key}/create_instance`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        mode:"cors",
        'X-CSRF-TOKEN': scrf,
                'Access-Control-Allow-Origin':`${API_Key}`, 
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': true
      },
      credentials:"include",
      
    })
    .then((response) => {
      if(response.status===200){
        setModalOpen5(true);
        setModalOpen6(false);
        setTimeout(() => {
          setModalOpen5(false);
        },7000);
        const noti={message:"Your Instance  has been Created Successfully . ",category:"Settings",querytype:"Instance Created"}

        fetch(`${API_Key}/notification`, {
          method: 'POST',
          headers:{
            Accept: "application/json",
            mode: 'cors',
            
            'Access-Control-Allow-Origin':`${API_Key}`,
          'Content-type': 'application/json',
            
            'Access-Control-Allow-Credentials': true,
          },
          credentials:"include",
          body: JSON.stringify(noti),
        })
        
          }


    })
  
    
    //Then with the error genereted...
    .catch((error) => {
      console.error('Error:', error);
      setModalOpen2(true);
  
    });
  
  
  
  
  
  
  
  
  
  } 
const Resetvpn = async()=>{
    setModalOpen6(true);
    fetch(`${API_Key}/terminate_instance`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        mode:"cors",
        'X-CSRF-TOKEN': scrf,
        'Access-Control-Allow-Origin':`${API_Key}`, 
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': true
      },
      credentials:"include",
      
    })
    .then((response) => {
      if(response.status===200){
        setModalOpen7(true);
        setModalOpen6(false);
        setTimeout(() => {
          setModalOpen7(false);
        },7000);
        const noti={message:"Your VPN has been Reset Successfully . ",category:"Settings",querytype:"VPN Reset"}

        fetch(`${API_Key}/notification`, {
          method: 'POST',
          headers:{
            Accept: "application/json",
            mode: 'cors',
            'Access-Control-Allow-Origin':`${API_Key}`,
          'Content-type': 'application/json',
            
            'Access-Control-Allow-Credentials': true,
          },
          credentials:"include",
          body: JSON.stringify(noti),
        })
        
          }


    })
  
    
    //Then with the error genereted...
    .catch((error) => {
      console.error('Error:', error);
      setModalOpen2(true);
  
    });
  }
  const toUpperCaseFilter = (d) => {
    return d.toUpperCase();
};


  return (
    <div>
    {modalOpen && (
      <Profileupdated
       
        setOpenModal={setModalOpen}
      />
    )}
    {modalOpen2 && (
      <Wentwrong
       
        setOpenModal={setModalOpen2}
      />
    )}
    {modalOpen3 && (
      <Invitefriend
       
        setOpenModal={setModalOpen3}
      />
    )}
    {modalOpen4 && (<Deactivate setOpenModal={setModalOpen4}/>)}
    {modalOpen5 && (<Instancesuccess setOpenModal={setModalOpen5}/>)}
    {modalOpen6 && (<Loading setOpenModal={setModalOpen6}/>)}
    {modalOpen7 && (<Resetvpnsucces setOpenModal={setModalOpen7}/>)}
  
    <div className='background_dashboard'>
    <div className='row justify-content-center'>
    <h1 className='commonhead'>Settings<div className='row justify-content-center'><hr className='commonheadhr'></hr></div></h1>
 
    
    </div>
    <div className='container-fluid'>
    <div className='row p-5'>
  <div className='col-lg-3 justify-content-center ' >
  <div className='row mt-5 justify-content-center pt-0 mb-0' onClick={() => setItem("A")} style={{cursor:"pointer"}}>
 <div className={item ==="A" ? "col-lg-6 col-md-6 pt-5 pb-0 justify-content-center settingdivchange":"col-lg-6 pt-5 pb-0 pr-5 pl-5 justify-content-center settingdiv1"}>

  <img src={General}  style={{
    height: "6rem",
    width:"6rem"
   
  
    
   
  }} className={item === "A"?"genraldiv2":"genraldiv"}  alt="Genral"  />

  



<h2 className="settinghead mb-2 pt-2 pb-5 mt-3 "  >General</h2>




</div>
</div>
<div className='row mt-5 justify-content-center p-0'   onClick={() => setItem("B")} style={{cursor:"pointer"}}>
<div className={item ==="B" ? "col-lg-6 pt-5 pb-0 pr-5 pl-5 justify-content-center settingdivchange":"col-lg-6 pt-5 pb-0 pr-5 pl-5 justify-content-center settingdiv1"}>

  <img src={Notification}  style={{
    height: "6rem",
    width:"6rem"
   
  
    
   
  }} className={item === "B"?"genraldiv2":"genraldiv"} alt="Genral"  />

  



<h2 className= ' settinghead mb-2 pt-2 pb-5 mt-3' >Notifications</h2>




</div>
</div>
<div className='row mt-5 justify-content-center p-0 ' onClick={() => setItem("C")} style={{cursor:"pointer"}}>
<div className={item ==="C" ? "col-lg-6 pt-5 pb-0 pr-5 pl-5 justify-content-center settingdivchange":"col-lg-6 pt-5 pb-0 pr-5 pl-5 justify-content-center settingdiv1"}>

  <img src={Network}  style={{
    height: "6rem",
    width:"6rem"
   
  
    
   
  }} className={item === "C"?"genraldiv2":"genraldiv"} alt="Genral"  />

  



<h2 className='settinghead mb-2 pt-2 pb-5 mt-3' onClick={() => setItem("C")} >Networks</h2>




</div>
</div>

    </div>
    
    <div className='col-lg-9 settingdiv2 mt-5 p-5' >

    {item === "A" && <div>
     <div className='container'>
     <div className='row p-5'>
     <div className='col-lg-12 col-md-6 col-12  '>
      <div className='row justify-content-center'>
    <div className='col-lg-9 col-12'>
<h2 className='setttingdiv2head'>Account Created</h2>
<p className='setttingdiv2para'>Congratulations!Your account has been created successfully. Login now and enjoy hacking!!!!!
</p>

    </div>
    <div className='col-lg-3 col-10 col-md-10 mt-3 justify-content-center'> 
    <div className='btn deactivatebtn3' ><Moment filter={toUpperCaseFilter} format="D MMM YYYY || HH:mm a">{usedetail.date}</Moment></div>
    </div>
      </div>
      
      <div className='row mt-5'>
      <div className='col-lg-9 col-12'>
  <h2 className='setttingdiv2head'>Invite a Friend</h2>
  <p className='setttingdiv2para'>Share, boost, and invite a friend to the preyground and test your skills! We have a lot waiting for you there!</p>
  
      </div>
      <div className='col-8 col-lg-3 mt-3 mb-2'>
      <div className='settinghoverbtn ' onClick={() => setModalOpen3(true)}>
   
      <BsShare color="white"  size={22}/>
      
    
      </div>

      </div>
      
        </div>
        <div className='row mt-5'>
      <div className='col-lg-9 col-12'>
  <h2 className='setttingdiv2head'>Deactivate Account</h2>
  <p className='setttingdiv2para'>Account deactivation will block your access to watch videos and run challenges. Are you sure you want to deactivate your account? </p>
  
      </div>
      <div className='col-10 col-lg-3 mt-3'>

      <button className='btn deactivatebtn' onClick={() => setModalOpen4(true)}>DEACTIVATE</button>

      </div>
      
        </div>
     </div>
     
     </div>
     </div>
    
    </div>}

    {item === "B" && <div>
    <div className='container'>
    <div className='row p-5'>
    <div className='col-lg-12 col-md-6 col-10  '>
    {/*} <div className='row'>
   <div className='col-9 col-9'>
<h2 className='setttingdiv2head'>Push Notifications</h2>
<p className='setttingdiv2para'>Congratulations! We heard that you are a skilled hacker. How about trying your skills by solving real-life scenarios? Come soon! </p>

   </div>
  

   <div className='col-lg-2 col-2 offset-1 ' style={{float:"right"}}>
   <label class="toggle" for="myToggle1" >
<input class="toggle__input" name="create_user" value="true"   type="checkbox" id="myToggle1" />
<div class="toggle__fill"></div>
</label>

   </div>
</div>*/}
     
     <div className='row mt-5'>
     <div className='col-9 col-9'>
 <h2 className='setttingdiv2head'>Email Notifications</h2>
 <p className='setttingdiv2para'>We will let you know about the new courses, challenges, rankings, and everything via email. 
 </p>
 
     </div>
     <div className='col-lg-2 col-2 offset-1 ' style={{float:"right"}}>
         <label class="toggle" for="myToggle2" >
    <input class="toggle__input" name="create_user" value="true"   type="checkbox" id="myToggle2" />
    <div class="toggle__fill"></div>
</label>
    

     </div>
     
       </div>
      {/*} <div className='row mt-5'>
       <div className='col-9 col-9'>
   <h2 className='setttingdiv2head'>SMS Notifications</h2>
   <p className='setttingdiv2para'>Let’s embark on the next stage of a promising career in hacking. Check out the new challenges and courses we have designed for you.</p>
   
       </div>
       <div className='col-lg-2 col-2 offset-1 ' style={{float:"right"}}>
       <label class="toggle" for="myToggle3" >
  <input class="toggle__input" name="create_user" value="true"   type="checkbox" id="myToggle3" />
  <div class="toggle__fill"></div>
</label>
  
  
       </div>
       
</div>*/}
         <div className='row mt-5'>
       <div className='col-9 col-9'>
   <h2 className='setttingdiv2head'>Receive Offers and Updates</h2>
   <p className='setttingdiv2para'>Are you looking for the most beneficial offer or updates in the field? Look no further, just reach us here!</p>
   
       </div>
       <div className='col-lg-2 col-2 offset-1 ' style={{float:"right"}}>
       <label class="toggle" for="myToggle4" >
  <input class="toggle__input" name="create_user"  checked={offer} onChange={(e)=>setoffer(e.target.value)} onClick={offers} value={offer}   type="checkbox" id="myToggle4" />
  <div class="toggle__fill"></div>
</label>
  
  
       </div>
       
         </div>
         <div className='row mt-5'>
         <div className='col-9 col-9'>
     <h2 className='setttingdiv2head'>Subscribe Newsletters</h2>
     <p className='setttingdiv2para'>Want to stay posted with the latest insights and trends? Connect with us via our newsletter, and we will share the updates.</p>
     
         </div>
         <div className='col-lg-2 col-2 offset-1 ' style={{float:"right"}}>
         <label class="toggle" for="myToggle5" >
    <input class="toggle__input" name="create_user" value="true"   type="checkbox" id="myToggle5" />
    <div class="toggle__fill"></div>
</label>
    
         </div>
         
           </div>
       
    </div>
    
    </div>
    </div>
   
    
    </div>}
    {item === "C" && <div>
    <div className='container'>
    <div className='row p-5'>
    <div className='col-lg-12 col-md-6 col-12  '>
     
     
     {/*<div className='row mt-5'>
     <div className='col-lg-9  col-8'>
 <h2 className='setttingdiv2head'>Reset Configuration File</h2>
 <p className='setttingdiv2para'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
 
     </div>
     <div className='col-lg-3 col-4'>
     <div className='settinghoverbtn ' onClick={Resetvpn} >
  
     <img src={ResetVpn} style={{height:"2rem",marginTop:"-1rem"}} alt="resetvpn" />
     
   
     </div>

     </div>
     
</div>*/}
       <div className='row '>
       <div className='col-lg-9  col-12'>
   <h2 className='setttingdiv2head'>Create instance</h2>
   <p className='setttingdiv2para'>To establish an instance that will assist you in configuring your dedicated VPN, you must click the "CREATE" button.</p>
   
       </div>
       <div className='col-lg-3 col-10 mt-3 mb-3'>
       <button className='btn deactivatebtn' disabled={true}  onClick={Createinstance}>CREATE</button>
  
       </div>
       
         </div>
       <div className='row mt-5'>
     <div className='col-lg-9 col-12'>
 <h2 className='setttingdiv2head'>Reset Vpn</h2>
 <p className='setttingdiv2para'>Has your VPN connection been lost? Aren't you able to perform challenges? If yes, your VPN connection needs to be re-established.</p>
 
     </div>
     <div className='col-lg-3 col-10 mt-3 mb-3'>

     <button className='btn deactivatebtn' disabled={true} onClick={Resetvpn}>RESET</button>

     </div>
     
       </div>
       <div className='row mt-5 '>
   <div className='col-lg-9 col-12'>
<h2 className='setttingdiv2head'>Download Configuration File</h2>
<p className='setttingdiv2para'>If you want to run the challenges in PreyGround, please download and connect to VPN  before starting your challenges. If you have not subscribed to the course, please subscribe first to get access to the challenges</p>

   </div>
   <div className='col-lg-3 col-10 mt-3 mb-3' >  
{file===""?   <button className='btn deactivatebtn2' disabled="true"  ><span><img src={DownloadFiles} style={{display:"inline",height:"1.5rem",marginRight:".3rem"}}  alt="src"/>Download</span> </button>:
  <a href={file} download><button className='btn deactivatebtn2'   ><span><img src={DownloadFiles} style={{display:"inline",height:"1.5rem",marginRight:".3rem"}}  alt="src"/>Download</span> </button></a> }
   </div>
     </div>
    </div>
    
    </div>
    </div>
    
    </div>}
    </div>
   
    </div></div>
    </div>
    </div>
  )
}

export default   (Setting)