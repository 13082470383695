import React,{useState,useEffect} from 'react'

import "./Entity.css";
import Labsimg from "../../../Assets/Entity/Labs-Neon (1).svg";
import {BsFillGridFill,BsListUl} from "react-icons/bs";
import BtnSlider from "./Btnslider";

import{RiArrowDropRightLine} from "react-icons/ri";
import Lock from "../../../Assets/Entity/Lab/Lab Card Thumb.svg"
import Labsimg2 from "../../../Assets/Entity/Lab/Challenges_-_Podium_c.png"
import { Line } from "rc-progress";
import axios from 'axios';
import { NavLink,useNavigate, } from 'react-router-dom';
import Loading from '../Modals/Loading';
import newlab from "../../../Assets/Entity/Lab/new lab.png"
import { map } from 'jquery';


const Labs = (props) => {
    const [view, setview] = useState("List");
    const [slideIndex, setSlideIndex] = useState(1);
    const[Labs,setLabs]=useState([]);
    const navigate=useNavigate();
    const[modal,setopenmodal]=useState(false);
    const API_Key = process.env.REACT_APP_API_URL;
    const[paidlabs,setpaidlabs]=useState([])
   
    useEffect(() => {
      setopenmodal(true);
      const fetchLabs = async () => {
        await axios
          .get(`${API_Key}/getlabs`,{withCredentials:"true"})
          .then((res) =>{ setLabs(res.data)
    
           console.log(res.data[0])
          if(res.status===200){
            setopenmodal(false);
          }
          })
          .catch((err) =>{console.log(err)
         // navigate("/signin");
          }
          );
      };
      fetchLabs();
    }, []);
    useEffect(() => {
      setopenmodal(true);
      const fetchpaidLabs = async () => {
        await axios
          .get(`${API_Key}/getlabs_details`,{withCredentials:"true"})
          .then((res) =>{
            
            const actualdata=res.data;
         
            setpaidlabs(actualdata);

            
         
           
          if(res.status===200){
            setopenmodal(false);
          }
          })
          .catch((err) =>{console.log(err)
          //navigate("/signin");
          }
          );
      };
      fetchpaidLabs();
    }, []);
    

    const nextSlide = () => {
      if(slideIndex !== Labs.length){
          setSlideIndex(slideIndex + 1)
      } 
      else if (slideIndex === Labs.length){
          setSlideIndex(1)
      }
  }

  const prevSlide = () => {
      if(slideIndex !== 1){
          setSlideIndex(slideIndex - 1)
      }
      else if (slideIndex === 1){
          setSlideIndex(Labs.length+paidlabs.length)
      }
  }

    const moveDot = index => {
        setSlideIndex(index)
    }
 
  return (
    <div>
    {modal && <Loading setOpenModal={setopenmodal} />}
    
    <div className='background_dashboard p-1'>
    <div className='Entitycommontopper'>
    <div className='container pb-2'>
   <div className='row justify-content-center pb-5'>
   <div className='col-lg-8 col-md-8 col-12 '>
   <h1 className='Entitycommonhead'>Labs<span><hr className='Entitycommonheadhr'></hr></span></h1>
   <p className='Entitycommonheadpara'>Gain access to the massive virtual hacking labs where you will find different hands-on challenges. The labs will be replicating the up-to-date security misconfigurations and vulnerabilities.  We add up new content where you can have unlimited fun while learning all the latest attack and exploitation techniques.</p>
   
   </div>
   <div className='col-lg-3 col-md-4 col-12 p-2 mt-4 mb-4 offset-1'>
   <img src={Labsimg} alt="labs"  ></img>
   
   </div>
    
    </div>
   
    </div>
    
   </div>
  <div className='container p-5'>
  <div className='row float-right'>
  <div className='col-lg-10 ' id='viewdiv'>
 

 <div className='row justify-content-center '>
  <div className='col-5'><button className='btn' ><BsFillGridFill color={view==="Grid"?"white":"grey"} size={15}/></button> </div>
  <div className='col-7'> <button  className='btn' onClick={() => setview("List")}><BsListUl  color={view==="List"?"white":"grey"} size={15}/></button> </div>
  </div>

  
 
  </div>
  </div>
  </div>
  <div className='container mt-5 p-5'>
  {view === "Grid" && (
    <div>
      <div id="container-slider">
        {Array.isArray(Labs) &&
          Labs.map((Lab, index) => (
            <div
              key={Lab.id}
              className={
                slideIndex === index + 1 ? "slide active-anim" : "slide"
              }
            >
              <div className="container">
                <NavLink
                  to={"/Labsdesc/" + Lab._id}
                  style={{ textDecoration: "none" }}
                >
                  <div className="row justify-content-center">
                    <div className="col-lg-10 col-md-10 col p-5" id="Labslider">
                      <div className="row">
                        <div className="col-lg-3 col">
                          <h1 className="entitylabhead">{Lab.title}</h1>
                      
                        </div>
                        <div className="col-lg-1 mt-2 col offset-lg-8">
                          <div className="Labbtn justify-content-center">
                            <RiArrowDropRightLine
                              className="span"
                              color="white"
                              size={20}
                              style={{ marginTop: "-2.4rem" }}
                            />
                          </div>
                        </div>
                      </div>
  
                      <div className="row justify-content-center mt-2 p-2">
                        <div className="col-lg-4 p-5">
                          <img src={Lab.image} alt="thumb" />
                        </div>
                      </div>
                      <div className="row justify-content-center">
                        <div className="col-lg-10">
                          <p className="Enitylabpara">{Lab.desc}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>
          ))}
        {/* Additional code for pagination dots and buttons */}
      </div>
      {/* Additional code for buttons */}
    </div>
  )}
  
  {view === "List" && <div>
  <div className='container mt-2 p-5'>
  {Labs && Labs.map((Labs,id)=>( <div className='row justify-content-center'>
  <div className='col-lg-12 col-md-10 col p-4 ' id='Lablistview' >
  <NavLink to={"/Labsdesc/"+Labs._id} style={{textDecoration:"none"}}>
  <div className='row'>
  <div className='col-lg-1 pt-2'>
  <div className='lablogodiv'>
  <img src={Labs.image} alt="logo" style={{width:"10rem"}}/>
  </div>
  </div>
  <div className='col-lg-2 pt-4'><h1 className='entitylabhead2'>{Labs.title}</h1></div>
  <div className='col-lg-6 pt-4'><p className='lablistpara'>{Labs.desc.substr(0,100)}</p></div>
  <div className='col-lg-2 pt-2'> <Line
  percent="70"
  style={{
    borderRadius: "2rem",
    width: "70%",
  
  }}
  strokeWidth="4.5"
  trailColor="#480812"
  trailWidth="4.5"
  strokeColor="red"
/></div>
  <div className='col-lg-1 pt-4'>
  <div className='Labbtn'>
 <RiArrowDropRightLine className='span' color='white' size={15} style={{marginTop:"-2.4rem"}} />
</div>
  </div>

  </div>
  </NavLink>
  </div>
  
  

  
  </div>))} 
  {paidlabs && paidlabs.map((Labs,id)=>( <div className='row justify-content-center'>
  <div className='col-lg-12 col-md-10 col p-4 ' id='Lablistview' >
  <NavLink to={"/Labsdesc/"+Labs._id} style={{textDecoration:"none"}}>
  <div className='row'>
  <div className='col-lg-1 pt-2'>
  <div className='lablogodiv'>
  <img src={Labs.image} alt="logo" style={{width:"10rem"}}/>
  </div>
  </div>
  <div className='col-lg-2 pt-4'><h1 className='entitylabhead2'>{Labs.title}</h1></div>
  <div className='col-lg-6 pt-4'><p className='lablistpara'>{Labs.desc.substr(0,100)}</p></div>
  <div className='col-lg-2 pt-2'> <Line
  percent="70"
  style={{
    borderRadius: "2rem",
    width: "70%",
  
  }}
  strokeWidth="4.5"
  trailColor="#480812"
  trailWidth="4.5"
  strokeColor="red"
/></div>
  <div className='col-lg-1 pt-4'>
  <div className='Labbtn'>
 <RiArrowDropRightLine className='span' color='white' size={15} style={{marginTop:"-2.4rem"}} />
</div>
  </div>

  </div>
  </NavLink>
  </div>
  
  

  
  </div>))} 


 
  
  
  
  </div>
  
  
  </div>}
  </div>
    
    </div></div>
  )
}

export default   (Labs)