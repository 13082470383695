import React ,{useEffect,lazy,Suspense}from 'react'
import { Helmet } from 'react-helmet';
import Aboutuspic from "../../Assets/About us/insight.png"

import Standing_flag from "../../Assets/About us/Mascot Flag.png"
import Navbar from '../Navbar/Navbar'

import Footer from '../Footer/Footer'
import "./Commonpages.css";
const renderLoader = () => <p>Loading</p>;
const Aboutus = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
      
        <Suspense fallback={renderLoader()} >  
        <Helmet>
        <title>About us | Hackers Prey</title>
        <meta name="description" content="Browse this page to learn about the Hackers Prey, where we provide you real-world challenges, which test your capability and push you to think out of the box."/>
      </Helmet>
        
          <section className="commonbackground " >
       
         <div className="container-fluid mt-0" >
              <div className="row ">
             
              <span className="Dataprivacy_span">ABOUT US</span>
                  <h2 className="Dataprivacy_head offset-md-1 ">ABOUT US</h2>



              </div>


          </div>
          </section>
          <section className="content_faq">
          <div className="container-fluid">
          <div className="row">
          <div className="col-lg-5 col-md-5 col-12 offset-md-1  pt-lg-0 order-2 order-lg-1 ">
          <p className="privacypara2"> Welcome to HackersPrey, the ultimate destination for aspiring hackers and cybersecurity enthusiasts. Our mission is to provide a unique and immersive experience within the hacking verse, where boundaries are pushed, skills are honed, and minds are expanded. 
          

</p>
          </div>
          <div className="col-lg-5 col-md-5 col-12 order-1 order-lg-2" id="dataprivacyimagediv">
            <img src={Aboutuspic } alt="dataprivacy" style={{width:"100%"}}  className="img-fluid" />
          </div>
      </div>
              
       

            </div>
            {/* ist portion end */}
            <div className='container'>
            <div className="row">
            <div className="col-lg-9 col-md-9 col-12 offset-md-1 mb-0" id="datacontentdiv"></div>
           <div className='col-lg-12 col-md-11 col-12'>
           <p className="aboutpara"> At HackersPrey, we offer a comprehensive platform that goes beyond traditional hacking courses. Our programs provide you with a plate full of real-world challenges, meticulously crafted to test your mettle. We believe in a hands-on approach, refusing to coddle or spoon-feed our members. Instead, we challenge them to their core, presenting mind-bending hacking challenge and intricate systems that require the utmost perseverance and ingenuity. Our environment is designed to make you scratch your head, bite your nails, and stretch your thinking to its limits.   We understand that hacking is not just about technical prowess; it's about adopting a hacker's mindset. Through our courses and challenges, we foster an environment that cultivates critical thinking, creativity, and resourcefulness.You will learn to analyze systems, identify vulnerabilities, and develop innovative techniques to exploit the vulnerabilities. 
</p>
           </div>    
           

</div>
             

            <div className="row">
    <div className="col-lg-4 col-md-6 col-12  " >
<img src={Standing_flag} alt="image_contact" className="img-fluid standing_flag" />
    </div>
    <div className="col-lg-8 col-md-7 col-12">
    <div className="datacontentdivpic2"></div>
    <p className="aboutpara2"> 

    Whether you are a seasoned hacker or a novice taking your first steps into this captivating realm, HackersPrey welcomes you. Join us on this exhilarating journey, where you will uncover the secrets of the hacking verse and unlock your true potential. Dare to think differently, embrace the challenges, and become part of a thriving community that lives, breathes, and conquers the art of hacking.
    



   <br></br> <b>HackersPrey - Igniting a revolution in hacking, where greatness is sculpted from the ashes of limitations. </b>
</p>

    </div>
</div>
     



</div>


{/*2nd potio ends */}





        








<Footer/>
          </section>
        </Suspense>
    )
}

export default Aboutus
