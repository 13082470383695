import React from "react";
import { GrFormCheckmark } from "react-icons/gr";
import TrainingIntro from '../../Assets/training-intro.png';
import TrainingIntro1 from '../../Assets/training-intro-1.jpg';
import TrainingIntro2 from '../../Assets/training-intro-2.jpg';
import TrainingVideo from '../../training-video.mp4';
import TrainingVideoW from '../../training-video.webm';


const benefits = [
  "Career Changers: Professionals transitioning into cybersecurity.",
  "Freelancers/Consultants: Seeking to enhance cybersecurity expertise.",
  "IT Professionals: Individuals in IT looking to specialize in cybersecurity.",
  "Ethical Hacking Enthusiasts: Passionate individuals seeking formal training.",
  "Tech-Savvy Individuals: Anyone interested in developing cybersecurity skills.",
  "Students/Graduates: Individuals currently pursuing or who have completed degrees in computer science, IT, or related fields.",
  "Final Year Students: College and university students seeking industrial training.",
];
const SummerTrainingIntro = () => {
  return (
    <div className="industrial-training-intro-section ind-training-highlight-section2 int-observe ind-intro" data-name="1">
      <div className="ind-training-highlight-section-left2">
        <h3  className="training-heading">
        Introduction To 6 Months Industrial Training In Cybersecurity
        </h3>
        <p className="training-desc">
          Elevate your career with our 6 Months Industrial Training in
          Cybersecurity, designed to provide in-depth, practical experience.
          This Cybersecurity Industrial Training In Chandigarh offers
          comprehensive training in cybersecurity and ethical hacking, ensuring
          you develop the skills demanded by today’s industry. Choose from our
          training sessions, available both online and offline, and gain a
          prestigious Cybersecurity certification. This Cybersecurity Training
          In Chandigarh not only equips you with the expertise needed to excel
          but also opens doors to valuable placement opportunities.
        </p>
        <p className="training-desc">
          Our 6 Months Industrial Training in Cybersecurity covers everything
          from basic cybersecurity to advanced-level concepts, with a strong
          focus on practical learning. You'll have access to state-of-the-art
          labs, where you can tackle real-world hacking challenges under the
          guidance of expert mentors. Whether you prefer online or offline
          learning, this Cybersecurity Industrial Training In Chandigarh is
          tailored to meet your needs and help you succeed in the ever-evolving
          field of cybersecurity. Secure your future with our advanced
          Cybersecurity Training In Chandigarh.
        </p>
      </div>
      <div className="training-highlight-section-right-hello">
      <video autoplay={"autoplay"} className="training-video-5"  loop muted playsInline width={"100%"} height={'590rem'} controlsList="nodownload">
      <source src={TrainingVideoW} type="video/webm"></source>
      <source src={TrainingVideo} type="video/mp4"></source>
     
      </video>
        { /* <p className="hero-subheading margin-bottom text-center">
          Who can Apply
        </p>

        {benefits.map((x) => (
          <div className="display-flex margin-bottom-2">
            <div className="lab-desc-list-icon-wrapper">
              <GrFormCheckmark className="lab-desc-list-icon" />
            </div>
            <p className="training-list">{x}</p>
          </div>
        ))} */}
      </div>
    </div>
  );
};

export default SummerTrainingIntro;
