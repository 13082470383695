import React, { useEffect, useState } from 'react'
import SummerTrainingHeroSection2 from './SummerTrainingHeroSection2'
import TrainingHighlights2 from './TrainingHighlights2'
import TrainingCurriculum from './TrainingCurriculum'
import TrainingCurriculum2 from './TrainingCurriculum2'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Loading from '../Dashboard/Modals/Loading'
import Footer from '../Footer/Footer'

const SummerTrainingInside = () => {
    const navigate=useNavigate()
    const [loading,setLoading]=useState(true)
    const [trainingLink,setTrainingLink]=useState();
    const [trainingStatus,setTrainingStatus]=useState(false);
    const [startDate,setStartDate]=useState('')
    const [registered,setRegistered]=useState(false)
    useEffect(()=>{
        const fetchTraining=async()=>{
            try{
                const response=await axios.get(`${process.env.REACT_APP_API_URL}/traininglink`,{withCredentials:true})
                setLoading(false);
               if(response.data.success){
              
                setTrainingStatus(response.data.data.startTraining);
                setTrainingLink(response.data.data.trainingLink);
                setStartDate(response.data.data.startDate)
                setRegistered(true)

               }
               else{
                setRegistered(false)
                setTrainingStatus(false);
               }
               
               
            }
            catch(error){
                navigate('/userhome')
            }
          
            
        }
        fetchTraining();
    },[])
    if(loading){
       return( <Loading/>)

    }
    else{
        return (
            <>
            <TrainingHighlights2/>
            <TrainingCurriculum2 startDate={startDate} registered={registered} trainingLink={trainingLink} trainingStatus={trainingStatus}  />
          <Footer/>
            </>
            )
    }

}

export default SummerTrainingInside
