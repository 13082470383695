import React,{useState,useEffect} from 'react'

import "./Common.css";
import {RiArrowRightSLine} from "react-icons/ri";
import { GoDotFill } from "react-icons/go";
import {IoMdLock,IoIosArrowForward} from "react-icons/io";
import User from "../../../Assets/images/dp3.png"
import Enity from "../../../Assets/images/IMG_2189.jpg"
import{RiArrowDropRightLine} from "react-icons/ri";
import { useNavigate } from 'react-router-dom';
import Loading from '../Modals/Loading';
const Notification = (props) => {
  const API_Key = process.env.REACT_APP_API_URL;
  const navigate=useNavigate();
 const[btntext,setbtntext]=useState("Show More");
 
    const[user,setuser]=useState([]);
   const [modal,setopenmodal]=useState(false);
    const[category,setcategory]=useState("");
    const[visible,setvisible]=useState(6);

 const showmoreres = ()=>{
setvisible((prevValue)=> prevValue + 3);
if(user.notificationtypesubscription.length){
  setbtntext("No data to show")

}

   //setvisible((prevValue)=> prevValue + 3); 
   
   
 }
   
    
    const fetchuserdetails = async () => {
setopenmodal(true)
      //setModalOpen3(true);
              
          try{
            const res = await fetch(`${API_Key}/userdata`,{
              method:"GET",
              headers:{
                mode: 'cors',
                'Access-Control-Allow-Origin':`${API_Key}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': true,
              },
              credentials:"include"
               
            });
            const data = await res.json([]);
           
            setuser(data);
            setopenmodal(false)
           
          
        
            if(res.status !== 200 ){
                const error = new Error(res.error);
                throw error;
                
            }else{
              //setModalOpen3(false);
            }
            
         
        
        
        }catch(err){
          console.log(err);
          navigate("/signin");
        
        } 
        
        }
      
        useEffect(() => {
          fetchuserdetails();
        },[ ] );

      
        
       
  return (
    <div >
    {modal && <Loading setOpenModal={setopenmodal} />}

    <div className='background_dashboard'>
    <div className='container'>
 
    {/*{user.notificationtypesubscription && user.notificationtypesubscription.map((noti,id)=>( <div className="col-lg-12 col-md-11 col-12   " id="notificationdiv" key={id}>
   

    <div className="row">
    <div className='col-lg-2 col'> <span style={{display:"inline",paddingLeft:"1rem"}}><GoPrimitiveDot color="#e32f12" size={25} /></span> {noti.category ==="Entity"? <img src={user.photo} style={{height:"4rem",marginLeft:"2rem",borderRadius:"50%"}} alt="user" /> : <img src={user.photo} style={{height:"4rem",marginLeft:"2rem",borderRadius:"50%"}} alt="user" />} </div>
    <div className='col-lg-2 col'><p className='quertypepara mt-2'>{noti.querytype}</p></div>
    <div className='col-lg-4 col'><p className='quertydescpara mt-2'>{noti.message}</p></div>
    <div className='col-lg-1 col offset-2'><p className='entitytypepara'>{noti.category}</p></div>
    <div className='col-lg-1 col mt-4'>   <div className='notificationbtn'> <RiArrowDropRightLine className='icon'/></div> </div>
    
    </div>

 
   
    
    </div>
  ))} */}  
  {user.notificationtypesubscription && [...user.notificationtypesubscription].reverse().slice(0,visible).map((noti,id)=>( <div className="col-lg-12 col-md-11 col-12   " id="notificationdiv" key={id}>
   

  <div className="row">
  <div className='col-lg-2 col-4'> <span style={{display:"inline",paddingLeft:"1rem"}}><GoDotFill color="#e32f12" size={25} /></span>  <img src={user.photo || User} style={{height:"4rem",marginLeft:"2rem",borderRadius:"50%"}} alt="user" />  </div>
  <div className='col-lg-2 col-2'><p className='quertypepara mt-2'>{noti.querytype}</p></div>
  <div className='col-lg-4 col-4'><p className='quertydescpara mt-2'>{noti.message}</p></div>
  <div className='col-lg-1 col-1 offset-lg-2'><p className='entitytypepara'>{noti.category}</p></div>
  <div className='col-lg-1 col-1 mt-4'>   <div className='notificationbtn'> <RiArrowDropRightLine className='icon'/></div> </div>
  
  </div>


 
  
  </div>
))}

  
    
   
   
    
    </div>
    <div className='row mt-5 justify-content-center'><button className='btn notificationbtns' onClick={showmoreres}>{btntext}</button></div>
    </div>
    </div>
  )
}

export default  ( Notification)