import React,{useEffect,useState} from 'react'
import Forgotpass from "../../Assets/forgot pass/Group 6206.svg";

import "./Authpage.css";
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import { useLocation ,useNavigate} from 'react-router-dom';
import {AiFillEye,AiFillEyeInvisible} from "react-icons/ai";
import Otpgeneratefirst from '../Dashboard/Modals/Otpgeneratefirst';
import Loading from "../Dashboard/Modals/Loading";
import Wrongotp from '../Dashboard/Modals/Wrongotp';
import Passmismatch from '../Dashboard/Modals/Passmismatch';
import Passchanges from '../Dashboard/Modals/Passchanges';
import { useForm } from "react-hook-form";
import Toomanyattempts from '../Dashboard/Modals/Toomanyattempts';
const Resetpassword = (props) => {

  useEffect(()=>{
    if(location.state===null){
      navigate('/forgotpass')
    }
  
  },[])
    const [passwordType, setPasswordType] = useState("password");
    const {register,handleSubmit,formState: { errors },reset,watch,trigger,} = useForm();
    const location = useLocation();
    const navigate=useNavigate();
    console.log(location.state===null);
   
    const d=location?.state;
    console.log("locationsasasa",location)
   
    const[email,setemail]=useState(d?.email);

    
    const password=watch("password")
    const[confirm_password,setconfirm_password]=useState("");
    const[modal,setopenmodal]=useState(false);
    const[modal2,setopenmodal2]=useState(false);
    const[modal3,setopenmodal3]=useState(false);
    const[modal4,setopenmodal4]=useState(false);
    const[modal5,setopenmodal5]=useState(false);
    const[modal6,setopenmodal6]=useState(false);
    const API_Key = process.env.REACT_APP_API_URL;
    const onSubmit = (data) => {
        //event.preventDefault();
        setopenmodal2(true);
     //const data = { email,otp,password,confirm_password};
      
  
   fetch(`${API_Key}/changepassword` , {
    method: 'POST',
    headers: {
      Accept: 'application/json',
            mode:"cors",
                    'Access-Control-Allow-Origin':`${API_Key}`, 
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': true
          },
          credentials:"include",
    body: JSON.stringify({email:d.email,otp:data.otp,password:data.password,confirm_password:data.confirm_password}),
  })
  .then((response) => { 
       
    if(response.status===200){
      setopenmodal5(true);
      setopenmodal2(false);
    
      }  else if(response.status===204){
      //setModalOpen2(true);
     setopenmodal(true);
      setopenmodal2(false);
    
    }else if(response.status===205){
      //setModalOpen2(true);
     setopenmodal3(true);
      setopenmodal2(false);
    }else if(response.status===206){
      setopenmodal4(true);
      setopenmodal2(false);
    }else if(response.status===429){
      setopenmodal6(true);
      setopenmodal2(false);
    }
  
  
  })
  
  .catch((error) => {
    console.error('Error:', error);
    //setModalOpen3(true);
  });
       
                
  
      
    
    
    
    
    
    
    
    
    }
    const togglePassword =()=>{
        if(passwordType==="password")
        {
         setPasswordType("text")
         return;
        }
        setPasswordType("password")
      }
      const togglePassword2 =()=>{
        if(passwordType==="text")
        {
         setPasswordType("password")
         return;
        }
        setPasswordType("password")
      }

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <div>
        {modal && <Otpgeneratefirst setOpenModal={setopenmodal}/>}
        {modal2 && <Loading setOpenModal={setopenmodal2}/>}
        {modal3 && <Wrongotp setOpenModal={setopenmodal3}/>}
        {modal4 && <Passmismatch setOpenModal={setopenmodal4}/>}
        {modal5 && <Passchanges setOpenModal={setopenmodal5}/>}
        {modal6 && <Toomanyattempts setOpenModal={setopenmodal6}/>}
                <section className="authbackground">
       <div id="authpagelinediv">
           <div className="container-fluid">

           <span className="Authpagespan1 ">RESET PASSWORD</span>
               <div className="row">
                   <div className="col-lg-5 col-md-6 col-12 offset-md-2 mt-5 ">
                       <img src={Forgotpass} alt="signup"  className="img-fluid"/>
                   </div>
                   <div className="col-lg-4 col-md-4 col-12  mt-2 mt-5  mb-5 p-5  ">
                   <div className="formdiv">
               <form onSubmit={handleSubmit(onSubmit)}>
                       <h2 className="text-center authhead">Reset Password</h2>
                       <div className="row justify-content-center "><hr className="signuphr"></hr></div>
                      
                        
                       <div class="input-group ">
                     <label>OTP</label>	 
                         </div>
                        <div class="input-group_field">
                        <input type="password" placeholder="Enter Otp" autoComplete='off' className={`mb-2 ${errors.otp && "invalid"}`} name='otp' 
                        {...register("otp", { required: "OTP is Required" ,
                        pattern: {
                          value: /^[0-9]{6}(?:-[0-9]{4})?$/,
                          message: "Please Enter Valid OTP",
                        }})}
                        onKeyUp={() => {
                          trigger("otp");
                        }}/>
                        {errors.otp && (
                          <small className="errormes ml-2">{errors.otp.message}</small>
                        )}
                         
                           </div>

                       <div class="input-group ">
                     <label>Password</label>	 
                         </div>
                        <div class="input-group_field">
                     
                       
                        <input type={passwordType} placeholder="Enter Password" name='password'  autoComplete='off' className={`mb-2 ${errors.password && "invalid"}`} 
                        
                        {...register("password", { required: "Password is Required",
                        pattern: {
                          value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                          message: "Password must have min 8 Characters (A-Z, a-z, 0-9) & Spl Characters",
                        },
                        minLength: {
                          value: 8,
                          message: "Password must have min 8 Characters (A-Z, a-z, 0-9) & Spl Characters",
                        },maxLength:{
                          value: 16,
                          message: "Password should have maximum 16 characters"

                        }
                       })}
                        onKeyUp={() => {
                          trigger("password");
                        }}/>	
                        {errors.password && (
                          <small className="errormes ml-0">{errors.password.message}</small>
                        )}
                       
                       {/* { passwordType==="password"? <AiFillEyeInvisible size={15}  onClick={togglePassword} style={{marginTop:"0rem"}} color='white'/>:<AiFillEye color='white' size={15} onClick={togglePassword2} style={{marginTop:"0rem",position:"absolute"}} />  }*/}
                   
                           </div>
                           
                           <div class="input-group ">
                     <label>Confirm Password</label>	 
                         </div>
                        <div class="input-group_field">
                        <input type="password" placeholder="Enter Confirm Password" autoComplete='off' name='confirm_password' className={`mb-2 ${errors.confirm_password && "invalid"}`} 
                        {...register("confirm_password", { required: "Confirm password is Required",
                        pattern: {
                          value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                          message: "the Password does not match",
                        },
                        validate:(value)=> value===password ||"the Password does not match",
                       
                       })}
                       onKeyUp={() => {
                        trigger("confirm_password");
                      }} />	
                      {errors.confirm_password && (
                        <small className="errormes ml-2">{errors.confirm_password.message}</small>
                      )}
                        
                           </div>
                          
                           
                      
<div className="row justify-content-center mb-3">
  <button className="btn authbutn">SUBMIT</button>
</div>
</form>
                   </div>
                   
                   </div>
               </div>
           </div>
           </div>
         



             
         <Footer/>
          </section>
        </div>
    )
}

export default Resetpassword
