import React,{useEffect} from 'react'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer';
import "./Commonpages.css";
const Corporrate = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  return (
    <div>
    
    <section className="commonbackground " >
   
    
  <br></br>     <br></br>
  <div className="container mt-5" id="Heading_container">
      <div className="row justify-content-center">
     
      <span className="Privacy_span">COMING SOON</span>
          <h2 className="Privacy_head">COMING SOON..</h2>
          <hr className="commonpagehr"></hr>
       
    
      </div>

     
  </div>
  </section>
  <Footer/>
</div>
  )
}

export default Corporrate