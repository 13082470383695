import React,{useState,useEffect} from 'react'

import "./Common.css";
import axios from 'axios';
import { useForm } from "react-hook-form";
import Feedbacksaved from "../Modals/Feedbacksaved";
import Wentwrong from '../Modals/Wentwrong';
import Loading from '../Modals/Loading';
import { useNavigate } from 'react-router-dom';
const Feedback = (props) => {

  const navigate=useNavigate();
  const[username,setusername]=useState("");
 
 
 const[credential,setcredential]=useState({subject:"",body:""})
 const[modal,setmodal]=useState(false);
 const[modal2,setmodal2]=useState(false);
 const[modal3,setmodal3]=useState(false);
 const {register,handleSubmit,formState: { errors },reset,watch,trigger,} = useForm();
 const API_Key = process.env.REACT_APP_API_URL;
  const onSubmit = async(data) => {
    setmodal3(true);
    //event.preventDefault();
    //const dataa = { subject:credential.subject,body:credential.body};
   
  fetch(`${API_Key}/postfeedback`, {
    method: 'POST',
    headers:{
      mode: 'cors',
      'Access-Control-Allow-Origin':`${API_Key}`,
      'Content-Type': 'application/json',
      'Access-Control-Allow-Credentials': true,
    },
    credentials:"include",
    body: JSON.stringify(data),
  })
  .then((response) => {response.json()
  //Then with the data from the response in JSON...
reset(credential)
    setmodal(true);

    setmodal3(false);

    setTimeout(() => {
      setmodal(false);
    },3000);
    const noti={message:"Your Feedback has been successfully Saved. ",category:"Feedback",querytype:"Feedback Saved"}

    fetch(`${API_Key}/notification`, {
      method: 'POST',
      headers:{
        Accept: "application/json",
        mode: 'cors',
        'Access-Control-Allow-Origin':`${API_Key}`,
      'Content-type': 'application/json',
        
        'Access-Control-Allow-Credentials': true,
      },
      credentials:"include",
      body: JSON.stringify(noti),
    })
  
  })
  //Then with the error genereted...
  .catch((error) => {
    setmodal2(true);
    setTimeout(() => {
      setmodal2(false);
    },3000);
    console.error('Error:', error);
  });








}
const fetchuser = async () => {


        
  try{
    const res = await fetch(`${API_Key}/Username`,{
        method:"GET",
        headers:{
          mode: 'cors',
          'Access-Control-Allow-Origin':`${API_Key}`,
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': true,
          
        },
        credentials:"include",
        //withCredentials: true,
    });
    const data = await res.json();
  
    setusername(data);
  

    if(res.status !== 200 ){
        const error = new Error(res.error);
        throw error;
        
    }
    
 


}catch(err){
  console.log(err);
  navigate("/signin");

} 

}
useEffect(() => {
  fetchuser();
},[] );
const handleinput=(event)=>{
  event.preventDefault();
      setcredential((prevState) => ({
          ...prevState,
          [event.target.name]: event.target.value,
        }));
  
  }
  return (
    <div>
    {modal && <Feedbacksaved setOpenModal={setmodal}/>}
    {modal2 && <Wentwrong setOpenModal={setmodal2}/>}
    {modal3 && <Loading setOpenModal={setmodal3}/>}
   
    <div className='background_dashboard p-1'>
   <div className='commontopper'>
   <div className='container'>
   <div className='row justify-content-center'>
   <h1 className='commonhead'>Share Your Thoughts<div className='row justify-content-center'><hr className='commonheadhr'></hr></div></h1>

   
   </div>
   <div className='row justify-content-center'>
   <p className='Commonpara'>Your feedback is important to us, kindly share your valuable feedback regarding any issues faced in using the Hackersprey portal. </p>
   </div>
   </div>
   
  </div>
 <div className='container'>
 <div className='row justify-content-center'>
 <div className='col-lg-10 col-md-7 col-10'>
 <form onSubmit={handleSubmit(onSubmit)}>
 <div className="form-input col-lg-12">
     
       <div className="col">
       <label className='feedbacklabel'>Subject</label>
       <input
         name="subject"
         type="text"
         autoComplete='off'
         
         onChange={handleinput}
        className={`form-control mb-1 ${errors.subject && "invalid"}`}
         placeholder="Enter Subject"  {...register("subject", { required: "Subject is Required",
         pattern: { value:/^[a-zA-Z ., ]*$/,
           message:"Please Enter  Subject",
  }
         })}
           
           onKeyUp={() => {
             trigger("subject");
           }} />
           {errors.subject && (
            <small className="errormes ml-2 ">{errors.subject.message}</small>
          )} 
         </div>
       <div className="col">
       <label className='feedbacklabel'>Message</label>
       <textarea
       name="body"
       type="text"
       autoComplete='off'
       className={`form-control mb-1 ${errors.body && "invalid"}`}
       placeholder="Enter your thoughts here ...."
       rows="7"
    
         
         onChange={handleinput}
         {...register("body", { required: "Message body is Required",
         pattern:{value:/^[a-zA-Z,. \n ]*$/,
           message:"Message body is Required",
           }
           
         })}
           
           onKeyUp={() => {
             trigger("body");
           }}
       ></textarea>
       {errors.body && (
        <small className="errormes ml-2 ">{errors.body.message}</small>
      )} 
       </div>
       
       </div>
       <div className='row justify-content-center'>
       <button className='btn feedbackbtn'>Submit</button>
       </div>
       
 </form>
 
 </div>
 </div>
 </div>

   




</div>
   
    
    </div>
  )
}

export default (Feedback)

