import React,{useState,useEffect} from 'react'
import "./Footer.css";
import footerlogo from "../../Assets/Landing page/Group 36.svg";
 import {AiOutlineMail} from "react-icons/ai";
import { NavLink ,Link} from 'react-router-dom';
import email from "../../Assets/footer/helpdesk.png"

const Footer = () => {
  const[user,setuser]=useState([]);
    const[show,setshow]=useState(true);
    const API_Key = process.env.REACT_APP_API_URL;
    const onEmailClick =async()=>{
      window.open(`mailto:"helpdesk@hackersprey.com"`);
    }
   
        
    return (
        <div>
        <section className="footer-top">
          <div className="container-fluid ">
          {show?  <div className="row col-lg-12 col-md-12 col-12 ">
     
          <div className="col-lg-4 col-md-3 col-12 mt-5">
<img src={footerlogo} alt="logo"  className="footerlogo"/><span className="footerspan">HACKERSPREY</span>
<p className="footerpara ">Support:</p>
<p className="footermailpara " onClick={onEmailClick} style={{cursor:"pointer",textDecoration:"none"}} ><span className="footerspans"><AiOutlineMail size={25} color="white"/></span> <img src={email} style={{height:"1.5rem"}} alt="email"/>
</p>
<br></br>

          </div>
          <div className="col-lg-2 col-md-4 col-12 mt-5">
          <ul>
            <li><Link to="/aboutus" reloadDocument  id='Footerlinks'>About Us</Link></li>
<li>  <NavLink to="/dataprivacy" id='Footerlinks'>Data Policy</NavLink> </li>
            <li><NavLink to="/faq" id='Footerlinks'>FAQs</NavLink> 
</li>
          
          </ul>
          </div>
          <div className="col-lg-2 col-md-4 col-12 mt-5">
          <ul>
  {/*<li>Newsletter</li>*/}
           
  <li> <NavLink to="/university" id="Footerlinks">University</NavLink></li> 
            <li><NavLink to="/leaderboard" id="Footerlinks">Leaderboard</NavLink> 
            
</li>    
<li><NavLink to="/bugbounty" id="Footerlinks">Bug Bounty</NavLink> 
            
</li>  
<li> <NavLink to="/blog" id="Footerlinks">Blogs</NavLink>
</li>    
          
          </ul>
          </div>
          <div className="col-lg-2 col-md-4 col-12 mt-5">
          <ul>
              <li><NavLink to="/privacypolicy" id="Footerlinks">Privacy Policy</NavLink> </li>
          

            <li><NavLink to="/termsofservice" id="Footerlinks"> Terms Of Service</NavLink></li>  
            <li><NavLink to="/contactus" id="Footerlinks"> Contact Us</NavLink></li>  
          </ul>
          </div>
     
          <div className="col-lg-2 col-md-2 col-12 ">
          <NavLink to="/signup" id="Footerlinks"> <button className="btn footerbtn">JOIN US </button></NavLink>
          </div> 
        </div>:  <div className="row col-lg-11 col-md-12 col-12 offset-1 ">
     
        <div className="col-lg-4 col-md-3 col-12 mt-5">
<img src={footerlogo} alt="logo"  className="footerlogo"/><span className="footerspan">HACKERSPREY</span>
<p className="footerpara ">Support:</p>
<p className="footermailpara " onClick={onEmailClick} style={{cursor:"pointer",textDecoration:"none"}} ><span className="footerspans"><AiOutlineMail size={25} color="white"/></span> <img src={email} style={{height:"1.5rem"}} alt="email"/>
</p>
<br></br>

        </div>
        <div className="col-lg-2 col-md-4 col-12 mt-5">
        <ul>
          <li><Link to="/aboutus" reloadDocument  id='Footerlinks'>About Us</Link></li>
<li>  <NavLink to="/dataprivacy" id='Footerlinks'>Data Policy</NavLink> </li>
          <li><NavLink to="/faq" id='Footerlinks'>FAQs</NavLink> 
</li>
        
        </ul>
        </div>
        <div className="col-lg-2 col-md-4 col-12 mt-5">
        <ul>
{/*<li>Newsletter</li>*/}
         
<li> <NavLink to="/university" id="Footerlinks">University</NavLink></li> 
          <li><NavLink to="/leaderboard" id="Footerlinks">Leaderboard</NavLink> 
          
</li>    
<li><NavLink to="/bugbounty" id="Footerlinks">Bug Bounty</NavLink> 
          
</li>  
<li> <NavLink to="/blog" id="Footerlinks">Blogs</NavLink>
</li>    
        
        </ul>
        </div>
        <div className="col-lg-2 col-md-4 col-12 mt-5">
        <ul>
            <li><NavLink to="/privacypolicy" id="Footerlinks">Privacy Policy</NavLink> </li>
        

          <li><NavLink to="/termsofservice" id="Footerlinks"> Terms Of Service</NavLink></li>  
          <li><NavLink to="/contactus" id="Footerlinks"> Contact Us</NavLink></li>  
        </ul>
        </div>
  
      
      </div>}
        
          </div>
        </section>
      
        </div>
    )
}

export default Footer
