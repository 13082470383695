import React from 'react'
import { GrFormCheckmark } from "react-icons/gr";
const benefits=[
    'Access to industry-recognized certifications upon completion',
    'Supplementary videos and tools to support and enhance learning',
    'Updated Curriculum with the latest technologies and advancements',
    'Real-World Hacking Challenges',
    'Practice labs for practical application of theoretical knowledge',
    'Acceleration of career growth opportunities',
    'Personalized mentorship and guidance',
    'Cloud Access For Hacking Challenges',
    'Development of job-ready skills and competencies',
]

const TrainingHighlights2 = () => {
    return (
        <div className='training-highlight-section trainer-cont2'>
            <div className='training-highlight-section-left'> 
            <h3 className='training-heading'>6 Weeks Summer Training In Cybersecurity 2024</h3>
            <p className='training-desc'>Welcome to our premier 6 weeks of summer training in cybersecurity, where we bridge the gap between education and industry demands, created to address the increasing demand for skilled cybersecurity professionals. This 6 Week summer internship in cyber security, provides a complete and immersive learning experience. This summer Internship in Cyber Security covers essential aspects such as threat detection, network security, ethical hacking, and more. This internship enables the students to understand and learn the current trends in the job market. students pursue internships to boost their resumes for future jobs and to further their academic pursuits.</p>
            </div>
            <div className='training-highlight-section-right'> 
            {benefits.map((x)=>(
     <div className="display-flex">
     <div className="lab-desc-list-icon-wrapper">
       <GrFormCheckmark className="lab-desc-list-icon" />
     </div>
     <p className="training-list">{x}</p>
    </div>  
            ))}
           
                   
            </div>
      
        </div>
      )
}

export default TrainingHighlights2
