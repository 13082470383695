import React,{useEffect,useState} from 'react'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'

import Privacypic from "../../Assets/Entity/Course/revex.png"
import email1 from "../../Assets/Dashboard/Mails/Screenshot 2023-06-12 at 9.30.18 AM.png"

import {Faqs3} from "../Home/Homedata";
import {AiOutlinePlusCircle,AiOutlineMinusCircle} from "react-icons/ai"

const Revex = () => {
    const [clicked, setClicked] = useState(false);
    const toggle = index => {
        if (clicked === index) {
          //if clicked question is already active, then close it
          return setClicked(null);
        }
    
        setClicked(index);
      };
   
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  return (
    <>
    <section className="Dynamicbackground">
   

    <div className="container mt-5">
   
    <div class="Heading-main">
  <span>REVEX</span>
  <h2>REVEX</h2>

  <div className="row justify-content-center">
  <hr className="justify-content-center"></hr>

</div>
<p className='revexpara'> Unlock the Secrets of Reverse Engineering </p>
</div>
    </div>
   
</section>
<section className="Privacydiv">
<div className="container-fluid">
    <div className="row">
        <div className="col-lg-6 col-md-10 col-12 offset-md-1 order-2 order-lg-1 mt-5">
        <p className="privacypara">Are you ready to embark on a thrilling journey into the world of reverse engineering? Look no further! RevEx is a free program designed to test your skills and expand your knowledge in this fascinating field. With five exciting challenges awaiting you, RevEx offers a challenge for both beginners and experienced individuals to showcase their reverse engineering prowess.

</p>
<p className="privacypara">Challenge yourself, solve each task, and pave your way towards becoming a <b>Certified Master of Reverse Engineering</b> a free certification from HackersPrey. The challenges are carefully crafted to cover various aspects of reverse engineering, allowing you to explore different techniques, tools, and methodologies.  </p>
        </div>
        <div className="col-lg-3 col-md-10 col-12 offset-1  justify-content-center order-1 order-lg-2 mt-5">
        <img src={Privacypic} style={{height:"50vh"}}  className="img-fluid logoimage"   alt="Privacy_pic" />
        </div>
    </div>
</div>
<div className="container-fluid">
<div className="row d-flex flex-column justify-content-center">
    <div className="col-lg-10 col-md-10 col-12 offset-md-1 mt-2">


<p className='privacypara'>To claim your well-deserved certificate from HackersPrey, all you need to do is complete all five challenges and submit a comprehensive report detailing your methodologies, thought processes, and solutions. The report should showcase your understanding of each challenge, the techniques employed, and any novel approaches you devised along the way. 

You can submit your report to <b>certification@hackersprey.com</b>, our expert panel will evaluate your solutions and methodologies. If you demonstrate a comprehensive understanding of reverse engineering concepts and exhibit proficiency in solving the challenges, you'll be awarded a prestigious certificate from HackersPrey, recognizing your expertise in this field.</p>

<br>

</br>
<p className='privacypara'><b>RevEx</b> is not just about achieving a certificate; it's about embarking on a remarkable learning journey and gaining practical skills that can be applied to real-world scenarios. Join us today, and let RevEx challenge your intellect, expand your horizons, and elevate your reverse engineering capabilities. Unleash your potential and unravel the secrets of the digital realm</p>
<br>

</br>
<p className='privacypara'><b>Note:</b> RevEx is a legal and ethical program designed solely for educational purposes. Participants are required to adhere to all applicable laws, regulations, and ethical standards. HackersPrey promotes responsible and lawful use of reverse engineering techniques.</p>
<br>

</br>



<br>

</br>
<div className='container mt-5'>
        <div className='row'>
        <div className='col-lg-12 col-md-12 col-12 mt-5 intershipdetaildiv2'>
       <div className='row justify-content-center' style={{position: "relative"}}>
       <h2 className='justify-content-center mainfaqhead'>FAQ'S</h2>
      
      <div className='row mt-5'>   <hr className='faqhr'></hr></div>
    

       
       </div>
       <div className='row justify-content-center '>
       <div className='col-lg-10 col-md-10 col mt-3'>
       <p className='mainfaqpara'>Please note that these FAQs provide general information about the REVEX program. For more detailed information and specific queries, it is recommended to reach out to us at <img src={email1} alt="email" style={{height:"30px",marginLeft:".3rem",marginTop:"-1px"}} /></p>
       </div>
      
       </div>
       
       
       <div className='row justify-content-center mt-5'>
       <div className='col-lg-10 col-md-10 col-10' >
    {Faqs3.map((faq,index)=>(
      <div className='row mt-5 mb-5'  key={index} id='faqcards'>
    
      <div className='col-lg-10 col-md-10 col-8'  onClick={() => toggle(index)} key={index} style={{cursor:"pointer"}}>
      <h2 className='faqcardshead'>{faq.question}</h2>
      </div>
      <div className='col-lg-1 col-md-1 col-1 offset-1'  onClick={() => toggle(index)} key={index} style={{cursor:"pointer"}}>
      {clicked===index ?  <AiOutlineMinusCircle color='#e32f1c' size={30} />  :<AiOutlinePlusCircle  color='#e32f1c' size={30} /> }
    
      </div>
      {clicked === index ? (
        <> <div className="container mt-1 p-3">
      <div className='row'>
      <div className='col-lg-11 col-md-11 col-11 '>
      <p className='faqansercard'>{faq.answer}</p>
      </div>
      </div>
      </div>
        
      
        
       
       
        </>  ) : null}
    
      </div>
    
      ))} 
      
  
      
       </div>
       </div>
        </div>
        </div>
        </div>
<br>

</br>
<br>

</br>




    </div>
    </div></div>

</section>
<Footer/>
    
    </>
  )
}

export default Revex