import React,{useState,useEffect} from 'react';
import { Helmet } from 'react-helmet';

import Loading from '../../Components/Dashboard/Modals/Loading';

import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";
import { Link, useNavigate,useParams } from 'react-router-dom';
import Navbar from "../Navbar/Navbar"
import Footer from '../Footer/Footer';
const LinkString="Pranay Rawat <a target='_blank' href='/'>HELLO</a>";
const linkJSX = <span dangerouslySetInnerHTML={{ __html: LinkString }} />;
const Blogdesc = (props) => {
  const [clicked, setClicked] = useState(false);
  const params=useParams()
  const navigate=useNavigate();
  const[blog,setblog]=useState([]);
  const [modalOpen, setModalOpen] = useState(true);
  const [loading,setLoading]=useState(true)
  const API_Key = process.env.REACT_APP_API_URL;
  const getblogdesc = async () => {
    setModalOpen(true);
   try{
    console.log(params,"params")
    let blog_id = params.blog_id;
    {/*const response = await fetch(`${API_Key}/getchallenge/` + blogid);
    setchallenge(await response.json());
    if(response.status===200){
      setModalOpen4(false);
    } */}
    console.log(blog_id,"blogssssssssssssssss")
    const res = await fetch(`${API_Key}/newblogroute/` +blog_id,{
      
      method:"GET",
      headers:{
        mode: 'cors',
        'Access-Control-Allow-Origin':`${API_Key}`,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': true,
      },
      credentials:"include"
  });
  const data = await res.json();
 
  setblog(data.data);
  console.log(data.data,"dataasash")
  if(res.status===200){
    setModalOpen(false);
  }
}catch(err){
  console.log(err);
  //navigate("/signin");

} 
  };
  useEffect(() => {
    
    getblogdesc();

  }, []);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const toggle = (index) => {
    if (clicked === index) {
      //if clicked question is already active, then close it
      return setClicked(null);
    }

    setClicked(index);
  };
 
 if(modalOpen){
return <Loading/>
 }
 else{
  return (
    <div>
       <Helmet>
    <title>{blog?.title}</title>
    
         <meta name="description" content={blog?.metadescription}/>
         <meta name="keywords" content={blog?.metakeywords}/>
    <meta name="author" content="Hackersprey"/>
  
    <link rel="canonical" href={`https://www.hackersprey.com/${blog?.blogroute}`}/>
    </Helmet>
      <section className="Dynamicbackground">
        <div className="container mt-5">
        <div class="Heading-main">
  
      <h3>{blog?.title}</h3>
      <hr className="offset-md-5"></hr>
    </div>
        </div>
       
    </section>
   <div className='blog-container'>
  <main className='blog-wrapper'>
    <figure>
      <img src={blog?.cover_image} alt={blog?.metakeywords[0]} />

    </figure>
    {blog?.content?.map((b)=>{

      switch(b.type){
        
       
        case "paragraph":
          return <p  dangerouslySetInnerHTML={{ __html: b.data }} className='blog-paragraph'/>
        case "h2":
          return <h2 className='blog-title-h2'>{b.data}</h2>
        case "h3":
          return <h3 className='blog-title-h3'>{b.data}</h3>

      }
        })}
    </main>
    </div>
    {blog?.faq.length!==0 ? <section className="Dynamicbackground">
        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12 mt-5 intershipdetaildiv2">
              <div
                className="row justify-content-center"
                style={{ position: "relative" }}
              >
                <h2 className="justify-content-center mainfaqhead">FAQ'S</h2>

                <div className="row mt-5">
                  {" "}
                  <hr className="faqhr"></hr>
                </div>
              </div>

              <div className="row justify-content-center mt-5">
                <div className="col-lg-10 col-md-10 col-10">
                  {blog?.faq?.map((faq, index) => (
                    <div className="row mt-5 mb-5" key={index} id="faqcards">
                      <div
                        className="col-lg-10 col-md-10 col-8"
                        onClick={() => toggle(index)}
                        key={index}
                        style={{ cursor: "pointer" }}
                      >
                        <p className="faqcardshead">{faq.question}</p>
                      </div>
                      <div
                        className="col-lg-1 col-md-1 col-1 offset-1"
                        onClick={() => toggle(index)}
                        key={index}
                        style={{ cursor: "pointer" }}
                      >
                        {clicked === index ? (
                          <AiOutlineMinusCircle color="#e32f1c" size={30} />
                        ) : (
                          <AiOutlinePlusCircle color="#e32f1c" size={30} />
                        )}
                      </div>
                      {clicked === index ? (
                        <>
                          {" "}
                          <div className="container mt-1 p-3">
                            <div className="row">
                              <div className="col-lg-11 col-md-11 col-11 ">
                                <p className="faqansercard">{faq.answer}</p>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                    
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> :null}
    <Footer/>
    </div>
)
 }
  
}

export default Blogdesc
