import React,{useEffect,useState} from 'react'

import { Line } from "rc-progress";
import Record from "../../../Assets/images/Activity.svg";
import User from "../../../Assets/images/Mask_Group_19.png"
import ReactStars from "react-rating-stars-component";
import {BsFlagFill,BsArrowUp} from "react-icons/bs";
import Edit from "../../../Assets/Entity/Lab/Edit.svg"
import Gotolink from "../../../Assets/Entity/Lab/Go To Link (1).svg";
       import { useParams } from 'react-router-dom';
       import Submitlabflag from '../Modals/Submitlabflag';
import AddReview from '../Modals/AddReview';
import Adddifficulty from '../Modals/Adddifficulty';
import User2 from "../../../Assets/images/dp3.png"
import axios from 'axios';
import{MdKeyboardArrowDown, MdKeyboardArrowUp} from "react-icons/md"
import Doc from "../../../Assets/Entity/Lab/Doc.svg"
import Labsicon from "../../../Assets/Dashboard/Challenges.svg"
import Labui from '../Modals/Labui';
import Loading from '../Modals/Loading';
import moment from 'moment';
import { faL } from '@fortawesome/free-solid-svg-icons';

const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100
  }
];
const Labsdesc = (props) => {
  const params=useParams()
  const[Labs,setLabs]=useState([]);
  const[Labsrate,setLabsrate]=useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [modalOpen3, setModalOpen3] = useState(false);
  const [modalOpen4, setModalOpen4] = useState(false);
  const [modalOpen5, setModalOpen5] = useState(false);
  const [modalOpen6, setModalOpen6] = useState(false);
const API_Key = process.env.REACT_APP_API_URL;
const getlabs = async () => {
  let labs_id = params.labs_id;
  const response = await fetch(`${API_Key}/getlab/` + labs_id,{
    method:"GET",
    headers:{
      mode: 'cors',
      'Access-Control-Allow-Origin':`${API_Key}`,
      'Content-Type': 'application/json',
      'Access-Control-Allow-Credentials': true,
    },
    credentials:"include"

  });
  setLabs(await response.json());
};
useEffect(() => {
  getlabs();
}, []);
{/*/getratinglab/:id*/}
const getlabsrating = async () => {

  setModalOpen6(true);
  let labs_id = params.labs_id;
  const response = await fetch(`${API_Key}/getratinglab/` + labs_id);
  //setLabsrate(await response.json([]));
 const check=await response.json([]);
 setLabsrate(check);
 if(response.status===200){
   setModalOpen6(false);
 }



};
useEffect(() => {
  getlabsrating();
}, []);
const gotolink = async(lab)=>{
  
  //setModalOpen4(true);
  setModalOpen5(true);
  const id=lab;
 

  const datas={id:id}
  fetch(`${API_Key}/teststartlab` , {
    method: 'POST',
    headers: {
      Accept: 'application/json',
            mode:"cors",
                    'Access-Control-Allow-Origin':`${API_Key}`, 
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': true
          },
          credentials:"include",
    body: JSON.stringify(datas),
  })

  //Then with the data from the response in JSON...
  .then(async(res) => { 
   
     const data=await res.json();
     
     //window.alert(data);
     if(res.status===200){
       setModalOpen4(false);

     }
      
 
  })
  //Then with the error genereted...
  .catch((error) => {
    console.error('Error:', error);
  });

}

    
  return (
    <div>
  
    

 {modalOpen && (
  <Submitlabflag
  id={props.match.params.labs_id}
    setOpenModal={setModalOpen}
  />
)}
{modalOpen2 && (
  <AddReview
    id={props.match.params.labs_id}
    category="lab"
    setOpenModal={setModalOpen2}
  />
)}
{modalOpen3 && (
  <Adddifficulty
  id={props.match.params.labs_id}
    setOpenModal={setModalOpen3}
    category="lab"
  />
)}
{modalOpen5 && (
  <Labui
  ip={Labs.ip}
        setOpenModal={setModalOpen5}
  />
)}
{modalOpen6 && (
  <Loading

        setOpenModal={setModalOpen6}
  />
)}
 <div className='Entity_background p-1'>
    <div className='Entitycommontopper'>
    <div className='container'>
    <div className='row'>
   <div className='col-lg-8 col-md-8 col-12 p-1'>
   <h1 className='Entitycommonhead'>{Labs.title}<span><hr className='Entitycommonheadhr'></hr></span></h1>
   <p className='Entitycommonheadpara'> {Labs.desc}</p>
   <div className='row p-2'>
  {/*<div className='col-lg-3 col'>
   <div className='row pl-4'>
   <p className='Enitylinepara'>Progress</p>
  <p className='Enitylinepara offset-4'>63%</p>
   <Line
   percent="70"
   style={{
     borderRadius: "2rem",
     width: "70%",
   
   }}
  
   strokeWidth="4"
   trailColor="#480812"
   trailWidth="4"
   strokeColor="red"
 />
   </div>
  
 
  </div>*/}
   <div className='col-lg-3 col ml-3 '>
   <div className='row pl-4'>
   <p className='Enitylinepara'>Hard</p>
   <p className='Enitylinepara offset-5'>{"5.0" || Labsrate}</p>
 <Line
   percent={"100" || Labsrate*10 }
   style={{
     borderRadius: "2rem",
     width: "70%",
   
   }}
   strokeWidth="3"
   trailColor="#480812"
   trailWidth="3"
   strokeColor="red"
  />
 <div className=' justify-content-center'><p className='offset-7 Enitylinepara2'>Difficulty</p></div>
   
 </div>
   </div>
   
   </div>
   </div>
   <div className='col-lg-3  ml-5 mt-5 offset-1'>
  <img src={Labs.image}/>
  

 
   
   </div>
    
    </div>
   
    </div>
    
   </div>
<div className='container p-5'>
<div className='row '>
<div className='col-lg-8 col-md-9 col-12'>
<div className='row col-lg-12 p-2'>
<p className='labdescpara'>



 </p>

</div>
<div className='row justify-content-center' >
<p className='labdescip' > Access Now</p>

</div>
<div className='row '>
<p className='labnetwrkhead'>Key Points<span><hr className='commonheadhr'></hr></span></p>

</div>

{Labs.networks && Labs.networks.map((Labs,id)=>(
  <div className='row mt-1'>
 <ul >   <li className="labnetwrkpara" >
<span>
                          <img
                            src={Record}
                            className="labdescspan mr-5"
                            style={{ height: "1.5rem" }}
                            alt="img"
                          />
                        </span>
{Labs.network_details}</li>
</ul>
</div>
))} 
{Labs.features && Labs.features.map((feature,id)=>(<div className='row mt-1'>
<ul >
<li className="labnetwrkpara" >
<span>
                            <img
                              src={Record}
                              className="labdescspan mr-5"
                              style={{ height: "1.5rem" }}
                              alt="img"
                            />
                          </span>
{feature.feature}</li>
</ul>

</div>))}
<div className='row mt-5 '>
<p className='labnetwrkhead'>Ethical Guidelines<span><hr className='commonheadhr'></hr></span></p>

</div>
<div className='row mt-1'>
<p className='Entitycommonheadpara2'>
This Lab is designed to be executed within a shared environment where multiple participants may be accessing and working on them simultaneously.
</p>


<p className='Entitycommonheadpara2'>
Usernames and Passwords you create while solving this lab are not stored permanently. Once you close the lab or log out, the usernames and passwords you created during the lab will be removed from the system.
</p>





                <p className='Entitycommonheadpara2'>       This Lab is designed to provide a hands-on learning experience and encourage users to experiment, explore, and practice their skills in a controlled environment. Feel free to try different approaches, test various techniques, and learn from both successful attempts and potential failures.

                </p> 


                  <p className='Entitycommonheadpara2'>      Remember to adhere to ethical guidelines and respect the shared environment by refraining from malicious activities or disrupting the experience for other users. </p>





                  <p className='Entitycommonheadpara2'>Enjoy this Lab and embrace the opportunity to enhance your expertise in web penetration testing!</p>




                
</div>


<div className='row mt-5 p-4'>
<p className='labreviewshead'>Reviews</p>
<hr className='reviewshr'></hr>
{Labs.reviews && Labs.reviews.map((reviw,id)=>(
<div className='col-lg-12 col-md-10 col-12'>
<div className='row'>
<div className='col-lg-5 col-7'>
<div className='row'>
<div className='col-lg-11 col-12 p-3'>

<img
  src={reviw.photo || User2}
  style={{ height: "4rem",borderRadius:"50%" }}
  alt="user"
/>
<span className="labreviewhead">{reviw.uid}</span>
<span className="labreviewhead2"> {moment(reviw.date).fromNow()} </span>

</div>
</div>
</div>
<div className='col-lg-3 col-5 offset-lg-4 p-5'>
<ReactStars
    count={5}
    edit={false}
    size={18}
    isHalf={true}
    emptyIcon={<i className="far fa-star"></i>}
    halfIcon={<i className="fa fa-star-half-alt"></i>}
    fullIcon={<i className="fa fa-star"></i>}
    activeColor="#ffa534"
    value={reviw.rating}
    color="lightgray"
   
 
  />
</div>

</div>
<div className="row col-lg-12">
<p className="labreviewpara">{reviw.review}</p>


</div>
<div ><hr className='reviewshr'></hr></div>
</div>

))}










</div>
</div>
<div className='col-lg-4 col-md-3 col-12  justify-content-center mt-2 mb-2 pb-5 pl-5 pr-5'>
<div className='row mb-2 mt-2' id='resourcecard'>
   <div className='col-lg-12 col-md-12 col-12 scorecard2' >
   <div className='row '>
   <div className='col-lg-3 col'>
   <h1 className='resourcehead pt-4 pb-2'>Score</h1>
   </div>
   <div className='col-lg-1 col pt-3 pb-2 offset-6'>
   
   
  
   </div>
  
   
  
 
   </div>
   <div className='row'>
   <h1 className='challscore pt-2 pb-3 pl-4 ml-2'>{Labs.score}</h1></div>
   </div>
   </div>
<div className='row  justify-content-center mt-4 p-2 mb-3' >

<button className='col-lg-12 btn' onClick={()=>gotolink(Labs._id)}>
<div className='col-lg-12 submitflagbtn'  id={Labs._id}  >
<span className='mr-3'> <img src={Gotolink} style={{height:"1.5rem"}} alt="write"/></span>Start Your Lab

</div></button>
</div> 


   
<div className='row justify-content-center p-2 ' >
<button className='col-lg-12 btn' >
<div className='col-lg-12 ' id='writereviewbtn'   onClick={() => setModalOpen(true)}><span className='mr-3'><BsFlagFill color='white' size={14}/> </span> SUBMIT FLAG</div></button>
</div>
<div className='row justify-content-center mt-4 p-2' >
<button className='col-lg-12 btn' >
<div className='col-lg-12 ' id='writereviewbtn' catid={Labs.category} onClick={() => setModalOpen2(true)}><span className='mr-3'><img src={Edit} style={{height:"1.5rem"}} alt="write"/></span>Write a Review</div></button>
</div>
<div className='row   justify-content-center mt-4 p-2' >
<button className='col-lg-12 btn' >
<div className='col-lg-12 submitflagbtn'  onClick={() => setModalOpen3(true)}><span className='mr-3'><img src={Labsicon} style={{height:"1.5rem"}} alt="write"/></span>Rate Difficulty</div></button>
</div>

   
   
  

</div>
</div>
</div>

   </div>
    
    </div>
  )
}

export default (Labsdesc)