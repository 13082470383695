import React from 'react';
import card1 from "../../Assets/Entity/webpwnui/asset5.png";
import card2 from "../../Assets/Entity/webpwnui/asset6.png";
import card3 from "../../Assets/Entity/webpwnui/asset7.png";
import card4 from "../../Assets/Entity/webpwnui/asset8.png";
import './webcardui.css';

const Webpwncard = () => {
  return (
    <div className='card-top-container'>
    <div className="card-container">
      <div
         className="card-inner"
      >
        <img
          className="card-image"
          src={card1}
        />
        <p className="card-header card1-back ">
          OWASP Top 10 Coverage
        </p>
        <p className="card-paragraph">
        Comprehensive coverage and practical exercises on all OWASP Top 10 vulnerabilities, such as injection attacks, broken authentication, data exposure, misconfigurations, and more.
        </p>
      </div>
      <div
        initial={{ scale: 1 }}
        whileHover={{ scale: 1.1 }}
        className="card-inner"
      >
        <img
          className="card-image"
          src={card3}
        />
        <p className="card-header card2-back">
          Real - World Scenarios
        </p>
        <p className="card-paragraph">
        Interactive exercises and challenges in a controlled environment simulating real-world web security scenarios, enabling practical application of knowledge.
        </p>
      </div>
    </div>
    <div
    
      className="card-container"
    >
      <div
        
        className="card-inner"
      >
        <img
          className="card-image"
          src={card2}
        />
        <p className="card-header  card3-back ">
          Extensive Practice
        </p>
        <p className="card-paragraph">
        Upon successful completion of the program, students have the opportunity to take a rigorous certification exam.This exam assesses student's proficiency in web pentesting.
        </p>
      </div>
      <div
        className="card-inner"
      >
        <img
          className="card-image"
          src={card4}
        />
        <p className="card-header card4-back ">
          Expert Guidance
        </p>
        <p className="card-paragraph">
        Video lectures and tutorials covering all aspects of web pentesting, including an in-depth exploration of the OWASP Top 10 vulnerabilities.
        </p>
      </div>
    </div>
    </div>
  )
}

export default Webpwncard
