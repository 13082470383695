import React, { useEffect } from "react";
import "./IndustrialTraining.css";

const IndustrialTrainingBar = ({
  activeSection,
  setActiveSection,
  onClick,
}) => {
  useEffect(() => {
    let indicator = document.querySelector("#indicator");
    let items = document.querySelectorAll(".training-cl");
    const marker = (e) => {
    
      indicator.style.left = e.offsetLeft+ "px";
      indicator.style.width = e.offsetWidth+ "px";
      
      items.forEach((item) => item.classList.remove("active-bar-train"));
      e.classList.add("active-bar-train");
    };
    if (activeSection && activeSection !== null && activeSection >= 0) {
      marker(items[activeSection - 1]);
    } else {
      marker(items[0]);
    }
  }, [activeSection]);

  return (
    <div className="industrial-training-bar-container  ">
      <div className="industrial-training-bar">
        <div id="indicator"></div>
        <span
          onClick={() => setActiveSection(1)}
          className={`training-cl cursor-pointer `}
        >
          Introduction
        </span>
        <span
          onClick={() => setActiveSection(2)}
          className={` training-cl cursor-pointer `}
        >
          Benefits
        </span>
        <span
          onClick={() => setActiveSection(3)}
          className={`training-cl cursor-pointer `}
        >
          Curriculum
        </span>
        <span
          onClick={() => setActiveSection(4)}
          className={`training-cl cursor-pointer `}
        >
          Overview
        </span>
        <span
          onClick={() => setActiveSection(5)}
          className={`training-cl cursor-pointer `}
        >
          Certificate
        </span>
        <span
          onClick={() => setActiveSection(6)}
          className={`training-cl cursor-pointer `}
        >
          Our Courses
        </span>
        <span
          onClick={() => setActiveSection(7)}
          className={`training-cl cursor-pointer `}
        >
          FAQs
        </span>
      </div>
      
    </div>
  );
};

export default IndustrialTrainingBar;
