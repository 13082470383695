import React,{useEffect} from 'react'

import "./Commonpages.css";
import Dataprivacypic from "../../Assets/dataprivacy/Group 5409.svg";
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import {Helmet} from "react-helmet";
const Dataprivacy = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <div>
        <Helmet>
        <title>Our Data Privacy | Hackers Prey</title>

        <meta name="description" content="The purpose of this document is to describe the Data Privacy followed by Hackers Prey which is applies to our customers and services. Read out this page now."/>
        </Helmet>
            <section className="commonbackground " >
        
      
          <br></br>     <br></br>
         <div className="container-fluid mt-5" >
              <div className="row ">
             
              <span className="Dataprivacy_span">DATA POLICY</span>
                  <h2 className="Dataprivacy_head offset-md-1">DATA POLICY</h2>
      
               
            
              </div>
        
             
          </div>
          </section>
         <section className="content_faq">
          <div className="container-fluid">
              <div className="row">
                  <div className="col-lg-5 col-md-5 col-12 offset-md-1  pt-lg-0 order-2 order-lg-1 ">
           
                  <p className="privacypara"> You have certain rights with respect to your Personal Data
                  as mentioned below. Please note that under certain circumstances, we may
                  not be able to fully comply with your requests or we may ask you to provide
                  additional information in accordance with your request.

</p>
                  </div>
                  <div className="col-lg-5 col-md-5 col-12 order-1 order-lg-2" id="dataprivacyimagediv">
                    <img src={Dataprivacypic} alt="dataprivacy"  className="img-fluid" />  
                  </div>
              </div>
            
                  <div className="row">
                      <div className="col-lg-10 col-md-10 col-12 offset-md-1 mb-0" id="datacontentdiv">

                      <h1 className='disclaimerhead mt-5'>Data Subject Rights</h1>
                      <h1 className='disclaimerhead2 mt-5'>Access</h1>
                    <p className='privacypara'>You can request a copy of your
                    Personal Data that was collected
                    by us.</p>
                    <h1 className='disclaimerhead2 mt-5'>Rectification </h1>
                    <p className='privacypara'>If you believe that any Personal
                    Data we are holding about you is
                    incorrect or incomplete, you can
                    request that we correct or
                    supplement it. You can also correct
                    some of this information directly
                    through your user profile or
                    profile/account settings after
                    logging into Hackersprey. Please
                    contact us as soon as possible
                    upon noticing any inaccuracies or
                    incomplete information.
                    </p>
                    <h1 className='disclaimerhead2 mt-5'>Objection </h1>
                    <p className='privacypara'>You can contact us to let us know
                    that you object to the collection or
                    use of your Personal Data for
                    certain purposes.
                    </p>
                    <h1 className='disclaimerhead2 mt-5'>Deleting data  </h1>
                    <p className='privacypara'>You can request that we delete
                    some or all of your Personal Data
                    from our systems. While this will
                    be done immediately, residual data
                    may be saved in certain logs and
                    this will be purged within 180 (one
                    hundred and eighty) days of
                    deleting your data. Once your data
                    is purged, you will no longer have
                    an account with Hackersprey.
                    </p>
                    <h1 className='disclaimerhead2 mt-5'>Processing restrictions   </h1>
                    <p className='privacypara'>You can always request us to
                    deactivate your Hackersprey
                    account to restrict further
                    processing of your Personal Data.
                    </p>
                    <h1 className='disclaimerhead2 mt-5'>Portability  </h1>
                    <p className='privacypara'>You have the right to ask for a
                    copy of your Personal Data in a
                    machine-readable format.
                    </p>
                    <h1 className='disclaimerhead2 mt-5'>Withdrawal of consent  </h1>
                    <p className='privacypara'>If we are processing your Personal
                    Data based on your consent (as
                    indicated at the time of collection
                    of such data), you have the right
                    to withdraw your consent at any
                    time. Please note, however, that if
                    you exercise this right, you may
                    have to then provide express
                    consent on a case-by-case basis
                    for the use or disclosure of certain
                    Personal Data of yours, if use or
                    disclosure is necessary to enable
                    you to use some or all of our
                    Products.
                    </p>
                    <h1 className='disclaimerhead2 mt-5'>Right to file a complaint  </h1>
                    <p className='privacypara'>You have the right to lodge a
                    complaint about Hackersprey’s
                    practices with respect to your
                    Personal Data with the supervisory
                    authority of your country or EU
                    Member State. The details of filing
                    complaint before the grievance
                    officer is also mentioned in the
                    policy.
                    </p>



  

                      </div>
                  </div> 
              </div>
         
      
    

         
           
            <Footer/>
          </section>
        </div>
    )
}

export default Dataprivacy
