import React,{useEffect,useState} from 'react'
import "./Dynamicpage.css";
import { Helmet } from 'react-helmet';
import Logo from "../../Assets/Entity/Course/webpwn.png"
import Logo1 from "../../Assets/Entity/Course/1657192139607webdrub.png"
import Logo2 from "../../Assets/Entity/Course/revex.png"
import {BiCheck} from "react-icons/bi";
import {AiOutlinePlusCircle,AiOutlineMinusCircle} from "react-icons/ai"
import { Link, NavLink , useLocation } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import Record2 from "../../Assets/images/Activity.svg"
import Record from "../../Assets/Landing page/arrow 2.png";
import {BsFillCalendarDayFill} from "react-icons/bs"
import {BiTime} from "react-icons/bi";
import {MdLocalOffer} from "react-icons/md"
import {GiRuleBook} from "react-icons/gi"
import Hacker from "../../Assets/Landing page/webhacklogo.png"
import image1 from "../../Assets/Landing page/internship photos/curriculum.svg"
import image2 from "../../Assets/Landing page/internship photos/certification.svg"
import image3 from "../../Assets/Landing page/internship photos/MicrosoftTeams-image.png"
import image4 from "../../Assets/Landing page/internship photos/handsonchallenges.svg";
import image5 from "../../Assets/Landing page/internship photos/selflearning.svg"
import email1 from "../../Assets/Dashboard/Mails/Screenshot 2023-06-12 at 9.30.18 AM.png"

import {Faqs2} from "../Home/Homedata";
const Internshipdetail = () => {

  const { pathname } = useLocation();
    const [item, setItem] = useState("C");
    const[style,setstyle]=useState("courseupper1");
    const [clicked, setClicked] = useState(false);

    const toggle = index => {
      if (clicked === index) {
        //if clicked question is already active, then close it
        return setClicked(null);
      }
  
      setClicked(index);
    };
 


    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  return (
    <div>
    <Helmet>
    <title>Our Hacking Course – Learn Hacking | Hackers Prey</title>
    <meta name="description" content="Hackers Prey is an excellent place to learn advanced hacking skills and concepts. Learn hacking courses online and gain various skill sets and capabilities."/>
  </Helmet>
    
        <section className="Dynamicbackground">
  

        <div className="container mt-5">
        {/*<div class="Heading-main">
      <span>INTERNSHIP</span>
      <h2>WE ARE OFFERING!</h2>
      <hr className="offset-md-5"></hr>
 
  </div>*/}
  <div class="Heading-main">
  <span>PREMIUM</span>
            <h2>JOIN WEBHACK!</h2>
            <hr className="offset-md-5 offset-4"></hr>
          </div>
          <div  className="row justify-content-center">
         
          </div>
    

        </div>
        <div className="container mt-1">
        <div className="row justify-content-center mt-1">
        <p className="course_para">Immerse Yourself in the World of Web Pentesting and Ignite Your Cybersecurity Journey with Unparalleled Expertise! </p>
    </div>
  
        </div>
       
    </section>
    <div className="mainconsection">
    <div className="coursesection">
            <div className="container-fluid ">
         
                <div className="row justify-content-center">
                <div className='col-lg-7 col-md-4 col-12 offset-md-1'>
             
<h2 className="Coursehead ">the web pentesting accelerator program</h2>
<hr className="coursehr"></hr>
<p  className="coursepara1">Are you ready to dive into the exciting world of web application security? Look no further! Our Web Pentesting accelerator program is designed to equip you with the skills and knowledge you need to become a proficient web pentester. Whether you're a beginner or have some experience in the field, this program will take your skills to the next level.</p>
<p  className="coursepara1">What sets our program apart is our focus on the OWASP Top 10, a list of the most critical web application security risks. Understanding these vulnerabilities is essential for any aspiring web pentester. Throughout the program, you'll gain in-depth knowledge of each vulnerability and learn practical techniques to identify and exploit them.</p>
</div>
<div className='col-lg-4 col-md-4 col-12 mt-5' >

<img src={Hacker} className="img-fluid" style={{height:"auto",padding:"5rem",marginTop:"5rem"}} alt="Whowearepic" />

</div>
</div>
</div>
<div className="container">
<p className="course_para1">Here's what you can expect during the program
</p>
        <div className="row"  >
       
        
           
                <div onClick={() => setItem("A")} style={{cursor:"pointer"}}  className={item ==="A" ? "col-lg-2 col-md-2 col-4 justify-content-center courseupper2":"col-lg-2 col-md-2 col-4 justify-content-center courseupper1"} >
                    <h2 className="text-center" >Comprehensive Curriculum</h2>
                </div>
                
                
                
                <div onClick={() => setItem("B")} style={{cursor:"pointer"}}  className={item ==="B" ? "col-lg-2 col-md-2 col-4 justify-content-center courseupper2":"col-lg-2 col-md-2 col-4 justify-content-center courseupper1"} >
                <h2 className="text-center" >Hands-on Challenges</h2>
        </div>
            <div onClick={() => setItem("C")} style={{cursor:"pointer"}}  className={item ==="C" ? "col-lg-2 col-md-2 col-4 justify-content-center courseupper2":"col-lg-2 col-md-2 col-4 justify-content-center courseupper1"}>
            <h2 className="text-center" >Self-paced Learning</h2>
        </div>
        <div onClick={() => setItem("D")} style={{cursor:"pointer"}}  className={item ==="D" ? "col-lg-2 col-md-2 col-4 justify-content-center courseupper2":"col-lg-2 col-md-2 col-4 justify-content-center courseupper1"}>
        <h2 className="text-center" > 24X7 Access</h2>
    </div>
    <div onClick={() => setItem("E")} style={{cursor:"pointer"}}  className={item ==="E" ? "col-lg-2 col-md-2 col-4 justify-content-center courseupper2":"col-lg-2 col-md-2 col-4 justify-content-center courseupper1"}>
    <h2 className="text-center" >Final Exam</h2>
</div>
               
               
               
           
           
       
              
           

               
        
   
          
            
     
         
            </div>
            </div>
            {item === "A" &&  <div className='container'>
    <div className='row  justify-content-center '>
    <div className='col-lg-10 col-md-12 col-12 mt-5  intershipdetaildiv'>
    
    <p className='intern_para'><span>Comprehensive Curriculum:</span> Our carefully crafted curriculum covers all aspects of web pentesting, from reconnaissance and scanning to exploitation and post-exploitation. You'll explore various tools, techniques, and methodologies professional pen testers use.</p>
    <div className='row justify-content-center'>
    <div  className='col-lg-8 col-md-8 col'>
    <img src={image1} alt="curriculum" className='img-fluid' style={{marginTop:"5rem",justifyContent:"center"}} />
    </div>
    </div>



    </div>
    
    </div>
    </div>}
    {item === "B" &&  <div className='container'>
    <div className='row  justify-content-center '>
    <div className='col-lg-10 col-md-10 col-12 mt-5  intershipdetaildiv'>
    
    <p className='intern_para'><span> Hands-on Challenges:</span>    Learning by doing is the key to mastering web pentesting. You'll have access to around 30 challenges, including practice labs, where you can apply your knowledge in a safe and controlled environment. These challenges will test your skills and help reinforce what you've learned.
    </p>
  
    <div className='row justify-content-center'>
    <div  className='col-lg-8 col-md-8 col'>
    <img src={image4} alt="curriculum" className='img-fluid' style={{marginTop:"5rem",justifyContent:"center"}} />
    </div>
    </div>

    </div>
    
    </div>
    </div>}
    {item === "C" &&  <div className='container'>
    <div className='row  justify-content-center '>
    <div className='col-lg-10 col-md-10 col-12 mt-5  intershipdetaildiv'>
    
    <p className='intern_para'><span>Self-paced Learning:</span> We understand that everyone has a unique learning style and schedule. That's why our program is self-paced, allowing you to learn at your own convenience. Pre-recorded videos will be provided, enabling you to access the content whenever and wherever you want.
    </p>
  
    <div className='row justify-content-center'>
    <div  className='col-lg-8 col-md-8 col'>
    <img src={image5} alt="curriculum" className='img-fluid' style={{marginTop:"5rem",justifyContent:"center"}} />
    </div>
    </div>

    </div>
    
    </div>
    </div>}
       
    {item === "D" &&  <div className='container'>
    <div className='row  justify-content-center '>
    <div className='col-lg-10 col-md-10 col-12 mt-5  intershipdetaildiv'>
    
    <p className='intern_para'><span>24X7 Access:</span> Access Anytime, Anywhere: Our cloud-based platform ensures that you can participate in hacking challenges whenever and wherever you want. Whether you prefer late-night hacking sessions or tackling challenges during your lunch break, the platform is available 24/7, allowing you to dive into the world of hacking at your convenience.</p>
  
    <div className='row justify-content-center'>
    <div  className='col-lg-8 col-md-8 col'>
    <img src={image3} alt="curriculum" className='img-fluid' style={{marginTop:"5rem",justifyContent:"center"}} />
    </div>
    </div>
    </div>
    
    </div>
    </div>}
    {item === "E" &&  <div className='container'>
    <div className='row  justify-content-center '>
    <div className='col-lg-10 col-md-10 col-12 mt-5  intershipdetaildiv'>
    
    <p className='intern_para'><span>Final Exam:</span> The Web Hack certification exam is a 24-hour practical examination that can only be taken after completing the six-week program. Participants are required to identify and exploit 10 hidden vulnerabilities within the given time frame. It is essential to appear for the exam within one month after the subscription ends; otherwise, candidates will not be permitted to take the exam. This ensures timely assessment of participants' skills and knowledge in web hacking.</p>
 
    <div className='row justify-content-center'>
    <div  className='col-lg-8 col-md-8 col'>
    <img src={image2} alt="curriculum" className='img-fluid' style={{marginTop:"5rem",justifyContent:"center"}} />
    </div>
    </div>

    

    </div>
    
    </div>
    
    </div>}
    
   <div className='container mt-5'>
    <p  className="coursepara1">By joining our program, you'll gain invaluable skills that are highly sought after in the cybersecurity industry. Web pentesting offers exciting career opportunities, and our Web Pentesting Accelerator Program will provide you with the foundation you need to kickstart your journey.</p>
    <p className='coursepara1'>Don't miss this chance to become a proficient web pentester. Register now and embark on an exciting six-week adventure that will transform your understanding of web application security. Secure your spot today!</p>
        </div>
        <div className='container mt-5'>
        <div className='row'>
        <div className='col-lg-7 col-md-7 col-12 mt-5 intershipdetaildiv2'>
        <h2 className='whatwewilllearnhead'>What you'll learn</h2>
        <hr className="coursehr"></hr>

        <ul className='intershipbullet mt-5' style={{margin:"1rem"}}>
        <li className='internbulletpara'><span><img src={Record} style={{height:"18px",marginRight:"2rem"}} /></span>Understanding Hypertext Transfer Protocol</li>
        <li className='internbulletpara'><span><img src={Record} style={{height:"18px",marginRight:"2rem"}} /></span>A Word About Session Management</li>
        <li className='internbulletpara'><span><img src={Record} style={{height:"18px",marginRight:"2rem"}} /></span>Document Object Model (DOM)</li>
        <li className='internbulletpara'><span><img src={Record} style={{height:"18px",marginRight:"2rem"}} /></span>Same Origin Policy (SOP)</li>
        <li className='internbulletpara'><span><img src={Record} style={{height:"18px",marginRight:"2rem"}} /></span>Cross Origin Resource Sharing (CORS)</li>
        <li className='internbulletpara'><span><img src={Record} style={{height:"18px",marginRight:"2rem"}} /></span>A Word About Information Gathering</li>
        <li className='internbulletpara'><span><img src={Record} style={{height:"18px",marginRight:"2rem"}} /></span>Passive Information Gathering</li>
        <li className='internbulletpara'><span><img src={Record} style={{height:"18px",marginRight:"2rem"}} /></span>A Word About Domain Name System</li>


        <li className='internbulletpara'><span><img src={Record} style={{height:"18px",marginRight:"2rem"}} /></span>DNS Enumeration</li>
        <li className='internbulletpara'><span><img src={Record} style={{height:"18px",marginRight:"2rem"}} /></span>SQL Injection</li>
        <li className='internbulletpara'><span><img src={Record} style={{height:"18px",marginRight:"2rem"}} /></span>Cross Site Request Forgery (CSRF) </li>
        <li className='internbulletpara'><span><img src={Record} style={{height:"18px",marginRight:"2rem"}} /></span>Cross Site Scripting (XSS) </li>

        <li className='internbulletpara'><span><img src={Record} style={{height:"18px",marginRight:"2rem"}} /></span>Insecure Design</li>
        <li className='internbulletpara'><span><img src={Record} style={{height:"18px",marginRight:"2rem"}} /></span>Server-Side Request Forgery (SSRF)</li>
        <li className='internbulletpara'><span><img src={Record} style={{height:"18px",marginRight:"2rem"}} /></span>Insecure Direct Object References (IDOR)</li>
        <li className='internbulletpara'><span><img src={Record} style={{height:"18px",marginRight:"2rem"}} /></span>XML External Entity Injection (XXE)</li>
        <li className='internbulletpara'><span><img src={Record} style={{height:"18px",marginRight:"2rem"}} /></span>Insecure Deserialization </li>
        
        </ul>
        </div>
        <div className='col-lg-4 col-md-4 col-10 offset-1 mt-5 intershipdetaildiv2'>
        <h2 className='whatwewilllearnhead'>&#x20B9; 5400.00 <span style={{fontSize:"1.5rem",color:"grey"}}><strike>&#x20B9; 10999</strike></span></h2>
       
        <Link  to={{

          pathname: "/subscription",
          state: {previouslink:"/subscription"}
         }} style={{textDecoration:"none"}} > <div className='row'>
      <button className="enrollbtntwo mt-2">ENROLL NOW</button>
       </div> </Link>
       <div className='row'>
     <h2 className='importantdetailshead'>Important details</h2>
       </div> 
       <div className='row'>
    <div className='col-2'><MdLocalOffer size={20} color="white" style={{margin:"1rem"}} /></div>
    <div className='col-10'>  <h2 className='importantdetailshead2'>Exclusive Pricing:</h2>
    <p className='importantdetailspara'>For First 100 Users Only</p>
    </div>
         </div> 
       <div className='row'>
    <div className='col-2'><BsFillCalendarDayFill size={20} color="white" style={{margin:"1rem"}} /></div>
    <div className='col-10'>  <h2 className='importantdetailshead2'>Program duration:</h2>
    <p className='importantdetailspara'>6 Weeks Accelerator Program</p>
    </div>
         </div> 

         <div className='row'>
         <div className='col-2'><BiTime size={20} color="white" style={{margin:"1rem"}} /></div>
         <div className='col-10'>  <h2 className='importantdetailshead2'>Class Timings:</h2>
         <p className='importantdetailspara'>Uninterrupted on-demand learning</p>
         </div>
              </div>

            
                   <div className='row'>
     <h2 className='importantdetailshead'>Course Features</h2>

       </div>
       <div className='row'>
       <ul className='intershipbullet mt-1' style={{margin:"1rem"}}>
       <li className='internbulletpara'><span><img src={Record2} style={{height:"18px",marginRight:"2rem"}} /></span>Self Paced Learning</li>

       <li className='internbulletpara'><span><img src={Record2} style={{height:"18px",marginRight:"2rem"}} /></span>30 Real World Hacking Challenges</li>
       <li className='internbulletpara'><span><img src={Record2} style={{height:"18px",marginRight:"2rem"}} /></span>Course Videos</li>
       <li className='internbulletpara'><span><img src={Record2} style={{height:"18px",marginRight:"2rem"}} /></span>Course Materials</li>


       <li className='internbulletpara'><span><img src={Record2} style={{height:"18px",marginRight:"2rem"}} /></span>Doubt Clearing Through Mail</li>
       <li className='internbulletpara'><span><img src={Record2} style={{height:"18px",marginRight:"2rem"}} /></span>Access To PreyGround</li>
       <li className='internbulletpara'><span><img src={Record2} style={{height:"18px",marginRight:"2rem"}} /></span>Practice Lab & Exam Attempt</li>

       <li className='internbulletpara'><span><img src={Record2} style={{height:"18px",marginRight:"2rem"}} /></span>Participation Certificate</li>
       <li className='internbulletpara'><span><img src={Record2} style={{height:"18px",marginRight:"2rem"}} /></span>Global Ranking</li>
      {/* <li className='internbulletpara'><span><img src={Record2} style={{height:"18px",marginRight:"2rem"}} /></span>Global Ranking Through Leaderboard</li>*/}
     

       
 
       </ul>
       </div>
        </div>
      
       
        
        </div>
        </div>
        <div className='container mt-5'>
        <div className='row'>
        <div className='col-lg-12 col-md-12 col-12 mt-5 intershipdetaildiv2'>
        <h2 className='whatwewilllearnhead'>Requirements</h2>
        <hr className="coursehr"></hr>
        <ul className='intershipbullet mt-5' style={{margin:"1rem"}}>
        <li className='internbulletpara'><span><img src={Record} style={{height:"18px",marginRight:"2rem"}} /></span>System with minimum i5 processor or better</li>
        <li className='internbulletpara'><span><img src={Record} style={{height:"18px",marginRight:"2rem"}} /></span>At least 8 GB of RAM</li>
        <li className='internbulletpara'><span><img src={Record} style={{height:"18px",marginRight:"2rem"}} /></span>Basic knowledge of Linux & Javascript</li>
        <li className='internbulletpara'><span><img src={Record} style={{height:"18px",marginRight:"2rem"}} /></span>Working Internet Connection</li>
        <li className='internbulletpara'><span><img src={Record} style={{height:"18px",marginRight:"2rem"}} /></span>Dedication to Learn</li>



        </ul>
        </div>
        </div>
        </div>
        <div className='container mt-5'>
        <div className='row'>
        <div className='col-lg-12 col-md-12 col-12 mt-5 intershipdetaildiv2'>
       <div className='row justify-content-center' style={{position: "relative"}}>
       <h2 className='justify-content-center mainfaqhead'>FAQ'S</h2>
      
      <div className='row mt-5'>   <hr className='faqhr'></hr></div>
    

       
       </div>
       <div className='row justify-content-center '>
       <div className='col-lg-10 col-md-10 col mt-3'>
       <p className='mainfaqpara'>Please note that these FAQs provide general information about the WebHack program. For more detailed information and specific queries, it is recommended to reach out to us at <img src={email1} alt="email" style={{height:"30px",marginLeft:".3rem",marginTop:"-1px"}} /></p>
       </div>
      
       </div>
       
       
       <div className='row justify-content-center mt-5'>
       <div className='col-lg-10 col-md-10 col-10' >
    {Faqs2.map((faq,index)=>(
      <div className='row mt-5 mb-5'  key={index} id='faqcards'>
    
      <div className='col-lg-10 col-md-10 col-8'  onClick={() => toggle(index)} key={index} style={{cursor:"pointer"}}>
      <h2 className='faqcardshead'>{faq.question}</h2>
      </div>
      <div className='col-lg-1 col-md-1 col-1 offset-1'  onClick={() => toggle(index)} key={index} style={{cursor:"pointer"}}>
      {clicked===index ?  <AiOutlineMinusCircle color='#e32f1c' size={30} />  :<AiOutlinePlusCircle  color='#e32f1c' size={30} /> }
    
      </div>
      {clicked === index ? (
        <> <div className="container mt-1 p-3">
      <div className='row'>
      <div className='col-lg-11 col-md-11 col-11 '>
      <p className='faqansercard'>{faq.answer}</p>
      </div>
      </div>
      </div>
        
      
        
       
       
        </>  ) : null}
    
      </div>
    
      ))} 
      
  
      
       </div>
       </div>
        </div>
        </div>
        </div>
        </div>
       
    </div>
   
<Footer/>
    </div>
  )
}

export default Internshipdetail