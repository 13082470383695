import React,{useEffect,useState,useRef} from 'react'
import Navbar from '../Navbar/Navbar'
import "../Home/Home.css"
import Footer from '../Footer/Footer'
import mainimage from "../../Assets/Entity/University/Mainimage.png"
import curriculum from "../../Assets/Entity/University/Advantages/Asset1.png"
import industy from "../../Assets/Entity/University/Advantages/Asset2.png"
import employ from "../../Assets/Entity/University/Advantages/Asset3.png"
import Implemenation from "../../Assets/Entity/University/Advantages/Asset4.png"
import bulb from "../../Assets/Entity/University/USps.png"
import time from "../../Assets/Entity/University/OurlibraryIcons/hours.png"
import video from "../../Assets/Entity/University/OurlibraryIcons/videos.png"
import handson from "../../Assets/Entity/University/OurlibraryIcons/Labs.png"
import Topics from "../../Assets/Entity/University/OurlibraryIcons/Topics.png"
import excer from "../../Assets/Entity/University/OurlibraryIcons/Exercise.png"
import job from "../../Assets/Entity/University/OurlibraryIcons/JobRoles.png"
import contactus from "../../Assets/Entity/University/contactusuni.png";
import login from "../../Assets/Entity/University/universitylogin.png"
import AOS from 'aos';
import 'aos/dist/aos.css';
import { NavLink } from 'react-router-dom'
import { useForm } from "react-hook-form";
import Loading from '../Dashboard/Modals/Loading'
import Wentwrong from '../Dashboard/Modals/Wentwrong'
import Emailsentsuccessforunirequest from '../Dashboard/Modals/Emailsentsuccessforunirequest'

const University = () => {
  const divRef = useRef();
  const scrollToDiv = () => {
    divRef.current.scrollIntoView({ behavior: 'smooth' });
  }
  const {register,handleSubmit,formState: { errors },reset,watch,trigger,} = useForm();
  const[subscriber,setsubscriber]=useState({name:"",email:""})
  const[modalopen,setModalOpen]=useState(false);
  const[modalopen2,setModalOpen2]=useState(false);
  const[modalopen3,setModalOpen3]=useState(false);
  const API_Key = process.env.REACT_APP_API_URL;
   const onSubmit = (data) => {
    setModalOpen(true);
    reset();
    fetch(`${API_Key}/universities-request`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        mode:"cors",
                'Access-Control-Allow-Origin':`${API_Key}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': true
      },
      credentials:"include",
      body: JSON.stringify(data),
    })
    .then((response) => {
   
      if(response.status===200){
       setModalOpen2(true)
      setModalOpen(false);
      }
    //Then with the data from the response in JSON...
    
    })
    //Then with the error genereted...
    .catch((error) => {
      console.error('Error:', error);
      setModalOpen(false);
      setModalOpen3(true);
    });
     }
     const handleinput=(event)=>{
      event.preventDefault();
          setsubscriber((prevState) => ({
              ...prevState,
              [event.target.name]: event.target.value,
            }));
      
      }
    useEffect(() => {
        AOS.init();
      }, [])
      useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

  return (
    <>
    {modalopen && <Loading setOpenModal={setModalOpen}/> }  
    {modalopen2 && <Emailsentsuccessforunirequest setOpenModal={setModalOpen2}/> }  
    {modalopen3 && <Wentwrong setOpenModal={setModalOpen3}/> } 
    
    <section className="Dynamicbackground">
    <div className="container mt-5">
    <div class="Heading-main">
      <span className='spanleaderboard'>University</span>
      <h2>UNIVERSITY</h2>
      <hr className="offset-md-5 offset-4"></hr>
    </div>
    <div  className="row justify-content-center">
   
    </div>
  </div>
    </section>
    <div className="mainconsection">
<div className='container'>
<div className='row justify-content-center '>

<div className='col-lg-7 col-md-7 col-10 mt-5 p-5'    data-aos="fade-left" >

<span><div className='vertical'></div></span><h1 className='univerhead'>Join Us in shaping the future of your students in the rapidly expanding field of <b>cyber security</b></h1>
<div className='row p-4'>
<p className='univerpara'>HackersPrey is here to revolutionize the way universities approach
Cyber Security education. Providing students with an unparalleled
learning experience that empowers them to become the ethical
hackers of tomorrow.</p>
</div>
<div className='row pl-3'>
<button className='btn universitydemobtn' onClick={scrollToDiv}>Request Demo</button>
</div>

</div>
<div className='col-lg-5 col-md-5 col-10 mt-3 p-5' data-aos="fade-right">

<img src={mainimage} alt="image"/>
</div>
</div>
</div>
<div className='container mt-5'>
<div className='row justify-content-center mt-5'>
<div className='col-lg-7 col-md-7 col-10 mt-5'>
<h1 className='univerhead '>Academic Login</h1><hr className="universityhr ml-2"></hr> 
<p className='univerpara mt-3'> Experience exclusive access for students hailing from our esteemed partner academic institutions. Simply log in here using your official academic email IDs, and unlock seamless access to a wealth of resources and information tailored to your cybersecurity education. Your journey to becoming a cybersecurity expert starts now.</p>
<div className='row pl-4 mt-5'>
<NavLink to="/unilogin" style={{textDecoration:"none"}}><button className='btn universitydemobtn'>Login Here</button></NavLink>
</div>
</div>
<div className='col-lg-4 col-md-5 col-10 mt-5 offset-1'>
<img src={login} alt="alt"/>
</div>
</div>

</div>
<div className='container'>
<div className='row justify-content-center mt-5'>
<h1 className='univerhead2'>advantages to academic Partners</h1>
</div>
<div className=" row justify-content-center mt-2 ">
<hr className="universityhr"></hr> 

</div>
<br></br><br></br>
<div className='row mt-5 justify-content-center '>
<div className='col-lg-3 col-10 mt-5' data-aos="fade-left">

<div className='card' id='universityadavnatgecard'>
<div className='row justify-content-center' id='uniadvantegphotodic'>

<img src={curriculum}  style={{height:"12rem"}} id="check" alt="curriculu"/>
</div>
<div className='row justify-content-center' >

<h1 className='advantagehead'>Enhanced
Curriculum</h1>

</div>
<div className='row justify-content-center p-4' >

<p className='advantagepara '>Get access to top-notch content and practical challenges that align with industry standards, keeping students ahead of the curve in the ever-evolving field of ethical hacking.</p>

</div>
</div>

</div>
<div className='col-lg-3 col-10 mt-5' data-aos="fade-left">

<div className='card' id='universityadavnatgecard'>
<div className='row justify-content-center' id='uniadvantegphotodic'>

<img src={industy}  style={{height:"12rem"}} id="check"  alt="curriculu"/>
</div>
<div className='row justify-content-center ' >

<h1 className='advantagehead'>Industry Relevant
Skills</h1>

</div>
<div className='row justify-content-center p-4' >

<p className='advantagepara'>Empower students with real-world skills for the job market. Our courses bridge theory and practice, preparing graduates for real-world cybersecurity challenges. </p>

</div>
</div>

</div>
<div className='col-lg-3 col-10 mt-5' data-aos="fade-right">

<div className='card' id='universityadavnatgecard'>
<div className='row justify-content-center' id='uniadvantegphotodic'>

<img src={employ}  style={{height:"12rem"}}  id="check" alt="curriculu"/>
</div>
<div className='row justify-content-center' >

<h1 className='advantagehead'>Improved
Employability</h1>

</div>
<div className='row justify-content-center p-4' >

<p className='advantagepara'>Position your institution as a hub for producing job-ready ethical hackers, boosting your graduates employability. Graduates with practical skills and hands-on experience are highly sought after by employers.  </p>

</div>
</div>

</div>
<div className='col-lg-3 col-10 mt-5' data-aos="fade-right">

<div className='card' id='universityadavnatgecard'>
<div className='row justify-content-center' id='uniadvantegphotodic'>

<img src={Implemenation}  style={{height:"12rem"}}  id="check" alt="curriculu"/>
</div>
<div className='row justify-content-center' >

<h1 className='advantagehead'>Easy Implemenation</h1>

</div>
<div className='row justify-content-center p-4' >

<p className='advantagepara'>HackersPrey provide the necessary tools, resources, and support to ensure a smooth learning experience. Our user-friendly interface makes it easy for students to navigate the platform.</p>

</div>
</div>

</div>

</div>
</div>

<div className='container'>
<div className='row justify-content-center mt-5'>
<h1 className='univerhead2'>Our USP'S</h1>
</div>
<div className=" row justify-content-center mt-2 ">
<hr className="universityhr"></hr> 

</div>
<div className='row justify-content-center mt-5'>
<div className='col-lg-10 col-md-10 col-10 mt-5' data-aos="fade-up">
<img src={bulb} alt="usp"/>

</div>
</div>
</div>
<div className='container mt-5'>
<div className='row justify-content-center mt-5'>
<h1 className='univerhead2'>Our Library includes</h1>
</div>
<div className=" row justify-content-center mt-2 ">
<hr className="universityhr"></hr> 

</div>
<div className='row mt-5'>
<div className='col-lg-4 col-md-3 col-6' id='universitylibrarycard1'>
<div className='row justify-content-center mt-3'>
<img src={time} alt="time" style={{height:"6rem",marginTop:"2rem"}}/>

</div>
<div className='row justify-content-center mt-2 '>
<h1 id='unilibinchead'>10+</h1>

</div>
<div className='row justify-content-center mt-2 '>
<p id='unilibincpara'>Hours of Writing Content</p>

</div>

</div>
<div className='col-lg-4 col-md-3 col-6' id='universitylibrarycard1'>
<div className='row justify-content-center mt-3'>
<img src={video} alt="time" style={{height:"6rem",marginTop:"2rem"}}/>

</div>
<div className='row justify-content-center mt-2 '>
<h1 id='unilibinchead'>70+</h1>

</div>
<div className='row justify-content-center mt-2 '>
<p id='unilibincpara'>Videos</p>

</div>

</div>
<div className='col-lg-4 col-md-3 col-6' id='universitylibrarycard1'>
<div className='row justify-content-center mt-3'>
<img src={excer} alt="time" style={{height:"6rem",marginTop:"2rem"}}/>

</div>
<div className='row justify-content-center mt-2 '>
<h1 id='unilibinchead'>80+</h1>

</div>
<div className='row justify-content-center mt-2 '>
<p id='unilibincpara'>Practice Challenges</p>

</div>
</div>
<div className='col-lg-4 col-md-3 col-6' id='universitylibrarycard2'>
<div className='row justify-content-center mt-3'>
<img src={handson} alt="time" style={{height:"6rem",marginTop:"2rem"}}/>

</div>
<div className='row justify-content-center mt-2 '>
<h1 id='unilibinchead'>5</h1>

</div>
<div className='row justify-content-center mt-2 '>
<p id='unilibincpara'>Hands on Labs</p>

</div>
</div>
<div className='col-lg-4 col-md-3 col-6' id='universitylibrarycard2'>

<div className='row justify-content-center mt-3'>
<img src={Topics} alt="time" style={{height:"6rem",marginTop:"2rem"}}/>

</div>
<div className='row justify-content-center mt-2 '>
<h1 id='unilibinchead'>20+</h1>

</div>
<div className='row justify-content-center mt-2 '>
<p id='unilibincpara'>Topics</p>

</div>
</div>
<div className='col-lg-4 col-md-3 col-6' id='universitylibrarycard2'>

<div className='row justify-content-center mt-3'>
<img src={job} alt="time" style={{height:"6rem",marginTop:"2rem"}}/>

</div>
<div className='row justify-content-center mt-2 '>
<h1 id='unilibinchead'>20+</h1>

</div>
<div className='row justify-content-center mt-2 '>
<p id='unilibincpara'>Job Roles</p>

</div>

</div>
</div>
</div>
{/*<div className='container mt-5'>
<div className='row justify-content-center mt-5'>
<div className='col-lg-7 col-md-7 col-10 mt-5'>
<h1 className='univerhead '>Tailored Pricing Academy</h1><hr className="universityhr ml-2"></hr> 
<p className='univerpara mt-3'>We do our best to support cyber security education that's why we offer the flexibility to buy licenses at special prices. Drop us a message to learn more and get started!</p>
</div>
<div className='col-lg-3 col-md-5 col-10 mt-5 offset-2'>
 <button className='btn universitydemobtn2'>Request Demo</button>
</div>
</div>

  </div>*/}
  <br></br><br></br>
  <div className='container mt-5' ref={divRef}>
<div className='row justify-content-center mt-5'>
<h1 className='univerhead2'>Tailored Pricing Academy</h1>
</div>
<div className=" row justify-content-center mt-2 ">
<hr className="universityhr"></hr> 

</div>
<div className=" row justify-content-center mt-2 ">
<p className='univerpara mt-3'>We do our best to support cyber security education that's why we offer the flexibility to buy licenses at special prices. </p>

</div>
<div className='row mt-5'>
<div className='col-lg-7'>
<form className="newsletter-form p-5 mt-5" onSubmit={handleSubmit(onSubmit)}>
            
<div className="row" >
<br></br>
                
  <div className="col-11" >

    <input type="text" name='username' className={`form-control mb-2 ${errors.username && "invalid"}`}  placeholder="Enter Your Name" autoComplete='off' onChange={handleinput}
    {...register("username", { required: "Name is Required", pattern: {
      value: /^[a-zA-Z ]*$/,
      message: "Please Enter Valid Name",
    },})}
                        onKeyUp={() => {
                          trigger("name");
                        }}/>	 
                        {errors.username && (
                          <small className="errormes mb-4 ml-2 ">{errors.username.message}</small>
                        )}
  </div>
  <div className="col-11 mt-5" >

    <input type="text" name='academyname' className={`form-control mb-2 ${errors.academyname && "invalid"}`}  placeholder="Enter Name of Your Academic Institution" autoComplete='off' onChange={handleinput}
    {...register("academyname", { required: "Academic Name is Required", pattern: {
      value: /^[a-zA-Z ]*$/,
      message: "Please Enter Valid Name",
    },})}
                        onKeyUp={() => {
                          trigger("academyname");
                        }}/>	 
                        {errors.academyname && (
                          <small className="errormes ml-2">{errors.academyname.message}</small>
                        )}
  </div>
  
  <div className="col-11 mt-5" id="inputbox">

    <input type="text" className={`form-control mb-2 ${errors.email && "invalid"}`}  placeholder="Enter Your Email" name="email"  autoComplete='off' onChange={(e)=>setsubscriber(e.target.value)}  {...register("email", { required: "Email is Required" ,
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: "Invalid email address",
    }})}
    onKeyUp={() => {
      trigger("email");
    }}/>	 
    {errors.email && (
      <small className="errormes ml-2">{errors.email.message}</small>
    )}
  </div>
  <div className="col-11 mt-5" >

    <textarea type="text" name='descriptiion' className={`form-control mb-2 ${errors.descriptiion && "invalid"}`}  placeholder="Write To Us " autoComplete='off' onChange={handleinput}
    {...register("descriptiion", { required: "Description is Required", pattern: {
      value: /^[a-zA-Z ]*$/,
      message: "Please Enter Valid Name",
    },})}
                        onKeyUp={() => {
                          trigger("descriptiion");
                        }}/>	 
                        {errors.descriptiion && (
                          <small className="errormes ml-2">{errors.descriptiion.message}</small>
                        )}
  </div>

</div>
<div className="row justify-content-center mt-5">
<button className="btn Newsletterbtn">SUBMIT</button>
</div>
</form> 

</div>
<div className='col-lg-4 col-md-6 col-10 offset-1 mt-5'>
<img src={contactus} alt="contactus" style={{marginTop:"4rem"}}/>
</div>
</div>
</div>
    </div>

    <Footer/>
    </>
  )
}

export default University